<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="80px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="品名" label-width="60px">
              <el-input v-model="queryForm.goodsName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="HS CODE" label-width="80px">
              <el-input v-model="queryForm.hsCode">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="录入时间">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeS"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        :picker-options="editStartOptions"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeE"
                        type="datetime"
                        size="small"
                        :picker-options="editEndOptions"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="添加人">
              <f-select clearable filterable class="f-full-width"
                        :dict="'sys_user'"
                        v-model="queryForm.createBy"
                        :is-need="queryForm.createBy"
                        placeholder="请选择" style="width:100%">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          v-if="buttonShowList.add"
          >新增
        </el-button>
        <el-button
          :disabled="selectionRows.length === 0"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleBatDel()"
          v-if="buttonShowList.plsc"
          >批量删除
        </el-button>
        <el-upload
          v-if="buttonShowList.dr"
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="margin: 0 10px; display: inline"
        >
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button
          v-if="buttonShowList.mbxz"
          type="primary"
          size="medium"
          @click="downloadModel(url.downloadSuccessCaseExcel, '已清关案例模板')"
          >模板下载
        </el-button>
        <el-button size="medium" type="primary" icon="el-icon-download"
                   @click="exportExcelPlus(url.downloadExcel,'已清关品名')"
                   v-if="buttonShowList['dc']">导出已清关产品</el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList.bj"
              >编辑
            </el-button>

          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <edit-success-cleared-case-dialog
      :visible.sync="addVisible"
      :row="row"
      @ok="handleSearch"
    ></edit-success-cleared-case-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditSuccessClearedCaseDialog from "@/views/isystem/modules/EditSuccessClearedCaseDialog";


export default {
  name: "BdSuccessClearedCase",
  mixins: [QueryMixins],
  components: {
    EditSuccessClearedCaseDialog,
  },
  data() {
    return {
      day32:2764800000,
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeE) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(new Date(this.queryForm.createTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeE).getTime() - this.day32);
          }
        },
      },
      editEndOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeS) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(new Date(this.queryForm.createTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeS).getTime());
          }
        },
      },
      loading: false,
      addVisible: false,
      authVisible: false,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 90,
        },
        {
          prop: "goodsName",
          label: "品名",
          width: 100,
        },
        {
          prop: "country",
          label: "国家",
          width: 170,
        },
        {
          prop: "material",
          label: "材质",
          width: 170,
        },
        {
          prop: "purpose",
          label: "用途",
          width: 200,
        },
        {
          prop: "hsCode",
          label: "税号",
          width: 100,
        },
        {
          prop: "addtionalCode",
          label: "301编码",
          width: 100,
        },
        {
          prop: "taxRate",
          label: "税率",
          width: 100,
        },
        {
          prop: "unitValue",
          label: "申报单价",
          width: 100,
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },
        {
          prop: "createBy",
          label: "录入人",
        },
      ],
      url: {
        list: "/sys/bdSuccessClearedCase/list",
        deleteBatch: "/sys/bdSuccessClearedCase/deleteBatch",
        downloadSuccessCaseExcel: "/sys/bdSuccessClearedCase/downloadClearedCaseExcel",
        importSuccessCase: "/file/doc1/importBdSuccessClearedCase",
        downloadExcel: "/file/doc1/clearedCase",
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importSuccessCase, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row) {
      this.row = row;
      this.addVisible = true;
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '0', '新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '0', '批量删除'),
        'dr':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '0', '导入'),
        'mbxz':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '0', '模板下载'),
        'bj':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '1', '删除'),
        'dc':this.$btnIsShow('isystem/BaseData/BdSuccessClearedCase', '1', '导出'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
