<!--已承保-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <!-- 卡片化 -->
            <el-card class="box-card">
                <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="10" style="display: flex">
                        <el-form-item label="下单开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.insuredStartTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="下单结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.insuredEndTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作员"> <!--待提交 已拒绝-->
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'operationsId'"
                                      v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="被保险人">
                            <el-input v-model="queryForm.insured" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-dialog
                :title="'索赔'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="发件邮箱"
                        :label-width="formLabelWidth"
                        prop="esFromEmail"
                >
                    <el-input v-model="form.esFromEmail" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="邮箱密码"
                        :label-width="formLabelWidth"
                        prop="emailPassword"
                >
                    <el-input
                            v-model="form.emailPassword"
                            autocomplete="off"
                            type="password"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="邮件标题"
                        :label-width="formLabelWidth"
                        prop="emailTitle"
                >
                    <el-input v-model="form.emailTitle" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="邮件内容"
                        :label-width="formLabelWidth"
                        prop="emailContent"
                >
                    <el-input v-model="form.emailContent" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上传附件" :label-width="formLabelWidth">
                    <f-upload v-model="form.attachFile" ref="attachRef" :dir="'osOrder'"></f-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="sendMailAndSave()">确 定</el-button>
            </div>
        </el-dialog>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="BatchDown()"
                           :disabled="this.insuranceNolist.length=='0'">＋ 批量保单下载
                </el-button>
                <el-button type="primary" size="medium" @click="BatchGetPolicy()"
                           :disabled="this.insuranceNolist.length=='0'">＋ 刷新保单
                </el-button>
                <el-button type="primary" size="medium" @click="handleExportXls('保险已承保订单')"
                           v-if="buttonShowList.dc"
                >导出</el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="SuoPei(scope.row)">索赔</el-button>
                        <el-button type="text" @click="tuiBao(scope.row)" :disabled="!checkPolicy(scope.row.policyUrl)">退保</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'action2'">
                        <!-- <a
                                :href="scope.row.policyUrl"
                                target="_blank"
                                :disabled="checkPolicy(scope.row.policyUrl)"
                        >下载</a
                        > a标签无法禁用-->
                        <el-button type="text" :disabled="checkPolicy(scope.row.policyUrl)" @click="openOutPage(scope.row.policyUrl)">下载</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
                        <span v-if="scope.row.orderStatus === '0'">待提交</span>
                        <span v-else-if="scope.row.orderStatus === '1'">待审核</span>
                        <span v-else-if="scope.row.orderStatus === '2'">审核通过</span>
                        <span v-else-if="scope.row.orderStatus === '3'">审核拒绝</span>
                        <span v-else-if="scope.row.orderStatus === '4'">已承保</span>
                        <span v-else-if="scope.row.orderStatus === '5'">索赔中</span>
                        <span v-else>已索赔</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryType'">
                        <span v-if="scope.row.deliveryType === '1'">卡车派</span>
                        <span v-else-if="scope.row.deliveryType === '2'">快递派</span>
                        <span v-else>待定</span>
                    </template>
                    <template v-else-if="scope.column.property === 'insuredType'">
                        <span v-if="scope.row.insuredType === '0'">企业</span>
                        <span v-else>个人</span>
                    </template>
                    <template v-else-if="scope.column.property === 'insureProduct'">
            <span v-if="scope.row.insureProduct === '大森林货运险'"
            >PICC大森林货运险</span
            >
                        <span v-else>PICC到仓险</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {downFile, getAction, postAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    esFromEmail: [
                        {required: true, message: "请输入发件邮箱", trigger: "change"},
                    ],
                    emailPassword: [
                        {required: true, message: "请输入邮箱密码", trigger: "change"},
                    ],
                    emailTitle: [
                        {required: true, message: "请输入邮件标题", trigger: "change"},
                    ],
                    emailContent: [
                        {required: true, message: "请输入邮件内容", trigger: "change"},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 140,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        sortable: "custom",
                    },
                    {
                        prop: "insuranceNo",
                        label: "保单号",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "insuranceCompany",
                        label: "保险公司",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "insured",
                        label: "被保险人",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "insuredTime",
                        label: "投保时间",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "declareTotal",
                        label: "申报价值总额",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "feeRate",
                        label: "费率%",
                        sortable: "custom",
                        width: 140,
                    },
                    {
                        prop: "rmbPremium",
                        label: "保费",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "cur",
                        label: "保费币种",
                        sortable: "custom",
                        width: 150,
                    },
                    {
                        prop: 'insuranceOrderId',
                        label: 'PICC订单号',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: "action2",
                        label: "保单下载",
                        width: 160,
                    },
                ],
                url: {
                    list: "/order/insurance/list",
                    batchDown: "/order/insurance/batchDown", //批量下载保单
                    batchGetPolicy: "/order/insurance/batchGetPolicy", //刷新保单
                    sendMailAndSave: "/order/insurance/sendMailAndSave", //索赔
                    tuiBao: '/order/insurance/tuiBao', //退保
                    exportXlsUrl: '/file/insurance/dcOsOrderInsuranceExcelVO2',  //导出
                    // exportXlsUrl: '/file/export/excel/downloadInsuranceCommitExcel',  //导出
                },
                optionsSettlementType: [
                    {
                        value: "0",
                        label: "PICC大森林货运险",
                    },
                    {
                        value: "1",
                        label: "PICC到仓险",
                    },
                ],
                optionssupplierType: [
                    {
                        value: "0",
                        label: "企业",
                    },
                    {
                        value: "1",
                        label: "个人",
                    },
                ],
                emailList: [],
                insuranceNolist: [],
            };
        },
        methods: {
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.insuranceNolist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.insuranceNolist.push(selection[i]["orderNo"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            SuoPei(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.form.id = row.id;
                this.dialogFormVisible = true;
            },
            checkPolicy(text) {
                console.log(text);
                if (text == "null" || text == undefined || text == "") {
                    return true;
                }
                return false;
            },
            //批量保单下载
            BatchDown() {
                let flag = true;
                if (this.selectionRows && this.selectionRows.length > 0) {
                    this.selectionRows.forEach(s => {
                        let insureProduct = s.insureProduct;
                        if (insureProduct.indexOf('跨境') !== -1) {
                            // this.$message.warning('跨境堡的订单不支持批量下载，请单个下载');
                            flag = false;
                        }
                    })
                }
                if (!flag) {
                    this.$message.warning('跨境堡的订单不支持批量下载，请单个下载');
                    return false;
                }
                let param = {...this.queryParam};
                if (this.insuranceNolist && this.insuranceNolist.length > 0) {
                    param["selections"] = this.insuranceNolist.join(",");
                }
                console.log("导出参数", param);
                downFile(this.url.batchDown, param).then((data) => {
                    if (!data) {
                        this.$message.warning("文件下载失败");
                        return;
                    }
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(
                            new Blob([data], {type: "application/force-download"}),
                            "PICC保单.zip"
                        );
                    } else {
                        let url = window.URL.createObjectURL(
                            new Blob([data], {type: "application/force-download"})
                        );
                        let link = document.createElement("a");
                        link.style.display = "none";
                        link.href = url;
                        link.setAttribute("download", "PICC保单.zip");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link); //下载完成移除元素
                        window.URL.revokeObjectURL(url); //释放掉blob对象
                    }
                });
            },
            //刷新保单
            BatchGetPolicy() {
                getAction(
                    this.url.batchGetPolicy + "?selections=" + this.insuranceNolist.join(",")).then((res) => {
                    this.$message({
                        type: "info",
                        message: res.message,
                    });
                    this.handleSearch();
                });
            },
            //索赔
            sendMailAndSave() {
                if (this.form.attachFile) {
                    this.form.fileList = this.form.attachFile.map((i) => {
                        return i.id;
                    });
                }
                postAction(this.url.sendMailAndSave, this.form)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            //关闭弹窗
                            this.dialogFormVisible = false;
                            this.handleQuery();
                        }
                    })
            },
            //退保
            tuiBao(row) {
                this.$confirm(`确定退保`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.tuiBao, {orderNo:row.orderNo})
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery();
                            }
                        })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.pageType = "2";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            openOutPage(url){
              window.open(url,'_blank')
            },
        },
        created() {
            this.queryForm.pageType = "2";
        },
      computed:{
        buttonShowList(){
          return {
            'dc':this.$btnIsShow('operation/baoxian/AlreadyInsurance','1','导出'),


          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
