<!--带入仓-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="90px" size="medium">
      <div style="display: flex">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="5" :sm="24">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="客户">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.customer"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="销售">
                <f-select
                    dict="sys_user_id"
                    :multiple="true"
                    class="textarea_table"
                    v-model="queryForm.salesmanIds"
                    :is-need="queryForm.salesmanIds"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="4">
              <el-form-item label="销售组别">
                <f-select
                    v-model="queryForm.deptIdList"
                    :dict="'sys_depart'"
                    :isNeed="queryForm.deptIdList"
                    :multiple="true"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="审核原因">
                <f-select
                    dict="lose_money_reason"
                    class="textarea_table"
                    v-model="queryForm.auditReason"
                    :is-need="queryForm.auditReason"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="处理类型">
                <f-select
                    dict="lose_reason_handle_type"
                    class="textarea_table"
                    v-model="queryForm.handleType"
                    :is-need="queryForm.handleType"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="扣款承担比例">
                <el-input
                    v-model="queryForm.subRecRate"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
              <el-button
                  size="medium"
                  @click="
                  exportExcelPlus(
                    url.downloadSalemanProfitAuditExportXls,
                    '已审核订单明细'
                  )
                "
              >导出
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">
      <div>
      <el-button type="primary" size="medium" @click="handleBatBack" :disabled="selectedRowKeys.length === 0" v-if="buttonShowLIst.back">退回待审核</el-button>
      <el-button type="primary" size="medium" @click="handleEditAfterBatch" :disabled="selectedRowKeys.length === 0" v-if="buttonShowLIst.bjAfter">编辑核对后提成</el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
          </template>
         <template v-else-if="scope.column.property === 'afterCheckCommission'">
           <el-input v-model="scope.row.afterCheckCommission" type="number" v-if="scope.row.subRecRateStr === '空白'"></el-input>
           <span v-text="scope.row.afterCheckCommission" type="number" v-else></span>
         </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import { setUpRouteUtil } from "@/utils/util";
import {postAction} from "../../../api/manage";

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: [
        "is_default",
        "transportStatus",
        "order_importer",
        "bd_port",
        "orderStatus",
        "pay_type",
        "pglx",
        "sys_depart"
      ],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 100,
        },
        {
          prop: "salesman",
          label: "销售",
        },

        {
          prop: "customer",
          label: "客户",
        },
        {
            prop: "realCmb",
            label: "实际体积",
        },
        {
          prop: "realKg",
          label: "过磅重",
        },
        {
          prop: "cbmBi",
          label: "体积比",
        },
        {
          prop: "totalVolumeWeight",
          label: "体积重",
        },
        {
            prop: "countFeeNum",
            label: "计费数量",
        },
        {
          prop: "minStartNum",
          label: "渠道最低消费",
        },
        {
          prop: "profitRate",
          label: "利润率",
        },
        {
          prop: "lastSettleTime",
          label: "销账时间",
        },
        {
          prop: "auditReason",
          label: "亏本订单审核原因",
          minWidth: 100,
        },
        {
          prop: "handleType",
          label: "亏本订单处理类型",
          minWidth: 100,
        },
        {
          prop: "subRecRateStr",
          label: "扣款承担比例",
        },
        {
          prop: "afterCheckCommission",
          label: "核对后提成",
        },
        {
          prop: "loseRemark",
          label: "审核备注",
        },
        {
          prop: "auditMan",
          label: "审核人",
        },
        {
          prop: "auditTime",
          label: "审核时间",
          minWidth: 100,
        },
        {
          prop: "notes",
          label: "备注",
          minWidth: 100,
        },
        {
          prop: "toeLoseTime",
          label: "进入待审核时间",
          minWidth: 100,
        },
      ],
      url: {
        list: "finance/fee/settleCommission/profitAuditList",
        // exportXlsUrl: "/order/sale/enterWarehouseListExportXls",
        downloadSalemanProfitAuditExportXls: "/finance/fee/settleCommission/profitAudit/export",
        backToAuditBatch: "/finance/audit/backToAuditBatch",
        editAfterCommissionBatch: "/finance/audit/editAfterCommissionBatch",
      },
      initData: { isAudit: "1" },
      isUsePost:true
    };
  },
  methods: {
    handleEditAfterBatch() {
      this.$confirm("确定编辑核对后提成？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            let bb = this.selectionRows.map(s =>{
              return {orderNo : s.orderNo,afterCheckCommissionStr : s.afterCheckCommission}
            });
            postAction(this.url.editAfterCommissionBatch, bb).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => { });
    },
    handleBatBack() {
      this.$confirm("确定退回待审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            let nos = this.selectionRows.map(s => s.orderNo);
            postAction(this.url.backToAuditBatch, nos).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => { });
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether = "0";
      if (this.$refs["GTable"]) {
        this.$refs["GTable"].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether = "0";
  },
  computed:{
    buttonShowLIst() {
      return {
        'back':this.$btnIsShow('sale/loseMoney/alreadyAudit','1','退回待审核'),
        'bjAfter':this.$btnIsShow('sale/loseMoney/alreadyAudit','1','编辑核对后提成'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
