<template>
  <el-dialog :title="title" :visible="visible" :width="dialogWidth" append-to-body
             custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">

    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="loseReason" label="亏本原因">
            <el-input v-model="addForm.loseReason" maxlength="20" show-word-limit
                      placeholder="请输入亏本原因"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="handleType" label="处理类型">
            <f-select v-model="addForm.handleType" :is-need="addForm.handleType" dict="lose_reason_handle_type"
                      placeholder="请选择处理类型"></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="subRecRate" label="扣款承担比例">
            <el-input v-model="addForm.subRecRate" maxlength="20" show-word-limit
                      placeholder="请输入扣款承担比例"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button  type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {httpAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";


export default {
  name: 'EditFinLoseReasonDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },
    // 弹窗类型

    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      additionalTableData: [],
      totalTableData: [],
      dialogWidth: '',

      addForm: {},
      rules: {

        loseReason: [
          {required: true, message: '请输入亏本原因', trigger: 'change'},
        ],
        handleType: [
          {required: true, message: '请输入处理类型', trigger: 'change'},
        ],
        subRecRate: [
          {required: true, message: '请选择扣款承担比例', trigger: 'change'},
        ],
      },
      url: {
        add: '/finance/audit/loseReason/add',
        edit: '/finance/audit/loseReason/edit',

      },

    }
  },
  watch: {
    visible(val) {
      if (val && this.row) {
        this.addForm = JSON.parse(JSON.stringify(this.row));
      }
    }
  },
  created() {
  },
  methods: {
    handleSubmit() {

        let url = this.url.add
        if (this.row.id) {
          url = this.url.edit
        }
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            httpAction(url, 'POST', this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            })
          }
        })


    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
