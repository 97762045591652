<template>

    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="billForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="billForm.orderNo" @keyup.enter.native="handleCheck" type="textarea" placeholder="多个用逗号或者空格分隔"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户">
                            <el-input v-model="billForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'salesmanId'"
                                      v-model="billForm.salesmanId"
                                      :isNeed="billForm.salesmanId"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用币种">
                            <f-select
                                    v-model="billForm.moneyType"
                                    :isNeed="billForm.moneyType"
                                    dict="bd_cur"></f-select>
                        </el-form-item>
                    </el-col>

                    <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                    <!--                    <el-form-item label="收款员">-->

                    <!--                        <f-select placeholder="请选择收款人"-->
                    <!--                                  v-model="billForm.billman"-->
                    <!--                                  :is-need="billForm.billman"-->
                    <!--                                  dict="operations">-->
                    <!--                        </f-select>-->
                    <!--                    </el-form-item>-->
                    <!--                </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                    class="f-flex-1 f-flex-time"
                                    v-model="stime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStartOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>

                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                    class="f-flex-1 f-flex-time"
                                    v-model="otime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item  label="费用状态">
                            <el-select class="select_width" v-model="billForm.statusList" placeholder="请选择费用状态" :multiple="true"
                            clearable filterable>
                                <el-option value="0" label="未销账"></el-option>
                                <el-option value="1" label="已销账"></el-option>
                                <el-option value="2" label="部分销账"></el-option>
                                <el-option value="9" label="已申请"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item  label="费用类别">
                            <el-select class="select_width" clearable filterable v-model="billForm.settleType" placeholder="请选择费用类别">
                                <el-option value="0" label="海运"></el-option>
                                <el-option value="1" label="快递"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="7" v-if="billForm.settleType ==='1'">
                        <el-form-item  label="发货时间">
                            <el-date-picker
                                class="f-flex-1 f-flex-time"
                                v-model="goodGoTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用名称">
                            <el-input v-model="billForm.feename" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="数据时间">
                      <f-select
                          class="textarea_table"
                          v-model="billForm.dataCreateTime"
                          :is-need="billForm.dataCreateTime"
                          :dict="'data_create_time'"
                          @keyup.enter.native="handleSearch"
                      ></f-select>
                    </el-form-item>
                  </el-col>
<!--                    <el-col :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                        <el-form-item label="费用名">-->
<!--                            <f-select v-model="billForm.feeId" :dict="'bd_fee_type'" :is-need="billForm.feeId"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleCheck">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="downloadRecFee"
                           v-if="buttonShowList.dcexcel"
                >导出excel
                </el-button>
                <el-button size="medium" type="primary" @click="noConvertBill"
                           v-if="buttonShowList.plkpsq"
                >批量开票申请</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="64vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <!--                <template v-if="scope.column.property === 'action'">-->
                    <!--                    <el-button type="text" @click="handleEdit(scope.row)">推送地址</el-button>-->
                    <!--                    <el-button type="text" @click="handleUpsOrder(scope.row)">拆单</el-button>-->
                    <!--                    <el-button type="text" @click="handleRecovery(scope.row)">打单</el-button>-->
                    <!--                </template>-->
                    <!--                <template v-else-if="scope.column.property === 'solicitation'">-->
                    <!--                    <span>{{parseDict(scope.row.solicitation,'fba_solicitation')}}</span>-->
                    <!--                </template>-->
                    <!--                <template v-else-if="scope.column.property === 'transportStatus'">-->
                    <!--                    <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>-->

                    <!--                </template>-->

                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.realAgent}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'payType'">
                        {{parseDict(scope.row.payType,'cus_pay_type')}}
                    </template>
                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="yiBillApply(scope.row)"
                                   v-if="scope.row.invoiceStatus === '0' && buttonShowList.kpsq"
                        >开票申请
                        </el-button
                        >
                        <el-button type="text" @click="downloadApply(scope.row)"
                                   v-if="scope.row.invoiceStatus === '1' && buttonShowList.xzfp" style="color: green"
                        >下载发票
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'status'">
                        {{parseDict(scope.row.status,'settle_status')}}
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanId'">
                      <span>{{parseDict(scope.row.salesmanId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'docId'">
                      <span>{{parseDict(scope.row.docId,'sys_user_id')}}</span>
                    </template>

                </template>

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


        <no-convert-bill ref="noConvert" @ok="handleCheck"></no-convert-bill>
        <convert-bill ref="convert" @ok="handleCheck"></convert-bill>
    </div>


</template>
<script>
    import QueryMixins from "../../../../mixins/QueryMixins";
    import {getAction, postAction,httpAction} from "@/api/manage";
    import noConvertBill from "./noConvertBill"
    import convertBill from "./convertBill"
    import { setUpRouteUtil } from '@/utils/util';


    export default {
        mixins: [QueryMixins],
        components: {
            noConvertBill,
            convertBill
        },
        data() {
            return {
              disableMixinMounted:true,
                dictCodes: ['cus_pay_type','settle_status', 'sys_user_id'],
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                billForm:{},
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                    },
                    {
                        prop: "logistics",
                        label: "最新物流",
                        width: 140,
                    },
                    {
                        prop: 'realAgent',
                        label: '客户',
                    },
                    {
                        prop: 'payType',
                        label: '付款方式',
                    },
                    {
                        prop: 'agent',
                        label: '结算对象',
                    },
                    {
                        prop: 'status',
                        label: '费用状态',
                    },
                    {
                        prop: 'feename',
                        label: '费用中文',
                    },
                    {
                        prop: 'feenameUs',
                        label: '费用英文',
                    },
                    {
                        prop: 'price',
                        label: '价格',
                    },
                    {
                        prop: 'quantity',
                        label: '数量',
                    },
                    {
                        prop: 'moneyType',
                        label: '费用币种',
                    },
                    {
                        prop: "realMoneyType",
                        label: "折合币种",
                        width: 100
                    },
                    {
                        prop: "exchangeRate",
                        label: "汇率",
                    },
                    {
                        prop: "exchangeTotal",
                        label: "折合金额",
                    },
                    {
                        prop: 'total',
                        label: '合计',
                    },
                    {
                        prop: 'createBy',
                        label: '录入人',
                    },
                    {
                        prop: 'salesmanId',
                        label: '业务员',
                    },
                    {
                        prop: 'docId',
                        label: '客服',
                    },
                    {
                        prop: 'screateTime',
                        label: '录入时间',
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                    },
                    // {
                    //     prop: 'rate',
                    //     label: '汇率',
                    // },
                    // {
                    //     prop: 'rateMoney',
                    //     label: '折合金额',
                    // },

                ],
                isUsePost:true,
                url: {
                    // list: '/shipping/hySettleReceive/allReceiveFeeList',
                    list: '/shipping/hySettleReceive/allReceiveFeeListPost',
                    // exportAllReceiveFeeExcel:"/shipping/hySettleReceive/exportAllReceiveFeeExcel",
                    // exportAllReceiveFeeExcel:"/shipping/hySettleReceive/exportAllReceiveFeeExcelPost",
                    exportAllReceiveFeeExcel:"/file/export/excel/downloadAllYingshouExcel",
                    billApply:"/shipping/hySettleReceive/takeBillApply",
                    downloadApply:"/shipping/hySettleReceive/downloadApply",
                    batchInvoiceApply:"/shipping/hySettleReceive/batchInvoiceApply",
                },

                rules:{
                    realCtn: [
                        {required: true, message: '请输入实际总件数', trigger: 'blur'},
                        {pattern: /^[1-9]\d*$/, message: '只能输入整数', trigger: 'blur'},

                    ],
                    realKg: [
                        {required: true, message: '请输入实际总重量', trigger: 'blur'},
                        {pattern: /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/, message: '只能输入最多三位小数的数字', trigger: 'change'}
                    ],
                },
                stime:[],
                otime:[],
                goodGoTime:[],
            };
        },
        // computed: {
        //
        // },
        created() {
        this.isUsePost = true;
          this.$set(this.billForm,'dataCreateTime','3');
          },
        methods: {
               handleSizeChange(val) {
                  this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleCheck()
               },
                handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleCheck()
        },
            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            handleSelectionChange(selection) { // 多选
                // console.log(selection)
                let arr = []
                let nos = []
                this.nameList = []
                this.cnamelist = []
                this.emailList = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    nos.push(selection[i]['orderNo'])
                    this.nameList.push(selection[i]['username'])
                    this.cnamelist.push(selection[i]['cname'])
                    this.emailList.push(selection[i]['email'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
                this.selectedNos = [...nos]
            },
            handleReceive(){
                // console.log("申请收款以下id的费用",this.selectedRowKeys)
            },
            handleReset() {
                this.billForm = {}
                this.otime = ''
                this.stime = ''
                this.goodGoTime = ''
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleCheck() {
                this.loading = true
                if (this.otime && this.otime.length>1){
                    this.billForm.createTimeS = this.otime[0]
                    this.billForm.createTimeE = this.otime[1]
                }else{
                    this.billForm.createTimeS = '';
                    this.billForm.createTimeS = '';
                }
                if (this.stime && this.stime.length>1) {
                    this.billForm.sCreateTimeS = this.stime[0]
                    this.billForm.sCreateTimeE = this.stime[1]
                }else{
                    this.billForm.sCreateTimeS = '';
                    this.billForm.sCreateTimeE = '';
                }
                if (this.goodGoTime && this.goodGoTime.length>1){
                    this.billForm.deliverGoodsTimeS = this.goodGoTime[0]
                    this.billForm.deliverGoodsTimeE = this.goodGoTime[1]
                }else{
                    this.billForm.deliverGoodsTimeS = '';
                    this.billForm.deliverGoodsTimeE = '';
                }
                let form = {...this.billForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.billForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.billForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                // console.log("查询对象", params)
              let method = "GET";
                if (this.isUsePost){
                  method = "POST";
                }
                httpAction(this.url.list,method, params).then((res) => {
                    if (res.success) {
                        const {records, total} = res.result
                        this.tableData = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            // noConvertBill(){
            //     console.log("申请收款以下id的费用",this.selectedRowKeys)
            //     if (this.selectedRowKeys.length === 0){
            //         this.$message.warning("请勾选费用")
            //         return
            //     }
            //
            //     let countCur = [];
            //     this.selectionRows.forEach(item => {
            //         countCur.push(item.moneyType)
            //     });
            //     let curArr = Array.from(new Set(countCur));
            //     console.log('curArr->',curArr)
            //     if(curArr.length > 1){
            //         this.$message.warning("所选费用的费用币种不一致！")
            //         return
            //     }
            //
            //     let count = [];
            //     this.selectionRows.forEach(item => {
            //         count.push(item.settleId)
            //     });
            //     let arr = Array.from(new Set(count));
            //     if(arr.length > 1){
            //         this.$message.warning("所选费用的结算对象不一致！")
            //         return
            //     }
            //     this.$refs.noConvert.edit(this.selectedRowKeys,this.selectedNos)
            // },
            convertBill(){
                // console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }
                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                // console.log(Array.from(new Set(count)))
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos)
            },
            downloadRecFee(){
                // this.loading = true
                if (this.otime && this.otime.length>1){
                    this.billForm.createTimeS = this.otime[0]
                    this.billForm.createTimeE = this.otime[1]
                }else{
                    this.billForm.createTimeS = '';
                    this.billForm.createTimeS = '';
                }
                if (this.stime && this.stime.length>1) {
                    this.billForm.sCreateTimeS = this.stime[0]
                    this.billForm.sCreateTimeE = this.stime[1]
                }else{
                    this.billForm.sCreateTimeS = '';
                    this.billForm.sCreateTimeE = '';
                }
                let form = {...this.billForm, ...this.filterForm}

                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                // console.log("开始下载");

                let filename = this.getDateStr() + '导出所有应收费用'

                let url = this.url.exportAllReceiveFeeExcel

                postAction(url, params, {responseType: 'blob'})
                    .then(res => {
                        // console.log(res)
                        if (res.size === 0) {
                            this.$message.error('导出失败，请稍后再试或联系管理员')
                            this.visible = false
                            return
                        }
                        var binaryData = [];
                        binaryData.push(res);

                        let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', filename + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象

                        this.visible = false
                        setTimeout(() => {   //设置延迟执行
                            this.$message.success("导出成功！")
                            // this.$emit('ok')
                            this.handleCheck();
                        }, 3000)
                    })
            },
            //开票申请
            yiBillApply(obj){
                // console.log(obj);
                this.$confirm('确定前往易票云进行开票？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {

                    postAction(this.url.billApply,obj).then((res) => {
                        // console.log(res);
                        //this.invoiceInfoList = res.result;   this.tableData
                        if (res.code === 200) {
                            this.$message.success("开票成功！");
                            // this.handleQuery();
                            this.handleCheck
                            // this.$emit('ok')
                        }
                    })
                }).catch(res => {
                    this.$message.error(res.message);
                })
            },
            //下载发票
            downloadApply(obj){
                // console.log(obj);
                var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                postAction(this.url.downloadApply, obj).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;
                    let applypdfurl=res.result;
                    // window.open(applypdfurl,"_blank");
                    newWindow.location.href =applypdfurl;
                });
            },
            noConvertBill() {
                // console.log("申请收款以下id的费用", this.selectionRows);
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }
                // this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);

                //批量开票申请
                this.$confirm('确定前往易票云进行开票？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {

                    postAction(this.url.batchInvoiceApply,this.selectionRows).then((res) => {
                        // console.log(res);
                        //this.invoiceInfoList = res.result;   this.tableData
                        if (res.code === 200) {
                            this.$message.success("开票成功！");
                            this.handleCheck();
                            // this.$emit("ok")
                        }
                    })
                }).catch(res => {
                    this.$message.error(res.message);
                })

            },
        },
        computed:{
          buttonShowList(){
            return {
              'dcexcel':this.$btnIsShow('hyFinanceManage/modules/receive/allReceiveFee','0','导出excel'),
              'plkpsq':this.$btnIsShow('hyFinanceManage/modules/receive/allReceiveFee','0','批量开票申请'),
              'kpsq':this.$btnIsShow('hyFinanceManage/modules/receive/allReceiveFee','1','开票申请'),
              'xzfp':this.$btnIsShow('hyFinanceManage/modules/receive/allReceiveFee','1','下载发票'),
            }
          }
        }
    };
</script>
