<template>
    <el-dialog
            title="退仓用车详情"
            :visible="visible"
            width="40%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        基本信息
        <div style="text-align: right">
          <el-button type="primary" @click="editTakeInfo" style="margin-right: 5px">确认修改</el-button>
          <el-button type="primary" @click="clickCopy">复制用车信息</el-button>
        </div>

        <el-form class="addEditBox" :model="form" label-width="100px" size="medium">


            <el-col :span="12">
                <el-form-item prop="takeNo" label="用车编号" :label-width="formLabelWidth">
                    <span v-text="form.takeNo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="ctnStr" label="件重体" :label-width="formLabelWidth">
                    <span v-text="form.ctnStr"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="originatingWarehouseId" label="起运仓库" :label-width="formLabelWidth">
                    <f-select v-model="form.originatingWarehouseId" :is-need="form.originatingWarehouseId"
                      :dict="'delivery_point'"></f-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="carTypeStr" label="车辆类型" :label-width="formLabelWidth">
                    <span v-text="form.carTypeStr"></span>
                </el-form-item>
            </el-col>

          <el-col :span="12">
            <el-form-item prop="arriveAtWarehouseId" label="派送地址" :label-width="formLabelWidth">
              <el-input v-model="form.shipToAddress" type="textarea" maxlength="250"></el-input>
            </el-form-item>
          </el-col>

            <el-col :span="12">
                <el-form-item prop="takeTime" label="用车时间" :label-width="formLabelWidth">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="form.takeTime"
                      type="datetime"
                      size="small"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="statusStr" label="用车状态" :label-width="formLabelWidth">
                    <span v-text="form.statusStr"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="truckingTime" label="派车时间" :label-width="formLabelWidth">
                    <span v-text="form.truckingTime"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="supplier" label="供应商" :label-width="formLabelWidth">
                    <span v-text="form.supplier"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="driverInfo" label="司机信息" :label-width="formLabelWidth">
                    <span v-text="form.driverInfo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="truckingNo" label="派车单号" :label-width="formLabelWidth">
                    <span v-text="form.truckingNo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="useCarTypeStr" label="派车类型" :label-width="formLabelWidth">
                    <span v-text="form.useCarTypeStr"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="workTypeStr" label="车辆作业性质" :label-width="formLabelWidth">
                    <span v-text="form.workTypeStr"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="outNo" label="出库编码" :label-width="formLabelWidth">
                    <span v-text="form.outNo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="doc" label="海运客服" :label-width="formLabelWidth">
                    <span v-text="form.doc"></span>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="remark" label="用车备注" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" type="textarea" maxlength="250" ></el-input>
                </el-form-item>
            </el-col>

        </el-form>

      <div style="margin-bottom:21.56px">订单信息</div>
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-row :gutter="20" class="flex_center" style="width:100%">
          <el-col :span="10">
          <el-form-item label="订单号" style="margin-bottom: 0;">
            <el-input v-model="queryForm.orderNo" placeholder="多订单号" type="textarea"
                      @keyup.enter.native="queryTakeDetail"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-search" @click="queryTakeDetail">查询</el-button>
            <el-button
                type="primary"
                size="medium"
                @click="openAddDialog"
            >加货
            </el-button>
            <el-button
                type="primary"
                size="medium"
                @click="tihuoBatch"
                :disabled="selectionRows.length===0"
            >踢货
            </el-button>
          </el-col>
          <el-col  :span="7">总计件重体：{{totalCtn}} / {{totalKg}} / {{totalCmb}}</el-col>
        </el-row>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="form.orderList"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
            :cell-style="{ 'text-align': 'center' }"
            tableHeight="30vh"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'orderNo'">
              <a
                  style="color: #00a0e9"
                  @click.prevent="seeDetail(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
              >
            </template>
            <template v-else-if="scope.column.property === 'ctn'">
              <el-input v-model="scope.row.ctn" type="number"></el-input>
            </template>
            <template v-else-if="scope.column.property === 'kg'">
              <el-input v-model="scope.row.kg" type="number"></el-input>
            </template>
            <template v-else-if="scope.column.property === 'cmb'">
              <el-input v-model="scope.row.cmb" type="number"></el-input>
            </template>
          </template>
        </f-table>

      </el-form>

        <span slot="footer">
            <el-button size="medium" @click="handleClose">关闭</el-button>
<!--            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>-->
        </span>
      <addtc-order-detail-list-module @ok="closeAddDialog" :visible="addTakeOrderDetailVisible" :take-id="selectedId"></addtc-order-detail-list-module>
    </el-dialog>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins";
    import {getAction,postAction, putAction} from "@/api/manage";
    import addtcOrderDetailListModule from "./AddtcOrderDetailListModule.vue";

    export default {
        name: 'zcOrderDetail',
        mixins: [QueryMixins],
        components: {addtcOrderDetailListModule},
        props: {
            visible: {
                type: Boolean
            },
            takeIds:{
                type:String,
                default:''
            },

        },
        data() {
            return {
              isEdit:false,
                addTakeOrderDetailVisible:false,
                selectedId:'',
                disableMixinMounted:true,
                form: {orderList:[]},
                formLabelWidth:'100px',
                columns: [
                  {
                    type: "selection",
                    width: "33",
                  },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },

                    {
                        prop: "ctn",
                        label: "件数",
                    },
                  {
                    prop: "kg",
                    label: "重量",
                  },
                  {
                    prop: "cmb",
                    label: "体积",
                  },
                    {
                        prop: "warehouseName",
                        label: "订单的交货仓库",
                    },

                ],
                url: {
                    //查询详情
                    getTakeOrderDetailById:'/warehouse/takeDelivery/getTakeOrderDetailById',
                  //查询订单列表
                    getTakeDetailByTakeId:'/warehouse/takeDelivery/getTakeDetailByTakeId',
                  tihuo:'/warehouse/takeDelivery/tihuoBatch',
                  editTake:'/warehouse/takeDelivery/editTcDeliveryById',
                },
              totalCtn:0,
              totalKg:0,
              totalCmb:0
            }
        },
        watch: {
            visible(val) {
                if (val && this.takeIds) {
                  this.queryForm.takeId = this.takeIds;
                    //勾选的订单号进行赋值
                    this.init();
                }
            },
        },
        methods: {
          copyInfo(id){
            getAction(this.url.getTakeOrderDetailById, {
              takeIds: id,
            }).then((res) => {
              console.log(res);
              if (res.success) {
                this.form = JSON.parse(JSON.stringify(res.result));
                // this.form.addressDetail = this.form.address + ',' + this.form.contact + "," + this.form.contactPhone;
                this.clickCopy();
              }
            });
          },
          doCopy(val) {
            let _this = this;
            this.$copyText(val).then(() => {
              _this.$message({
                message: '复制成功，可以使用ctrl+v快捷键粘贴',
                type: 'success'
              });
            }).catch(e => {
              _this.$message.error('复制失败');
            })
          },
          clickCopy() {
            let totalCtn = 0;
            let totalKg = 0;
            let totalCmb = 0;
            let takeNo = this.form.takeNo || '';
            let createBy = this.form.createBy || '';
            let originatingWarehouseName = this.form.originatingWarehouseName || '';
            let shipToAddress = this.form.shipToAddress || '';
            let shipToContact = this.form.shipToContact || '';
            if (shipToContact) {
              shipToContact = "," + shipToContact;
            }
            let shipToPhone = this.form.shipToPhone || '';
            if (shipToPhone) {
              shipToPhone = "/" + shipToPhone;
            }
            let carTypeStr = this.form.carTypeStr || '';
            let useCarTypeStr = this.form.useCarTypeStr || '';
            let statusStr = this.form.statusStr || '';
            let takeTime = this.form.takeTime || '';
            let truckingTime = this.form.truckingTime || '';
            let supplier = this.form.supplier || '';
            let driverInfo = this.form.driverInfo || '';
            let truckingNo = this.form.truckingNo || '';
            let address = this.form.address || '';
            let contact = this.form.contact || '';
            let remark = this.form.remark || '';
            let workTypeStr = this.form.workTypeStr || '';
            let outNo = this.form.outNo || '';
            let doc = this.form.doc || '';
            let content = '用车编号：' + takeNo + '\n'
                + '申请人：' + createBy + '\n'
                + '起运仓库：' + originatingWarehouseName + '\n'

                + '派送地址：' + shipToAddress + shipToContact + shipToPhone + '\n'
                + '用车时间：' + takeTime + '\n'

                + '用车备注：'+ remark +'\n';
            let list = this.form.orderList;
            if (list && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                let obj = list[i];
                content = content +
                    +obj.orderNo + ' ' + obj.ctn + '件 ' + obj.kg + 'kg ' + obj.cmb + '方 '  + obj.channelName + '\n'
                totalCtn += Number(obj.ctn);
                totalKg += Number(obj.kg);
                totalCmb += Number(obj.cmb);
              }
            }
            content = content + "合计： " + totalCtn + "件 " + totalKg.toFixed(3) + "kg " + totalCmb.toFixed(3) +"方 ";
            this.doCopy(content);
          },
          init(){
            getAction(this.url.getTakeOrderDetailById,{takeIds:this.takeIds}).then((res) =>{
              console.log(res);
              if(res.success){
                this.form = JSON.parse(JSON.stringify(res.result));
                // this.form.addressDetail = this.form.address + ',' + this.form.contact + "," + this.form.contactPhone;
                this.caculate();
              }
            });
          },
          editTakeInfo(){
            let params = {
              takeId:this.takeIds,
              takeTime:this.form.takeTime,
              remark:this.form.remark,
              originatingWarehouseId:this.form.originatingWarehouseId,
              arriveAtWarehouseId:this.form.arriveAtWarehouseId,
              shipToAddress:this.form.shipToAddress
            };
            putAction(this.url.editTake,params)
                .then(res=>{
                  this.$message.success(res.message);
                  this.init();
                })
          },
          closeAddDialog(){
            this.addTakeOrderDetailVisible = false;
            this.queryTakeDetail();
          },
          openAddDialog(){
            this.selectedId = this.takeIds;
            this.addTakeOrderDetailVisible = true;
          },
          tihuoBatch(){
            let ids = this.selectionRows.map(s=>s.id);
            let orderIds = this.selectionRows.map(s=>s.orderId);
            putAction(this.url.tihuo,{
              orderIds:orderIds,
                  ids:ids,
              type:'2'
            }
            )
                .then(res => {
                  this.$message.success(res.message);
                  this.queryTakeDetail();
                })
          },
          queryTakeDetail(){
            postAction(this.url.getTakeDetailByTakeId,this.queryForm)
                .then(res=>{
                  this.form.orderList = res.result;
                  this.caculate();
                })
          },
          caculate(){
            this.totalCtn = 0;
            this.totalKg = 0;
            this.totalCmb = 0;
            let list = this.form.orderList;
            if (list && list.length > 0) {
              list.forEach(l=>{
                let ctn = l.ctn;
                let kg = l.kg;
                let cmb = l.cmb;
                if (ctn) {
                  this.totalCtn += Number(l.ctn);
                }
                if (kg) {
                  this.totalKg += Number(l.kg);
                }
                if (cmb) {
                  this.totalCmb += Number(l.cmb);
                }
              })
              this.totalKg = this.totalKg.toFixed(3);
              this.totalCmb = this.totalCmb.toFixed(3);
            }
          },
            seeDetail(orderNo){
                this.handleClose();
                this.$intoDocPage(orderNo);
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                //不需要刷新页面
                this.$emit('ok');
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
        height: 70vh;
        overflow: scroll;
    }
    .addEditBox {
      max-height: 615px;
    }
</style>
