<template>
  <div>
    <el-card class="box-card">
      <el-form
        class="multiline_select"
        ref="form"
        :model="queryForm"
        label-width="80px"
        size="medium"
      >
        <el-card class="box-card">
          <el-row>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearchHasTime"
                  type="textarea"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="交货仓库">
                <f-select
                  :dict="'delivery_point'"
                  :is-need="queryForm.deliveryPoint"
                  v-model="queryForm.deliveryPoint"
                  autocomplete="off"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="拼柜仓库">
                <f-select
                  v-model="queryForm.lclPlace"
                  :dict="'lclPlace'"
                  :is-need="queryForm.lclPlace"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="货物所在仓">
                <f-select
                  :dict="'delivery_point'"
                  :is-need="queryForm.volumeLocationId"
                  v-model="queryForm.volumeLocationId"
                ></f-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="渠道">
                <f-select
                  :dict="'transportStatus'"
                  v-model="queryForm.channelId"
                  :is-need="queryForm.channelId"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="国家">
                <f-select
                  v-model="queryForm.shipmentCountry"
                  :is-need="queryForm.shipmentCountry"
                  :dict="'bd_country'"
                  @keyup.enter.native="handleSearchHasTime"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="排柜操作">
                <f-select
                  v-model="queryForm.operateId"
                  :is-need="queryForm.operateId"
                  :dict="'sys_user_id'"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客服">
                <f-select
                  v-model="queryForm.docId"
                  :isNeed="queryForm.docId"
                  :dict="'sys_user_id'"
                >
                </f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="材积录入人">
                <f-select
                  v-model="queryForm.createByS"
                  :isNeed="queryForm.createByS"
                  :dict="'sys_user_id'"
                  @changetAllData="changetCreateBy"
                >
                </f-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="fbaCode">
                <el-input
                  v-model="queryForm.fbaCode"
                  @keyup.enter.native="handleSearchHasTime"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="创建时间">
                 <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeS"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeE"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="到仓时间">
                 <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoArrivedTimeS"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoArrivedTimeE"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label-width="30px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearchHasTime"
                  >查询
                </el-button>
                <el-button plain @click="handleResetS">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <div class="bbb">
        <el-button type="primary" size="medium" @click="exportExcelPlusFn"
        v-if="buttonShowList.dc"
          >导出
        </el-button>
        <el-button type="primary" size="medium" @click="InitiateTransfer" :disabled='!selectionRows.length' v-if="buttonShowList.fqzc"
          >发起转仓
        </el-button>
        <el-button type="primary" size="medium" @click="()=>changeVisible=true" :disabled='!selectionRows.length' v-if="buttonShowList.xgszc"
          >修改所在仓
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
          </template>
          
        </template>
        
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      title="修改所在仓"
      :visible="changeVisible"
      width="30%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="handleClose"
    >
      <el-form
        :model="formWarehouse"
        :rules="rulesWarehouse"
        ref="formWarehouse"
        label-width="94px"
        size="medium"
      >
      <el-form-item prop="volumeLocationId" label="货物所在仓:">
        <f-select :dict="'delivery_point'" :is-need="formWarehouse.volumeLocationId" v-model="formWarehouse.volumeLocationId" autocomplete="off" @changetAllData="changetLocation"></f-select>
            </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="changeWarehouse"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <initiate-transfer-dialog  :visible="visible"  @ok="()=>visible = false" :rows='selectionRows'/>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { putAction, getAction ,postAction} from "@/api/manage";
import InitiateTransferDialog from "./modules/InitiateTransferDialog.vue";
export default {
  name: "NormalOutWarehouse",
  mixins: [QueryMixins],
  components: {
    InitiateTransferDialog,
  },
  data() {
    return {
      isUsePost: true,
      row: {},
      columns: [
        {
          type: "selection",
        },
        // {
        //     prop: "action",
        //     label: "操作",
        // },
        {
          prop: "orderNo",
          label: "订单号",
          width: 130,
        },
         {
          prop: "withinOrderRemark",
          label: "客户单号",
          width: 130,
        },
        // {
        //     prop: "isLimit",
        //     label: "是否受限制",
        // },
        // {
        //     prop: "lclPlaceName",
        //     label: "拼柜仓库",
        // },
        {
          prop: "deliveryPointName",
          label: "交货仓库",
        },
        {
          prop: "lclPlaceName",
          label: "拼柜仓库",
        },
        {
          prop: "volumeLocation",
          label: "货物所在仓",
        },
       
        {
          prop: "cargoArrivedTime",
          label: "到仓时间",
        },
        {
          prop: "totalNum",
          label: "件数",
        },
        {
          prop: "totalWeight",
          label: "实重",
        },
        {
          prop: "totalVolume",
          label: "体积",
        },
        {
          prop: "channelName",
          label: "渠道",
        },
        {
          prop: "shipmentCountry",
          label: "国家",
        },
        {
          prop: "fbaCode",
          label: "fbaCode",
        },
        {
          prop: "operator",
          label: "排柜操作",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "createBy",
          label: "材积录入人",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },
        // {
        //   prop: "action",
        //   label: "操作",
        //   minWidth: 100,
        // },
      ],
      url: {
        //查询列表的接口
        list: "/warehouse/volume/list",
        exportExcelUrl: "/file/export/excel/volumeLocation/export",
        updateVolumeLocation:"/interface/ttOrder/updateVolumeLocation"
      },
      visible: false,
      changeVisible: false,
      formWarehouse:{},
                rulesWarehouse:{
                     volumeLocationId: [
                        {required: true, message: '请选择货物所在仓', trigger: 'blur'},

                    ],
      },
      disableMixinMounted:true,
    };
  },
  methods: {
    handleSearchHasTime() {
      this.ipagination.currentPage = 1;
      this.handleSearch();
    },
    exportExcelPlusFn() {
      if (
        !this.queryForm.orderNo &&
        !this.queryForm.deliveryPoint &&
        !this.queryForm.lclPlace &&
        !this.queryForm.volumeLocationId &&
        !this.queryForm.channelId &&
        !this.queryForm.shipmentCountry &&
        !this.queryForm.operateId &&
        !this.queryForm.docId &&
        !this.queryForm.createBy &&
        !this.queryForm.fbaCode &&
        !this.createTimeS && ! this.createTimeE && !this.cargoArrivedTimeS && ! this.cargoArrivedTimeE
      ) {
        this.$message({
          type: "warning",
          message: "请输入搜索条件后再操作导出",
        });
        return;
      }
      this.exportExcelPlus(this.url.exportExcelUrl, "常规出库数据");
    },
    InitiateTransfer() {
      this.visible = true
    },
    handleClose() {
      this.changeVisible = false
    },
     changeWarehouse(){
      
                  this.$refs.formWarehouse.validate((valid) => {
                    if(valid){
                  const params = {
                    ids:this.selectionRows.map(item => item.volumeIdList).reduce((acc, curr) => acc.concat(curr), []),
                    volumeLocationId:this.formWarehouse.volumeLocationId,
                    volumeLocation:this.formWarehouse.volumeLocation
                }
                postAction(this.url.updateVolumeLocation, params).then((res) => {
                    if(res.success){
                      this.changeVisible=false
                      this.formWarehouse={}
                      this.handleQuery()
                    }
                });
                    }
                  })
                
            },
             changetLocation(e){
                if(e && e.title){
                    this.formWarehouse.volumeLocation = e.title
                }else{
                    this.formWarehouse.volumeLocation = ''
                }
            },
            changetCreateBy(e){
                if(e && e.title){
                    this.queryForm.createBy = e.title
                }else{
                    this.queryForm.createBy = ''
                }
            },
            handleResetS(){
              this.handleReset()
            }
  },
  computed:{
    buttonShowList() {
      return {
        'xgszc':this.$btnIsShow('warehouse2.0/ModifyWarehouse','0','修改所在仓'),
        'fqzc':this.$btnIsShow('warehouse2.0/ModifyWarehouse','0','发起转仓'),
        'dc':this.$btnIsShow('warehouse2.0/ModifyWarehouse','0','导出'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
