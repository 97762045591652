<!--待收货-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row style="display: flex;flex-wrap: wrap;">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input class="textarea_table" v-model="queryForm.orderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="运单号">
                            <el-input class="textarea_table" v-model="queryForm.runOrderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单开始时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <!--    <f-select v-model="queryForm.cusName" :dict="'sys_customer'" :is-need="queryForm.cusName"></f-select>-->
                            <el-input class="textarea_table" v-model="queryForm.cusName"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanId" :dict="'salesmanId'"
                                      :is-need="queryForm.salesmanId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select v-model="queryForm.docId" :dict="'docId'" :is-need="queryForm.docId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否购买保险">
                            <f-select v-model="queryForm.insurance" :dict="'is_default'"
                                      :is-need="queryForm.insurance"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="配送方式">
                            <f-select v-model="queryForm.deliveryType" :dict="'wh_type'"
                                      :is-need="queryForm.deliveryType"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :multiple="true" v-model="selectChannelList" :dict="'kd_channel'" :isNeed="selectChannelList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货运类型">
                            <f-select v-model="queryForm.goodType" :dict="'kd_good_type'" :isNeed="queryForm.goodType"></f-select>
                        </el-form-item>
                    </el-col>

                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                       <el-form-item label="交货仓库" prop="deliveryWarehouse">
                            <f-select  clearable filterable
                                      :dict="'delivery_point'"
                                      v-model="queryForm.deliveryWarehouse"
                                      :isNeed="queryForm.deliveryWarehouse"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                              <el-form-item label="送货/提货开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliveryStartTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="送货/提货开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                        </el-col>


                         <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                              <el-form-item label="送货/提货结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliveryEndTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="送货/提货结束时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                        </el-col>

                         <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="自主飞">
                      <f-select
                          v-model="queryForm.isAutoFly"
                          :dict="'is_default'"
                          :isNeed="queryForm.isAutoFly"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="国家">
                      <f-select v-model="queryForm.countryList" :multiple="true" :dict="'bd_country'" :isNeed="queryForm.countryList"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchM">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
             <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="ConfirWarehouse" :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.qrdc">确认到仓
                </el-button>
                 <el-button type="primary" size="medium" @click="handleIsAutoFly" :disabled="!selectionRows.length"
                           v-if="buttonShowList.zzf">批量修改自主飞
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="67vh"
                    :isRecordTableScroll="true"
                    :show-operation="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleProblemdialogFormVisible(scope.row)"
                                   v-if="buttonShowList.bjwtj"
                        >标记问题件
                        </el-button>
                        <el-button type="text" @click="handleCancel(scope.row)"
                                   v-if="buttonShowList.qxdd"
                        >取消订单
                        </el-button>
                        <el-button type="text" @click="caiJiConvert(scope.row)"
                                   v-if="buttonShowList.cjlr"
                        >材积录入
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'insurance'">
                        <span v-if="scope.row.isInsured== '0' && scope.row.insurance== '1'" style="color: #dd0000"> {{parseDict(scope.row.insurance,'is_default')}} / 未买</span>
                        <span v-else-if="scope.row.isInsured== '0'"> {{parseDict(scope.row.insurance,'is_default')}} / 未买</span>
                        <span v-else>{{parseDict(scope.row.insurance,'is_default')}} / 已买</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryType'">
                        <span v-if="scope.row.deliveryType === '0'">自送货</span>
                        <span v-else>大森林上门提货</span>
                    </template>
                    <template v-else-if="scope.column.property === 'declareType'">
                        <span style="color: red">{{parseDict(scope.row.declareType,'kd_declare_type')}}</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'channel'">-->
<!--                        <span>{{parseDict(scope.row.channel,'kd_channel')}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="handleEdit(scope.row.orderNo)">{{scope.row.orderNo}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'cusName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                           style="color:#57a3f3;">{{scope.row.cusName}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'receiveCountry'">
                        <span>{{scope.row.receiveCountry}}</span>
                        <span v-if="scope.row.receiveFbaCode"> - {{scope.row.receiveFbaCode}}</span>
                        <span v-if="scope.row.zipCode"> - {{scope.row.zipCode}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isBattery'">
                        <span>{{(scope.row.isBattery == '1' ? '是' : '否')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isCi'">
                        <span>{{(scope.row.isCi == '1' ? '是' : '否')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'goodType'">
                        <span>{{parseDict(scope.row.goodType,'kd_good_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryWarehouse'">
                        <span>{{parseDict(scope.row.deliveryWarehouse,'delivery_point')}}</span>
                    </template>
                      <template v-else-if="scope.column.property === 'isAutoFly'">
                        <span>{{parseDict(scope.row.isAutoFly,'is_default')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-p-v-8 bottom_flex">
                <el-col :span="12" class="total_price">
                  <span>{{'总票数：' + (countList.total || '0')}}</span>
                  <span>{{'总件数：' + (countList.totalCtn || '0')}}</span>
                  <span>{{'总计费重：' + (countList.totalUpdateFeeWeight || '0')}}</span>
                </el-col>
                <el-col class="f-text-right" :span="12">
                  <el-pagination
                          v-bind="ipagination"
                          layout="total, sizes, prev, pager, next, jumper"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange">
                  </el-pagination>
                </el-col>
            </el-row>
        </el-card>

        <add-problem-dialog :visible.sync="proVisible" :row="row" @ok="handleQuery"
                            v-if="proVisible"></add-problem-dialog>


                    <el-dialog title="确认到仓" :visible.sync="dialogFormVisible" width="40%"
                   :close-on-click-modal="false">
                   <el-form :model="form" :rules="rules" ref="dialogForm">
                    <el-form-item label="确认到仓时间" prop="commitArrivalTime">
                     <el-date-picker
                                        class="f-flex-1"
                                        v-model="form.commitArrivalTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="请选择确认到仓时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>

                </el-form-item>
                   </el-form>
                     <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
                   </el-dialog>


                      <auto-fly-dialog  :visible="autoFlyVis" :autoFlyList='autoFlyList'
      @update:visible="
        (val) => {
          autoFlyVis = val;
        }
      "
      @ok="handleQuery"/>
    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {getAction, getAllSupplier} from '@/api/manage'
    import AddProblemDialog from "../kdorder/modules/AddProblemDialog";
    import {setUpRouteUtil} from '@/utils/util';
    import {postAction} from "../../api/manage";
     import AutoFlyDialog from "./modules/AutoFlyDialog.vue";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        components: {
            AddProblemDialog,
            AutoFlyDialog
        },
        data() {
            return {
                isUsePost:true,
                dictCodes: ['kd_declare_type', 'is_default','kd_good_type','delivery_point'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '100px',
                //控制弹框显示
                proVisible: false,
                selectChannelList: [],  //筛选渠道列表
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                    },
                    {
                        prop: 'deliveryTime',
                        label: '送货/提货时间',
                        width: 120
                    },
                    {
                        prop: 'deliveryType',
                        label: '配送方式',
                    },
                    {
                        prop: 'warehouse',
                        label: '配送信息',
                        showMessage: 'tihuoInfo',
                        overFlow: true,
                    },
                    {
                        prop: 'runOrderNo',
                        label: '运单号',
                    },
                    {
                        prop: 'cusName',
                        label: '客户名',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                    },
                    {
                        prop: 'insurance',
                        label: '是否购买保险',
                    },
                      {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
                    {
                        prop: 'receiveCountry',
                        label: '目的地',
                    },
                        {
                        prop: 'isAutoFly',
                        label: '自主飞',
                    },
                    {
                        prop: 'isBattery',
                        label: '是否带电池',
                        width: 65
                    },
                    {
                        prop: 'isCi',
                        label: '是否带磁',
                        width: 60
                    },
                    {
                        prop: 'channelName',
                        label: '渠道名称',
                    },
                    {
                        prop: 'declareType',
                        label: '报关类型',
                    },
                    {
                        prop: 'ctn',
                        label: '预计件数',
                        sortable: 'custom',
                    },
                    {
                        prop: 'kg',
                        label: '预计重量',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'cmb',
                        label: '预计体积',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'driver',
                        label: '司机',
                    },
                    {
                        prop: 'goodType',
                        label: '货运类型',
                        sortable: 'custom',
                    },
                    //    {
                    //     prop: 'deliveryWarehouse',
                    //     label: '交货仓',
                    // },

                ],
                url: {
                    list: '/order/kdOrder/list',
                    cancel: '/order/kdOrder/cancel',
                    queryBysupId: "/sys/supplierChannel/queryBysupId", // 根据供应商ID 获取供应商渠道
                    listCount: "/order/kdOrder/listCount", //获取total-总票数,totalCtn-总件数,totalUpdateFeeWeight-总计费重
                    batchUpdateOrderStatus:'/order/kdOrder/batchUpdateOrderStatus'
                },
                allSupplierList: [], //快递供应商
                supplierChannelList: [], //快递供应商的渠道
                countList: {},  //统计总值
                dialogFormVisible:false,
                form:{

                },
                rules:{
                    commitArrivalTime:[   {required: true, message: '请选择确认到仓时间', trigger: 'change'}]
                },
                   autoFlyVis:false,
                autoFlyList:[]
            }
        },
        methods: {
            handleSearchM() {
                this.queryForm.channelIds = this.selectChannelList;
                this.handleSearch();
            },
            initMoreFuc() {
              this.getListCount();
            },
            getListCount(){
              postAction(this.url.listCount,this.queryForm)
                .then(res=>{
                    if (res.result){
                        this.countList = res.result;
                    }else {
                        this.countList = {total:0,totalCtn:0,totalUpdateFeeWeight:0}
                    }

                })
            },
            initCusPage({cusId}) {
                if (cusId) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            //进入编辑页面
            handleEdit(val) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + val);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.orderStatus = '0';
                this.ipagination.currentPage = 1;
                this.selectChannelList = [];
                this.getSupplierChannel();
                this.handleQuery()
            },
            //点击标记问题件
            handleProblemdialogFormVisible(row) {
                //打开标记问题弹窗
                this.row.orderNo = row.orderNo;
                this.proVisible = true;
            },
            //取消订单
            handleCancel(row) {
                this.$confirm(`确定取消${row.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    getAction(this.url.cancel + '?orderNo=' + row.orderNo)
                        .then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery()
                            }
                        }).catch(err => {
                    })
                }).catch(() => {

                })
            },
            //材积录入
            caiJiConvert(row) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push({path: '/kdorder/Edit?orderNo=' + row.orderNo, query: {activeName: 'second'}});
            },
            //获取所有的 快递供应商
            initAllSupplier() {
                this.allSupplierList = [];
                getAllSupplier("2")
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id; //供应商ID
                                obj.label = res.result[i].chinaseName; //供应商中文名
                                this.allSupplierList.push(obj);
                                console.log("allSupplierList", this.allSupplierList);
                            }
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //获取供应商的渠道
            getSupplierChannel(code) {
                this.supplierChannelList = [];
                let url = code ? this.url.queryBysupId + "?supId=" + code : this.url.queryBysupId + "?supId=" ;
                getAction(url).then((res) => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id; //供应商ID
                            obj.label = res.result[i].channelName; //供应商中文名
                            this.supplierChannelList.push(obj);
                        }
                    }
                });
            },
            ConfirWarehouse() {
                  this.dialogFormVisible = true
                  this.form.orderNo = this.selectionRows.map(item=>item.orderNo).join()
            },
             handleIsAutoFly() {
                 this.autoFlyList = this.selectionRows.map((item) => item.orderNo)
               this.autoFlyVis = true

            },
            handleSave(){
                 this.$refs['dialogForm'].validate(valid => {
                    if(valid){
                    this.form.logisState='7'
                    postAction(this.url.batchUpdateOrderStatus,this.form).then((res) => {
                    if (res.success) {
                         this.$message.success(res.message);
                         this.dialogFormVisible = false;
                         this.form = {}
                         this.handleQuery()
                    }
                });
                    }
                 })

            }
        },
        created() {
            this.queryForm.orderStatus = '0';
            this.initAllSupplier();
            this.getSupplierChannel();
        },
      computed:{
        buttonShowList(){
          return {
            'qrdc':this.$btnIsShow('kdorder/NoReceiveGoods','0','确认到仓'),
            'bjwtj':this.$btnIsShow('kdorder/NoReceiveGoods','1','标记问题件'),
            'qxdd':this.$btnIsShow('kdorder/NoReceiveGoods','1','取消订单'),
            'cjlr':this.$btnIsShow('kdorder/NoReceiveGoods','1','材积录入'),
            'zzf':this.$btnIsShow('kdorder/NoReceiveGoods','0','批量修改自主飞'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
