<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="90px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="亏本原因">
                                <el-input  v-model="queryForm.loseReason"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="处理类型">
                          <f-select  v-model="queryForm.handleType" :is-need="queryForm.handleType" dict="lose_reason_handle_type"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="扣款承担比例">
                          <el-input  v-model="queryForm.subRecRate"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>


            </el-form>
          <div class="bbb">
            <el-button type="text" size="medium"  @click="handleEdit({},'新增亏本处理')">新增</el-button>
            <el-upload  action="/" :show-file-list="false"
                       :http-request="uploadSectionFile" accept=".xls,.xlsx" style="margin:0 10px;display:inline">
              <el-button size="medium" type="primary">导入</el-button>
            </el-upload>
            <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
                       @click="handleBatDel()" >批量删除
            </el-button>
          </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
            <template slot-scope="scope">
              <template v-if="scope.column.property === 'action'">

                <el-button type="text" size="medium"  @click="handleEdit(scope.row,'编辑亏本处理')">编辑</el-button>
              </template>
            </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

      <edit-fin-lose-reason-dialog :visible.sync="reasonVisible"  :row="reasonForm" :title="reasonTitle" @ok="handleSearch"></edit-fin-lose-reason-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import { postAction} from "@/api/manage";
    import EditFinLoseReasonDialog from "./EditFinLoseReasonDialog.vue";
    import EditAdditionalFeeSetDialog from "../../channel/modules/EditAdditionalFeeSetDialog.vue";
    export default {
        name: "LoseReason",
        mixins: [QueryMixins,EditFinLoseReasonDialog],
        components: {EditAdditionalFeeSetDialog, EditFinLoseReasonDialog},
        data() {
            return {
                isUsePost:true,

                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "loseReason",
                        label: "亏本原因",
                    },
                    {
                        prop: "handleTypeStr",
                        label: "处理类型",
                    },
                  {
                    prop: "subRecRateStr",
                    label: "扣款承担比例",
                  },
                  {
                    prop: "createBy",
                    label: "录入人",
                  },
                  {
                    prop: "createTime",
                    label: "录入时间",
                  },
                  {
                    prop: "updateBy",
                    label: "修改人",
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/finance/audit/loseReason/page",
                    importUrl :  "/finance/audit/loseReason/import",
                    del :  "/finance/audit/loseReason/del",
                },
                reasonVisible:false,
                reasonForm:{},
              reasonTitle:'',

            };
        },
        methods: {
          handleEdit(row,title) {

            this.reasonForm = row;
            this.reasonTitle = title;
            this.reasonVisible = true;
          },
          handleBatDel() {
            this.$confirm("确定删除？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "error",
              closeOnClickModal: false,
            })
                .then(() => {
                  postAction(this.url.del, this.selectedRowKeys).then((res) => {
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    this.handleQuery();
                  });
                })
                .catch(() => { });
          },
          uploadSectionFile(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importUrl, form)
                .then((res) => {
                  //这里做上传之后的操作
                  if (res.success) {
                    this.$message.success(res.message);
                    this.handleQuery();
                  } else {
                    this.handleQuery();
                  }
                })
          },
        },
      computed:{

      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
