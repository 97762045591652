<!--带入仓-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="100px" size="medium">
            <div style="display:flex;">
                <el-card class="box-card">
                  <el-row :gutter="24" class="inputs_row">
                    <el-col :md="5" :sm="24">
                  <el-form-item label="订单号">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                      <el-col :md="5" :sm="24">
                  <el-form-item label="客户">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.customer"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                    <el-col :md="5" :sm="24">
                  <el-form-item label="销售">
                    <f-select
                        dict="sys_user_id"
                        :multiple="true"
                        class="textarea_table"
                        v-model="queryForm.salesmanIds"
                        :is-need="queryForm.salesmanIds"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></f-select>
                  </el-form-item>
                  </el-col>

                    <el-col :md="5" :sm="24">
                    <el-form-item label="体积比起始值">
                      <el-input
                          v-model="queryForm.cbmBiStart"
                          type="number"
                          @keyup.enter.native="handleSearch"
                      ></el-input>
                    </el-form-item>
                    </el-col>
                    <el-col :md="5" :sm="24">
                      <el-form-item label="体积比结束值">
                        <el-input
                            v-model="queryForm.cbmEnd"
                            type="number"
                            @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="9">
                      <el-form-item label="销账时间" label-width="80px">
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.lastSettleTimeStart"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.lastSettleTimeEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="9">
                      <el-form-item label="进入待审核时间" >
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.toeLoseTimeStart"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.toeLoseTimeEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item label="销售组别">
                        <f-select
                            v-model="queryForm.deptIdList"
                            :dict="'sys_depart'"
                            :isNeed="queryForm.deptIdList"
                            :multiple="true"
                        >
                        </f-select>
                      </el-form-item>
                    </el-col>
                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                    <el-button
                        size="medium"
                        @click="
                  exportExcelPlus(
                    url.downloadSalemanUnCommisstionProfitAuditExportXls,
                    '待审核订单明细'
                  )
                "
                    >导出
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="buttonShowList.plsh"
                    :disabled="selectedRowKeys.length === 0" @click="initLoseReasonBos"
                >批量审核</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="buttonShowList.syncProfit"
                    @click="syncOrderProfit"
                >同步订单利润</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="65vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
                    </template>
                  <template v-else-if="scope.column.property === 'customer'">
                    <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog title="批量审核" :visible.sync="dialogFormVisibleBatchAudit" :close-on-click-modal="false" append-to-body width="50%" height="100%">
            <el-form :model="form" ref="loseAudit" :rules="rules">
                <el-form-item prop="loseReason" label="亏本订单审核原因">
                    <el-select
                            v-model="form.loseReason"
                            clearable filterable @change="fillLoseReasonForm">
                      <el-option v-for="(item,index) in loseReasonBos" :key="index" :value="item.loseReason" :label="item.loseReason" ></el-option>
                    </el-select>
                </el-form-item>
              <el-form-item prop="handleTypeStr" label="处理类型" >
                <f-select

                    dict="lose_reason_handle_type"
                    class="textarea_table"
                    v-model="form.handleType"
                    :is-need="form.handleType"
                    @keyup.enter.native="handleSearch"
                    :disabled="form.loseReason !== '其他'"
                ></f-select>
              </el-form-item>
              <el-form-item prop="subRecRate" label="扣款承担比例">
                <el-input v-model="form.subRecRate" :disabled="form.loseReason !== '其他'" maxlength="20" show-word-limit></el-input>
              </el-form-item>
                <el-form-item prop="loseRemark" label="备注">
                  <el-input v-model="form.loseRemark" maxlength="100" show-word-limit
                            placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisibleBatchAudit = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="batchAuditLoseMoneyOrders()">确 定</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    import QueryMixins from "../../../mixins/QueryMixins";
    import {getAction, postAction} from '@/api/manage'
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','pglx'],
                ids: [],
                row: {},
                form: {},
                dialogFormVisibleBatchAudit: false,
                formLabelWidth: "100px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 80,
                    },
                    {
                        prop: "salesman",
                        label: "销售",
                    },
                    {
                        prop: "customer",
                        label: "客户",
                    },
                    {
                        prop: "realCmb",
                        label: "实际体积",
                    },
                  {
                    prop: "realKg",
                    label: "过磅重",
                  },
                  {
                    prop: "cbmBi",
                    label: "体积比",
                  },
                  {
                    prop: "totalVolumeWeight",
                    label: "体积重",
                  },
                    {
                        prop: "countFeeNum",
                        label: "计费数量",
                    },
                  {
                    prop: "minStartNum",
                    label: "渠道最低消费",
                  },
                    {
                        prop: "profit",
                        label: "利润",
                    },
                  {
                    prop: "profitRate",
                    label: "利润率",
                  },

                  {
                    prop: "lastSettleTime",
                    label: "销账时间",
                    minWidth: 80,
                  },
                  {
                    prop: "isShen",
                    label: "是否已审核",
                  },
                  {
                    prop: "notes",
                    label: "备注",
                    minWidth: 100,
                  },
                  {
                    prop: "toeLoseTime",
                    label: "进入待审核时间",
                    minWidth: 100,
                  },

                ],
                url: {
                    list: "finance/fee/settleCommission/profitAuditList",
                    syncOrderProfit: "finance/fee/settleCommission/syncOrderProfit",
                    downloadSalemanUnCommisstionProfitAuditExportXls: "finance/fee/settleCommission/profitAudit/export",
                    getLoseReasonBOs:'finance/audit/loseReason/findBoList',
                    auditBatch:'finance/audit/auditBatch',
                },
              rules:{
                auditReason:[
                  {required:true,message:'请选择原因',trigger:'change'}
                ],
                handleType:[
                  {required:true,message:'请选择处理类型',trigger:'change'}
                ],
                subRecRate:[
                  {required:true,message:'请选择扣款承担比例',trigger:'change'}
                ]
              },
              initData: { isAudit: "0" },
              isUsePost:true,
              loseReasonBos:[],
              loseReasonBoMap:{},
            };
        },
        methods: {
          fillLoseReasonForm(val){
            let obj = this.loseReasonBoMap && this.loseReasonBoMap[val];
            console.log("匹配到的数据",obj);
            if (obj) {
              this.form = JSON.parse(JSON.stringify(obj));
            }
            if (val === '其他') {
              this.$set(this.form,'handleType','');
              this.$set(this.form,'handleTypeStr','');
              this.$set(this.form,'subRecRate','');
            }
          },
            initLoseReasonBos(){
            this.form = {};
            this.dialogFormVisibleBatchAudit = true;
              if (this.loseReasonBos && this.loseReasonBos.length > 0) {

              }else{
                postAction(this.url.getLoseReasonBOs)
                    .then(res => {
                      this.loseReasonBos = res.result;
                      this.loseReasonBoMap = this.listToMap(this.loseReasonBos,'loseReason')
                    })
              }
            },
            syncOrderProfit(){
                postAction(this.url.syncOrderProfit,{orderNo:this.queryForm.orderNo}).then(res =>{
                    if(res.success){
                        this.$message.success(res.message)
                    }
                    this.handleQuery();
                })
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$intoDocPage(orderNo);
                // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },

            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.queryForm.isWhether='0'
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            batchAuditLoseMoneyOrders() {
                this.$refs['loseAudit'].validate(valid => {
                  if (valid) {
                    let nos = this.selectionRows.map(r => r.orderNo);
                    let checkForm = JSON.parse(JSON.stringify(this.form));
                    checkForm.nos = nos;
                    postAction(this.url.auditBatch,checkForm).then((res) => {
                      this.$message({
                        type: "success",
                        message: res.message,
                      });
                      this.form = {};
                      this.handleSearch();
                      this.dialogFormVisibleBatchAudit = false;
                    });

                  }
                })

            },
        },
        created() {
            this.queryForm.isWhether='0'
        },
      computed:{
        buttonShowList() {
          return {
            'plsh':this.$btnIsShow('sale/WaitAuditModule','0','批量审核'),
            'syncProfit':this.$btnIsShow('sale/WaitAuditModule','0','同步订单利润'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
