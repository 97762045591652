<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请编号">
                            <el-input v-model="queryForm.applyNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="销账编号">
                            <el-input v-model="queryForm.mergeNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="销账时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                :picker-options="editStartOptions"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="btime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="feetime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="申请人">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.creator"
                                      :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="审批人">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.checkAccountBy"
                                      :isNeed="queryForm.checkAccountBy">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="结算对象">
                            <el-input v-model.trim="queryForm.settleName" @keyup.enter.native="handleCheck"
                                      placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务类型">
                            <el-select v-model="queryForm.agentPayType" clearable>
                                <el-option value="-1" label="全部"></el-option>
                                <el-option value="0" label="海运"></el-option>
                                <el-option value="1" label="柜子"></el-option>
                                <el-option value="2" label="快递"></el-option>
                                <el-option value="3" label="海运/柜子混合"></el-option>
<!--                                <el-option value="4" label="海外仓"></el-option>-->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.salesmanId"
                                :is-need="queryForm.salesmanId"
                                :father-val="queryForm.salesmanId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款对象">
                            <el-input v-model.trim="queryForm.payMan" @keyup.enter.native="handleCheck"
                                      placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="付款开户行">
                            <f-select :dict="'receive_bank'"
                                      v-model="queryForm.forestBankName"
                                      :isNeed="queryForm.forestBankName">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="数据时间">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.dataCreateTime"
                                    :is-need="queryForm.dataCreateTime"
                                    :dict="'data_create_time'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <div>
                    <el-button size="medium" type="primary" @click="setProof"
                               v-if="buttonShowList.scpz"
                    >生成凭证
                    </el-button>

                    <el-button size="medium" type="primary" @click="downloadPayExcel"
                               v-if="buttonShowList.dcexcel"
                    >导出excel
                    </el-button>
                    <!--<el-button size="medium" type="primary" @click="jumpBindBillView">合并销账单流水</el-button>-->


<!--                    <el-button size="medium" type="primary" @click="synPayBankId"-->
<!--                               v-if="$btnIsShow('hyFinanceManage/modules/pay/payRemoved','0','同步付款银行')"-->
<!--                    >同步付款银行-->
<!--                    </el-button>-->
                </div>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="61vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <!--                        <el-popconfirm-->
                        <!--                            title="是否生成应付销账凭证？"-->
                        <!--                            confirm-button-text="确认"-->
                        <!--                            cancel-button-text="不用了"-->
                        <!--                            icon="el-icon-info"-->
                        <!--                            icon-color="red"-->
                        <!--                            @confirm="setProof"-->
                        <!--                        >-->
                        <!--                            <el-button type="text" slot="reference" plain>-->
                        <!--                                生成凭证-->
                        <!--                            </el-button>-->
                        <!--                        </el-popconfirm>-->
                        <!--                        <el-divider direction="vertical"></el-divider>-->
                        <el-button
                            type="text"
                            slot="reference"
                            plain
                            @click="waitwait(scope.row)"
                            v-if="buttonShowList.xzsj"
                        >
                            销账时间
                        </el-button>
                        <el-popconfirm
                            title="取消销账将退回至待销账，是否确认？"
                            confirm-button-text="确认"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="cancelPayRemoved(scope.row)">
                            <el-button slot="reference" type="text"
                                       v-if="buttonShowList.xzsj"
                            > 取消销账
                            </el-button>
                        </el-popconfirm>
                        <el-button plain type="text" v-if="buttonShowList.xzfksq"
                                   @click="downloadPayApply(scope.row)"
                        >下载付款申请
                        </el-button>
                        <el-button plain style="margin-left: 0" type="text" v-if="buttonShowList.dcxzmx"
                                   @click="downloadRemovedMergeDetail(scope.row)"
                        >导出销账明细
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'invoiceNoThird'">
                        <span v-for="(item, index) in scope.row.supplierInvoiceNos" :key="index">
                            {{item+';'}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'agentPayType'">
                        <span v-if="scope.row.agentPayType=='0'">海运费用申请</span>
                        <span v-if="scope.row.agentPayType=='1'">柜子的费用申请</span>
                        <span v-if="scope.row.agentPayType=='2'">快递费用申请</span>
                        <span v-if="scope.row.agentPayType=='3'">海运/柜子混合费用申请</span>
                        <span v-if="scope.row.agentPayType=='4'">海外仓费用申请</span>
                    </template>
                    <template v-else-if="scope.column.property === 'mergeNo'">
                        <!--                        <el-button type="text" @click="showMergeDetail(scope.row)">{{scope.row.mergeNo}}</el-button>-->
                        <a style="color: #00a0e9"
                           @click.prevent="showMergeDetail(scope.row)">{{ scope.row.mergeNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{ parseDict(scope.row.creator, 'sys_create_by') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'applyNo'">
<!--                        <el-button type="text" @click="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</el-button>-->
                        <a style="color: #00a0e9"
                           @click.prevent="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</a>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <remove-dialog ref="removeDialog" @ok="this.handleQuery"></remove-dialog>
        <merge-detail-dialog ref="mergeDetailDialog"></merge-detail-dialog>
        <apply-detail-dialog ref="applyDetail"></apply-detail-dialog>
        <container-apply-detail-dialog ref="containerApplyDetailDialog"
                                       @ok="this.handleQuery"></container-apply-detail-dialog>
        <add-bill-no-dialog :visible.sync="billVisible":applyNos="applyNos":voucherType="voucherType"></add-bill-no-dialog>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import removeDialog from "./removeDialog";
import mergeDetailDialog from "@/views/hyFinanceManage/modules/pay/mergeDetailDialog";
import applyDetailDialog from "./applyDetailDialog";
import containerApplyDetailDialog from "@/views/hyFinanceManage/modules/pay/containerApplyDetailDialog";
import AddBillNoDialog from "../voucher/AddBillNoDialog";

export default {
    mixins: [QueryMixins],
    components: {
        removeDialog, mergeDetailDialog, applyDetailDialog, containerApplyDetailDialog, AddBillNoDialog
    },
    data() {
        return {
            dictCodes: ['sys_create_by'],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 80,
                },
                {
                    prop: "mergeNo",
                    label: "销账编码",
                    width: 120,
                },
                {
                    prop: "removeTime",
                    label: "销账时间",
                },

                {
                    prop: "applyNo",
                    label: "申请编号",
                    width: 120,
                },
                {
                    prop: "agentPayType",
                    label: "申请类型",
                },
                {
                    prop: "invoiceNoThird",
                    label: "代理InvoiceNo",
                    width: 100,
                },
                // {
                //     prop: 'invoiceNo',
                //     label: '发票号',
                // },
                // {
                //     prop: 'url',
                //     label: '账单附件',
                // },
                {
                    prop: "invoiceRemark",
                    label: "备注",
                    sortable: true,
                },
                {
                    prop: "settleName",
                    label: "代理商",
                },
                {
                    prop: "payMoney",
                    label: "应付金额",
                },
                {
                    prop: "payCur",
                    label: "应付币种",
                },
                {
                    prop: "realMoney",
                    label: "实付费用",
                },
                {
                    prop: "realCur",
                    label: "实付币种",
                },
                {
                    prop: "mergeSalesMoney",
                    label: "结算金额",
                },
                {
                    prop: "mergeSalesMoneyCur",
                    label: "结算币种",
                },
                {
                    prop: "bankName",
                    label: "收款开户行",
                },
                {
                    prop: "settleAccount",
                    label: "收款账号",
                },
                {
                    prop: "payMan",
                    label: "收款名称",
                },

                {
                    prop: "forestBankName",
                    label: "付款开户行",
                },
                {
                    prop: "forestAccount",
                    label: "付款账号",
                },
                {
                    prop: "forestPayMan",
                    label: "付款名称",
                },
                // {
                //     prop: "bank",
                //     label: "收款银行",
                // },
                // {
                //     prop: "accountMan",
                //     label: "收款人",
                // },
                // {
                //     prop: "payBank",
                //     label: "付款银行",
                // },
                // {
                //     prop: "payAccount",
                //     label: "付款账号",
                // },
                {
                    prop: "creator",
                    label: "申请人",
                },
                // {
                //   prop: "mergeTime",
                //   label: "合并时间",
                // },
                {
                    prop: "createTime",
                    label: "申请时间",
                },
                {
                    prop: "lastPayTime",
                    label: "最晚付款时间",
                },
                {
                    prop: "checkAccountBy",
                    label: "审批人",
                },
                {
                    prop: "checkTime",
                    label: "审批时间",
                },
            ],

            url: {
                list: "/shipping/hySettlePay/removedList",
                setProof: "/shipping/hySettlePay/setProof",
                // removeBatch: '/shipping/hySettlePay/removeBatch',
                // backToWaitPay: '/shipping/hySettlePay/backToWaitPay',
                cancelPayRemoved: '/shipping/hySettlePay/cancelPayRemoved',
                downloadPayExcel:'/shipping/hySettlePay/chargeOffExcel',
                downloadPayOutApply: "/file/jasper/downloadPayOutApply",
                synPayBankId:"/shipping/hySettlePay/synPayBankId",
                downloadRemovedMergeDetail:"/shipping/hySettlePay/downloadRemovedMergeDetail",
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],

            btime: [],  //开船时间
            feetime: [],  //开船时间
            // queryForm: {agentPayType: '0'}
            queryForm: {},


            billVisible:false,//控制弹窗是否显示,
            applyNos:[],//传递给弹窗的 申请编号
            voucherType:"",//传递给弹窗的 凭证类型
        };
    },
    computed: {
      buttonShowList(){
        return {
          'scpz':this.$btnIsShow('hyFinanceManage/modules/pay/payRemoved','0','生成凭证'),
          'dcexcel':this.$btnIsShow('hyFinanceManage/modules/pay/payRemoved','0','导出excel'),
          'xzsj':this.$btnIsShow('hyFinanceManage/modules/pay/payRemoved','1','销账时间'),
          'xzfksq':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','1','下载付款申请'),
          'dcxzmx':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','1','导出销账明细'),

        }
      }
    },
    created() {
      // this.$set(this.queryForm, 'agentPayType', '0');
        this.queryForm.dataCreateTime = '3';

    },

    methods: {
        setProof() {
            if ((this.btime === null || this.btime.length === 0) && (this.feetime === null || this.feetime.length === 0)
                && (this.otime === null || this.otime.length === 0)) {
                this.$message.warning("查询条件的【下单时间】【开船时间】【费用时间】须填写一个或多个");
                return;
            }


            // console.log("应付-生成凭证以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选需要生成凭证的申请号");
                return;
            }

            let agentPayType = []

            let count = [];

            this.selectionRows.forEach((item) => {
                count.push(item.id);
                if (item.agentPayType == '1') {
                    agentPayType.push(item.id)
                }
            });
            if (agentPayType.length > 0) {
                this.$message.warning("柜子类型的销账编号不支持生成凭证")
                return;
            }
            // this.loading = true;
            let arr = Array.from(new Set(count));

            let obj = {}
            obj.agentPayMergeIds = arr;
            if (this.otime !== null && this.otime.length > 1) {
                obj.orderCreateTimeS = this.otime[0];
                obj.orderCreateTimeE = this.otime[1];
            } else {
                obj.orderCreateTimeS = '';
                obj.orderCreateTimeE = '';
            }
            if (this.btime !== null && this.btime.length > 1) {
                obj.boatTimeS = this.btime[0];
                obj.boatTimeE = this.btime[1];
            } else {
                obj.boatTimeS = '';
                obj.boatTimeE = '';
            }
            if (this.feetime !== null && this.feetime.length > 1) {
                obj.feeCreateTimeS = this.feetime[0];
                obj.feeCreateTimeE = this.feetime[1];
            } else {
                obj.feeCreateTimeS = '';
                obj.feeCreateTimeE = '';
            }
            // console.log('obj', obj)
            let fileName = '应付凭证' + new Date().toLocaleDateString();
            postAction(this.url.setProof, obj, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // this.loading = false;
                }
            })

        },
        showApplyDetail(row) {
            // this.$refs.applyDetail.showDetail(row);
            if (row.agentPayType == '1') {
                this.$refs.containerApplyDetailDialog.showDetail(row);
            } else {
                this.$refs.applyDetail.showDetail(row);
            }
        },
        cancelPayRemoved(row) {
            // console.log('进入取消应付销账的方法', row)
            postAction(this.url.cancelPayRemoved, row).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.$message.success("退回成功！");
                    this.handleQuery();
                }
            });
        },
        showMergeDetail(row) {
            this.$refs.mergeDetailDialog.showDetail(row);
        },
        importBill() {
            this.$message.warning("此功能后续开放！");
        },
        backToWaitPay(row) {
            postAction(this.url.backToWaitMerge, row).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.$message.success("退回成功！");
                    this.handleQuery();
                }
            });
        },
        doRemove(row) {
            this.$refs.removeDialog.edit(row);
        },
        removeBatch() {
            // console.log("申请付款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选付款申请");
                return;
            }
            let payCount = [];
            this.selectionRows.forEach((item) => {
                payCount.push(item.id);
            });

            let payArr = Array.from(new Set(payCount));
            // if(payArr.length > 1){
            //     this.$message.warning("所选费用的结算对象不一致！")
            //     return
            // }
            // let arr = this.tableData.filter(item => {return this.selectionRows.indexOf(item) === -1})
            // console.log("arr--",arr)
            // this.$refs.payApply.edit(this.selectionRows,arr,this.columns)
            //跳过审批流程，直接审批完成
            postAction(this.url.removeBatch, payArr).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.$message.success("合并成功");
                    this.handleQuery();
                }
            });
        },
        waitwait(obj) {
            this.$message.warning("后续开放！");
        },
        handleReset() {
            this.stime = []
            this.otime = []
            this.btime = []
            this.feetime = []
            this.queryForm = {}
            // this.$set(this.queryForm, 'agentPayType', '0');
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleCheck() {

            this.loading = true;
            if (this.stime && this.stime.length > 1) {
                this.queryForm.removeTimeS = this.stime[0];
                this.queryForm.removeTimeE = this.stime[1];
            } else {
                this.queryForm.removeTimeS = '';
                this.queryForm.removeTimeE = '';
            }
            if (this.otime && this.otime.length > 1) {
                this.queryForm.oCreateTimeS = this.otime[0];
                this.queryForm.oCreateTimeE = this.otime[1];
            } else {
                this.queryForm.oCreateTimeS = '';
                this.queryForm.oCreateTimeE  = '';
            }
            if (this.btime && this.btime.length > 1) {
                this.queryForm.boatTimeS = this.btime[0];
                this.queryForm.boatTimeE = this.btime[1];
            } else {
                this.queryForm.boatTimeS = '';
                this.queryForm.boatTimeE = '';
            }
            if (this.feetime && this.feetime.length > 1) {
                this.queryForm.feeCreateTimeS = this.feetime[0];
                this.queryForm.feeCreateTimeE = this.feetime[1];
            } else {
                this.queryForm.feeCreateTimeS = '';
                this.queryForm.feeCreateTimeE = '';
            }
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        noConvertBill() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用");
                return;
            }

            let countCur = [];
            this.selectionRows.forEach((item) => {
                countCur.push(item.moneyType);
            });
            let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            if (curArr.length > 1) {
                this.$message.warning("所选费用的费用币种不一致！");
                return;
            }

          let settleIdCount = [];
          let settleNameCount = [];
          this.selectionRows.forEach((item) => {
            settleIdCount.push(item.settleId);
            settleNameCount.push(item.settlement);
          });
          let idArr = Array.from(new Set(settleIdCount));
          let nameArr = Array.from(new Set(settleNameCount));
          if (idArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }
          if (nameArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }
            this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
        },
        downloadPayApply(row) {
          let that = this;
            // console.log('进入下载付款申请')
            postAction(this.url.downloadPayOutApply, {id: row.id, applyNo: row.applyNo}, {responseType: 'blob'})
                .then(res => {
                  if (res && res.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(res)
                    return false;
                  }else {
                    // console.log(res)
                    if (res.size === 0) {
                      this.$message.error('导出失败，请稍后再试或联系管理员')
                      return
                    }
                    var binaryData = [];
                    binaryData.push(res);
                    let filename = row.applyNo + '-' + this.getDateStr() + '付款申请'

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xls');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // else{
                    //     let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                    //     window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                    // }

                    // this.visible=false
                    setTimeout(() => {   //设置延迟执行
                      this.$message.success("导出成功！")
                      // this.$emit('ok')
                      // this.handleCheck();
                    }, 3000)
                  }

                })


        },
        downloadPayExcel() {

            // if ((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
            //     && (this.queryForm.settleName === '' || this.queryForm.settleName === undefined)
            //     && (this.stime === null || this.stime.length === 0)
            //     && (this.queryForm.payCur === '' || this.queryForm.payCur === undefined)) {
            //     this.$message.warning('导出excel必须添加查询条件')
            //     return
            // }

            this.loading = true;

            this.queryForm.downloadExcelType = 'payRemoved';
            if (this.stime && this.stime.length > 1) {
                this.queryForm.removeTimeS = this.stime[0];
                this.queryForm.removeTimeE = this.stime[1];
            } else {
                this.queryForm.removeTimeS = '';
                this.queryForm.removeTimeE = '';
            }
            if (this.otime && this.otime.length > 1) {
                this.queryForm.oCreateTimeS = this.otime[0];
                this.queryForm.oCreateTimeE = this.otime[1];
            } else {
                this.queryForm.oCreateTimeS = '';
                this.queryForm.oCreateTimeE = '';
            }
            if (this.btime && this.btime.length > 1) {
                this.queryForm.boatTimeS = this.btime[0];
                this.queryForm.boatTimeE = this.btime[1];
            } else {
                this.queryForm.boatTimeS = '';
                this.queryForm.boatTimeE = '';
            }
            if (this.feetime && this.feetime.length > 1) {
                this.queryForm.feeCreateTimeS = this.feetime[0];
                this.queryForm.feeCreateTimeE = this.feetime[1];
            } else {
                this.queryForm.feeCreateTimeS = '';
                this.queryForm.feeCreateTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("开始下载");

            let filename = this.getDateStr() + '导出应付已销账'

            let url = this.url.downloadPayExcel

            getAction(url, params, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        this.visible = false
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象

                    this.visible = false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        this.handleCheck();
                    }, 3000)
                })
        },
        synPayBankId(){
            getAction(this.url.synPayBankId).then(res=>{
                // console.log(res)
            })
        },
        downloadRemovedMergeDetail(row) {
            // console.log('进入下载付款申请')
            getAction(this.url.downloadRemovedMergeDetail, {id: row.id, mergeNo: row.mergeNo}, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);
                    let filename = row.mergeNo + '-' + this.getDateStr() + '应付销账明细'

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xls');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // else{
                    //     let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                    //     window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                    // }

                    // this.visible=false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        // this.handleCheck();
                    }, 3000)
                })


        },
        jumpBindBillView() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选需要绑定流水的申请号");
                return;
            }

            //传递值
            // this.selectionRows
            this.applyNos = this.selectionRows.map(a=>a.applyNo);
            this.voucherType = "4";
            //打开弹窗
            this.billVisible = true;
        },
    },

};
</script>
<style scoped>
/* 单行就设置间距小一点 */
/* .el-form-item {
  margin-bottom: 2px;
} */
</style>
