<template>
  <el-dialog
    title="提货用车详情"
    :visible="visible"
    width="40%"
    custom-class="c-custom-dialog"
    append-to-body
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    基本信息
    <div style="text-align: right">
      <el-button type="primary" @click="editTakeInfo" style="margin-right: 5px"
        >确认修改</el-button
      >
      <el-button type="primary" @click="clickCopy">复制提货信息</el-button>
    </div>

    <el-form class="addEditBox" :model="form" label-width="100px" size="medium">
      <el-row>
        <el-col :span="12">
          <el-form-item
            prop="takeNo"
            label="用车编号"
            :label-width="formLabelWidth"
          >
            <span v-text="form.takeNo"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="ctnStr"
            label="件重体"
            :label-width="formLabelWidth"
          >
            <span v-text="form.ctnStr"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="address"
            label="提货地址信息"
            :label-width="formLabelWidth"
          >
            <el-input
              type="textarea"
              maxlength="250"
              v-model="form.address"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="contact"
            label="联系人"
            :label-width="formLabelWidth"
          >
            <span v-text="form.contact"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            :span="4"
            prop="contactPhone"
            label="电话"
            :label-width="formLabelWidth"
          >
            <span v-text="form.contactPhone"></span>
          </el-form-item>

          <el-form-item
              :span="20"
              prop="isNeedSendTakeDriverPhone"
              label="提货司机信息短信推送"
              :label-width="formLabelWidth"
              key="contactAdd"
          >
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'1'"
                @click.native.prevent="radioClick4('1')"
            >是</el-radio>
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'0'"
                @click.native.prevent="radioClick4('0')"
            >否</el-radio>
          </el-form-item>
        </el-col>


        <el-col :span="12">
          <el-form-item
            prop="isWeiban"
            label="是否尾板"
            :label-width="formLabelWidth"
          >
            <f-select
              :dict="'unit_whether'"
              v-model="form.isWeiban"
              :is-need="form.isWeiban"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="isBanyun"
            label="是否搬运"
            :label-width="formLabelWidth"
          >
            <f-select
              :dict="'unit_whether'"
              v-model="form.isBanyun"
              :is-need="form.isBanyun"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="carTypeStr"
            label="车辆类型"
            :label-width="formLabelWidth"
          >
            <span v-text="form.carTypeStr"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="warehouseId"
            label="交货仓库"
            :label-width="formLabelWidth"
          >
            <f-select
              v-model="form.warehouseId"
              :dict="'delivery_point'"
              :is-need="form.warehouseId"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="takeTime"
            label="提货时间"
            :label-width="formLabelWidth"
          >
            <el-date-picker
              v-model="form.takeTime"
              type="datetime"
              size="small"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="statusStr"
            label="用车状态"
            :label-width="formLabelWidth"
          >
            <span v-text="form.statusStr"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="truckingTime"
            label="派车时间"
            :label-width="formLabelWidth"
          >
            <span v-text="form.truckingTime"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="supplier"
            label="供应商"
            :label-width="formLabelWidth"
          >
            <span v-text="form.supplier"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="driverInfo"
            label="司机信息"
            :label-width="formLabelWidth"
          >
            <span v-text="form.driverInfo"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="truckingNo"
            label="派车单号"
            :label-width="formLabelWidth"
          >
            <span v-text="form.truckingNo"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="useCarTypeStr"
            label="派车类型"
            :label-width="formLabelWidth"
          >
            <span v-text="form.useCarTypeStr"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="workTypeStr"
            label="车辆作业性质"
            :label-width="formLabelWidth"
          >
            <span v-text="form.workTypeStr"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="outNo"
            label="出库编码"
            :label-width="formLabelWidth"
          >
            <span v-text="form.outNo"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="doc"
            label="海运客服"
            :label-width="formLabelWidth"
          >
            <span v-text="form.doc"></span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="remark"
            label="提货备注"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.remark"
              type="textarea"
              maxlength="250"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom:21.56px">订单信息</div>

    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-row :gutter="20" class="flex_center">
        <el-col :span="10">
          <el-form-item label="订单号" style="margin-bottom: 0">
            <el-input
              v-model="queryForm.orderNo"
              placeholder="多订单号"
              type="textarea"
              @keyup.enter.native="queryTakeDetail"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="queryTakeDetail"
            >查询</el-button
          >
          <el-button type="primary" size="medium" @click="openAddDialog"
            >加货
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="tihuoBatch"
            :disabled="selectionRows.length === 0"
            >踢货
          </el-button>
        </el-col>
        <el-col :span="7"
          >总计件重体：{{ totalCtn }} / {{ totalKg }} / {{ totalCmb }} /
          {{ totalOceanPkgs }}</el-col
        >
      </el-row>
      <!-- <span> 总计件重体：{{totalCtn}} / {{totalKg}} / {{totalCmb}}</span> -->
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="form.orderList"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="30vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="seeDetail(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
        </template>
      </f-table>
    </el-form>

    <span slot="footer">
      <el-button size="medium" @click="handleClose">关闭</el-button>
      <!--            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>-->
    </span>
    <add-take-order-detail-list-module
      @ok="closeAddDialog"
      :visible="addTakeOrderDetailVisible"
      :take-id="selectedId"
    ></add-take-order-detail-list-module>
  </el-dialog>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import { getAction, postAction, putAction } from "@/api/manage";
import AddTakeOrderDetailListModule from "./AddTakeOrderDetailListModule.vue";
export default {
  name: "takeOrderDetail",
  mixins: [QueryMixins],
  components: { AddTakeOrderDetailListModule },
  props: {
    visible: {
      type: Boolean,
    },
    takeIds: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isEdit: false,
      addTakeOrderDetailVisible: false,
      selectedId: "",
      disableMixinMounted: true,
      form: { orderList: [] },
      formLabelWidth: "130px",
      columns: [
        {
          type: "selection",
          width: "33",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },

        {
          prop: "ctn",
          label: "件数",
        },
        {
          prop: "kg",
          label: "重量",
        },
        {
          prop: "cmb",
          label: "体积",
        },
        {
          prop: "warehouseName",
          label: "订单的交货仓库",
        },
      ],
      url: {
        copyTakeRecordUrl: "/warehouse/takeDelivery/copyTakeRecord",
        //查询详情
        getTakeOrderDetailById:
          "/warehouse/takeDelivery/getTakeOrderDetailById",
        //查询订单列表
        getTakeDetailByTakeId: "/warehouse/takeDelivery/getTakeDetailByTakeId",
        tihuo: "/warehouse/takeDelivery/tihuoBatch",
        editTake: "/warehouse/takeDelivery/editTakeDeliveryById",
      },
      totalCtn: 0,
      totalKg: 0,
      totalCmb: 0,
      totalOceanPkgs: 0,
    };
  },
  watch: {
    visible(val) {
      if (val && this.takeIds) {
        this.queryForm.takeId = this.takeIds;
        //勾选的订单号进行赋值
        this.init();
      }
    },
  },
  methods: {
    radioClick4(e){
      e === this.form.isNeedSendTakeDriverPhone? (this.form.isNeedSendTakeDriverPhone ='') :(this.form.isNeedSendTakeDriverPhone = e)
    },
    doCopy(val) {
      let _this = this;
      this.$copyText(val)
        .then(() => {
          _this.$message({
            message: "复制成功，可以使用ctrl+v快捷键粘贴",
            type: "success",
          });
        })
        .catch((e) => {
          _this.$message.error("复制失败");
        });
    },
    clickCopy() {
      let totalCtn = 0;
      let totalKg = 0;
      let totalCmb = 0;
      let totalOceanPkgs = 0;
      let takeNo = this.form.takeNo || "";
      let doc = this.form.createBy || "";
      let statusStr = this.form.statusStr || "";
      let useCarTypeStr = this.form.useCarTypeStr || "";
      let takeTime = this.form.takeTime || "";
      let addressDetail = this.form.address || "";
      let warehouseName = this.form.warehouseName || "";
      let address = this.form.address || "";
      let contact =  this.form.contact || "";

      if (contact) {
        contact = "/ " + contact;
      }
      let contactPhone =  this.form.contactPhone || "";
      if (contactPhone) {
        contactPhone = "/ " + contactPhone;
      }
      let isWeiban = this.form.isWeiban === "1" ? "是" : "否";
      let isBanyun = this.form.isBanyun === "1" ? "是" : "否";
      let remark = this.form.remark || "";
      let content =
        "用车序号：" +
        takeNo +
        "\n" +
        "申请人：" +
        doc +
        "\n" +
        "用车时间：" +
        takeTime +
        "\n" +
        "装货地址信息：" +
        addressDetail  + contact  + contactPhone +
        "\n" +
        "送达地址信息：" +
          warehouseName +
        "\n" +
        "是否搬货：" +
        isBanyun +
        "\n" +
        "是否尾板车：" +
        isWeiban +
        "\n" +
        "需求备注："+
         remark +
         "\n";
      let originatingWarehouseName = this.form.originatingWarehouseName || "";

      let content2 =
          "用车编号：" +
          takeNo +
          "\n" +
          "申请人：" +
          doc +
          "\n" +
          "起运仓库：" +
          addressDetail+
          "\n" +
          "到达仓库：" +
          warehouseName +
          "\n" +
          "用车时间：" +
          takeTime +
          "\n" +
          "用车备注：" + remark + "\n";
      let list = this.form.orderList;
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let obj = list[i];
          content =
            content +
            +obj.orderNo +
            " " +
            obj.ctn +
            "件 " +
            obj.kg +
            "kg " +
            obj.cmb +
            "方 " +
            obj.oceanPkgs +
            "托 " +
            obj.channelName +
            "\n";
          content2 =
              content2 +
              +obj.orderNo +
              " " +
              obj.ctn +
              "件 " +
              obj.kg +
              "kg " +
              obj.cmb +
              "方 " +
              obj.oceanPkgs +
              "托 " +
              obj.channelName +
              "\n";
          totalCtn += Number(obj.ctn);
          totalKg += Number(obj.kg);
          totalCmb += Number(obj.cmb);
          totalOceanPkgs += Number(obj.oceanPkgs);
        }
      }
      content =
        content +
        "合计： " +
        totalCtn +
        "件 " +
        totalKg.toFixed(3) +
        "kg " +
        totalCmb.toFixed(3) +
        "方 " +
        totalOceanPkgs +
        "托";
      content2 =
          content2 +
          "合计： " +
          totalCtn +
          "件 " +
          totalKg.toFixed(3) +
          "kg " +
          totalCmb.toFixed(3) +
          "方 " +
          totalOceanPkgs +
          "托";
      if (this.form.type === '2') {
        this.doCopy(content2);
      }else {
        this.doCopy(content);
      }

    },
    copyInfo(takeId){
      getAction(this.url.getTakeOrderDetailById, {
        takeIds: takeId,
      }).then((res) => {
        console.log(res);
        if (res.success) {
          this.form = JSON.parse(JSON.stringify(res.result));
          // this.form.addressDetail = this.form.address + ',' + this.form.contact + "," + this.form.contactPhone;
          this.clickCopy();
        }
      });
    },
    init() {
      getAction(this.url.getTakeOrderDetailById, {
        takeIds: this.takeIds,
      }).then((res) => {
        console.log(res);
        if (res.success) {
          this.form = JSON.parse(JSON.stringify(res.result));
          // this.form.addressDetail = this.form.address + ',' + this.form.contact + "," + this.form.contactPhone;
          this.caculate();
        }
      });
    },
    editTakeInfo() {
      let params = {
        takeId: this.takeIds,
        takeTime: this.form.takeTime,
        remark: this.form.remark,
        isWeiban: this.form.isWeiban,
        isBanyun: this.form.isBanyun,
        address: this.form.address,
        warehouseId: this.form.warehouseId,
        isNeedSendTakeDriverPhone: this.form.isNeedSendTakeDriverPhone
      };
      putAction(this.url.editTake, params).then((res) => {
        this.$message.success(res.message);
        this.init();
      });
    },
    closeAddDialog() {
      this.addTakeOrderDetailVisible = false;
      this.queryTakeDetail();
    },
    openAddDialog() {
      this.selectedId = this.takeIds;
      this.addTakeOrderDetailVisible = true;
    },
    tihuoBatch() {
      let ids = this.selectionRows.map((s) => s.id);
      let orderIds = this.selectionRows.map((s) => s.orderId);
      putAction(this.url.tihuo, { orderIds: orderIds, ids: ids }).then(
        (res) => {
          this.$message.success(res.message);
          this.queryTakeDetail();
        }
      );
    },
    queryTakeDetail() {
      postAction(this.url.getTakeDetailByTakeId, this.queryForm).then((res) => {
        this.form.orderList = res.result;
        this.caculate();
      });
    },
    caculate() {
      let list = this.form.orderList;
      this.totalCtn = 0;
      this.totalKg = 0;
      this.totalCmb = 0;
      this.totalOceanPkgs = 0;
      if (list && list.length > 0) {
        list.forEach((l) => {
          let ctn = l.ctn;
          let kg = l.kg;
          let cmb = l.cmb;
          let oceanPkgs = l.oceanPkgs;
          if (ctn) {
            this.totalCtn += Number(l.ctn);
          }
          if (kg) {
            this.totalKg += Number(l.kg);
          }
          if (cmb) {
            this.totalCmb += Number(l.cmb);
          }
          if (oceanPkgs) {
            this.totalOceanPkgs += Number(l.oceanPkgs);
          }
        });
        this.totalKg = this.totalKg.toFixed(3);
        this.totalCmb = this.totalCmb.toFixed(3);
      }
    },
    seeDetail(orderNo) {
      this.handleClose();
      this.$intoDocPage(orderNo);
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.add, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.$emit("update:visible", false);
      //不需要刷新页面
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
  height: 70vh;
  overflow: scroll;
}
.addEditBox {
  max-height: 615px;
  // overflow-y: scroll;
}
</style>
