<template>
  <el-dialog
      title="退仓加货"
      :visible="visible"
      width="50%"
      custom-class="c-custom-dialog"
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false">
    <!--:disabled="selectedRowKeys.length === 0"-->
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium" :rules="rules">
      <el-row :gutter="20" class="flex_center">
        <el-col :span="10">
          <el-form-item label="订单号" prop="orderNo">
            <el-input v-model="queryForm.orderNo" placeholder="多订单号" type="textarea"
                      @keyup.enter.native="handleQueryForm"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleQueryForm">查询</el-button>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>

    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="50vh"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'orderNo'">
          <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
        </template>
        <template v-else-if="scope.column.property === 'ctn'">
          <el-input v-model="scope.row.ctn" type="number"></el-input>
        </template>
        <template v-else-if="scope.column.property === 'kg'">
          <el-input v-model="scope.row.kg" type="number"></el-input>
        </template>
        <template v-else-if="scope.column.property === 'cmb'">
          <el-input v-model="scope.row.cmb" type="number"></el-input>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleAddOrder">确认加货</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {putAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: 'AddTakeOrderDetailListModule',
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean
    },
    takeId: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      isUsePost: true,
      disableMixinMounted:true,
      columns: [
        {
          type: "selection",
        },

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "ctn",
          label: "箱数",
        },
        {
          prop: "kg",
          label: "重量",
        },
        {
          prop: "cmb",
          label: "体积",
        },

        {
          prop: "warehouseName",
          label: "订单的交货仓库",
        },

      ],
      form: {},
      formLabelWidth: '100px',
      rules: {
        orderNo: [{required: true, message: '请输入订单号后查询',trigger: "blur",},],
      },
      url: {
        addTakeOrders: '/warehouse/takeDelivery/addGoods',

        //查询
        list: '/warehouse/takeDelivery/getAddTCGoodsIPage',
      },

    }
  },
  watch: {
    visible(val) {
      if (val && this.takeId) {
        //勾选的订单号进行赋值
        this.queryForm.takeId = this.takeId;
      }
    },
  },
  methods: {
    handleQueryForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.handleQuery();
        }
      });
    },
    handleAddOrder() {
      //加货
      putAction(this.url.addTakeOrders, this.selectionRows).then((res) => {
        this.$message({
          type: 'success',
          message: res.message
        })
        this.handleClose();
        this.$emit('ok');
      })

    },


    handleClose() {
      this.$emit('ok');
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
