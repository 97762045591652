<template>
  <div>
    <el-dialog
        :title="'复核分税：' + week"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
        append-to-body
    >
        <el-card class="box-card">
          <el-row class="inputs_row">
              <el-col :xs="24" :sm="12" :md="12" :lg="8">
                  <span>柜号：{{reviewTaxForm.containerNo}}</span>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="8">
                <span>国家：{{reviewTaxForm.country}}</span>
              </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>ETD：{{reviewTaxForm.etd}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>排柜操作：{{reviewTaxForm.operator}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>文件操作：{{reviewTaxForm.docOperator}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>派送操作：{{reviewTaxForm.endOperator}}</span>
            </el-col>

          </el-row>
        </el-card>
      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="reviewTaxForm.orderVOs"
            :cell-style="{ 'text-align': 'center' }"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'orderNo'">
<!--                  点击订单号 跳进销售结算页面-->
                  <a style="color: #00a0e9" @click.prevent="$intoDocPageCost(scope.row.orderNo) && handleClose()">{{ scope.row.orderNo}}</a>
                </template>
            </template>
        </f-table>

      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import {getAction} from "@/api/manage";
export default {
  name: "ReviewTaxDialog",
  mixins: [],
  components: {
  },
  props:{
    containerId:{
      type:String
    },
    week:{
      type:String
    },
    visible:{
      type:Boolean
    },

  },
  data() {
    return {
      reviewTaxForm:{orderVOs:[]},
      loading:false,
      url: {
        queryReviewTaxVOById:'operation/container/delivery/queryReviewTaxVOById',
      },
      columns: [

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "hasTax",
          label: "是否包税",
        },
        {
          prop: "taxMoney",
          label: "税金",
        },
        {
          prop: "billman",
          label: "收款人",
        },

      ],

    };
  },
  watch: {
    visible(val) {
      if(val && this.containerId) {
        this.loading = true;
        getAction(this.url.queryReviewTaxVOById,{id:this.containerId})
            .then(res => {
              this.reviewTaxForm = res.result;
              this.loading = false;
            }).catch(() => {
              this.loading = false;
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  methods: {
    goToCost({orderNo}){
      this.$intoDocPageCost(orderNo);
      this.handleClose();
    },
    handleClose() {
      //重置对象
      this.reviewTaxForm = {orderVOs:[]};
      this.$emit("ok");
    },
  },
  computed:{
  }
};
</script>

<style lang="scss" scoped>
</style>
