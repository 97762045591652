<!--渠道设置-->
<template>
  <div class="main-full-content" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="周数">
              <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="柜号">
              <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
              type="textarea" placeholder="多个柜号精确查询"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单号">
              <el-input type="textarea" v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户名">
              <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="24">
            <el-form-item label="目的地">
              <f-select
                  v-model="queryForm.podEnd"
                  :isNeed="queryForm.podEnd"
                  :dict="'sys_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="SHIPMENT ID" label-width="100px">
              <el-input v-model="queryForm.shipmentId" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="REFRENCE ID" label-width="100px">
              <el-input v-model="queryForm.referenceId" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="仓库代码" label-width="100px">
              <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="4" :sm="24">
            <el-form-item label="操作员">
              <f-select
                  v-model="queryForm.operateId"
                  :isNeed="queryForm.operateId"
                  :dict="'sys_user_id'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="24">
            <el-form-item label="派送操作">
              <f-select
                      v-model="queryForm.endOperatorId"
                      :isNeed="queryForm.endOperatorId"
                      :dict="'operationsId'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="4" :sm="24">
            <el-form-item label="渠道名称">
              <f-select
                  v-model="queryForm.transportStatus"
                  :isNeed="queryForm.transportStatus"
                  :dict="'transportStatus'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="4" :sm="24">
            <el-form-item label="国家">
              <f-select
                      v-model="queryForm.shipmentCountry"
                      :isNeed="queryForm.shipmentCountry"
                      :dict="'bd_country'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="6" :sm="24">
            <el-form-item label="预约时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.appointmentTime"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.appointmentTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="6" :sm="24">
            <el-form-item label="提柜时间">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.shipmentPickedTime"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.shipmentPickedTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
          <!--                    <el-form-item label="清关属性">-->
          <!--                      <f-select-->
          <!--                          :dict="'qg_prop'"-->
          <!--                          v-model="queryForm.qgProp"-->
          <!--                          :is-need="queryForm.qgProp"-->
          <!--                      ></f-select>-->
          <!--                    </el-form-item>-->
          <!--                  </el-col>-->
          <!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
          <!--                    <el-form-item label="海外仓中转服务" label-width="120px">-->
          <!--                      <f-select-->
          <!--                          :dict="'haiwai_service'"-->
          <!--                          v-model="queryForm.haiwaiService"-->
          <!--                          :is-need="queryForm.haiwaiService"-->
          <!--                      ></f-select>-->
          <!--                    </el-form-item>-->
          <!--                  </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="锁国外仓租柜租" label-width="120px">
              <f-select
                  :dict="'is_default'"
                  v-model="queryForm.lockCangzu"
                  :is-need="queryForm.lockCangzu"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="锁国外查验">
              <f-select
                  :dict="'is_default'"
                  v-model="queryForm.lockChayan"
                  :is-need="queryForm.lockChayan"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="三锁服务">
              <f-select
                  :dict="'is_default'"
                  v-model="queryForm.sanLock"
                  :is-need="queryForm.sanLock"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="地址类型">
              <f-select
                  :dict="'address_type'"
                  v-model="queryForm.shipmentType"
                  :is-need="queryForm.shipmentType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="航线名称">
              <f-select
                  :dict="'fba_solicitation'"
                  v-model="queryForm.solicitation"
                  :is-need="queryForm.solicitation"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="放货状态">
              <f-select
                  :dict="'delivery_order_status'"
                  :multiple="true"
                  v-model="queryForm.deliveryList"
                  :is-need="queryForm.deliveryList"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="是否打单超过52天">
              <el-select v-model="queryForm.isOverPrintTime" clearable filterable>
                <el-option value="1" label="是"></el-option>
                <el-option value="0" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="派送时间超过7天未更新">
              <el-select v-model="queryForm.isOverNoUpdate" clearable filterable>
                <el-option value="1" label="是"></el-option>
                <el-option value="0" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
            <el-form-item label="已派送完成无POD文件">
              <el-select v-model="queryForm.hasPod" clearable filterable>
                <el-option value="1" label="是"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                <el-form-item label="是否上传pod">
                    <el-select v-model="queryForm.isPod" clearable filterable>
                        <el-option value="1" label="是"></el-option>
                        <el-option value="0" label="否"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
              <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                  :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                  style="margin-right:3px"></i>{{ showLoadingSearch ? '收起' : '更多' }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">

        <el-button
            type="primary"
            size="medium"
            @click="handleRejection(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plgs"
        >批量拒收
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleDeliveryChange(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plpsbg"
        >批量派送变更
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleAppointment(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plyy"
        >批量预约
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleStop(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plztsh"
        >批量暂停收货
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleSend(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plpscg"

        >批量派送成功
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleSendTiQu(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.pltqcg"

        >批量提取成功
        </el-button>
        <el-button
                type="primary"
                size="medium"
                @click="exceptionOrderFinish(null, 'batch')"
                :disabled="selectionRows.length === 0 ||btnDisabled" v-if="buttonShowList.ycwjdd"
        >异常完结订单
        </el-button>
        <el-button
                type="primary"
                size="medium"
                @click="exportNoUpdateTT('导出未更新订单TT')"
                 v-if="buttonShowList.tt"
        >未更新订单TT
        </el-button>
          <el-upload
                  class="upload-demo"
                  action=""
                  :http-request="uploadPODFile"
                  :show-file-list="false"
                  ref="add"
                  :multiple="true"
                  :before-upload="beforeFile"
                  :disabled="selectionRows.length === 0"
                  v-if="buttonShowList.pod"
                  style="margin-left: 10px">
              <el-button :disabled="selectionRows.length === 0" type="primary" size="small" >批量上传POD</el-button>
          </el-upload>
        <el-button
            size="medium"
            type="primary"
            :disabled="selectionRows.length === 0"
            @click="holdOrderBatch"
            v-if="buttonShowList.plhh"
            style="margin-left: 10px"
        >批量hold货
        </el-button>
        <!--            <el-button type="primary" size="medium" class="c-theme-error-button" @click="handleBatDel" :disabled="selectionRows.length === 0">批量删除</el-button>-->
      </div>
      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
            class="stress f-m-l-12 f-cursor-pointer"
            @click="handleClearSelection"
        >清空</span
        >
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="60vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                v-if="Number(scope.row.orderStatus) < 99
                                && scope.row.diffCabinetTime > 7
                                && scope.row.status === '0'
                                && buttonShowList.cgz"
                @click="handleStrippingSendMessage(scope.row)"
            >拆柜中
            </el-button>
            <el-button
                type="text"
                @click="handleAppointment(scope.row, 'one')"
                v-if="Number(scope.row.orderStatus) < 99
                                && buttonShowList.yy"
            >预约
            </el-button>
            <!--<el-button-->
                <!--type="text"-->
                <!--@click="handleSend(scope.row, 'one')"-->
                <!--v-if="-->
                                <!--Number(scope.row.orderStatus) < 99-->
                                <!--&& scope.row.status === '0'-->
                                <!--&& $btnIsShow('operation/cabinet/WaitForHandOut','1','派送成功')"-->
            <!--&gt;-->
              <!--派送成功-->
            <!--</el-button>-->
            <!--<el-button-->
                <!--type="text"-->
                <!--@click="handleDeliveryChange(scope.row, 'one')"-->
                <!--v-if="Number(scope.row.orderStatus) < 99-->
                                <!--&& scope.row.status === '0'-->
                                <!--&& $btnIsShow('operation/cabinet/WaitForHandOut','1','派送变更')"-->
            <!--&gt;-->
              <!--派送变更-->
            <!--</el-button>-->
            <!--<el-button-->
                <!--type="text"-->
                <!--@click="handleRejection(scope.row, 'one')"-->
                <!--v-if="Number(scope.row.orderStatus) < 99-->
                                <!--&& scope.row.status === '0'-->
                                <!--&& $btnIsShow('operation/cabinet/WaitForHandOut','1','拒收')"-->
            <!--&gt;-->
              <!--拒收-->
            <!--</el-button>-->
            <!--<el-button-->
                <!--type="text"-->
                <!--@click="handleStop(scope.row, 'one')"-->
                <!--v-if="Number(scope.row.orderStatus) < 99-->
                                <!--&& scope.row.status === '0'-->
                                <!--&& $btnIsShow('operation/cabinet/WaitForHandOut','1','暂停收货')"-->
            <!--&gt;-->
              <!--暂停收货-->
            <!--</el-button>-->
            <el-button
                type="text"
                @click="handlePOClose(scope.row)"
                v-if="Number(scope.row.orderStatus) < 99
                                && buttonShowList.po"
            >
              PO关闭
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'week'">
            <a style="color: #00a0e9"
               @click.prevent="infomation(scope.row.containerId)"
            >{{ scope.row.week }}</a>
          </template>

          <template v-else-if="scope.column.property === 'isInsured'">
            <span v-if="scope.row.isInsured === '1'">是</span>
            <span v-else>否</span>
          </template>
          <template v-else-if="scope.column.property === 'podEnd'">
            <span>{{ parseDict(scope.row.podEnd, "sys_port") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'cusId'">

            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{
                scope.row.customer
              }}</a>

          </template>
          <template v-else-if="scope.column.property === 'logNoList'">
            <span v-if="scope.row.logNoList && scope.row.logNoList.length" >{{scope.row.logNoList[0].logNo}}</span>
          </template>
          <template v-else-if="scope.column.property === 'realCtn'">
            <span>{{ scope.row.realCtn }}/{{ scope.row.realKg }}/{{ scope.row.realCmb }}</span>
          </template>
          <template v-else-if="scope.column.property === 'labelType'">
            <span>{{ parseDict(scope.row.labelType, "label_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'deliveryType'">
            <span>{{ parseDict(scope.row.deliveryType, "label_delivery_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'delivery'">
                        <span :style="['1','2','5'].indexOf(scope.row.delivery) !== -1 ? 'color: red;' : ''">
                          {{ $parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus) }}
                            <span v-if="scope.row.isNoMore==='1'" style="color: red"><br>（无循环订单）</span>
                            <span v-if="scope.row.isOverPrintTime==='1'" style="color: red"><br>超52天打单</span>
                        </span>

            <span style="color: red" v-if="Number(scope.row.arrivedOver7Days) > 6 && scope.row.arrivedTime == null"><br>到港时间超过7天未更新</span>
            <span style="color: red" v-if=" Number(scope.row.pickedOver7Days) > 6 && scope.row.returnedTime == null && scope.row.specialReminderFlag != 1 && scope.row.statusTime == scope.row.pickedUpdateTime"><br>提柜时间超过7天未更新</span>
            <span style="color: red" v-if="Number(scope.row.isOverNoUpdate)==1"><br>派送时间超过7天未更新</span>
            <span style="color: red" v-if="Number(scope.row.isOver7DayDelivery)==1"><br>超7天未更新</span>

          </template>
          <template v-else-if="scope.column.property === 'orderStatus'">
            <span v-if="Number(scope.row.orderStatus) >= 99" style="color: green">已派送</span>
            <span v-else style="color: red">未派送</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
              <br>
              <span v-if="scope.row.isPod === '1'" style="color:green;">已上传POD</span>
              <span v-else style="color: red">未上传POD</span>
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <span>{{scope.row.containerNo}}</span>
            <!--<span style="color: red" v-if="Number(scope.row.overDays) > 6"><br>超过7天未更新</span>-->
          </template>
        </template>

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
        title="预约"
        :visible.sync="appointmentFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="yuyue">
        <el-form-item
            label="预约时间"
            :label-width="formLabelWidth"
            prop="appointmentTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.appointmentTime"
              type="datetime"
              size="small"
              placeholder="完成时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :dict="'the_label_type'"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="预计到达目地时间"
            :label-width="formLabelWidth"
            prop="estimatedOfArrivalTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.estimatedOfArrivalTime"
              type="datetime"
              size="small"
              placeholder="预计到达目的地时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressId"
              :dict="'sys_express'"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="logNo"
            v-if="form.deliveryType == '2'"
        >
          <el-input v-model="form.logNo" type="test"></el-input>
        </el-form-item>
        <el-form-item
            label="快递链接"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressIdUrl"
              :dict="'sys_express_url'"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="查询网址"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '5'"
            prop="website"
        >
          <el-input v-model="form.website"></el-input>
        </el-form-item>
        <el-form-item
            label="查询单号"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '5'"
            prop="logNo"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="预约号"
            :label-width="formLabelWidth"
            prop="reservationNumber"
        >
          <el-input v-model="form.reservationNumber" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
                v-if="this.form.changeTypeType !='batch'"
                label="派送数量"
                :label-width="formLabelWidth"
                prop="reservationNumber"
        >
          <el-input v-model="form.partCtn" maxlength="10" show-word-limit placeholder="非必填项目，不填写默认全部配送" ></el-input>
        </el-form-item>
        <el-form-item
                v-if="form.partCtn && this.form.changeTypeType !='batch'"
                label="派送地址"
                :label-width="formLabelWidth"
                prop="deliveryAddress"
        >
          <el-input v-model="form.deliveryAddress" maxlength="100" show-word-limit  ></el-input>
        </el-form-item>

        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <!--                    <el-upload-->
          <!--                            :action="'/sys/oss/file/upload'"-->
          <!--                            :file-list="fileList"-->
          <!--                            :http-request="handleUpload"-->
          <!--                            :on-preview="handlePreview"-->
          <!--                            :on-remove="handleRemove"-->
          <!--                            :before-upload="beforeUpload"-->
          <!--                            :on-exceed="onexceed"-->
          <!--                            v-bind="$attrs"-->

          <!--                            :disabled="uploadDisable"-->
          <!--                    >-->
          <!--                        <template slot="trigger">-->
          <!--                            <template-->
          <!--                                    v-if="this.$attrs.drag === '' || this.$attrs.drag === true"-->
          <!--                            >-->
          <!--                                <i class="el-icon-upload"></i>-->
          <!--                                <div class="el-upload__text">-->
          <!--                                    将文件拖到此处，或<em>点击上传</em>-->
          <!--                                </div>-->
          <!--                            </template>-->
          <!--                            <template v-else>-->
          <!--                                <template v-if="this.$attrs['list-type'] === 'picture-card'">-->
          <!--                                    <i class="el-icon-plus"></i>-->
          <!--                                </template>-->
          <!--                                <el-button :disabled="uploadDisable" size="small" type="primary" v-else-->
          <!--                                >{{uploadDisable ? '回显中，请稍后' : '点击上传'}}-->
          <!--                                </el-button-->
          <!--                                >-->
          <!--                            </template>-->
          <!--                        </template>-->
          <!--                        <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>-->
          <!--                    </el-upload>-->
          <f-upload v-model="fileList" :trigger="true"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            size="medium"
            @click="
            appointmentFormVisible = false;
            form = {};
            fileList = [];
          "
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('appointemt')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>

    <el-dialog
        :title="this.isTiQu?'提取成功':'派送成功'"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="paisong">
        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :dict="this.isTiQu?'the_label_type_tiqu':'the_label_type'"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
        >
          <f-select
              v-model="form.expressId"
              :dict="'sys_express'"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="expressId"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item label="快递链接" :label-width="formLabelWidth">
          <f-select
              v-model="form.expressIdUrl"
              :dict="'sys_express_url'"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
        <el-form-item
            :label="this.isTiQu?'提取时间':'派送完成时间'"
            :label-width="formLabelWidth"
            prop="deliveryTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.deliveryTime"
              type="datetime"
              size="small"
              :placeholder="this.isTiQu?'提取时间':'完成时间'"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="交货时间"
            :label-width="formLabelWidth"
            prop="deliveryGoodTime"
            v-if="this.isTiQu"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.deliveryGoodTime"
              type="datetime"
              size="small"
              placeholder="交货时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <f-upload v-model="fileList2" :trigger="true" :multiple="true"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false ; fileList = []"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('delivery')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>

    <el-dialog
        title="拒收"
        :visible.sync="dialogRejectionFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="jushou">
        <el-form-item
            label="拒收原因"
            :label-width="formLabelWidth"
            prop="rejection"
        >
          <f-select
              v-model="form.rejection"
              :isNeed="form.rejection"
              :dict="'rejection'"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogRejectionFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('rejection')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>

    <el-dialog
        title="暂停收货"
        :visible.sync="dialogStopFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="zanting">
        <el-form-item
            label="暂停类型"
            :label-width="formLabelWidth"
            prop="stopReceiving"
        >
          <f-select
              v-model="form.stopReceiving"
              :isNeed="form.stopReceiving"
              :dict="'stop_receiving'"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogStopFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('stop')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>

    <el-dialog
        title="派送变更"
        :visible.sync="dialogSendTheChangeFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="changepaisong">
        <el-form-item
            label="派送变更"
            :label-width="formLabelWidth"
            prop="changeType"
        >
          <el-select v-model="form.changeType">
            <el-option label="更改预约时间" value="0"/>
            <el-option label="更改派送地址" value="1" v-if="form.changeTypeType !='batch'"/>
            <el-option label="更改派送地址" value="1" v-if="form.changeTypeType =='batch'"/>
            <el-option label="更改派送方式" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item
            label="预约时间"
            :label-width="formLabelWidth"
            prop="appointmentTime"
            v-if="form.changeType == '0'"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.appointmentTime"
              type="datetime"
              size="small"
              placeholder="预约时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="派送地址"
            :label-width="formLabelWidth"
            prop="addressId"
            v-if="form.changeType == '1' && form.changeTypeType !='batch'"
        >
          <f-select
              v-model="form.addressId"
              :dict="'fba_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '0'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'person_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '1'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'orther_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '2'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'person_address3'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '3'"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="派送地址"
            :label-width="formLabelWidth"
            prop="addressId"
            v-if="form.changeType == '1' && form.changeTypeType =='batch'"
        >
          <f-select
              v-model="form.addressId"
              :dict="'fba_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '0'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'person_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '1'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'orther_address'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '2'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :dict="'person_address3'"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '3'"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
            v-if="form.changeType == '2'"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :dict="'the_label_type'"
          ></f-select>

        </el-form-item>
        <el-form-item
            label="预计到达目地时间"
            :label-width="formLabelWidth"
            prop="estimatedOfArrivalTime"
            v-if="form.changeType == '2'"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.estimatedOfArrivalTime"
              type="datetime"
              size="small"
              placeholder="预计到达目的地时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressId"
              :dict="'sys_express'"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="logNo"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="快递链接"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressIdUrl"
              :dict="'sys_express_url'"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="查询网址"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '5'"
            prop="website"
        >
          <el-input v-model="form.website"></el-input>
        </el-form-item>
        <el-form-item
            label="查询单号"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '5'"
            prop="logNo"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <f-upload :disabled="uploadDisable" v-model="fileList" :trigger="true">
          </f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogSendTheChangeFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="saveSendTheChange"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    <el-dialog
            title="异常订单完结"
            :visible.sync="ExceptionOrderDialogFlag"
            :close-on-click-modal="false"
    >
      <el-form :model="exceptionOrderForm" :rules="rules" ref="exceptionOrderForm">
        <el-form-item
                v-if="ExceptionOrderDialogFlag"
                prop="exceptionType"
                label="异常订单类型"
                :label-width="formLabelWidth"
        >
          <f-select :dict="'order_exception_type'"
                    v-model="exceptionOrderForm.exceptionType"
                    :isNeed="exceptionOrderForm.exceptionType"
          ></f-select>
        </el-form-item>
        <el-form-item
                prop="remark"
                label="备注"
                :label-width="formLabelWidth"
                v-if="exceptionOrderForm.exceptionType == 5"
        >
          <el-input v-model="exceptionOrderForm.remark" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="ExceptionOrderDialogFlag = false && this.handleExceptionOrderRest()"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="orderExceptionFinish"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import childTable from "@/components/common/childTable.vue";
import {setUpRouteUtil} from '@/utils/util';
import {downFile, postRefreshAction} from "../../../api/manage";
import {numValidator} from "../../../utils/validator";
import OssClientMixins from "../../../mixins/OssClientMixins";
import qaqFile from "../../../mixins/qaqFile";
export default {
  components: {childTable},
  name: "Potential",
  mixins: [QueryMixins,OssClientMixins,qaqFile],
  data() {
    const bolFileListValidator = (rule, value, callback) => {
      if (!this.fileList.length) {
        callback(new Error("请上传POD文件"));
      } else {
        callback();
      }
    };
    let checkWords = (rule, value, callback) => {
      // console.log(value)
      if (value === '') {
        this.$message.warning('关键词不能为空！')
        return callback(new Error('关键词不能为空！'))
      }
      let regBox = {regWords: /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g}
      // console.log('第一个条件')
      // console.log(regBox.regWords.test(value))
      let result = regBox.regWords.test(value)
      if (result === true) {
        this.$message.warning('关键词格不能中文混杂！！')
        return callback(new Error('关键词格不能中文混杂！！'))

      } else {
        return callback()
      }
    }

    return {
      appointmentType:'one',
      btnDisabled: false,
      exceptionOrderForm:{
        orderNo:null,
        exceptionType:null,
        remark:null
      },
      //异常订单弹窗标志
      ExceptionOrderDialogFlag: false,
      /*汽运国家二字代码，目的国家二字代码，基本代码，是否FBA 品名类型 包税*/
      qiyun: "",
      mudi: "",
      isFba: "",
      pinming: "",
      bond: "",
      fileList: [],
      fileList2: [],
      tip: "",
      rules: {
        stopReceiving: [
          {required: true, message: "请选择暂停原因", trigger: "change"},
        ],
        deliveryTime: [
          {required: true, message: "请选择派送完成时间", trigger: "change"},
        ],
        rejection: [
          {required: true, message: "请选择拒收原因", trigger: "change"},
        ],
        changeType: [
          {required: true, message: "请选择派送变更", trigger: "change"},
        ],
        appointmentTime: [
          {required: true, message: "请选择预约时间", trigger: "change"},
        ],
        deliveryType: [
          {required: true, message: "请选择派送方式", trigger: "change"},
        ],
        estimatedOfArrivalTime: [
          {
            required: true,
            message: "请选择预计到达目地时间",
            trigger: "change",
          },
        ],
        // expressId: [
        //     {required: true, message: "请选择快递公司", trigger: "change"},
        // ],
        addressId: [
          {required: true, message: "请选择派送地址", trigger: "change"},
        ],
        logNo: [
          {required: true, message: "请输入跟踪单号", trigger: "change"},
          {validator: checkWords, trigger: 'change'},
        ],
        website: [
          {required: true, message: "请输入查询网址", trigger: "change"},
        ],
        exceptionType:[
          {required: true, message: "请选择订单异常完结类型", trigger: "change"},
        ],
        remark:[
          {required: true, message: "请输入备注", trigger: "change"},

        ],
        deliveryAddress:[
          {required: true, message: "请输入派送地址", trigger: "change"}
        ],

        // reservationNumber:[
        //     {max: 80, message: '长度不能超过80', trigger: 'change'},
        // ],
        // fileId: [{validator:bolFileListValidator, trigger: "change"}],
      },
      emailList: [],
      jushouList: [],
      ids: [],
      row: {},
      form: {},
      childUrl: "",
      selectRow: {},
      formLabelWidth: "140px",
      //控制弹框显示
      dialogFormVisible: false,
      appointmentFormVisible: false,
      dialogSendTheChangeFormVisible: false,
      dialogRejectionFormVisible: false,
      dialogStopFormVisible: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: 'left'
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
          fixed: 'left'
        },
        {
          prop: 'delivery',
          label: '放货状态',
          width: 90,
          fixed: 'left'
        },
        {
          prop:'logNoList',
          label:'快递单号',
          width:200,
          fixed:'left'
        },
        // {
        //     prop: 'containerId',
        //     label: '收款状态',
        //     sortable: 'containerId',
        //     width: 140
        // },
        {
          prop: "week",
          label: "周数",
          sortable: "week",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "containerNo",
          label: "柜号",
          sortable: "containerNo",
          width: 120,
        },
        {
          prop: "appointmentTime",
          label: "预约时间",
          sortable: "appointmentTime",
          width: 130,
        },
        {
          prop: "orderNo",
          label: "订单号",
          sortable: "orderNo",
          width: 100,
          fixed: 'left'
        },
        // {
        //     prop: 'isInsured',
        //     label: '订单状态',
        //     sortable: 'isInsured',
        //     width: 140
        // },
        {
          prop: "cusId",
          label: "客户名",
          sortable: "cusId",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "podEnd",
          label: "目的地",
          sortable: "podEnd",
          minWidth: 120,
        },
        {
          prop: "shipmentCode",
          label: "仓库代码",
          minWidth: 80,
        },
        {
          prop: "shipmentId",
          label: "SHIPMENT ID",
          sortable: "shipmentId",
          minWidth: 120,
        },
        {
          prop: "referenceId",
          label: "REFERENCE ID",
          sortable: "referenceId",
          minWidth: 120,
        },
        {
          prop: "realCtn",
          label: "实件/重/实体",
          sortable: "realCtn",
          width: 140,
        },
        {
          prop: "operator",
          label: "操作员",
          sortable: "operateId",
        },
        {
          prop: "doc",
          label: "客服",
          sortable: "docId",
          minWidth: 140,
        },
        {
          prop: "channelName",
          label: "渠道名称",
          sortable: "transportStatus",
          minWidth: 140,
        },
        {
          prop: "isInsured",
          label: "保险状态",
          sortable: "isInsured",
          width: 140,
        },
        {
          prop: "printTime",
          label: "打单日期",
          sortable: "isInsured",
          width: 140,
        },
        {
          prop: "reverseNo",
          label: "预约号",
        },
        {
          prop: "kacheCom",
          label: "卡车公司",
        },
        {
          prop: "disWarehouse",
          label: "拆柜仓库",
        },
        {
          prop: "arriveWarehouseTime",
          label: "到仓时间",
        },
        {
          prop: "orderStatus",
          label: "订单是否派送",
        },
      ],
      url: {
        list: "/operation/container/WaitForHandOutList",
        saveForLabeling: "/operation/container/updateDeliveryStatus",
        appointmentDelivery: "/operation/container/appointmentDelivery",
        upload: "/sys/oss/file/upload",
        strippingSendMessage: "/operation/container/strippingSendMessage",
        saveSendTheChange: "/operation/container/updateDeliveryChange",
        getAddressIdById: '/interface/ttOrder/getAddressIdById',
        orderExceptionFinish:'/operation/container/orderExceptionFinish',
        exportXlsUrl:"/file/export/excel/OperationExcel/overNoUpdateExportXls",
        holdOrderBatch: "/interface/ttOrder/updateTtOrderDelivery1Batch",

      },
      dictCodes: [
        "os_container",
        "customer_id",
        "sys_port",
        "all_user_id",
        "label_type",
        "label_delivery_type",
        "transportStatus",
        "delivery_hold"
      ],
      uploadDisable: false, //bol文件是否可上传
      fullscreenLoading: false,
      showLoadingSearch: false,
      isTiQu: false,
      isUsePost:true
    };
  },

  watch: {
    fileList: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log('fileList文件值发生变化',val)
      }
    },
    fileList2: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log('fileList2文件值发生变化',val)
      }
    },
    '$route': {
      deep: true,
      handler(to, from) {
        let clickIndex = 0;
        if (to.path !== '/operation/cabinet/WaitForHandOut') {
          // console.log(to,from,'跳转的地址不是/docpage/Edit');
          return;
        }
        console.log("待派送路由发送变化了",to,from)
        if (this.$route.query.containerNo) {

          this.$set(this.queryForm,'containerNo',this.$route.query.containerNo);
          this.handleQuery();
        }
      }
    }
  },
  methods: {
    holdOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要hold货的订单");
      }
      let orderNoList = [];

      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(
          `确定hold货？该操作将会将订单状态变更为手动扣货！`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          }
      )
          .then(() => {
            postAction(this.url.holdOrderBatch, {orderNoList: orderNoList, isOper: this.bb['暂存仓库'] ? '1' : '0'}).then((res) => {
              this.$message.success(res.message);
              this.handleQuery();
            });
          })
          .catch(() => {});
    },
      //导出派送时间超过七天未更新TT
      exportNoUpdateTT(fileName) {
          if (!fileName || typeof fileName != 'string') {
              fileName = '导出文件'
          }
          let params = JSON.parse(JSON.stringify(this.queryForm));
          params.isOverNoUpdate = '1'
          console.log('导出参数', params)
          postRefreshAction(this.url.exportXlsUrl, params, {responseType: 'blob'}).then((data) => {
              this.loading = false;
              // this.$store.commit("setFullscreenLoading", false);
              if (data.type === 'application/json') {
                  const reader = new FileReader()
                  reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      // that.$message.error(message);// 将错误信息显示出来
                  }
                  reader.readAsText(data)
                  return false;
              }else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                  window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
              } else {
                  let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                  let link = document.createElement('a');
                  link.style.display = 'none';
                  link.href = url;
                  link.setAttribute('download', fileName + '.xls');
                  document.body.appendChild(link);
                  link.click()
                  document.body.removeChild(link);//下载完成移除元素
                  window.URL.revokeObjectURL(url) //释放掉blob对象
              }
          }).catch(err=>{
              this.loading = false;
              // this.$store.commit("setFullscreenLoading", false);
          })
      },
    handleExceptionOrderRest(){
      this.exceptionOrderForm={
        orderNo: null,
        exceptionType:null,
        remark:null
      }
    },
    //异常订单完结按钮
    exceptionOrderFinish(row,type){
      console.log(this.exceptionOrderForm)
      this.handleExceptionOrderRest()
      console.log(this.exceptionOrderForm)
      this.setForm(row,type)
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.ExceptionOrderDialogFlag = true;
    },

    infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
      this.$router.push("/operation/cabinet/Edit?id=" + id);
      // console.log(id);
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleAppointment(row, type) {
      this.appointmentType = 'one'
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        this.appointmentType = 'batch'
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.appointmentFormVisible = true;
    },
    handleStrippingSendMessage(row) {
      // console.log(row);
      putAction(this.url.strippingSendMessage, row)
          .then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success(res.message);
            }
          })
    },
    async save(type) {
      let messagetype = "";
      let bb = true;
      let formName = "form";
      if (type == "appointemt") {
          formName = "yuyue";
        this.form.status = "0";
        if (this.form.shipmentType == "0") {
          messagetype = "40";
        } else {
          messagetype = "41";
        }
      } else if (type == "delivery") {
          formName = "paisong";
        //派送成功 判断 是否包含 已经扣货的订单
        if (this.selectionRows && this.selectionRows.length > 0) {
          let isAllDelete = true;
          let warningMsg = '';
          let indexList = [];
          this.selectionRows.forEach(s => {

            let delivery = s.delivery;
            if (delivery === '1') {
              warningMsg = '存在已经扣货的订单不允许派送';
              isAllDelete = false;
              return false;
            }
          })
          if (!isAllDelete) {
            this.$message.warning(warningMsg);
            return
          }
        } else {
          let warningMsg = '存在已经扣货的订单不允许派送';
          if (this.form && this.form.delivery === '1') {
            this.$message.warning(warningMsg);
            return
          }
        }
        this.form.status = "1";
        if (this.form.deliveryType == "1" || this.form.deliveryType == "3") {
          if (this.form.shipmentType == "0") {
            messagetype = "45";
          } else {
            messagetype = "46";
          }
        } else if (this.form.deliveryType == "2") {
          messagetype = "43";
        } else if (this.form.deliveryType == "4") {
          messagetype = "44";
        } else if (this.form.deliveryType == "5") {
          messagetype = "42";
        } else if (this.form.deliveryType == "6") {
          if (this.isTiQu) {
            messagetype = "126";
          } else {
            messagetype = "53";
          }
        } else if (this.form.deliveryType == "7") {
          if (this.isTiQu) {
            messagetype = "127";
          } else {
            messagetype = "52";
          }
        } else if (this.form.deliveryType == "8") { //客户自提
          messagetype = "128";
        } else if (this.form.deliveryType == "2222") { //客户自提
          messagetype = "2222";
        }

      } else if (type == "rejection") {
          formName = "jushou";
        //拒收点击确定 跳出弹框
        await this.$confirm(`确定拒收吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }).then(() => {
          this.form.status = "2";
          messagetype = "54";
          bb = true;
        }).catch(() => {
          bb = false;
        })

      } else if (type == "stop") {
        this.form.status = "3";
        messagetype = "55";
        if (this.form.stopReceiving == "1") {
          messagetype = "56";
        } else {
          messagetype = "55";
        }
      }
      if (bb) {
        if (this.fileList && this.fileList.length > 0) {
          this.form.bolId = this.fileList[0].id;
          this.form.bolUrl = this.fileList[0].url;
        } else if (this.fileList2 && this.fileList2.length > 0) {
          this.form.bolId = this.fileList2[0].id;
          this.form.bolUrl = this.fileList2[0].url;
        }
        this.handleSendComand(this.form, messagetype, type,formName);
      }

    },
    //同步文件到 osorderfile
    syncOsOrderFile(obj) {
      let list = [];
      let url = "";
      let ossId = "";
      let size = "";
      let ext = "";
      let name = "";
      if (this.fileList && this.fileList.length > 0) {
        url = this.fileList[0].url;
        ossId = this.fileList[0].id;
        size = this.fileList[0].size;
        ext = this.fileList[0].ext;
        name = this.fileList[0].name;
      }
      if (this.fileList2 && this.fileList2.length > 0) {
        url = this.fileList2[0].url;
        ossId = this.fileList2[0].id;
        size = this.fileList2[0].size;
        ext = this.fileList2[0].ext;
        name = this.fileList2[0].name;
      }
      if (url && ossId) {
        if (obj && obj.labelList && obj.labelList.length > 0) {
          obj.labelList.forEach(l => {
            if (this.fileList && this.fileList.length > 0) {
              for (var i = 0; i < this.fileList.length; i++) {
                let aa = this.fileList[i];
                if (aa) {
                  url = aa.url;
                  ossId = aa.id;
                  size = aa.size;
                  ext = aa.ext;
                  name = aa.name;
                  list.push({orderNo: l.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
                }
              }
            }
            if (this.fileList2 && this.fileList2.length > 0) {
              for (var i = 0; i < this.fileList2.length; i++) {
                let aa = this.fileList2[i];
                if (aa) {
                  url = aa.url;
                  ossId = aa.id;
                  size = aa.size;
                  ext = aa.ext;
                  name = aa.name;
                  list.push({orderNo: l.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
                }
              }
            }
          })
        } else {
          if (this.fileList && this.fileList.length > 0) {
            for (var i = 0; i < this.fileList.length; i++) {
              let aa = this.fileList[i];
              if (aa) {
                url = aa.url;
                ossId = aa.id;
                size = aa.size;
                ext = aa.ext;
                name = aa.name;
                list.push({orderNo: obj.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
              }
            }
          } else if (this.fileList2 && this.fileList2.length > 0) {
            for (var i = 0; i < this.fileList2.length; i++) {
              let aa = this.fileList2[i];
              if (aa) {
                url = aa.url;
                ossId = aa.id;
                size = aa.size;
                ext = aa.ext;
                name = aa.name;
                list.push({orderNo: obj.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
              }
            }
          }

        }
        if (list && list.length > 0) {
          postAction('/order/os/file/saveFileList', list).then(res => {

          })
        }

      }

    },
    // 异常订单完结确认
    orderExceptionFinish(){
      this.$refs['exceptionOrderForm'].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          //新增
          putAction(this.url.orderExceptionFinish, this.exceptionOrderForm)
                  .then(res => {
                    if (res.success) {
                      this.$message.success("异常完结订单成功");
                      this.ExceptionOrderDialogFlag = false;
                      this.handleQuery();
                    } else {
                      this.btnDisabled = true;
                      this.$message.error("异常完结订单失败")
                    }
                  }).catch(err => {
            this.btnDisabled = true;
          })
          this.fullscreenLoading =false
        }
      })
    },
    saveSendTheChange() {
      this.$refs['changepaisong'].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          if (this.form.labelList && this.form.labelList.length > 0) {
            this.form.labelList.forEach(l => {
              if (this.fileList && this.fileList.length > 0) {
                l.bolId = this.fileList[0].id;
                l.bolUrl = this.fileList[0].url;

              } else if (this.fileList2 && this.fileList2.length > 0) {
                l.bolId = this.fileList2[0].id;
                l.bolUrl = this.fileList2[0].url;
              }
            })
          }
          //调用接口 上传到对应的文件集合里面去
          this.syncOsOrderFile(this.form);
          putAction(this.url.saveSendTheChange, this.form)
              .then((res) => {
                this.fullscreenLoading = false;
                // console.log(res);
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogSendTheChangeFormVisible = false;
                  this.handleQuery();
                }
              })
        }
      })
    },
    setExpressIdUrl() {
      this.form.expressIdUrl = this.form.expressId;
      // console.log(this.form);
      // this.$forceUpdate()
    },
    handlePOClose(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.$confirm(`确定PO关闭?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
          .then(() => {

            let form = JSON.parse(JSON.stringify(this.form));
            form.type = '57';
            form.actionType = "1";

            // console.log(this.ArrivalAtPortFrom)
            if (form.labelList && form.labelList.length > 0) {
              form.labelList.forEach(l => {
                if (this.fileList && this.fileList.length > 0) {
                  l.bolId = this.fileList[0].id;
                  l.bolUrl = this.fileList[0].url;
                } else if (this.fileList2 && this.fileList2.length > 0) {
                  l.bolId = this.fileList2[0].id;
                  l.bolUrl = this.fileList2[0].url;
                }
              })
            }
            this.syncOsOrderFile(form);
            putAction(this.url.saveForLabeling, form)
                .then((res) => {
                  // console.log(res);
                  if (res.success) {
                    this.$message.success("操作成功");
                    this.dialogFormVisible = false;
                    this.appointmentFormVisible = false;
                    this.dialogStopFormVisible = false;
                    this.dialogRejectionFormVisible = false;
                    this.form = {};
                    this.fileList = [];
                    this.handleQuery();
                  }
                })
                .catch((err) => {

                })
          })
          .catch(() => {
          });
    },
    handleStop(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];

      this.dialogStopFormVisible = true;
    },
    handleRejection(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.dialogRejectionFormVisible = true;
    },
    handleDeliveryChange(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
        let that = this;
        //根据id 查询出 addressid 用来回显
        getAction(this.url.getAddressIdById, {id: row.id})
            .then(res => {
              that.$set(this.form, 'addressId', res.result);
            })
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
        this.$set(this.form, 'addressId', '');
      }
      // console.log(this.form)
      this.fileList = [];
      this.fileList2 = [];
      this.dialogSendTheChangeFormVisible = true;

    },
    handleSend(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.isTiQu = false;
      this.dialogFormVisible = true;


    },
    handleSendTiQu(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.isTiQu = true;
      this.dialogFormVisible = true;


    },
    handleSendComand(row, type, actionName,formName) {
      var r = /^[1-9]\d{0,10}$/;　　//正整数
      console.log(r.test(row.partCtn))
      if((row.partCtn  && row.partCtn != '' && row.partCtn !='' && !r.test(row.partCtn))){
        this.$message.error("派送数量必须为正整数")
        return;
      }
      let form = JSON.parse(JSON.stringify(row));
      form.type = type;
      form.actionType = "1";
      form.actionName = actionName;
      form.page = 'ForHandOut'
      if (this.fileList && this.fileList.length > 0) {
        form.bolId = this.fileList[0].id;
        form.bolUrl = this.fileList[0].url;
      } else if (this.fileList2 && this.fileList2.length > 0) {
        form.bolId = this.fileList2[0].id;
        form.bolUrl = this.fileList2[0].url;
      }
      // console.log(form);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          // console.log(this.ArrivalAtPortFrom)
          if (form.labelList && form.labelList.length > 0) {
            form.labelList.forEach(l => {
              if (this.fileList && this.fileList.length > 0) {
                l.bolId = this.fileList[0].id;
                l.bolUrl = this.fileList[0].url;
              } else if (this.fileList2 && this.fileList2.length > 0) {
                l.bolId = this.fileList2[0].id;
                l.bolUrl = this.fileList2[0].url;
              }
            })
          }
          this.syncOsOrderFile(form);
          let url = this.url.saveForLabeling
          if ("appointemt" == actionName && this.appointmentType == 'one') {
            url =this.url.appointmentDelivery
          }
          putAction(url, form)
              .then((res) => {
                this.fullscreenLoading = false;
                // console.log(res);
                if (res.success) {
                  this.$message.success("操作成功");
                  this.dialogFormVisible = false;
                  this.appointmentFormVisible = false;
                  this.dialogStopFormVisible = false;
                  this.dialogRejectionFormVisible = false;
                  this.form = {};
                  this.fileList = [];
                  this.fileList2 = [];
                  this.handleQuery();
                }
              })
              .catch((err) => {
                this.fullscreenLoading = false;
              });
        } else {
          return false;
        }
      })

    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      this.jushouList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
        if (selection[i]['orderLabelLingNum'] > 0 && selection[i]['status'] == '0') {
          this.jushouList.push(selection[i]);
        }
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {}
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    //导入
    importExcel() {
    },
    handlePreview(file) {
      // console.log("文件的url", file.url);
      if (!file.url) {
        this.$message.error("下载失败");
        return;
      }
      const type = file.url.substring(file.url.lastIndexOf(".") + 1);
      // console.log("type", type);
      if (
          type === "jpg" ||
          type === "jpeg" ||
          type === "img" ||
          type === "gif" ||
          type === "png" ||
          type === "pneg"
      ) {
        // console.log("是图片预览", file.url);
        this.imgUrl = file.url;
        this.visible = true;
        this.fileList = [];
      }
      // 判断文件类型
      else if (
          type === "doc" ||
          type === "docx" ||
          type === "xlsx" ||
          type === "xls" ||
          type === "ppt" ||
          type === "pptx"
      ) {
        // console.log("不是图片下载", file.url);
        // 在当前浏览器直接下载
        document.location.href = file.url;
      } else {
        // 图片在浏览器打开 新的页面展示
        window.open(file.url, "hello");
      }
    },
    handleUpload(data) {
      // console.log("上传文件", data);
      const formData = new FormData();
      // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
      formData.append("file", data.file);
      formData.append("dir", 'operate');
      // console.log("客户文件", formData);
      postAction(this.url.upload, formData).then((res) => {
        // console.log(res);
        if (res.success) {
          this.form.bolId = res.result.id;
          this.form.bolUrl = res.result.url;

          this.fileList.push(res.result);
          if (this.$refs['form'] && this.$refs['form'].$refs['yanwu']) {
            this.$refs['form'].$refs['yanwu'].clearValidate();
          }
          if (this.$refs['yanwu']) {
            this.$refs['yanwu'].clearValidate();
          }
        }
      });
    },
    onexceed() {
      this.$message.warning("最多上传1个文件哦,亲");
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isLt2M && isFile;
    },
    handleRemove(file) {
      // console.log(file);
      if (file.id) {
        const index = this.fileList.indexOf(file);
        const {id} = this.fileList[index];
        deleteAction("/sys/oss/file/delete", {id})
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.fileList.splice(index, 1);
              }
            })
            .catch((err) => {
              this.$emit("delete", index);
              this.fileList.splice(index, 1);
            });
        //如果传递参数为合同，则删除合同
      }
    },
    setForm(row, type) {
      let that = this;
      if (row != null) {
        this.selectRow = JSON.parse(JSON.stringify(row));
        this.form = JSON.parse(JSON.stringify(row));

      }

      if (type == "one") {
        this.selectRow = JSON.parse(JSON.stringify(row));
        this.form = JSON.parse(JSON.stringify(row));
      } else {
        let orderNos = "";
        let emails = "";
        for (let i = 0; i < this.selectionRows.length; i++) {
          // console.log(this.selectionRows[i]);
          orderNos += this.selectionRows[i].orderNo + ",";
          emails += this.selectionRows[i].email + ",";
          if (this.fileList && this.fileList.length > 0) {
            this.selectionRows[i].bolId = this.fileList[0].id
            this.selectionRows[i].bolUrl = this.fileList[0].url
          }
          if (this.fileList2 && this.fileList2.length > 0) {
            this.selectionRows[i].bolId = this.fileList2[0].id
            this.selectionRows[i].bolUrl = this.fileList2[0].url
          }

        }
        this.form.orderNos = orderNos.substring(0, orderNos.length - 1);
        this.exceptionOrderForm.orderNo =orderNos.substring(0,orderNos.length - 1)
        this.form.emails = emails.substring(0, emails.length - 1);
        this.form.labelList = this.selectionRows;
        this.form.changeTypeType = 'batch'
        this.form.shipmentType = this.selectionRows[0].shipmentType;
        this.$set(this.form, 'addressId', this.selectionRows[0].addressId);
      }
    },
  },
  created() {

  },
  computed:{
    buttonShowList(){
      return {
        'plgs':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量拒收'),
        'plpsbg':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量派送变更'),
        'plyy':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量预约'),
        'plztsh':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量暂停收货'),
        'plpscg':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量派送成功'),
        'pltqcg':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量提取成功'),
        'ycwjdd':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','异常完结订单'),
        'tt':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','未更新订单TT'),
        'pod':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量上传POD'),
        'cgz':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','拆柜中'),
        'yy':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','预约'),
        'po':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','PO关闭'),
        'plhh':this.$btnIsShow('docpage/AllOrderModule','0','批量hold货'),
        'zcck':this.$btnIsShow('docpage/AllOrderModule','1','暂存仓库'),

      }
    },
    bb() {
      let ss = localStorage.getItem("USERINFO");
      var user = JSON.parse(ss);
      let staging = this.buttonShowList.zcck;
      // 排除理员、总经理、admin的权限
      if(user && user.roleName) {
        ['管理员', '总经理'].indexOf(user.roleName) !== -1 ? staging = false : '';
      }
      if(user && user.username) {
        ['admin'].indexOf(user.username) !== -1 ? staging = false : '';
      }
      return {
        '暂存仓库': staging
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
