<template>
  <div class="main-full-content">
    <el-dialog
        width="800px"
        title="预览"
        append-to-body
        :visible="imgVisible"
        custom-class="c-custom-dialog"
        :before-close="() => { imgVisible = false }">
      <img :src="imgUrl" width="100%" >
    </el-dialog>
    <el-form ref="form" :model="queryForm" label-width="90px" size="medium">
        <el-card class="box-card">
            <el-row style="display: flex; flex-wrap: wrap">
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="订单号">
                        <el-input
                                class="textarea_table"
                                v-model="queryForm.orderNo"
                                type="textarea"
                                @keyup.enter.native="handleSearch"
                        ></el-input>
                    </el-form-item>
                </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="渠道">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.transportStatusList"
                      :is-need="queryForm.transportStatusList"
                      :multiple="true"
                      :dict="'transportStatus'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
<!--                  <el-select v-model="queryForm.transportStatusList" clearable filterable multiple @click.native="initChannel">-->
<!--                    <el-option  v-for="(item,index) in channelList" :key="index" :value="item.value" :label="item.text"></el-option>-->
<!--                  </el-select>-->
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="客户名">
                  <el-input
                      class="textarea_table"
                      v-model="queryForm.customer"
                      @keyup.enter.native="handleSearch"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="业务员">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :dict="'sys_user_id'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="客服">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.docId"
                      :is-need="queryForm.docId"
                      :dict="'sys_user_id'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="录入人">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.createBy"
                      :is-need="queryForm.createBy"
                      :dict="'sys_user'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="处理人">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.chuliMan"
                      :is-need="queryForm.chuliMan"
                      :dict="'sys_user'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="恢复人">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.huifuMan"
                      :is-need="queryForm.huifuMan"
                      :dict="'sys_user'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="开单仓库">
                      <f-select 
                      class="textarea_table"
                      :dict="'delivery_point_kaidan'"
                      v-model="queryForm.suozaiCangkuId"
                      :isNeed="queryForm.suozaiCangkuId"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="拼柜仓库">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.lclPlace"
                      :is-need="queryForm.lclPlace"
                      :dict="'lclPlace'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="问题件类型">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.problemType"
                      :is-need="queryForm.problemType"
                      :data-list="problemTypeList"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="反筛选问题件类型">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.problemTypeNo"
                      :is-need="queryForm.problemTypeNo"
                      :dict="'problem_type'"
                      :data-list="problemTypeList"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="问题件状态">
                  <f-select
                      class="textarea_table"
                      v-model="queryForm.datalevel"
                      :is-need="queryForm.datalevel"
                      :dict="'problem_status'"
                      @keyup.enter.native="handleSearch"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                <el-form-item label="录入时间">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                <el-form-item label="恢复时间">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.huifuTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.huifuTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                <el-form-item label="处理时间">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.chuliTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.chuliTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="货物所在仓"  prop="volumeLocationId">
                <f-select
                    v-model="queryForm.volumeLocationId"
                    :isNeed="queryForm.volumeLocationId"
                    :dict="'delivery_point'"
                ></f-select>
              </el-form-item>
            </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                                style="margin-right: 5px;float: left">查询</el-button>
                        <el-button plain @click="handleReset" style="margin-right: 5px;float: left">重置</el-button>
                      <el-button
                          type="primary"
                          size="medium"
                          icon="el-icon-download"
                          @click="handleExportXls('问题件列表')"
                          style="margin-right: 5px;float: left"
                          v-if="buttonShowList.dc"
                      >导出</el-button>
                      <el-button
                          type="primary"
                          size="medium"
                          icon="el-icon-plus"
                          style="margin-right: 5px;float: left"
                          v-if="buttonShowList.tjwtj"
                          @click="remarkProblem()"
                      >添加问题件</el-button>
                       <el-button
                          type="primary"
                          size="medium"
                          style="margin-right: 5px;float: left"
                          v-if="buttonShowList.hfwtj"
                          :disabled="!selectedRowKeys.length"
                          @click="remarkProblemBatch()"
                      >批量恢复问题件</el-button>

                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="79vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleDel(scope.row)"

                       v-if="buttonShowList.hf && scope.row.datalevel==='0'"
              >恢复</el-button>
            <el-button type="text" @click="handleLook(scope.row)"

            >预览图片</el-button>
            <el-button type="text" @click="handleDel2(scope.row)"

                       v-if="buttonShowList.qxhf && scope.row.datalevel==='1'"
            >取消恢复</el-button>
            <el-button type="text" @click="handleFenpei(scope.row)"

                       v-if="buttonShowList.zdclr"
              >指定处理人</el-button>
            <el-button
                type="text"
                @click="handleEditPro(scope.row)"
                v-if="buttonShowList.bj"
            >编辑</el-button>
            <el-button
                type="text"
                @click="communicate(scope.row)"
                v-if="buttonShowList.gt"
            >沟通</el-button>
          </template>
          <template v-else-if="scope.column.property === 'fileIds'">
            <el-button type="text" @click="handleSee(scope.row)"
                       v-if="buttonShowList.ck"
              >查看</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'problemType'">
            <span>{{ parseDict(scope.row.problemType, "problem_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
                    style="color: #00a0e9"
                    @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'cusName'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.cusName}}</a>
          </template>
          <template v-else-if="scope.column.property === 'datalevel'">
            <span v-if="scope.row.datalevel==='0'">待恢复</span>
            <span v-else>已恢复</span>
          </template>
          <template v-else-if="scope.column.property === 'isPaigui'">
            <span v-if="scope.row.isPaigui==='已排柜'" style="color:red">{{scope.row.isPaigui}}</span>
            <span v-else>{{scope.row.isPaigui}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
      title="指定处理人"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <el-form :model="fenpei" ref="fenpei" :rules="rules">
        <el-form-item label="处理人" label-width="140px" prop="chuliMan">
          <f-select
            v-model="fenpei.chuliMan"
            :is-need="fenpei.chuliMan"
            :dict="'sys_user'"
            autocomplete="off"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" @click="fenpeiSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
        title="问题件"
        :visible="visible2"
        width="50%"
        append-to-body
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose3">
      <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="warehouseRemark" label="问题描述">
              <el-input class="padding_right_area" v-model="form.warehouseRemark" maxlength="2000" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="remark" label="处理状态">
              <el-input class="padding_right_area" v-model="form.remark" maxlength="500" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="problemType" label="问题类型">
              <f-select v-if="form.problemTypeList && form.problemTypeList.length > 0"
                  v-model="form.problemTypeList"
                  :is-need="form.problemTypeList"
                        :multiple="true"
                  :dict="'problem_type'"
              ></f-select>
              <f-select v-else
                        v-model="form.problemType"
                        :is-need="form.problemType"
                        :dict="'problem_type'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="fileList" label="附件上传">
              <f-upload v-model="form.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
            <el-button size="medium" @click="handleClose3">取消</el-button>
            <el-button type="primary" size="medium" @click="editPro">确定</el-button>
        </span>
    </el-dialog>
    <el-dialog
      title="问题件"
      :visible="visible"
      width="50%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="handleClose2"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="94px"
        size="medium"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="" label="问题类型:">
              <span>{{ parseDict(form.problemType, "problem_type") }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="" label="问题描述:">
              <span>{{ form.warehouseRemark }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="" label="处理状态:">
              <span>{{ form.remark }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="fileList" label="附件上传">
              <f-upload v-model="form.fileList" :dir="'osOrder'"></f-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose2">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <add-no-order-problem-dialog
        :visible.sync="proVisible"
        :problem-type-list="problemTypeList"
        @ok="handleQuery"
    ></add-no-order-problem-dialog>

    <problem-comments  :visible.sync="commetVisible" :row="selectedRow"
                       @ok="handleQuery"></problem-comments>
  </div>
</template>

<script>
import { putAction, deleteAction, getallusers } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction,postAction } from "../../api/manage";
import { setUpRouteUtil } from '@/utils/util';
import AddNoOrderProblemDialog from "./modules/AddNoOrderProblemDialog";
import problemComments from "@/views/warehouse2.0/modules/problemComments";

export default {
  name: "ProblemModule",
  mixins: [QueryMixins],
  components: {
            AddNoOrderProblemDialog,
    problemComments
  },
  data() {
    return {
      selectedRow:{},
      channelList:[],
      commetVisible:false,
      imgVisible:false,
      imgUrl:'',
      proVisible:false,
      dialogFormVisible: false,
      visible: false,
      visible2: false,
      form: { fileList: [] ,transportStatusList: [],problemTypeList:[]},
      fenpei: {},
      dictCodes: ["problem_type"],
      rules: {
        // chuliMan: [
        //   { required: true, message: "请选择人员", trigger: "change" },
        // ],
        warehouseRemark: [
          { required: true, message: '请输入问题描述', trigger: 'blur' },
        ],
        problemType: [
          { required: true, message: '请选择问题类型', trigger: 'change' },
        ],
      },
      columns: [
           {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "orderNo",
          label: "订单号",
          sortable: "custom",

        },
        {
          prop: "datalevel",
          label: "问题件状态",
          sortable: "custom",
        },
          {
          prop: "allVolumeLocation",
          label: "货物所在仓",

        },
          {
              prop: "isPaigui",
              label: "是否排柜",
          },
        {
          prop: "huifuMan",
          label: "恢复人",
          sortable: "custom",
        },
        {
          prop: "huifuTime",
          label: "恢复时间",
          sortable: "custom",
          minWidth: 140,

        },
          {
          prop: "transportStatus",
          label: "渠道",
          sortable: "transportStatus",

        },
        {
          prop: "chuliMan",
          label: "处理人",
          sortable: "custom",

        },
        {
          prop: "chuliTime",
          label: "处理时间",
          sortable: "custom",
          minWidth: 140,

        },
        {
          prop: "cusName",
          label: "客户名",
          sortable: "custom",

        },
        {
          prop: "salesName",
          label: "业务员",
          sortable: "custom",

        },
        {
          prop: "docName",
          label: "客服",
          sortable: "custom",

        },
        {
          prop: "remark",
          label: "处理状态",
          sortable: "custom",
          // overFlow:true,
        },
        {
          prop: "warehouseRemark",
          label: "问题描述",
          sortable: "custom",
          // overFlow:true,
        },
        {
          prop: "problemType",
          label: "问题类型",
          sortable: "custom",

        },
        {
          prop: "suozaiCangkuName",
          label: "开单仓库",
          sortable: "custom",

        },
       
        {
          prop: "createBy",
          label: "录入人",
          sortable: "custom",

        },
        {
          prop: "createTime",
          label: "录入时间",
          sortable: "custom",
          minWidth: 140,

        },
        {
          prop: "fileIds",
          label: "附件",
          sortable: "custom",

        },
      ],
      url: {
        list: "/interface/ttOrder/getProblemIPage",
        huifu: "/interface/ttOrder/updateProblemHuifu",
        qxhuifu: "/interface/ttOrder/updateProblemNoHuifu",
        fenpei: "/interface/ttOrder/updateProblemChuli",
        edit: "/interface/ttOrder/updateFbaWarehouseProblemInfo",
        getById: "/interface/ttOrder/getOneProblemById",
        exportXlsUrl: '/file/export/excel/downloadProblemVoExcel',
        batchUpdateProblemHuifu:"/interface/ttOrder/batchUpdateProblemHuifu"

      },
      isUsePost:true,
    };
  },
  methods: {
    communicate(row) {
      this.selectedRow = JSON.parse(JSON.stringify(row));
      this.commetVisible = true;
    },
    remarkProblem() {
      //打开标记问题弹窗
      this.proVisible = true;
    },
    remarkProblemBatch() {
            putAction(this.url.batchUpdateProblemHuifu, this.selectionRows.map(item=>{
        return {
          id:item.id,
          orderNo:item.orderNo
        }
      })).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.form = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    },
    handlePreview(file) {
      if (!file.url) {
        this.$message.error('下载失败')
        return
      }
      const type = file.url.substring(file.url.lastIndexOf('.')+1);
      if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
        this.imgUrl = file.url
        this.imgVisible = true
      }
      // 判断文件类型
      else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
        // 在当前浏览器直接下载
        document.location.href = file.url
      } else {
        // 图片在浏览器打开 新的页面展示
        window.open(file.url, 'hello')
      }

    },
    handleDel2({ id, orderNo }) {
      this.$confirm(`确定恢复订单${orderNo}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            putAction(this.url.qxhuifu, { id, orderNo }).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {});
    },
    initCusPage({cusId}) {
      if (cusId){
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/customer/Edit');
        this.$router.push(`/customer/Edit?id=${cusId}`)
      }

    },
    //进入客服页
    intoDocpage(orderNo) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleSubmit() {
      if (this.form.fileList) {
        this.form.fileIds = this.form.fileList
          .map(function (e) {
            return e.id;
          })
          .join(",");
      }
      putAction(this.url.edit, this.form).then((res) => {
        this.$message.success(res.message);
        this.visible = false;
        this.handleQuery();
      });
    },
    editPro() {
      let url = this.url.edit
      this.$refs.form.validate((valid) => {
        if (valid) {
          /*将filelist的id取出来拼成ids给form*/
          if (this.form.fileList){
            this.$set(this.form,'fileIds',this.form.fileList.map(function (e) {
              return  e.id
            }).join(","));
          }
          if (!this.form.orderNo){
            this.$set(this.form,'orderNo',this.row.orderNo);
          }
          if (this.form.problemTypeList && this.form.problemTypeList.length > 0) {
            this.form.problemType = this.form.problemTypeList.join(',');
          }
          putAction(url,  this.form).then((res)=>{
            this.$message({
              type: 'success',
              message: res.message
            })
            this.visible2=false;
            this.handleQuery();
          })
        }
      })
    },
    handleLook(row) {
      getAction(this.url.getById, { id: row.id }).then((res) => {
        this.form = res.result;
        if (!this.form.fileList || this.form.fileList.length===0) {
          this.$message.warning('没有上传图片。无法预览');
          return false;
        } else {
          this.handlePreview(this.form.fileList[0])
        }

      });
    },
    handleSee(row) {
      getAction(this.url.getById, { id: row.id }).then((res) => {
        this.form = res.result;
        if (!this.form.fileList) {
          this.form.fileList = [];
        } else {
          this.form.fileList.map((f) => {
            // f.name = f.fileName;
          });
        }
        this.visible = true;
      });
    },
    handleEditPro(row) {
      getAction(this.url.getById, { id: row.id }).then((res) => {
        this.form = res.result;
        if (!this.form.fileList) {
          this.form.fileList = [];
        } else {
          this.form.fileList.map((f) => {
            // f.name = f.fileName;
          });
        }
        if (this.form && this.form.problemType && this.form.problemType.indexOf(',') !== -1) {
          this.form.problemTypeList = this.form.problemType.split(',');
        }
        this.visible2 = true;

      });
    },
    fenpeiSubmit() {
      this.$refs["fenpei"].validate((valid) => {
        if (valid) {
          putAction(this.url.fenpei, this.fenpei).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.dialogFormVisible = false;
            this.handleQuery();
          });
        }
      });
    },
    handleFenpei(row) {
      this.$set(this.fenpei, "id", row.id);
      this.$set(this.fenpei, "chuliMan", row.chuliMan);
      this.$set(this.fenpei, "orderNo", row.orderNo);
      this.dialogFormVisible = true;
    },
    handleDel({ id, orderNo }) {
      this.$confirm(`确定恢复订单${orderNo}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.huifu, { id, orderNo }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleClose() {
      this.dialogFormVisible = false;

      if (this.$refs["fenpei"]) {
        this.$refs["fenpei"].clearValidate();
      }
      this.handleQuery();
    },
    handleClose3() {
      this.visible2 = false;
      this.handleQuery();
    },
    handleClose2() {
      this.visible = false;
      if (this.$refs["form"]) {
        this.$refs["form"].clearValidate();
      }
      this.handleQuery();
    },
    initChannel(){
      getAction('/sys/dict/getDictItems/transportStatus').then(res=>{
        this.channelList = res.result;
      })
    },
    getTransportStatus(list){
      if (list != null && list.length > 0) {
        this.$set(this.queryForm,'transportStatus',list.join(","))
      }else {
        this.$set(this.queryForm,'transportStatus',"")
      }
    },
    initVue() {
      // this.queryForm.orderNo = this.$route.query.orderNo;
      console.log("created,log初始化....");
     // this.initChannel();
      this.handleQuery();
    },
    handleQuery() {
      this.tableData = [];
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm, ...this.initData}
      // for (let item in form) {
      //     if (typeof form[item] === 'string') {
      //         form[item] = form[item].trim()
      //         if (form[item] === '') {
      //             form[item] = null
      //         }
      //     }
      // }
      //清空字符串的前后空格
      this.trimParamsStr(form);
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else if (this.enabledCreatetime){
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
        //排序
        //提货中查询
        if (this.queryForm.column === 'sort') {
          this.$set(params, 'column', 'sort')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
      // let method = "GET";
      // if (this.isUsePost) {
      //     method = "POST";
      // }
      if (!this.isUsePost) {
        getAction(this.url.list, params).then((res) => {
          // consoloe.log(res)
          if (res.success) {
            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const {records, total} = res.result
              this.tableData = records || res.result;
              this.ipagination && (this.ipagination.total = total || res.result.length)
            } else {

              this.initTableData();
            }

          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        }).catch(err => {
          this.loading = false;
          // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        })
      }else {
        postAction(this.url.list, params).then((res) => {
          // consoloe.log(res)
          if (res.success) {
            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            if (res.result && res.result.page) {
              const {records, total} = res.result.page;
              this.tableData = records;
              this.problemTypeList = res.result.problemTypeList;

              this.ipagination && (this.ipagination.total = total || res.result.length)
            } else {

              this.initTableData();
            }

          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        }).catch(err => {
          this.loading = false;
          this.initTableData();
          // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        })
      }

    },
  },

  created() {
    // this.queryForm.orderNo = this.$route.query.orderNo;
  },
  computed : {
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('docpage/ProblemModule','1','导出'),
        'tjwtj':this.$btnIsShow('docpage/ProblemModule','0','添加问题件'),
        'hf':this.$btnIsShow('docpage/ProblemModule','1','恢复'),
        'qxhf':this.$btnIsShow('docpage/NoProblemModule','1','取消恢复'),
        'zdclr':this.$btnIsShow('docpage/ProblemModule','1','指定处理人'),
        'bj':this.$btnIsShow('docpage/ProblemModule','1','编辑'),
        'gt':this.$btnIsShow('docpage/ProblemModule','1','沟通'),
        'ck':this.$btnIsShow('docpage/ProblemModule','1','查看'),
        'hfwtj':this.$btnIsShow('docpage/ProblemModule','0','批量恢复问题件'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .no_margin {
    margin: 12px 0;
    padding: 0 0px 0px 0px !important;
    height: 22px !important;
  }
</style>
