<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="auto"
      size="medium"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单号">
              <el-input
                type="textarea"
                class="textarea_table"
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户">
              <el-input
                v-model="queryForm.customer"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="柜号">
              <el-input
                v-model="queryForm.containerNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="内部单号">
              <el-input
                class="textarea_table"
                v-model="queryForm.withinOrderRemark"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="应收锁定" label-width="80px">
              <f-select
                v-model="queryForm.recLock"
                :dict="'lock_status'"
                :is-need="queryForm.recLock"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户等级" label-width="80px">
              <f-select
                v-model="queryForm.grade"
                :dict="'customer_type'"
                :is-need="queryForm.grade"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="业务类型" label-width="80px">
              <f-select
                :dict="'container_type'"
                v-model="queryForm.containerType"
                :is-need="queryForm.containerType"
              ></f-select>
            </el-form-item>
          </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="应付锁定" label-width="80px">
                            <f-select v-model="queryForm.payLock" :dict="'lock_status'"
                                      :is-need="queryForm.payLock"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称" label-width="80px">
                            <f-select :dict="'transportStatus'" :multiple="true" v-model="queryForm.transportStatusList"
                                      :is-need="queryForm.transportStatusList"></f-select>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                        <el-form-item label="拼柜类型" label-width="80px">-->
<!--                            <el-select v-model="queryForm.pglx" filterable clearable>-->
<!--                                <el-option value="0" label="LCL"></el-option>-->
<!--                                <el-option value="1" label="FCL"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="拼柜仓库" label-width="80px">
                      <f-select :dict="'lclPlace'" :multiple="true" v-model="queryForm.lclPlaceList"
                                :is-need="queryForm.lclPlaceList"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="FBA CODE" label-width="90px">
                      <el-input v-model="queryForm.fbaCode" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="CODE or ZIP" label-width="90px">
                            <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="船名航次" label-width="90px">
                            <el-input v-model="queryForm.shipNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="报关方式" label-width="80px">
                            <f-select :dict="'os_declare_type'" v-model="queryForm.declareType"
                                      :is-need="queryForm.declareType" :remove-value="'3'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务提成发放状态" label-width="110px">
                            <f-select :dict="'salesman_commission'" v-model="queryForm.salesmanCommission"
                                      :is-need="queryForm.salesmanCommission"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作提成发放状态" label-width="110px">
                            <f-select :dict="'salesman_commission'" v-model="queryForm.operateCommission"
                                      :is-need="queryForm.operateCommission"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单是否锁定" label-width="90px">
                            <f-select :dict="'is_default'" v-model="queryForm.isLockOrder"
                                      :is-need="queryForm.isLockOrder"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否入仓" label-width="90px">
                            <el-select  v-model="queryForm.isRealCmb" clearable filterable>
                              <el-option value="0" label="否"></el-option>
                              <el-option value="1" label="是"></el-option>
                              <el-option value="2" label="已开单未入仓"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="订单已有的状态" label-width="100px">
                      <f-select :dict="'order_time_dian'" v-model="queryForm.manZuColumnList" :multiple="true"
                                :is-need="queryForm.manZuColumnList"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="订单没有的状态" label-width="100px">
                      <f-select :dict="'order_time_dian'" v-model="queryForm.buManZuColumnList" :multiple="true"
                                :is-need="queryForm.buManZuColumnList"></f-select>
                    </el-form-item>
                  </el-col>

                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="交货地点" label-width="80px">
                            <f-select :dict="'delivery_point'" v-model="queryForm.deliveryPoint"
                                      :is-need="queryForm.deliveryPoint"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否包税" label-width="80px">
                            <f-select :dict="'is_default'" v-model="queryForm.hasTax"
                                      :is-need="queryForm.hasTax"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作员" label-width="80px">
                            <f-select :dict="'operationsId'" v-model="queryForm.operateId"
                                      :is-need="queryForm.operateId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服" label-width="80px">
                            <f-select :dict="'docId'" v-model="queryForm.docId" :is-need="queryForm.docId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员" label-width="80px">
                            <f-select :dict="'salesmanId'" v-model="queryForm.salesmanIdList"
                                      :is-need="queryForm.salesmanIdList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款人" label-width="80px">
                            <f-select :dict="'billman_id'" v-model="queryForm.billmanId"
                                      :is-need="queryForm.billmanId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单状态" label-width="80px">
                            <f-select :dict="'all_order_status'" v-model="queryForm.orderStatus"
                                      :is-need="queryForm.orderStatus"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="放货状态">
                            <f-select :dict="'delivery_order_status'" :multiple="true" v-model="queryForm.deliveryList" :is-need="queryForm.deliveryList"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="问题件状态">
                            <f-select :dict="'warning_status'" v-model="queryForm.warningStatus"
                                      :is-need="queryForm.warningStatus"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款状态">
                            <f-select :dict="'os_rec_status'" :multiple="true" v-model="queryForm.recStatusList"
                                  :is-need="queryForm.recStatusList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="付款状态">
                            <f-select :dict="'os_pay_status'" v-model="queryForm.payStatus"
                                      :is-need="queryForm.payStatus"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="目的地">
                            <f-select :dict="'bd_country'" v-model="queryForm.shipmentCountry"
                                      :is-need="queryForm.shipmentCountry"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="SHIPMENT ID" label-width="90px">
                            <el-input  type="textarea"
                                       class="textarea_table"
                                       v-model="queryForm.shipmentId"
                                       @keyup.enter.native="handleSearch">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="起运港">
                            <f-select :dict="'hy_bd_port'" v-model="queryForm.pol" :is-need="queryForm.pol"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="目的港">
                            <f-select :dict="'hy_bd_port'" v-model="queryForm.podEnd"
                                      :is-need="queryForm.podEnd"></f-select>
                        </el-form-item>
                    </el-col>
<!--                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                        <el-form-item label="锁国外仓租柜租">-->
<!--                            <f-select :dict="'is_default'" v-model="queryForm.lockCangzu"-->
<!--                                      :is-need="queryForm.lockCangzu"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="锁价服务">
                            <f-select :dict="'is_default'" v-model="queryForm.lockChayan"
                                      :is-need="queryForm.lockChayan"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="欧洲三锁">
                            <f-select :dict="'is_default'" v-model="queryForm.sanLock"
                                      :is-need="queryForm.sanLock"></f-select>
                        </el-form-item>
                    </el-col>
          <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="产品名">
              <el-input  v-model="queryForm.goodsName"></el-input>
            </el-form-item>
          </el-col>
<!--                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                        <el-form-item label="地址类型">-->
<!--                            <f-select :dict="'address_type'" v-model="queryForm.shipmentType"-->
<!--                                      :is-need="queryForm.shipmentType"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                    <el-form-item label="地址类型">-->
<!--&lt;!&ndash;                      <f-select&ndash;&gt;-->
<!--&lt;!&ndash;                          :dict="'address_type'"&ndash;&gt;-->
<!--&lt;!&ndash;                          v-model="queryForm.shipmentType"&ndash;&gt;-->
<!--&lt;!&ndash;                          :is-need="queryForm.shipmentType"&ndash;&gt;-->
<!--&lt;!&ndash;                      ></f-select>&ndash;&gt;-->

<!--                      &lt;!&ndash;              <el-select&ndash;&gt;-->
<!--                      &lt;!&ndash;                  multiple&ndash;&gt;-->
<!--                      &lt;!&ndash;                  collapse-tags&ndash;&gt;-->
<!--                      &lt;!&ndash;                  :dict="'address_type'"&ndash;&gt;-->
<!--                      &lt;!&ndash;                  v-model="queryForm.shipmentType"&ndash;&gt;-->
<!--                      &lt;!&ndash;                  :is-need="queryForm.shipmentType"&ndash;&gt;-->
<!--                      &lt;!&ndash;                  style="margin-left: 20px;"&ndash;&gt;-->
<!--                      &lt;!&ndash;                  placeholder="请选择">&ndash;&gt;-->
<!--                      &lt;!&ndash;              </el-select>&ndash;&gt;-->

<!--                                    <f-select-->
<!--                                        :dict="'address_type'"-->
<!--                                        v-model="queryForm.shipmentType"-->
<!--                                        :is-need="queryForm.shipmentType"-->
<!--                                        multiple-->
<!--                                        collapse-tags-->
<!--                                        style="margin-left: 20px;"-->
<!--                                        placeholder="请选择">-->
<!--                                    </f-select>-->


<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="地址类型" label-width="80px">
                      <f-select :dict="'address_type'" :multiple="true" v-model="queryForm.shipmentType"
                                :is-need="queryForm.shipmentType"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="航线名称">
                            <f-select :dict="'fba_solicitation'" v-model="queryForm.solicitation"
                                      :is-need="queryForm.solicitation"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订舱单号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                     <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="文件操作">
                           <f-select
                        :isNeed="queryForm.docOperateId"
                        v-model="queryForm.docOperateId"
                        :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                     <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                         <el-form-item label="派送操作">
                           <f-select
                        :isNeed="queryForm.endOperateId"
                        v-model="queryForm.endOperateId"
                        :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="工作单日期" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="应收锁定时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.recLockTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.recLockTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="应付锁定时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.payLockTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>

                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.payLockTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="装柜时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.containerLoadStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.containerLoadEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="入仓时间" label-width="80px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.rcTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.rcTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船日期" label-width="80px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="到港日期" label-width="80px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.shipmentArrivedTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.shipmentArrivedTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="红线订单">
                            <el-select v-model="queryForm.isLowLevel"  clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="销售锁价服务" label-width="100px">
                            <el-select v-model="queryForm.saleLockPrice"  clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="2" label="是+分"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
                     <el-form-item label="第三方收款">
                      <el-select v-model="queryForm.thirdPartyCollection"  clearable filterable>
                        <el-option value="0" label="风控催收"></el-option>
                        <el-option value="1" label="诉讼催收"></el-option>
                        <el-option value="2" label="其他"></el-option>
                      </el-select>
                     </el-form-item>
                    </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="销售组别" label-width="100px">
                            <el-select  :style="{ width: inputWid()}" v-model="queryForm.departId" multiple clearable filterable>
                                <el-option
                                        v-for="item in departList"
                                        :key="item.value"
                                        :label="item.text"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" label-width="100px">
            <el-form-item label="数据时间">
              <f-select
                  class="textarea_table"
                  v-model="queryForm.dataCreateTime"
                  :is-need="queryForm.dataCreateTime"
                  :dict="'data_create_time'"
                  @keyup.enter.native="handleSearch"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item prop="orderTypeTwoList" label="订单类型2">
              <f-select
                  :dict="'order_type_two'"
                  v-model="queryForm.orderTypeTwoList"
                  :is-need="queryForm.orderTypeTwoList"
                  multiple
              ></f-select>
            </el-form-item>

          </el-col>
          <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label="能否排柜">
              <el-select v-model="queryForm.isCanArrangeContainer" clearable filterable>
                <el-option value="1" label="能"></el-option>
                <el-option value="0" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

                        <el-form-item label-width="20px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                            <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch">
                                <i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                                   style="margin-right:3px"></i>
                                {{showLoadingSearch ? '收起' :'更多'}}
                            </el-button>
                        </el-form-item>
                         <el-form-item label="" label-width="20px" v-if="buttonShowList.dcczbb">
                            <el-button
                                    size="medium"
                                    type="primary"
                                    @click="clickOverValue('0')"
                                    v-if ="!queryForm.overSevenLoadCabinet || queryForm.overSevenLoadCabinet == '0'"
                            >超7天未装柜
                            </el-button>
                            <el-button
                                    v-else
                                    size="medium"
                                    type="info"
                                    @click="clickClearOverValue('0')"
                            >超7天未装柜
                            </el-button>
                        </el-form-item>
                        <el-form-item label="" label-width="20px"  v-if="buttonShowList.dcczbb">
                            <el-button
                                    size="medium"
                                    type="primary"
                                    @click="clickOverValue('1')"
                                    v-if ="!queryForm.overSevenHandOut || queryForm.overSevenHandOut == '0'"
                            >超7天未派送
                            </el-button>
                            <el-button
                                    v-else
                                    size="medium"
                                    type="info"
                                    @click="clickClearOverValue('1')"
                            >超7天未派送
                            </el-button>
                        </el-form-item>
                </el-row>
            </el-card>
        </el-form>

        <el-dialog title="统计数据显示"
                   :visible.sync="dialogKdCountVisible"
                   :close-on-click-modal="false"
                   v-if="dialogKdCountVisible">
            <f-table
                    ref="kdCountTb"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnsCount"
                    :data="kdCountNum"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh">
            </f-table>
        </el-dialog>

        <el-dialog title="特别提醒"
                   :visible.sync="dialogTieReminderVisible"
                   :close-on-click-modal="false"
                   v-if="dialogTieReminderVisible">
            <el-form :model="tieForm" ref="tieForm" :rules="rules">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="tieForm.title"></el-input>
                </el-form-item>
                <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="tieForm.remark" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="文件类型" :label-width="formLabelWidth" prop="fileType">
                    <f-select :dict="'os_order_filelist'" v-model="tieForm.fileType"
                              :isNeed="tieForm.fileType"></f-select>
                </el-form-item>
                <el-form-item label="提醒文件" :label-width="formLabelWidth" prop="fileList">
                    <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
                </el-form-item>
                <el-form-item label="是否推送邮件" :label-width="formLabelWidth" prop="isSendEmail">

                    <template>
                        <el-radio-group v-model="tieForm.isSendEmail">
                            <el-radio label="0">否</el-radio>
                            <el-radio label="1">是</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>
                <el-form-item label="是否异常动态" :label-width="formLabelWidth" prop="isRemind">
                    <el-radio-group v-model="tieForm.isRemind">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
            </div>
        </el-dialog>

        <el-card class="box-card last_card">
            <div class="f-p-b-10">
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-download"
                    @click="exportExcelPlus(url.exportTT,'tt工作表')"
                    :loading="exportLoading"
                    v-if="buttonShowList.dctt"
                >导出TT</el-button>

                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-download"
                    :disabled="selectionRows.length === 0"
                    @click="rucangexcel"
                    :loading="exportLoading"
                    v-if="buttonShowList.hbrcd"
                >合并入仓单</el-button>


                <el-button
                    size="medium"
                    type="primary"
                    :disabled="selectionRows.length === 0"
                    @click="holdOrderBatch"
                    v-if="buttonShowList.plhh"
                >批量hold货
                </el-button>
                <el-button
                    size="medium"
                    type="primary"
                    :disabled="selectionRows.length === 0"
                    @click="releaseOrderBatch"
                    v-if="buttonShowList.plfh"
                >批量放货
                </el-button>
                <el-button type="primary" @click="tongjiCmb">统计方数</el-button>
                <el-button
                    type="primary"
                    v-if="buttonShowList.plqxdd"
                    :disabled="selectionRows.length === 0"
                    @click="cancelOrderBatch"
                >批量取消订单
                </el-button>
                <el-button
                    type="primary"
                    v-if="buttonShowList.pljsyzgdd"
                    :disabled="selectionRows.length === 0"
                    @click="unlockOrderBatch"
                >批量解锁已装柜
                </el-button>
                <el-button
                    type="primary"
                    v-if="buttonShowList.plsdyzgdd"
                    :disabled="selectionRows.length === 0"
                    @click="lockOrderBatch"
                >批量锁定已装柜
                </el-button>
                <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.downloadHyYfModel, '海运应付批量导入模板下载')"
                    v-if="buttonShowList.inyf"
                >应付导入模板
                </el-button>
                <el-upload
                        action="/"
                        :show-file-list="false"
                        :http-request="uploadSectionFile"
                        accept=".xls,.xlsx"
                        style="margin:0 10px;display:inline">
                    <el-button
                        size="medium"
                        type="primary"
                        :loading="btnLoading"
                        v-if="buttonShowList.inyf"
                    >应付导入
                    </el-button>
                </el-upload>
                <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.downloadHyYsModel, '海运应收批量导入模板下载')"
                    v-if="buttonShowList.ysmbxz"
                >应收导入模板
                </el-button>
                <el-upload
                        action="/"
                        :show-file-list="false"
                        :http-request="uploadSectionFileYs"
                        accept=".xls,.xlsx"
                        style="margin:0 10px;display:inline">
                    <el-button
                        size="medium"
                        type="primary"
                        :loading="btnLoadingYs"
                        v-if="buttonShowList.ysdr"
                    >应收导入
                    </el-button>
                </el-upload>

        <el-button
          type="primary"
          v-if="buttonShowList.tj"
          @click="osCount"
          >统计
        </el-button>
        <el-button
          type="primary"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.tbtx"
          @click="tieReminder"
          >特别提醒
        </el-button>
        <el-button
          type="primary"
          :disabled="selectionRows.length === 0 && !queryForm.orderNo"
          v-if="buttonShowList.gxcztc"
          @click="updateOperationCommission"
        >
          更新操作提成
        </el-button>
        <el-button
          type="primary"
          :disabled="selectionRows.length === 0 && !queryForm.orderNo"
          v-if="buttonShowList.gxywtc"
          @click="updateSalesCommission"
        >
          更新业务提成
        </el-button>
        <!--               <el-button type="primary" :disabled="selectionRows.length === 0 && !queryForm.orderNo"-->
        <!--                         v-if="$btnIsShow('docpage/AllOrderModule','0','更新销售锁价服务')" @click="updateSalesLockPrice">-->
        <!--                更新销售锁价服务-->
        <!--               </el-button>-->

        <el-button
          type="primary"
          :disabled="selectionRows.length === 0 && !queryForm.orderNo"
          v-if="buttonShowList.gxxssjfw"
          @click="updateSalesLockPrice"
        >
          更新销售锁价服务</el-button
        >

              <el-button
                  type="primary"
                  :disabled="selectionRows.length === 0 && !queryForm.orderNo"
                  v-if="buttonShowList.bjdsfsk"
                  @click="updateThirdPartyCollection"
              >
                标记第三方收款</el-button
              >

                <el-upload
                        action="/"
                        :show-file-list="false"
                        :http-request="uploadFileToOsOrderFile"
                        accept=".xls,.xlsx"
                        multiple
                        style="display: inline-flex; margin: 0px 10px"
                        v-if="buttonShowList.plscwj"
                >
                    <el-button size="medium" type="primary">批量上传文件</el-button>
                </el-upload>
                <el-button
                        type="primary"
                        v-if="buttonShowList.plxgdcsj"
                        @click="showUpdateCargoTimeDialog"
                >
                    批量修改到仓时间
                </el-button>
                <el-dropdown
                    class="f-m-l-10 f-m-t-10 f-m-r-10"
                    size="small"
                    @command="handleCommand"
                    v-if="buttonShowList.tdxz"
                >
                  <el-button :disabled="selectionRows.length === 0" type="primary">
                    提单下载
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">提单PDF</el-dropdown-item>
                      <el-dropdown-item command="1">提单EXCEL</el-dropdown-item>
                      <el-dropdown-item command="2">电放提单PDF</el-dropdown-item>
                      <el-dropdown-item command="3">电放条形章PDF</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
                <el-button
                    size="medium"
                    type="primary"
                    :disabled="selectionRows.length === 0"
                    @click="changeToDead"
                    v-if="buttonShowList.kswcdd"
                >快速完成订单
                </el-button>
                <el-button
                    size="medium"
                    type="primary"
                    :disabled="selectionRows.length === 0"
                    @click="$intoDocPageBatch(selectedRowOrderNos)"
                    v-if="buttonShowList.pldkdd"
                >批量打开订单
                </el-button>
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                  @click="excepOrder0Batch"
                  v-if="buttonShowList.plzwzcdd"
              >批量转为正常订单
              </el-button>
               <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.plbcddlx"
                  @click="typeVisible=true"
              >批量保存订单类型2
              </el-button>
                <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-download"
                        @click="exportExcelPlus(url.downloadOperationExcel,getCurrentDate())"
                        :loading="exportLoading"
                        v-if="buttonShowList.dcczbb"
                >导出操作报表</el-button>
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-download"
                  @click="exportExcelPlus(url.downloadClearedCase,'已清关产品')"
                  :loading="exportLoading"
                  v-if="buttonShowList.dcyqg"
              >导出已清关产品</el-button>
                   <el-button
                        size="medium"
                        type="primary"
                        @click="()=>caiwuVisible=true"
                        :loading="exportLoading"
                        v-if="buttonShowList.xgcwbz && showCaiwuRemark"
                >批量修改财务备注</el-button>
                    <el-button type="primary" size="medium"  v-if="buttonShowList.派送文件2"
                           @click="exportPrejudgment"  :disabled="selectedRowKeys.length===0">派送文件2</el-button>
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                  @click="batchRecoverArrangeContainer"
                  v-if="buttonShowList.批量恢复可排柜"
              >批量恢复可排柜
              </el-button>
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                  @click="batchUnArrangeContainer"
                  v-if="buttonShowList.批量不可排柜"
              >批量不可排柜
              </el-button>
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                  @click="batchTemporaryStorage"
                  v-if="buttonShowList.批量暂存"
              >批量暂存
              </el-button>
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectionRows.length === 0"
                  @click="batchUnTemporaryStorage"
                  v-if="buttonShowList.批量取消暂存"
              >批量取消暂存
              </el-button>
                <el-dialog
                        title="批量修改到仓时间"
                        :visible.sync="dialogUpdateCargoTimeBatchVisible"
                        width="400px"
                        :close-on-click-modal="false"
                        :before-close="() => { dialogUpdateCargoTimeBatchVisible = false }"
                >
                    <el-form :model="cargoArrivedTimeForm" ref="cargoArrivedTimeForm" >
                        <el-form-item
                                label="到仓时间"
                                :label-width="formLabelWidth"
                        >
                            <el-date-picker
                                            v-model="cargoArrivedTimeForm.cargoArrivedTime"
                                            type="datetime"
                                            size="small"
                                            placeholder="到仓时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogUpdateCargoTimeBatchVisible = false"
                        >取 消</el-button
                        >
                        <el-button type="primary" @click="updateCargoTimeBatch">确 定</el-button>
                    </div>
                </el-dialog>
        <el-dialog
          title="更新销售锁价服务"
          :visible.sync="dialogSalesPriceNameFormVisible"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <el-form :model="salesPriceForm" ref="salesPriceForm" :rules="rules">
            <el-form-item
              label="批量更改销售锁价服务"
              :label-width="formSalesPriceLabelWidth"
              prop="isSalesPriceName"
            >
              <el-radio-group v-model="salesPriceForm.isSalesPriceName">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogSalesPriceNameFormVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="updateService">确 定</el-button>
          </div>
        </el-dialog>


              <el-dialog
                  title="标记第三方收款"
                  :visible.sync="dialogSignThirdPartyCollectionFormVisible"
                  :close-on-click-modal="false"
                  :before-close="handleThirdClose"
              >
                <el-form :model="thirdPartyCollectionForm" ref="thirdPartyCollectionForm" :rules="rules">
                  <el-form-item
                      label="标记第三方收款"
                      :label-width="formSalesPriceLabelWidth"
                      prop="thirdPartyCollection"
                  >
                    <el-radio-group v-model="thirdPartyCollectionForm.thirdPartyCollection">
                      <el-radio label="0">风控催收</el-radio>
                      <el-radio label="1">诉讼催收</el-radio>
                      <el-radio label="2">其他</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogSignThirdPartyCollectionFormVisible = false"
                  >取 消</el-button
                  >
                  <el-button type="primary" @click="updateThirdService">确 定</el-button>
                </div>
              </el-dialog>


      </div>

            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
                <span class="f-m-l-20" style="font-weight: bold">统计&nbsp;</span>
                <span>预计体积：</span>
                <span style="color: red">{{tongji.cmb?tongji.cmb:0}}</span>
                <span>CBM</span>
                <span class="f-m-l-15">实际体积：</span>
                <span style="color: red">{{tongji.realCmb?tongji.realCmb:0}}</span> <span>CBM</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    :show-operation="true"
                    :tableHeight="showLoadingSearch ? '53vh' : '60vh'"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
                    @handleKeyup="handleSearch">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'delivery'">
                        <span v-if="['1','2','5'].indexOf(scope.row.delivery) !== -1"
                              style="background-color: orange;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
                      <span v-if="['0','3','4','6'].indexOf(scope.row.delivery) !== -1"
                            style="background-color: green;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
                      <br>
                        <span v-if="scope.row.isNoMore === '1' && Number(scope.row.orderStatus) < 99" style="color: red">(无循环订单)</span>
                        <span v-else style="background-color: orange;color: white"></span>
                    </template>
                    <template v-else-if="scope.column.property === 'warningStatus'">
                        <span v-if="scope.row.warningStatus === '1'" style="color: red">已恢复</span>
                        <span v-if="scope.row.warningStatus === '0'" style="color: red">是问题件</span>
                    </template>
                  <template v-else-if="scope.column.property === 'thirdPartyCollection'">
                    <span v-if="scope.row.thirdPartyCollection === '0'">风控催收</span>
                    <span v-if="scope.row.thirdPartyCollection === '1'" >诉讼催收</span>
                    <span v-if="scope.row.thirdPartyCollection === '2'" >其他</span>
                  </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                        <img src="../images/v.png" alt="" v-if="scope.row.grade ==='0'">
                        <img src="../images/icon-heart.png" alt=""
                             v-if="scope.row.grade !=='0' && scope.row.cusOrderQuantity ===0">
                    </template>
                  <template v-else-if="scope.column.property === 'orderStatus'">
                    <span>
                     {{ scope.row.statusContent }}
                      <!-- {{parseDict(scope.row.orderStatus,'os_message_log')}} -->
                      <br></span>
                    <span v-if="Number(scope.row.realCmb) > 0" style="color:green">(已录材积)<br></span>
                    <span v-else style="color:red">(未录材积)<br></span>
                    <template v-if="scope.row.insurance ==='1'">
                      <p v-if="scope.row.isInsured ==='1'" style="color: green">已买保险<br></p>
                      <p v-else style="color: red">未买保险<br></p>
                    </template>
                    <span v-if="scope.row.insurance ==='0'" style="color: green">不需要保险<br></span>
                    <span v-if="scope.row.invoiceStatus ==='1'" style="color: green">发票已审核<br></span>
                    <span v-if="scope.row.invoiceStatus ==='0'" style="color: red">发票未审核<br></span>
                    <span v-if="scope.row.receiptUrl"><a style="margin-left: -18px" :href="scope.row.receiptUrl">签收单</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </template>
                    <template v-else-if="scope.column.property === 'recStatus'">
                        <span>{{parseDict(scope.row.recStatus,'os_rec_status')}}</span>
                        <br>
                        <span v-if="scope.row.feename ==='1'" style="color: red">已录入海运费</span>
                        <br v-if="scope.row.feename ==='1'">
                        <span v-if="scope.row.recLock ==='1'" style="color: red">已锁定</span>
                        <span v-if="scope.row.recLock ==='0'" style="color: green">未锁定</span>
                        <br>
                        <span v-if="scope.row.isLowLevel==='1'" style="color: red">红线订单</span>
                    </template>
                    <template v-else-if="scope.column.property === 'payStatus'">
                        <span>{{parseDict(scope.row.payStatus,'os_pay_status')}}</span>
                        <br>
                        <span v-if="scope.row.payLock ==='1'" style="color: red">已锁定</span>
                        <span v-if="scope.row.payLock ==='0'" style="color: green">未锁定</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipmentCountry'">
                      <!-- FBA / AWD 地址 -->
                      <span v-if="scope.row.shipmentType == '0' || scope.row.shipmentType == '5'">
                          {{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentCode}}
                      </span>
                      <!-- 商业地址 -->
                       <span v-else-if="scope.row.shipmentType == '3'">
                        <span v-if="scope.row.shipmentState"> {{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentState}}&nbsp;&nbsp;{{scope.row.shipmentZip}}&nbsp;&nbsp;(商业地址)</span>
                         <span v-else>{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentZip}}&nbsp;&nbsp;</span>
                      </span>
                       <!-- 住宅地址 -->
                       <span v-else-if="scope.row.shipmentType == '1'">
                        <span v-if="scope.row.shipmentState"> {{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentState}}&nbsp;&nbsp;{{scope.row.shipmentZip}}&nbsp;&nbsp;(住宅地址)</span>
                         <span v-else>{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentZip}}&nbsp;&nbsp;</span>
                      </span>
                      <!-- 第三方海外仓 ／ 大森林海外仓 -->
                      <span v-else-if="scope.row.shipmentType == '2' || scope.row.shipmentType == '4'">
                        {{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentZip}}&nbsp;&nbsp;{{scope.row.shipmentCompany}}
                      </span>
                        <!-- <span v-if="scope.row.shipmentType ==='0'">{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentCode}}</span>
                        <span v-else-if="scope.row.shipmentType === '5' && scope.row.shipmentCode !== null && scope.row.shipmentCode !== ''">
                          {{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentCode}}
                        </span>
                        <span v-else>{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentZip}}</span> -->
                    </template>
<!--                    <template v-else-if="scope.column.property === 'shipmentId'">-->
<!--                        <span>{{scope.row.shipmentId}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'containerType'">
                        <span v-if="scope.row.containerType ==='0'">LCL&nbsp;&nbsp;{{scope.row.solicitationText}}{{scope.row.channelName}}</span>
                        <span v-if="scope.row.containerType ==='1'">FCL&nbsp;&nbsp;{{scope.row.channelName}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'pol'">
                        <span>{{parseDict(scope.row.pol,'hy_bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'hy_bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookNo'">
                        <span>{{parseDict(scope.row.bookNo,'bookAgent_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompany'">
                        <span>{{parseDict(scope.row.shipCompany,'ship_company')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>{{scope.row.operator}}</span>
                        <br>
                        <span v-if="scope.row.operateId && scope.row.operateCommission ==='0'"
                              style="margin-top: 10px;cursor:hand">未发提成</span>
                        <span v-if="scope.row.operateId && scope.row.operateCommission ==='1'"
                              style="margin-top: 10px;cursor:hand;color: #0000cc">已发提成</span>
                    </template>
                    <template v-else-if="scope.column.property === 'docId'">
                        <span>{{scope.row.doc}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanId'">
                        <span>{{scope.row.salesman}}</span>
                        <br>
                        <span v-if="scope.row.salesmanId &&scope.row.salesmanCommission ==='0'"
                              style="margin-top: 10px;cursor:hand">未发提成</span>
                        <span v-if="scope.row.salesmanId &&scope.row.salesmanCommission ==='1'"
                              style="margin-top: 10px;cursor:hand;color: #0000cc">已发提成</span>
                    </template>
                    <template v-else-if="scope.column.property === 'billmanId'">
                        <span>{{scope.row.billman}}</span>
                    </template>
                  <template v-else-if="scope.column.property === 'shipmentId'">
                    <el-popover
                        placement="top-start"
                        width="300"
                        trigger="hover"

                    >
                      <div>{{ scope.row.shipmentId }}</div>
                      <span slot="reference" v-if="scope.row.shipmentId && scope.row.shipmentId.length > 33">{{scope.row.shipmentId.substr(0, 33) + "..."}}</span>
                      <span slot="reference" v-else>{{scope.row.shipmentId}}</span>
                    </el-popover>
                  </template>
                  <template v-else-if="scope.column.property === 'referenceId'">
                    <el-popover
                        placement="top-start"
                        width="300"
                        trigger="hover"

                    >
                      <div>{{ scope.row.referenceId }}</div>
                      <span slot="reference" v-if="scope.row.referenceId && scope.row.referenceId.length > 33">{{scope.row.referenceId.substr(0, 33) + "..."}}</span>
                      <span slot="reference" v-else>{{scope.row.referenceId}}</span>
                    </el-popover>
                  </template>
                  <template v-else-if="scope.column.property === 'deptId'">
                    <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


   <el-dialog
            title="批量保存订单类型2"
            :visible="typeVisible"
            width="33%"
            append-to-body
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="typeHandleClose">
        <el-form :model="typeForm" :rules="typeRules" ref="typeForm" label-width="94px" size="medium">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="orderTypeTwo" label="订单类型2">
                      <f-select v-model="typeForm.orderTypeTwo" :isNeed="typeForm.orderTypeTwo" :dict="'order_type_two'"></f-select>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="typeHandleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="changeOrderType">确定</el-button>
        </span>
    </el-dialog>


     <el-dialog
            title="批量修改财务备注"
            :visible="caiwuVisible"
            width="33%"
            append-to-body
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="()=>{caiwuVisible=false;caiwuForm={}}">
        <el-form :model="caiwuForm" :rules="caiwuRules" ref="caiwuForm" label-width="94px" size="medium">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="caiwuRemark" label="财务备注">
                       <el-input v-model="caiwuForm.caiwuRemark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="()=>{caiwuVisible=false;caiwuForm={}}">取消</el-button>
            <el-button type="primary" size="medium" @click="changeCaiwuRemark">确定</el-button>
        </span>
    </el-dialog>

    </div>
</template>

<script>
import { deleteAction, postAction, putAction,getAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from "@/utils/util";
import OssClientMixins from "@/mixins/OssClientMixins";

export default {
  name: "ProductModule",
  mixins: [QueryMixins,OssClientMixins],
  components: {},
  data() {
    return {
      disableMixinMounted:true,
      dialogUpdateCargoTimeBatchVisible:false,
      cargoArrivedTimeForm:{
          cargoArrivedTime:null,
          orderNo:null,
      },
      departList:[],
      fsee: false,
      dictCodes: [
        "is_record",
        "os_message_log",
        "os_rec_status",
        "os_pay_status",
        "hy_bd_port",
        "ship_company",
        "bookAgent_id",
        'sys_depart'
      ],
      span: 12,
      btnDisable: false,
      dialogFormVisible: false,
      ids: [],
      row: {},
      salesPriceForm: { batchUpdateSalesDto: [] },
      thirdPartyCollectionForm:{batchUpdateThirdPartyCollectionDto: [] },
      selectRow: {},
      visible: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: "",
      assignTitle: "",
      btnLoading: false,
      btnLoadingYs: false,
      tieForm: { fileList: [] },
      formLabelWidth: "100px",
      formSalesPriceLabelWidth: "200px",
      rules: {
        title: [
          { required: true, message: "请填写标题", trigger: "change" },
          { max: 100, message: "长度不能超过100", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请填写提醒事项", trigger: "change" },
          { max: 1000, message: "长度不能超过1000", trigger: "change" },
        ],
        isSalesPriceName: [
          {
            required: true,
            message: "请选择更改销售锁价服务",
            trigger: "change",
          },
        ],
        thirdPartyCollection:[
          {
            required:false,
            message: "请标记第三方收款",
            trigger: "change",
          },
        ],
      },
      dialogSalesPriceNameFormVisible: false,
      dialogSignThirdPartyCollectionFormVisible: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "工作号",
          minWidth: 120,
        },
        {
          prop: "customer",
          label: "客户",
          minWidth: 100,
        },
        {
          prop: "orderStatus",
          label: "订单状态",
          minWidth: 80,
        },
        {
          prop: "delivery",
          label: "放货状态",
          minWidth: 75,
        },
        // {
        //   prop: "caiwuRemark",
        //   label: "财务备注",
        //   // minWidth: 75,
        // },
        {
          prop: "warningStatus",
          label: "问题件状态",
          minWidth: 70,
        },
        {
          prop: "thirdPartyCollection",
          label: "第三方收款",
          minWidth: 70,
        },
        {
          prop: "recStatus",
          label: "收款状态",
          minWidth: 65,
        },
        {
          prop: "payStatus",
          label: "付款状态",
          minWidth: 65,
        },
        {
          prop: "createTime",
          label: "工作单日期",

          minWidth: 75,
        },
        {
          prop: "containerType",
          label: "业务类型",
          minWidth: 120,
        },
        {
          prop: "shipmentCountry",
          label: "目的地",
          minWidth: 80,
        },
        {
          prop: "lclPlaceName",
          label: "拼柜仓库",
          minWidth: 80,
        },
         {
          prop: "allVolumeLocation",
          label: "货物所在仓",
        },
        {
          prop: "shipmentId",
          label: "SHIPMENT ID",
          minWidth: 100,
        },
        {
          prop: "referenceId",
          label: "REFERENCE ID",
          minWidth: 100,
        },
        {
          prop: "ctn",
          label: "预计件数",

                        minWidth: 50,
                    },
                    {
                        prop: "realCtn",
                        label: "实件",

                        minWidth: 50,
                    },
                    {
                        prop: "realCmb",
                        label: "实体",
                        //
                        minWidth: 60,
                    },
                    {
                        prop: "shipCompany",
                        label: "船公司",
                        minWidth: 50,
                    },
                    {
                        prop: "etd",
                        label: "ETD",

                        minWidth: 50,
                    },
                    {
                        prop: "eta",
                        label: "ETA",

          minWidth: 50,
        },
        {
          prop: "pol",
          label: "起运港",
          minWidth: 50,
        },
        {
          prop: "podEnd",
          label: "目的港",
          minWidth: 50,
        },
        {
          prop: "operateId",
          label: "操作员",
          minWidth: 50,
        },
        {
          prop: "docId",
          label: "客服",
          minWidth: 50,
        },
        {
          prop: "salesmanId",
          label: "业务员",
          minWidth: 50,
        },
        {
          prop: 'deptId',
          label: '销售组别',
          minWidth: 50,
        },
        {
          prop: "billmanId",
          label: "收款人",
          minWidth: 50,
        },
        {
          prop: "cargoArrived",
          label: "入仓时间",
        },
        {
          prop: "remark",
          label: "内部备注",
          minWidth: 90,
          overFlow: true,
        },
        {
          prop: "bookNo",
          label: "订舱单号",
          minWidth: 50,
        },
        {
          prop: "containerNo",
          label: "柜号",
          minWidth: 50,
        },
      ],
      columnsCount: [
        {
          prop: "receivable",
          label: "应收",
        },
        {
          prop: "realPay",
          label: "实收",
        },
        {
          prop: "payable",
          label: "应付",
        },
      ],
      url: {
          //下载入仓单
        downloadMergeRucang: "/file/jasper/download/mergeRucang",
        list: "/interface/ttOrder/getBackAllOrderPage",

        //优化之后的
        exportTT: "/file/export/excel/downloadTTExcel",
        downloadOperationExcel: "/file/export/excel/downloadOperationExcel",
        downloadClearedCase: "/file/doc1/clearedCaseNo",
        importHySettles: "/order/os/goods/importHySettles",
        importHySettlesYs: "/order/os/goods/importHySettlesYs",
        downloadHyYfModel: "/order/os/settle/Hy/in/downloadHyYfExcel",
        downloadHyYsModel: "/order/os/settle/Hy/in/downloadHyYsExcel",
        //批量取消订单
        // cancelOrderBatch: '/order/all/order/cancelOrderBatch',
        cancelOrderBatch: "/interface/ttOrder/cancelOrderBatch",
        excepOrder0Batch: "/interface/ttOrder/excepOrder0Batch",
        //批量解锁装柜订单
        // unlockOrderBatch: '/order/all/order/unlockContainerLoadOrder',
        unlockOrderBatch: "/interface/ttOrder/unlockContainerLoadOrder",
        //批量锁装柜订单
        // lockOrderBatch: '/order/all/order/lockContainerLoadOrder',
        lockOrderBatch: "/interface/ttOrder/lockContainerLoadOrder",
        //统计所有订单的cmb，realCmb

        tongjiCmb: "/interface/ttOrder/caculateCmb",
        //批量hold货
        holdOrderBatch: "/interface/ttOrder/updateTtOrderDelivery1Batch",
        releaseOrderBatch: "/interface/ttOrder/updateTtOrderDelivery0Batch",
        osSettleCountPost: "/order/all/order/caculateOsSettleCountPost", //海运统计
        upload: "/sys/oss/file/upload",
        // tieReminder: "/order/all/order/tieReminder",  //特别提醒
        tieReminder: "/file/sendEmail/tieReminder", //特别提醒
        updateOperateCommission:
          "/interface/ttOrder/updateTtOrderDetailOperateCommissionBatch", //更新操作提成
        updateSalesCommission:
          "/interface/ttOrder/updateTtOrderDetailSalesmanCommissionBatch", //更新业务提成,
        batchUpdateSalesLockPrices:
          "/interface/ttOrder/batchUpdateSalesLockPrices", //更新销售锁价服务
        batchUpdateThirdPartyCollection:
            "/interface/ttOrder/batchUpdateThirdPartyCollection",
        getDepartList:'/sys/sysDepart/querySalesGroupDepartList',
        updateCargoArrivedTimeBatch:'/interface/ttOrder/updateCargoArrivedTimeBatch', // 根据订单号批量修改到仓时间
        downloadHBLByOrderNoList: '/jeecg-cloud-file-biz/file/jasper/downloadHBLByOrderNoList', //提单下载
        updateOrderToDead:'/interface/ttOrder/updateOrderToDead',
        batchUpdateOrderTypeTwo:"/interface/ttOrder/batchUpdateOrderTypeTwo",
        updateBatchRemark:"/interface/ttOrder/updateBatchRemark",
         exportDeliveryDocTwo:"/file/export/excel/container/exportDeliveryDocTwo",
        updateIsArrangeContainer: '/interface/ttOrder/batchUpdateIsArrangeContainer',
        updateIsTemporaryStorage: '/interface/ttOrder/batchUpdateIsTemporaryStorage',

      },
      salesmanlist: [],
      userlist: [],
      countrylist: [],
      showLoadingSearch: false,
      tongji: {},
      dialogKdCountVisible: false,
      dialogTieReminderVisible: false,
      kdCountNum: {}, //快递统计总数
      btnTt: false,
      typeVisible:false,
      typeForm:{},
      typeRules:{
          orderTypeTwo: [
          { required: true, message: "请选择订单类型2", trigger: "change" },
        ],
      },
      caiwuVisible:false,
      caiwuForm:{},
      caiwuRules:{
           caiwuRemark: [
          { required: true, message: "请填写财务备注", trigger: "change" },
        ],
      },
      showCaiwuRemark:false

    };
  },
    computed: {
        inputWid() {
            return function () {
                let strLength = '220px'
                if (this.queryForm.departId && this.queryForm.departId.length > 0){
                    this.departList.forEach(item=>{
                        if(item.value ==  this.queryForm.departId[0]){
                            strLength = (String(item.text).length * 21 + 90) + 'px'
                        }
                    })
                }
                return strLength
            }
        },
        bb() {
          let ss = localStorage.getItem("USERINFO");
          var user = JSON.parse(ss);
          let staging = this.buttonShowList.zcck;
          // 排除理员、总经理、admin的权限
          if(user && user.roleName) {
            ['管理员', '总经理'].indexOf(user.roleName) !== -1 ? staging = false : '';
          }
          if(user && user.username) {
            ['admin'].indexOf(user.username) !== -1 ? staging = false : '';
          }
          return {
            '暂存仓库': staging
          }
        },
        buttonShowList() {
          return {
            'zcck':this.$btnIsShow('docpage/AllOrderModule','1','暂存仓库'),
            'dctt':this.$btnIsShow('docpage/AllOrderModule','0','导出TT'),
            'dcczbb':this.$btnIsShow('docpage/AllOrderModule','0','导出操作报表'),
            'dcyqg':this.$btnIsShow('docpage/AllOrderModule','0','导出已清关产品'),
            'hbrcd':this.$btnIsShow('docpage/AllOrderModule','0','合并入仓单'),
            'plhh':this.$btnIsShow('docpage/AllOrderModule','0','批量hold货'),
            'plfh':this.$btnIsShow('docpage/AllOrderModule','0','批量放货'),
            'plqxdd':this.$btnIsShow('docpage/AllOrderModule','0','批量取消订单'),
            'pljsyzgdd':this.$btnIsShow('docpage/AllOrderModule','0','批量解锁已装柜订单'),
            'plsdyzgdd':this.$btnIsShow('docpage/AllOrderModule','0','批量锁定已装柜订单'),
            'yfmbxz':this.$btnIsShow('docpage/AllOrderModule','0','海运应付批量导入模板下载'),
            'inyf':this.$btnIsShow('docpage/AllOrderModule','0','批量海运应付导入'),
            'ysmbxz':this.$btnIsShow('docpage/AllOrderModule','0','海运应收批量导入模板下载'),
            'ysdr':this.$btnIsShow('docpage/AllOrderModule','0','批量海运应收导入'),
            'tj':this.$btnIsShow('docpage/AllOrderModule', '0', '统计'),
            'tbtx':this.$btnIsShow('docpage/AllOrderModule', '0', '特别提醒'),
            'gxcztc':this.$btnIsShow('docpage/AllOrderModule', '0', '更新操作提成'),
            'gxywtc':this.$btnIsShow('docpage/AllOrderModule', '0', '更新业务提成'),
            'gxxssjfw':this.$btnIsShow('docpage/AllOrderModule', '1', '更新销售锁价服务'),
            'bjdsfsk':this.$btnIsShow('docpage/AllOrderModule', '1', '标记第三方收款'),
            'plscwj':this.$btnIsShow('docpage/AllOrderModule','0','批量上传文件'),
            'plxgdcsj':this.$btnIsShow('docpage/AllOrderModule', '1', '批量修改到仓时间'),
            'tdxz':this.$btnIsShow('docpage/AllOrderModule', '0', '提单下载'),
            'kswcdd':this.$btnIsShow('docpage/AllOrderModule','0','快速完成订单'),
            'pldkdd':this.$btnIsShow('docpage/AllOrderModule','0','批量打开订单'),
            'plzwzcdd':this.$btnIsShow('docpage/AllOrderModule','0','批量转为正常订单'),
            'plbcddlx':this.$btnIsShow('docpage/AllOrderModule','0','批量保存订单类型2'),
            'xgcwbz':this.$btnIsShow('docpage/AllOrderModule','0','批量修改财务备注'),
            'xscwbz':this.$btnIsShow('docpage/AllOrderModule','0','显示财务备注'),
            '派送文件2':this.$btnIsShow('docpage/AllOrderModule','0','派送文件2'),
            '批量不可排柜':this.$btnIsShow('docpage/AllOrderModule','0','批量不可排柜'),
            '批量恢复可排柜':this.$btnIsShow('docpage/AllOrderModule','0','批量恢复可排柜'),
            '批量暂存':this.$btnIsShow('docpage/AllOrderModule','0','批量暂存'),
            '批量取消暂存':this.$btnIsShow('docpage/AllOrderModule','0','批量取消暂存'),
          }
        }
    },
  methods: {
      getCurrentDate(){
          const today = new Date();
          const year = today.getFullYear();
          const month = today.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的
          const day = today.getDate();
        // 输出格式为 YYYY-MM-DD
          return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      },
      clickOverValue(type){
          if(type == '0'){
              this.$set(this.queryForm,"overSevenLoadCabinet",'1')
          }
          if(type == '1'){
              this.$set(this.queryForm,"overSevenHandOut",'1')
          }
     },
      clickClearOverValue(type){
          if(type == '0'){
              this.$set(this.queryForm,"overSevenLoadCabinet",'0')
          }
          if(type == '1'){
              this.$set(this.queryForm,"overSevenHandOut",'0')
          }
     },
    // 提单下载
    handleCommand(cmd) {
      let isContinue = true, message='';
      try {
        this.selectionRows && this.selectionRows.forEach((item, index) => {
          if(item.containerId && item.cusId){
            if(index !== 0) {
              if(item.cusId !== this.selectionRows[index-1].cusId) {
                isContinue = false;
                message = '所选订单客户不一致无法下载';
                throw Error("跳出循环");
              }
              if(item.containerId !== this.selectionRows[index-1].containerId) {
                isContinue = false;
                message = '所选订单柜子不一致无法下载';
                throw Error("跳出循环");
              }
            }
          } else {
            isContinue = false;
            message = '不同客户订单无法合并下载';
            throw Error("跳出循环");
          }
        })
      } catch (e) {
        console.log(e);
      }
      if(isContinue == false) {
        this.$message.warning(message);
        return;
      }
      switch ( cmd ) {
        case '0':
            this.preview(this.url.downloadHBLByOrderNoList , {orderNos:this.selectedRowOrderNos.join(','),t:'pdf', }, 'HBL提单');
            break;
        case '1':
            this.downloadExcel(this.url.downloadHBLByOrderNoList , 'HBL提单',{orderNos:this.selectedRowOrderNos.join(','),t:'excel', });
            break;
        case '2':
            this.preview(this.url.downloadHBLByOrderNoList , {orderNos:this.selectedRowOrderNos.join(','),t:'pdf', isDian: '1' }, '电放提单');
            break;
        case '3':
            this.preview(this.url.downloadHBLByOrderNoList , {orderNos:this.selectedRowOrderNos.join(','),t:'pdf', isTiao: '1' }, '电放条形章');
            break;
      }
    },
      //合并入仓单excel
      rucangexcel() {
          // if (!this.form.deliveryPoint) {
          //     //没有选择拼柜仓库 提示 要选择
          //     this.$message.warning('请选择拼柜仓库之后在下载');
          //     return false;
          // }
          let params = {t: 'excel', orderNos: this.selectedRowOrderNos};

          //文件名
          let dateString = this.getFormattedDate()
          console.log(this.selectRow)
          let fileName = "入仓单-" + this.selectionRows[0].deliveryPointName +"-" + dateString;
          this.downloadExcelPost(this.url.downloadMergeRucang, fileName, params);
          this.$emit("ok");
      },
      getFormattedDate() {
          const today = new Date();
          let year = today.getFullYear();
          let month = (today.getMonth() + 1).toString().padStart(2, '0');
          let day = today.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
      },
    async uploadFileToOsOrderFile(param) {
      //file就是当前添加的文件
      // var fileObj = param.file;
      // //FormData 对象
      // var form = new FormData();
      // //文件对象，key是后台接受的参数名称
      // form.append("file", fileObj);
      // form.append("dir", 'operate');
      //执行上传方法
      // postAction(this.url.upload, form).then((res) => {
      //这里做上传之后的操作
      let uuid = this.generateUUID(6);
      let id = uuid;
      this.handleUploadOss(param, uuid,'','1');
      // if (res.success && res.result) {
      //先校验
    },
    //初始化组别列表
    getDepartList(){
     getAction(this.url.getDepartList).then(res=>{
         this.departList = res.result
     })
    },
    updateSalesCommission() {
      let item = "orderNo";
      if (typeof this.queryForm[item] === "string") {
        this.queryForm[item] = this.queryForm[item].trim();
        if (this.queryForm[item] === "") {
          this.queryForm[item] = null;
        }
      }

      this.$confirm("该操作会将未发业务提成的更新为已发提成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          let params = {};
          let list = [];
          if (this.selectionRows && this.selectionRows.length > 0) {
            this.selectionRows.forEach((s) => {
              list.push(s.orderNo);
            });
            params.orderNoList = list;
          }

          params.orderNo = this.queryForm.orderNo;
          postAction(this.url.updateSalesCommission, params)
            .then((res) => {
              //这里做上传之后的操作
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {});
    },
      showUpdateCargoTimeDialog() {
          let str = this.queryForm.orderNo
          if (!str || str.trim().length == 0) {
              this.$message("请输入需要批量修改到仓时间的订单号！")
              return;
          }
          this.dialogUpdateCargoTimeBatchVisible = true
      },
      updateCargoTimeBatch() {
          let str = this.queryForm.orderNo
          this.cargoArrivedTimeForm.orderNo = str
          postAction(this.url.updateCargoArrivedTimeBatch, this.cargoArrivedTimeForm).then(res=>{
             this.dialogUpdateCargoTimeBatchVisible = false
            if(res.success){
                this.$message.success(res.message)
            }
          })
      },
    updateSalesLockPrice() {
      let that = this
      console.log(this.selectionRows, 66666);
      //   每次先置空上次选择
      this.selectionRows.forEach((seleItem) => {
        console.log(that.salesPriceForm,'123123123')
        //orderNo 工作号 shipmentCountry 目的地
        that.salesPriceForm.batchUpdateSalesDto.push({
          orderNo: seleItem.orderNo,
          orderStatus: seleItem.orderStatus,
          shipmentCountry: seleItem.shipmentCountry,
        });
      });
      console.log(this.salesPriceForm, " this.salesPriceForm");
      this.dialogSalesPriceNameFormVisible = true;
    },
    updateThirdPartyCollection(){
      let that = this
      this.selectionRows.forEach((thirdPartyCollectionItem) => {
        that.thirdPartyCollectionForm.batchUpdateThirdPartyCollectionDto.push({
          orderNo: thirdPartyCollectionItem.orderNo,
        });
      });
      this.dialogSignThirdPartyCollectionFormVisible = true;
    },

    saveSalesPriceMessage() {
      console.log(this.salesPriceForm);
      this.$refs["salesPriceForm"].validate((valid) => {
        if (valid) {
          let list = this.fileList;
          console.log("list", this.form);
          if (list && list.length > 0) {
            postAction(
              "/order/os/file/saveFileListWithContainerId?containerId=" +
                this.sailForm.containerId +
                "&type=50",
              list
            ).then((res) => {});
          }
          console.log(this.salesPriceForm);
          this.fullscreenLoading = true;
          putAction(this.url.addShipMessage, this.sailForm)
            .then((res) => {
              this.fullscreenLoading = false;
              if (res.success) {
                this.$message.success(res.message);
                if (this.sailForm.isDelay === "0") {
                  this.dialogSailFormVisible = false;
                } else {
                  this.dialogSalesPriceNameFormVisible = false;
                }
                this.handleSearch();
              }
            })
            .catch((err) => {
              this.fullscreenLoading = false;
              // this.$message.error(err.message);
            });
        } else {
          return false;
        }
      });
    },

    updateOperationCommission() {
      let item = "orderNo";
      if (typeof this.queryForm[item] === "string") {
        this.queryForm[item] = this.queryForm[item].trim();
        if (this.queryForm[item] === "") {
          this.queryForm[item] = null;
        }
      }

      this.$confirm("该操作会将未发操作提成的更新为已发提成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          let params = {};
          let list = [];
          if (this.selectionRows && this.selectionRows.length > 0) {
            this.selectionRows.forEach((s) => {
              list.push(s.orderNo);
            });
            params.orderNoList = list;
          }

          params.orderNo = this.queryForm.orderNo;
          postAction(this.url.updateOperateCommission, params)
            .then((res) => {
              //这里做上传之后的操作
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {});
    },
    handleQuery() {
      this.loading = true;
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime
      this.$store.commit('setHistoryNumber', params);
      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else if (this.enabledCreatetime) {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
        //排序
        //提货中查询
        if (this.queryForm.column === "sort") {
          this.$set(params, "column", "sort");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      postAction(
        this.url.list +
          "?pageNo=" +
          params.pageNo +
          "&pageSize=" +
          params.pageSize +
          "&column=" +
          params.column +
          "&order=" +
          params.order,
        params
      )
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const { records, total } = res.result;
              this.tableData = records || res.result;
              this.ipagination &&
                (this.ipagination.total = total || res.result.length);
            } else {
              this.tableData = [];
              this.ipagination = 0;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          // this.tongjiCmb();
        });
    },
    downloadModel(url, fileName) {
      let params = { ...this.queryForm, ...this.filterForm };
      postAction(url, params, { responseType: "blob" }).then((response) => {
        if (!response) {
          return;
        }
        const blob = new Blob([response]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          let u = window.URL.createObjectURL(response);
          let aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = u;
          aLink.setAttribute("download", fileName + ".xls");
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
          window.URL.revokeObjectURL(u);
        }
      });
    },
    // 批量海运应付导入
    uploadSectionFile(param) {
      this.btnLoading = true;
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      this.$confirm("如果出现相同的费用，请选择新增还是覆盖?", "提示", {
        confirmButtonText: "覆盖",
        cancelButtonText: "新增",
        distinguishCancelAndClose: true,
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          form.append("type", "1");
          postAction(this.url.importHySettles, form)
            .then((res) => {
              this.btnLoading = false;
              //这里做上传之后的操作
              if (res.success) {
                this.$message.success(res.message);

                this.handleQuery();
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        })
        .catch((action) => {
          form.append("type", "2");
          if (action === "cancel") {
            postAction(this.url.importHySettles, form)
              .then((res) => {
                this.btnLoading = false;
                //这里做上传之后的操作
                if (res.success) {
                  this.$message.success(res.message);

                  this.handleQuery();
                }
              })
              .finally(() => {
                this.btnLoading = false;
              });
          } else {
            this.btnLoading = false;
          }
        });
    },
    // 批量海运应付导入
    uploadSectionFileYs(param) {
      this.btnLoadingYs = true;
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importHySettlesYs, form)
        .then((res) => {
          //这里做上传之后的操作
          if (res.success) {
            this.$message.success(res.message);

            this.handleQuery();
          }
        })
        .finally(() => {
          this.btnLoadingYs = false;
        });
    },
    cancelOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要取消的订单");
      }
      let orderNoList = [];
      console.log("批量取消订单", list);
      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      putAction(this.url.cancelOrderBatch, orderNoList).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.$message.warning(res.message);
          this.handleQuery();
        }
      });
    },
    excepOrder0Batch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要转为正常的订单");
      }
      let orderNoList = [];
      console.log("批量转为正常订单", list);
      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      putAction(this.url.excepOrder0Batch, orderNoList).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.$message.warning(res.message);
          this.handleQuery();
        }
      });
    },
    lockOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要锁定的订单");
      }
      let orderNoList = [];
      console.log("批量解锁订单", list);
      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(
        `确定锁定订单，该操作会将已装柜的订单的基本信息派送信息产品信息不允许修改！`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).then(() => {
        postAction(this.url.lockOrderBatch, orderNoList).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          } else {
            this.$message.warning(res.message);
            this.handleQuery();
          }
        });
      });
    },
    unlockOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要取消的订单");
      }
      let orderNoList = [];
      console.log("批量解锁订单", list);
      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(
        `确定解锁订单，该操作会将已装柜的订单的基本信息派送信息产品信息允许修改！`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).then(() => {
        postAction(this.url.unlockOrderBatch, orderNoList).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          } else {
            this.$message.warning(res.message);
            this.handleQuery();
          }
        });
      });
    },

      changeToDead() {
          let that = this;
          let list = this.selectedRowOrderNos;
          if (!list || list.length === 0) {
              this.$message.warning("请勾选要批量快速完成的订单！");
          }

          that.$confirm(`此操作将会把订单状态扭转到已完成`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
          }).then(()=>{
              getAction(that.url.updateOrderToDead,{no:that.selectedRowOrderNos.join(',')})
                  .then(res=>{
                      that.$message({type:'success',message:res.message});
                      that.$emit("ok");
                      // that.initAllInfo();
                  })
          })
         },
    holdOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要hold货的订单");
      }
      let orderNoList = [];

      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(
        `确定hold货？该操作将会将订单状态变更为手动扣货！`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          postAction(this.url.holdOrderBatch, {orderNoList: orderNoList, isOper: this.bb['暂存仓库'] ? '1' : '0'}).then((res) => {
            this.$message.success(res.message);
            this.handleQuery();
          });
        })
        .catch(() => {});
    },

    batchRecoverArrangeContainer() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要批量恢复可排柜的订单");
      }
      let orderNoList = [];

      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(
          `确定恢复？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          }
      )
          .then(() => {
            postAction(this.url.updateIsArrangeContainer, {
              orderNoList: orderNoList,
              isCanArrangeContainer: '1'
            }).then((res) => {
              this.$message.success(res.message);
              this.handleQuery();
            });
          })
          .catch(() => {
          });
    },

    batchUnArrangeContainer() {
      {
        let list = this.selectionRows;
        if (!list || list.length === 0) {
          this.$message.warning("请勾选要批量不可排柜的订单");
        }
        let orderNoList = [];

        list.forEach((l) => {
          orderNoList.push(l.orderNo);
        });
        this.$confirm(
            `确定设置成不可排柜状态？该操作会影响排柜！`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
            }
        )
            .then(() => {
              postAction(this.url.updateIsArrangeContainer, {
                orderNoList: orderNoList,
                isCanArrangeContainer: '0'
              }).then((res) => {
                this.$message.success(res.message);
                this.handleQuery();
              });
            })
            .catch(() => {
            });
      }
    },

    batchTemporaryStorage() {
      {
        let list = this.selectionRows;
        if (!list || list.length === 0) {
          this.$message.warning("请勾选批量暂存的订单");
        }
        let orderNoList = [];

        list.forEach((l) => {
          orderNoList.push(l.orderNo);
        });
        this.$confirm(
            `确定设置成暂存状态？`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
            }
        )
            .then(() => {
              postAction(this.url.updateIsTemporaryStorage, {
                orderNoList: orderNoList,
                isTemporaryStorage: '1',
                temporaryStorageStartTime: new Date().getTime()
              }).then((res) => {
                this.$message.success(res.message);
                this.handleQuery();
              });
            })
            .catch(() => {
            });
      }
    },

    batchUnTemporaryStorage() {
      {
        let list = this.selectionRows;
        if (!list || list.length === 0) {
          this.$message.warning("请勾选批量取消暂存的订单");
        }
        let orderNoList = [];

        list.forEach((l) => {
          orderNoList.push(l.orderNo);
        });
        this.$confirm(
            `确定批量取消暂存状态？该操作会立刻生成仓租费！`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
            }
        )
            .then(() => {
              postAction(this.url.updateIsTemporaryStorage, {
                orderNoList: orderNoList,
                isTemporaryStorage: '0',
                temporaryStorageEndTime: new Date().getTime()
              }).then((res) => {
                this.$message.success(res.message);
                this.handleQuery();
              });
            })
            .catch(() => {
            });
      }
    },


    releaseOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要放货的订单");
      }
      let orderNoList = [];

      list.forEach((l) => {
        orderNoList.push(l.orderNo);
      });
      this.$confirm(`确定放货？该操作将会将订单放货！`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.releaseOrderBatch, orderNoList).then((res) => {
            this.$message.success(res.message);
            this.handleQuery();
          });
        })
        .catch(() => {});
    },

    exportTT() {
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.queryForm.ids = this.selectedRowKeys.join(",");
      }
      this.trimParamsStr(this.queryForm);
      this.downloadExcelPost(this.url.exportTT, "tt工作表", this.queryForm);
    },
    initCusPage({ cusId }) {
      if (cusId) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, "/customer/Edit");
        this.$router.push(`/customer/Edit?id=${cusId}`);
      }
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      this.$intoDocPage(orderNo);
    },
    showRate(val) {
      if (val.indexOf("%") !== -1) {
        return val;
      } else {
        return val + "%";
      }
    },
    formatTaxrate(val) {
      if (val && val.indexOf("%") === -1) {
        return val + "%";
      } else {
        return val;
      }
    },
    handleAdd() {
      this.selectRow = {};
      this.dialogFormVisible = true;
    },
    //获取国家基础数据

    handleEdit(row) {
      this.selectRow = row;
      this.dialogFormVisible = true;
    },
    //退回
    handleBack(row) {
      this.$confirm(`确定将【${row.name}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.baturl, { id: row.id }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleQuery();
        });
      });
    },
    handleBatApproval() {
      console.log("批量操作对象：", this.selectedRowKeys);
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.approvalBatch, { id }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleQuery();
        });
      });
    },
    handleBatTransfer() {
      this.ids = this.selectedRowKeys;
      this.assignTitle = "批量转让";
      this.assignVisible = true;
    },
    handleBatBack() {
      console.log("批量操作对象：", this.selectedRowKeys);
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        putAction(
          this.url.batbatch + "?ids=" + this.selectedRowKeys.join(","),
          { ids: this.selectedRowKeys.join(",") }
        ).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleQuery();
        });
      });
    },
    handleBatAddFollower() {
      this.ids = this.selectedRowKeys;
      this.followTitle = "批量添加跟进人";
      this.visible = true;
    },
    handleSendEmail() {
      this.row = this.selectionRows[0];
      this.emailVisible = true;
    },
    handleSendMsg() {
      this.row = this.selectionRows[0];
      this.msgVisible = true;
    },
    handleFrozen(row) {
      this.$confirm(`确定将【${row.name}】冻结?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.fronzenUrl, { id: row.id }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleQuery();
        });
      });
    },
    handlTransfer(row) {
      this.row = row;
      this.ids = [row.id];
      this.assignTitle = "转让";
      this.assignVisible = true;
    },
    handlAddRecord() {},
    handlAddFollower(row) {
      this.row = row;
      this.ids = [row.id];
      this.followTitle = "添加跟进人";
      this.visible = true;
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.queryForm.pageSize = 10;
      this.ipagination.currentPage = 1;

      this.handleQuery();

      if (this.$refs["GTable"]) {
        this.$refs["GTable"].handleReset();
      }
    },
    tongjiCmb() {
      for (let item in this.queryForm) {
        if (typeof this.queryForm[item] === "string") {
          this.queryForm[item] = this.queryForm[item].trim();
          if (this.queryForm[item] === "") {
            this.queryForm[item] = null;
          }
        }
      }
      postAction(this.url.tongjiCmb, this.queryForm).then((res) => {
        if (res.result) {
          this.tongji = res.result;
        } else {
          this.tongji = { cmb: 0, realCmb: 0 };
        }
      });
    },
    osCount() {
      this.kdCountNum = [];
      this.trimParamsStr(this.queryForm);
      postAction(this.url.osSettleCountPost, this.queryForm).then((res) => {
        if (res.result) {
          if (res.result["实收"] && res.result["实收"].length) {
            res.result["实收"].forEach((item, index) => {
              if (this.kdCountNum[index]) {
                this.kdCountNum[index].realPay = item || "";
              } else {
                this.kdCountNum.push({
                  realPay: item || "",
                });
              }
            });
          }
          if (res.result["应付"]) {
            res.result["应付"].forEach((item, index) => {
              if (this.kdCountNum[index]) {
                this.kdCountNum[index].payable = item || "";
              } else {
                this.kdCountNum.push({
                  payable: item || "",
                });
              }
            });
          }
          if (res.result["应收"]) {
            res.result["应收"].forEach((item, index) => {
              if (this.kdCountNum[index]) {
                this.kdCountNum[index].receivable = item || "";
              } else {
                this.kdCountNum.push({
                  receivable: item || "",
                });
              }
            });
          }
          this.dialogKdCountVisible = true;
        }
      });
    },
    tieReminder() {
      this.tieForm = {};
      this.tieForm.fileList = [];
      this.$set(this.tieForm,"isSendEmail",'1')
      this.$set(this.tieForm,"isRemind",'0')
      this.dialogTieReminderVisible = true;
    },
    //发送特别提醒文件
    sendTieReminderEmail() {
      this.$refs.tieForm.validate((valid) => {
        if (valid) {
          if (
            this.tieForm.fileList &&
            this.tieForm.fileList.length &&
            !this.tieForm.fileType
          ) {
            this.$message.warning("有文件请选择文件类型！");
            return;
          }
          this.tieForm.orderNos = this.selectedRowOrderNos.join(",");
          postAction(this.url.tieReminder, this.tieForm).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogTieReminderVisible = false;
            }
          });
        }
      });
    },
    handleClose() {
      this.dialogSalesPriceNameFormVisible = false
      this.salesPriceForm = {  };
      this.salesPriceForm.batchUpdateSalesDto = [];
    },
    handleThirdClose(){
      this.dialogSignThirdPartyCollectionFormVisible = false
      this.thirdPartyCollectionForm = { };
      this.thirdPartyCollectionForm.batchUpdateThirdPartyCollectionDto = [];
    },
    updateService() {
      this.$refs["salesPriceForm"].validate((valid) => {
        if (valid) {
          console.log(this.salesPriceForm, "this.salesPriceForm");
          postAction(this.url.batchUpdateSalesLockPrices, this.salesPriceForm)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.salesPriceForm = {};
                this.salesPriceForm.batchUpdateSalesDto =[];
                this.dialogSalesPriceNameFormVisible = false;
                this.handleSearch();
              }
            })
            .catch((err) => {})
            .finally(() => {});
        }
      });
    },
    updateThirdService(){
      this.$refs["thirdPartyCollectionForm"].validate((valid)=>{
        if (valid){
          console.log(this.thirdPartyCollectionForm, "this.thirdPartyCollectionForm");
          postAction(this.url.batchUpdateThirdPartyCollection,this.thirdPartyCollectionForm)
          .then((res)=>{
            if (res.success){
              this.$message.success(res.message);
              this.thirdPartyCollectionForm = {};
              this.thirdPartyCollectionForm.batchUpdateThirdPartyCollectionDto = [];
              this.dialogSignThirdPartyCollectionFormVisible = false;
              this.handleSearch();
            }
          })
          .catch((err)=>{})
          .finally(()=>{});
        }
      });
    },
    changeOrderType() {
         this.$refs["typeForm"].validate((valid)=>{
          if(valid){
  postAction(this.url.batchUpdateOrderTypeTwo,{
    orderTypeTwo:this.typeForm.orderTypeTwo,
    orderNoList:this.selectionRows.map(item=>item.orderNo)
  })
          .then((res)=>{
            if (res.success){
              this.$message.success(res.message);
               this.typeHandleClose()
              this.handleQuery();

            }
          })
          .catch((err)=>{})
          }
         })

    },
    typeHandleClose(){
      this.typeVisible=false
      this.typeForm={}
    },
    changeCaiwuRemark(){
               this.$refs["caiwuForm"].validate((valid)=>{
          if(valid){
  postAction(this.url.updateBatchRemark ,{
    caiwuRemark:this.caiwuForm.caiwuRemark,
    orderNoList:this.selectionRows.map(item=>item.orderNo)
  })
          .then((res)=>{
            if (res.success){
              this.$message.success(res.message);
              this.caiwuVisible=false
              this.caiwuForm={}
              this.handleQuery();

            }
          })
          .catch((err)=>{})
          }
         })
    },
      exportPrejudgment () {
                const containerIdList = [];
                let fileNameList = [];
                let uniquefileName =[];

                this.selectionRows.forEach(item=>{
                  if(Boolean(item.containerId)){
                    containerIdList.push(item.containerId)
                    fileNameList.push(item.containerNo)
                  }
                })

                if (this.selectionRows.length == 1 && !this.selectionRows[0].containerId){
                  this.$message.warning('柜号不存在');
                  return
                }
const uniqueArray = [...new Set(containerIdList)];
uniquefileName = [...new Set(fileNameList)];
                 if(uniqueArray.length>100) {
                    this.$message.warning('最多支持 100 个柜子一起导出,请减少勾选项');
                    return
                }

this.downloadExcelPost(this.url.exportDeliveryDocTwo,uniqueArray.length>1?'派送文件-合并':'派送文件-'+uniquefileName[0],{ids:uniqueArray})
            }
  },
  created() {
    this.$set(this.queryForm,'dataCreateTime','6');
    //初始化国家
    this.enabledCreatetime = false;
    this.queryForm.initCountry = 1;
    this.queryForm.pageSize = 10;
    this.queryForm.cusId = this.$route.query.id;
    //初始化组别列表
    this.getDepartList()
    console.log(this.buttonShowList.xscwbz,'this.buttonShowList.xscwbz');

    if(this.$route.path=='/hyFinanceManage/AllOrderModule' && this.buttonShowList.xscwbz){
      this.showCaiwuRemark=true
      this.columns.splice(5,0,{
          prop: "caiwuRemark",
          label: "财务备注",
          minWidth: 75,
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
