<template>
    <div class="main-full-content">
        <el-dialog
            class="yulan"
            title="预览"
            append-to-body
            :visible="imgVisible"
            custom-class="dialogClass"
            top="50px"
            :before-close="() => { imgVisible = false }">
            <div class="addEditBox">
              <img :src="imgUrl" width="100%">
            </div>
        </el-dialog>
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请编号">
                            <el-input type="textarea" v-model="queryForm.applyNo" @keyup.enter.native="handleCheck" placeholder="用逗号或者空格分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.settleName" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.payCur" dict="bd_cur" :isNeed="queryForm.payCur"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="申请时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="btime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="feetime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="销账时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="rmTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务类型">
                            <el-select v-model="queryForm.agentPayType">
                                <el-option value="-1" label="全部"></el-option>
                                <el-option value="0" label="海运"></el-option>
                                <el-option value="2" label="快递"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.salesmanId"
                                :is-need="queryForm.salesmanId"
                                :father-val="queryForm.salesmanId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款银行">
                            <f-select :dict="'receive_bank'"
                                      v-model="queryForm.receiveBankId"
                                      :isNeed="queryForm.receiveBankId">
                            </f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="数据时间">
                      <f-select
                          class="textarea_table"
                          v-model="queryForm.dataCreateTime"
                          :is-need="queryForm.dataCreateTime"
                          :dict="'data_create_time'"
                          @keyup.enter.native="handleSearch"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="setProof"
                           v-if="buttonShowList.scpz"
                >生成凭证</el-button>

                <el-button size="medium" type="primary" @click="downloadWaitCheckExcel"
                           v-if="buttonShowList.dcdqyexcel"
                >导出excel
                </el-button
                >
                <!--<el-button size="medium" type="primary" @click="jumpBindBillView">合并销账单流水</el-button>-->
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="61vh"
                :show-operation="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleCheckBill(scope.row)"
                        >销账
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'checkStatus'">
                        <span v-if="scope.row.checkStatus === '3'">已销账</span>
                        <el-divider
                            direction="vertical"
                            v-if="scope.row.checkStatus === '3' && buttonShowList.qxxz"
                        ></el-divider>
                        <el-button v-if="scope.row.checkStatus === '3' && buttonShowList.qxxz"  type="text" @click="cancelRemove(scope.row)"
                        >取消销账
                        </el-button>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'checkUnusualInfo' && scope.row.checkUnusualInfo === null">-->
                    <template v-else-if="scope.column.property === 'checkUnusualInfo'">
                        <span>无</span>
                    </template>

                    <template v-else-if="scope.column.property === 'applyNo'">
                        <el-button type="text" @click="showApplyDetail(scope.row)">{{ scope.row.applyNo }}
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'picUrl'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a @click.prevent="showImg(item.url)" target="_Blank" download style="color: #00a0e9">{{item.name}}</a><br>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'picUrlDown'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a :href="item.url" target="_Blank" download style="color: #00a0e9">{{item.name}}</a><br>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'billBys'">
                        <span v-html="cutout(scope.row.billBys)"/>
                    </template>
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{parseDict(scope.row.creator,'sys_create_by')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'settleAccount'">
                        <span>{{scope.row.bankName + ' - '+ scope.row.account}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'agentPayType'">
                        <span v-if="scope.row.agentPayType=='0'">海运费用申请</span>
                        <span v-if="scope.row.agentPayType=='1'">柜子的费用申请</span>
                        <span v-if="scope.row.agentPayType=='2'">快递费用申请</span>
                        <span v-if="scope.row.agentPayType=='3'">海运/柜子混合费用申请</span>
                        <span v-if="scope.row.agentPayType=='4'">海外仓费用申请</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <receive-apply-update ref="apply" @ok="this.handleQuery"></receive-apply-update>
        <remove-dialog ref="removeDialog" @ok="this.handleQuery"></remove-dialog>
        <add-bill-no-dialog :visible.sync="billVisible":applyNos="applyNos":voucherType="voucherType"></add-bill-no-dialog>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import removeDialog from "./removeDialog";
import receiveApplyUpdate from "./receiveApplyUpdate";
import { setUpRouteUtil } from '@/utils/util';
import AddBillNoDialog from "../voucher/AddBillNoDialog";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../../../utils/downloadJasper";

export default {
    mixins: [QueryMixins],
    // name:'removed',
    components: {
        removeDialog,
        receiveApplyUpdate,
        AddBillNoDialog
    },
    data() {
        return {
            dictCodes:['sys_create_by'],
            queryForm:{agentPayType:'0'},
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                  type: "selection",
                  width: 50,
                },
                // {
                //   prop: "action",
                //   label: "操作",
                //   width: 130,
                // },
                {
                    prop: "checkStatus",
                    label: "查账状态",
                    width: 150,
                },
                {
                    prop: "checkUnusualInfo",
                    label: "异常原因",
                },
                {
                    prop: "checkAccountBy",
                    label: "查账人",
                },
                // {
                //     prop: "salesman",
                //     label: "业务员",
                // },
                {
                    prop: "applyNo",
                    label: "申请编号",
                    width: 110,
                },
                {
                    prop: "agentPayType",
                    label: "申请类型",
                },
                {
                    prop: "remark",
                    label: "备注",
                },
                {
                    prop: "settleName",
                    label: "结算对象",
                },
                // {
                //     prop: "company",
                //     label: "公司名称",
                // },
                {
                    prop: "payMoney",
                    label: "应收金额",
                },
                {
                    prop: "payCur",
                    label: "应收币种",
                },
                {
                    prop: "realMoney",
                    label: "账单金额",
                },
                {
                    prop: "realCur",
                    label: "账单币种",
                },
                {
                    prop: "billMoney",
                    label: "水单金额",
                },
                {
                    prop: "salesMoney",
                    label: "实际到账金额",
                },
                {
                    prop: "salesMoneyCur",
                    label: "实际到账币种",
                },
              {
                prop: "salesman",
                label: "业务员",
              },
                {
                    prop: "creator",
                    label: "申请人",
                },
                {
                    prop: "createTime",
                    label: "申请时间",
                },
                {
                    prop: "removeTime",
                    label: "销账时间",
                },
                {
                    prop: "removeBy",
                    label: "销账人",
                },
                {
                    prop: "settleAccount",
                    label: "收款银行",
                },
                {
                    prop: "picUrl",
                    label: "水单预览",
                },
                {
                    prop: "picUrlDown",
                    label: "水单下载",
                },
                {
                    prop: "billBys",
                    label: "水单付款人",
                    width: 100,
                },
                {
                    prop: "billAmount",
                    label: "水单总金额",
                },
                // {
                //   prop: "billTime",
                //   label: "账单时间",
                // },
            ],
            imgVisible: false,
            imgUrl: '',
            url: {
                list: "/shipping/hySettleReceive/removed",
                setProof: "/shipping/hySettleReceive/setProof",
                cancelRemoved: "/shipping/hySettleReceive/cancelRemoved",
                // downloadWaitCheckExcel:"/shipping/hySettleReceive/downloadWaitCheckExcel",
                downloadWaitCheckExcel:"/file/export/excel/downloadCaiwuDm2",

            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],

            btime: [],  //开船时间
            feetime: [],  //开船时间
            rmTime: [],  //开船时间

            billVisible:false,//控制弹窗是否显示,
            applyNos:[],//传递给弹窗的 申请编号
            voucherType:"",//传递给弹窗的,凭证类型
        };
    },
    computed: {
      buttonShowList() {
        return {
          'scpz':this.$btnIsShow('hyFinanceManage/modules/receive/removed','0','生成凭证'),
          'dcdqyexcel':this.$btnIsShow('hyFinanceManage/modules/receive/removed','0','导出当前页excel'),
          'qxxz':this.$btnIsShow('hyFinanceManage/modules/receive/removed','0','取消销账'),
        }
      }
    },
    created() {
      // this.queryForm.orderType='0'
      this.$set(this.queryForm,'orderType','0');
      this.$set(this.queryForm,'dataCreateTime','3');
    },
    methods: {
        setProof(){
            // console.log(this.btime)
            // console.log(this.feetime)
            // console.log(this.otime)
            if((this.btime===null || this.btime.length===0)
                && (this.feetime===null || this.feetime.length===0)
                && (this.otime===null || this.otime.length===0)){
                this.$message.warning("查询条件的【下单时间】【开船时间】【费用时间】须填写一个或多个");
                return;
            }

            // console.log("应收-生成凭证以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选需要生成凭证的申请号");
                return;
            }
            this.loading = true;

            let count = [];
            this.selectionRows.forEach((item) => {
                count.push(item.id);
            });

            let arr = Array.from(new Set(count));

            let obj = {}
            obj.agentPayIds = arr;
            if (this.otime !== null && this.otime.length > 1) {
                obj.orderCreateTimeS = this.otime[0];
                obj.orderCreateTimeE = this.otime[1];
            }
            if (this.btime !== null && this.btime.length > 1) {
                obj.boatTimeS = this.btime[0];
                obj.boatTimeE = this.btime[1];
            }
            if (this.feetime !== null && this.feetime.length > 1) {
                obj.feeCreateTimeS = this.feetime[0];
                obj.feeCreateTimeE = this.feetime[1];
            }
            // console.log('obj',obj)
            let fileName = '应收凭证'+ new Date().toLocaleDateString();
            postAction(this.url.setProof, obj, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })

        },
        cancelRemove(row){

            let obj = {
                id:row.id,
                applyNo:row.applyNo
            }
            postAction(this.url.cancelRemoved,obj).then((res)=>{
                // console.log(res)
                if(res.success){
                    this.$message.success('取消销账成功！')
                    this.handleCheck()
                }else{
                    this.$message.error(res.message)
                }
            })

        },


        cutout(cellValue) {
            if (cellValue === null) {
                return ''
            }
            return cellValue.replace(/\,/g, '</br>')
        },
        showImg(url) {
            // console.log('url',url);
            this.imgUrl = url;
            this.imgVisible = true;
        },
        initCusPage({cusId}) {
            if (cusId) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${cusId}`)
            }

        },
        showApplyDetail(row) {
            // this.$refs.apply.showDetail(row);
            this.$refs.apply.onlyShowDetail(row);
        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        handleCheckBill(row) {
            // console.log(row);
            this.$refs.removeDialog.edit(row);
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.$set(this.queryForm,'agentPayType','0')
            this.otime = "";
            this.stime = "";
            this.btime = "";
            this.feetime = "";
            this.ipagination.currentPage = 1;
            this.handleQuery();
        },
        handleCheck() {
            this.loading = true;

            if (this.stime && this.stime.length > 1) {
                this.queryForm.sCreateTimeS = this.stime[0];
                this.queryForm.sCreateTimeE = this.stime[1];
            }else{
                this.queryForm.sCreateTimeS = '';
                this.queryForm.sCreateTimeE = '';
            }

            if (this.otime && this.otime.length > 1) {
                this.queryForm.oCreateTimeS = this.otime[0];
                this.queryForm.oCreateTimeE = this.otime[1];
            }else{
                this.queryForm.oCreateTimeS = '';
                this.queryForm.oCreateTimeE = '';
            }

            if (this.btime && this.btime.length > 1) {
                this.queryForm.boatTimeS = this.btime[0];
                this.queryForm.boatTimeE = this.btime[1];
            }else{
                this.queryForm.boatTimeS = '';
                this.queryForm.boatTimeE = '';
            }

            if (this.feetime && this.feetime.length > 1) {
                this.queryForm.feeCreateTimeS = this.feetime[0];
                this.queryForm.feeCreateTimeE = this.feetime[1];
            }else{
                this.queryForm.feeCreateTimeS = '';
                this.queryForm.feeCreateTimeE = '';
            }

            if (this.rmTime && this.rmTime.length > 1) {
                this.queryForm.removeTimeS = this.rmTime[0];
                this.queryForm.removeTimeE = this.rmTime[1];
            }else{
                this.queryForm.removeTimeS = '';
                this.queryForm.removeTimeE = '';
            }
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
      downloadWaitCheckExcel2() {
        // if(this.selectionRows.length ===0){
        //     this.$message.warning("请勾选费用");
        //     return;
        // }
        if((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
            && (this.queryForm.username === '' || this.queryForm.username === undefined)
            && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
            && (this.stime ===null || this.stime.length === 0)
            && (this.otime ===null || this.otime.length === 0)
            && (this.feetime ===null || this.feetime.length === 0)
            && (this.btime ===null || this.btime.length === 0)
            && (this.rmTime ===null || this.rmTime.length === 0)
            && (this.queryForm.company === '' || this.queryForm.company === undefined)
            && (this.queryForm.payCur ==='' || this.queryForm.payCur===undefined)){
          this.$message.warning('导出excel必须添加查询条件')
          return
        }
        this.loading = true;

        this.queryForm.downloadExcelType='removed';
        if (this.stime && this.stime.length > 1) {
          this.queryForm.sCreateTimeS = this.stime[0];
          this.queryForm.sCreateTimeE = this.stime[1];
        } else {
          this.queryForm.sCreateTimeS = '';
          this.queryForm.sCreateTimeE = '';
        }

        if (this.otime && this.otime.length > 1) {
          this.queryForm.oCreateTimeS = this.otime[0];
          this.queryForm.oCreateTimeE = this.otime[1];
        } else {
          this.queryForm.oCreateTimeS = '';
          this.queryForm.oCreateTimeE = '';
        }

        if (this.btime && this.btime.length > 1) {
          this.queryForm.boatTimeS = this.btime[0];
          this.queryForm.boatTimeE = this.btime[1];
        } else {
          this.queryForm.boatTimeS = '';
          this.queryForm.boatTimeE = '';
        }

        if (this.feetime && this.feetime.length > 1) {
          this.queryForm.feeCreateTimeS = this.feetime[0];
          this.queryForm.feeCreateTimeE = this.feetime[1];
        }else{
          this.queryForm.feeCreateTimeS = '';
          this.queryForm.feeCreateTimeE = '';
        }

        if (this.rmTime && this.rmTime.length > 1) {
          this.queryForm.removeTimeS = this.rmTime[0];
          this.queryForm.removeTimeE = this.rmTime[1];
        }else{
          this.queryForm.removeTimeS = '';
          this.queryForm.removeTimeE = '';
        }

        let form = {...this.queryForm, ...this.filterForm};
        for (let item in form) {
          if (typeof form[item] === "string") {
            form[item] = form[item].trim();
            if (form[item] === "") {
              form[item] = null;
            }
          }
        }
        const {prop, order} = this.isorter;
        // 传入的参数
        const params = {
          ...form,
          column: prop,
          order: order.indexOf("asc") !== -1 ? "asc" : "desc",
        };
        //判断 是否要查询startTime

        if (!params.column) {
          if (this.queryForm.column === "startTime") {
            this.$set(params, "column", "startTime");
          } else {
            this.$set(params, "column", "createTime");
          }
          //提货中查询
          if (this.queryForm.column === "distributionTime") {
            this.$set(params, "column", "distributionTime");
          }
        }
        if (this.ipagination) {
          params.pageNo = this.ipagination.currentPage;
          params.pageSize = this.ipagination.pageSize;
        }
        // console.log("开始下载");

        let filename = this.getDateStr() + '导出已销账'

        let url = this.url.downloadWaitCheckExcel

        downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("POST",url,params,filename + ".xlsx",this);
      },
        downloadWaitCheckExcel() {
            // if(this.selectionRows.length ===0){
            //     this.$message.warning("请勾选费用");
            //     return;
            // }
            if((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
                && (this.queryForm.username === '' || this.queryForm.username === undefined)
                && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
                && (this.stime ===null || this.stime.length === 0)
                && (this.otime ===null || this.otime.length === 0)
                && (this.feetime ===null || this.feetime.length === 0)
                && (this.btime ===null || this.btime.length === 0)
                && (this.rmTime ===null || this.rmTime.length === 0)
                && (this.queryForm.company === '' || this.queryForm.company === undefined)
                && (this.queryForm.payCur ==='' || this.queryForm.payCur===undefined)){
                this.$message.warning('导出excel必须添加查询条件')
                return
            }
            this.loading = true;

            this.queryForm.downloadExcelType='removed';
            if (this.stime && this.stime.length > 1) {
                this.queryForm.sCreateTimeS = this.stime[0];
                this.queryForm.sCreateTimeE = this.stime[1];
            } else {
                this.queryForm.sCreateTimeS = '';
                this.queryForm.sCreateTimeE = '';
            }

            if (this.otime && this.otime.length > 1) {
                this.queryForm.oCreateTimeS = this.otime[0];
                this.queryForm.oCreateTimeE = this.otime[1];
            } else {
                this.queryForm.oCreateTimeS = '';
                this.queryForm.oCreateTimeE = '';
            }

            if (this.btime && this.btime.length > 1) {
                this.queryForm.boatTimeS = this.btime[0];
                this.queryForm.boatTimeE = this.btime[1];
            } else {
                this.queryForm.boatTimeS = '';
                this.queryForm.boatTimeE = '';
            }

            if (this.feetime && this.feetime.length > 1) {
                this.queryForm.feeCreateTimeS = this.feetime[0];
                this.queryForm.feeCreateTimeE = this.feetime[1];
            }else{
                this.queryForm.feeCreateTimeS = '';
                this.queryForm.feeCreateTimeE = '';
            }

            if (this.rmTime && this.rmTime.length > 1) {
                this.queryForm.removeTimeS = this.rmTime[0];
                this.queryForm.removeTimeE = this.rmTime[1];
            }else{
                this.queryForm.removeTimeS = '';
                this.queryForm.removeTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("开始下载");

            let filename = this.getDateStr() + '导出已销账'

            let url = this.url.downloadWaitCheckExcel

            getAction(url, params, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        this.visible = false
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象

                    this.visible = false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        this.handleCheck();
                    }, 3000)
                })
        },
        jumpBindBillView() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选需要绑定流水的申请号");
                return;
            }

            //传递值
            // this.selectionRows
            this.applyNos = this.selectionRows.map(a=>a.applyNo);
            this.voucherType = "3";
            //打开弹窗
            this.billVisible = true;
        },
    },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
.addEditBox {
  height: 780px;
  overflow: scroll;
}
</style>
