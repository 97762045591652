<template>
  <div>

    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="订单号">
              <el-input
                  key="queryFormOrderNo"
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="用车编号">
              <el-input
                  key="queryFormOrderNo"
                  v-model="queryForm.takeNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="客户名" key="queryFromCustomer">
              <el-input v-model.trim="queryForm.customer"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="业务员" key="queryFormSalesmanId">
              <f-select
                  v-model="queryForm.salesmanId"
                  :isNeed="queryForm.salesmanId"
                  :dict="'salesmanId'">
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="客服" label-width="80px" key="queryFormDocId">
              <f-select :dict="'docId'" v-model="queryForm.docId"
                        :is-need="queryForm.docId"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="供应商" label-width="90px" key="queryFormThSupplier">
              <el-input v-model="queryForm.thSupplier" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="联系人" label-width="90px" key="queryFormContact">
              <el-input v-model="queryForm.contact" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="电话" label-width="90px" key="qeuryFormContactTel">
              <el-input v-model="queryForm.contactTel" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="交货仓库" key="queryFormDeliveryPointId">
              <f-select :dict="'delivery_point'" v-model="queryForm.deliveryPointId"
                        :isNeed="queryForm.deliveryPointId"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="用车状态" key="queryFormTakeStatusList">
              <f-select :dict="'warehouse_take_order_status'" v-model="queryForm.takeStatusList"
                        :multiple="true" :isNeed="queryForm.takeStatusList"></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="提货时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.takeTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.takeTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="下单时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch">查询
            </el-button>

            <el-button
                size="medium"
                type="primary"
                @click="handleReset"
                icon="reload"
                style="margin-left: 8px">重置
            </el-button>

          </el-col>

        </el-row>
      </el-card>

    </el-form>

    <div class="f-p-b-15">

      <el-button type="primary" size="medium" @click="realSendTake(null,selectionRows)"
                 :disabled="selectedRowKeys.length==0"
                 v-if="buttonShowList.fqth">批量合并发起
      </el-button>
      <el-button type="primary" size="medium" @click="cancelTakeIds()" :disabled="selectedRowKeys.length===0"
                 v-if="buttonShowList.qx">批量取消
      </el-button>
      <el-button type="primary" size="medium" @click="delTakeIds" :disabled="selectedRowKeys.length==0"
                 v-if="buttonShowList.sc">批量删除
      </el-button>
      <el-button type="primary" size="medium" @click="addTakeDeliveryInfo"
                 v-if="buttonShowList.plzjth">批量增加提货
      </el-button>
    </div>
    <el-form :model="form" :rules="rules" ref="tabref">

      <f-table
          v-loading="loading"
          ref="GTable"
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="(tableData = form.tableData)"
          :cell-class-name="checkboxClassName"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          :isNeedTopScrollTail="true"
          @cell-click="handleCellClick"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="saveBatch(scope.row)"
                       :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                       v-if="buttonShowList.bc">保存
            </el-button>
            <el-button type="text" @click="realSendTake(scope.row,null)"
                       :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                       v-if="buttonShowList.fqth">发起
            </el-button>
            <el-button type="text" @click="handleDel(scope)"
                       :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                       v-if="buttonShowList.sc">删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{
                scope.row.orderNo
              }}</a>
          </template>


          <template v-else-if="scope.column.property === 'thSupplier'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.contact'"
                :key="'tableData.' + scope.$index + '.contact'"

            >
              <f-select
                  v-if="scope.row.canEditthSupplier"
                  v-model="scope.row.contactId"
                  :is-need="scope.row.contactId"
                  :data-list="scope.row.supplierList"
                  :show-size="1"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                  @change="updateSup(scope.row.contactId, scope.$index,scope.row.supplierMap,scope.row.cusId)">
              </f-select>
              <span v-else>{{ getThSupplier(scope.row) }}</span>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'contact'">
            <span>公司：{{ scope.row.thSupplier }}</span><br>
            <span>地址：{{ scope.row.contactAdd }}</span><br>
            <span>联系人：{{ scope.row.contact }}</span><br>
            <span>联系电话：{{ scope.row.contactTel }}</span>
          </template>

          <template v-else-if="scope.column.property === 'getsTime'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.getsTime'"
                :key="'tableData.' + scope.$index + '.getsTime'"
                :rules="rules.getsTime"
            >
              <el-date-picker
                  v-if="scope.row.canEditgetsTime"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                  class="c-full-width"
                  v-model="scope.row.getsTime"
                  type="datetime"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间"
              >
              </el-date-picker>
              <span v-else>{{ scope.row.getsTime }}</span>
            </el-form-item>
          </template>


          <template v-else-if="scope.column.property === 'ctn'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.ctn'"
                :key="'tableData.' + scope.$index + '.ctn'"
                :rules="rules.number2"
            >
              <el-input
                  v-if="scope.row.canEditctn"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0"
                  v-model="scope.row.ctn"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  type="number"
              ></el-input>
              <span v-else>{{ scope.row.ctn }}</span>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'kg'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.kg'"
                :key="'tableData.' + scope.$index + '.kg'"
                :rules="rules.trailerKg"
            >
              <el-input v-model="scope.row.kg"
                        type="number"
                        v-if="scope.row.canEditkg"
                        :disabled="scope.row.takeId && scope.row.takeId.length > 0"
              ></el-input>
              <span v-else>{{ scope.row.kg }}</span>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'cmb'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.cmb'"
                :key="'tableData.' + scope.$index + '.cmb'"
                :rules="rules.trailerKg"

            >
              <el-input
                  v-if="scope.row.canEditcmb"
                  v-model="scope.row.cmb"
                  type="number"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0"
              ></el-input>
              <span v-else>{{ scope.row.cmb }}</span>
            </el-form-item>
          </template>

          <template v-else-if="scope.column.property === 'remark'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.remark'"
                :key="'tableData.' + scope.$index + '.remark'"
            >
              <el-input
                  v-if="scope.row.canEditremark"
                  v-model="scope.row.remark"
                  maxlength="100"
                  show-word-limit
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0">
              </el-input>
              <span v-else>{{ scope.row.remark }}</span>
            </el-form-item>

          </template>
          <template v-else-if="scope.column.property === 'isWeiban'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.isWeiban'"
                :key="'tableData.' + scope.$index + '.isWeiban'"
            >
              <f-select
                  v-if="scope.row.canEditisWeiban"
                  v-model="scope.row.isWeiban"
                  :is-need="scope.row.isWeiban"
                  :dict="'unit_whether'"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0">
              </f-select>
              <span v-else>{{ scope.row.isWeiban === '1' ? '是' : '否' }}</span>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'isBanyun'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.isBanyun'"
                :key="'tableData.' + scope.$index + '.isBanyun'"
            >
              <f-select
                  v-if="scope.row.canEditisBanyun"
                  v-model="scope.row.isBanyun"
                  :is-need="scope.row.isBanyun"
                  :dict="'unit_whether'"
                  :disabled="scope.row.takeId && scope.row.takeId.length > 0">
              </f-select>
              <span v-else>{{ scope.row.isBanyun === '1' ? '是' : '否' }}</span>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'takeStatus'">
                        <span :style="getStatusColor(scope.row.takeStatus)"
                              style="color: #00b7ee">{{ scope.row.takeStatus }}</span>
          </template>
          <template v-else-if="scope.column.property === 'takeFeeStr'">
            <span v-html="scope.row.takeFeeStr"></span>
          </template>
          <template v-else-if="scope.column.property === 'busNo'">
            <div v-if="scope.row.busNo">
              <a style="color: #00a0e9" v-if="scope.row.busNo.indexOf('ZC') !== -1" @click.prevent="openzcDetail(scope.row.takeId)">{{
                  scope.row.busNo
                }}</a>
              <a style="color: #00a0e9" v-else-if="scope.row.busNo.indexOf('TC') !== -1" @click.prevent="opentcDetail(scope.row.takeId)">{{
                  scope.row.busNo
                }}</a>
              <a style="color: #00a0e9" v-else @click.prevent="openTakeDetail(scope.row.takeId)">{{
                  scope.row.busNo
                }}</a>
            </div>

          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-form>
    <!-- </el-card> -->
    <el-dialog title="添加联系人"
               v-if="supplierVisible"
               :visible.sync="supplierVisible" width="30%" center :close-on-click-modal="false">

      <el-form :model="supplier" :rules="supplierRules" ref="supplier">
        <el-form-item label="供应商名" prop="name" :label-width="formLabelWidth" key="suppliername">
          <el-input v-model="supplier.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司名" prop="company" :label-width="formLabelWidth" key="suppliercompany">
          <el-input v-model="supplier.company" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact" :label-width="formLabelWidth" key="suppliercontact">
          <el-input v-model="supplier.contact" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="tel" :label-width="formLabelWidth" key="suppliertel">
          <el-input v-model="supplier.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country" :label-width="formLabelWidth" key="suppliercountry">
          <el-select
              :loading="coloading"
              @click.native="initCountryList"
              v-model="supplier.country" placeholder="请选择" filterable clearable @change="initStateList">
            <el-option v-for="(item,index) in countrylist" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省" prop="state" :label-width="formLabelWidth" key="supplierstate">
          <el-select
              :loading="stateloading"
              v-model="supplier.state" placeholder="请选择" filterable clearable @change="initCityList">
            <el-option v-for="item in statelist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="city" :label-width="formLabelWidth" key="suppliercity">
          <el-select
              :loading="cityloading"
              v-model="supplier.city" placeholder="请选择" filterable clearable>
            <el-option v-for="item in citylist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address" :label-width="formLabelWidth" key="supplieraddress">
          <el-input v-model="supplier.address" autocomplete="off" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth" key="supplierremark">
          <el-input v-model="supplier.remark" autocomplete="off" type="textarea" show-word-limit
                    maxlength="255"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button type="success" plain @click="saveSupplier" :disabled="supplierDisable">确 定</el-button>
        <el-button type="success" plain @click="supplierVisible = false">取 消</el-button>
      </div>

    </el-dialog>
    <el-dialog
        :title="'确认批量发起提货'"
        v-if="dialogFormVisible"
        :visible.sync="dialogFormVisible"
        width="30%"
        center
        :close-on-click-modal="false"
    >
      <el-form :model="trailerForm" ref="form">
        <el-form-item
            class="el_form_item"
            prop="getsTime"
            label="提货时间"
            :label-width="formLabelWidth"
            key="trailerFormgetsTime"
        >
          <el-date-picker
              class="c-full-width"
              v-model="trailerForm.getsTime"
              type="datetime"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="editStartOptions"
              placeholder="请选择时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="是否需要尾板车"
            prop="cmb"
            key="trailerFormcmb"
            :label-width="formLabelWidth"
            :rules="rules.isWeiban"
        >
          <f-select v-model="trailerForm.isWeiban" :is-need="trailerForm.isWeiban" :dict="'unit_whether'"></f-select>

        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="是否需要装车"
            prop="isBanyun"
            key="trailerFormisBanyun"
            :label-width="formLabelWidth"
            :rules="rules.isBanyun"
        >
          <f-select v-model="trailerForm.isBanyun" :is-need="trailerForm.isBanyun" :dict="'unit_whether'"></f-select>

        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="托盘数"
            prop="oceanPkgs"
            key="trailerFormoceanPkgs"
            :rules="rules.number2"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="trailerForm.oceanPkgs"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="备注"
            prop="remark"
            key="trailerFormremark"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="trailerForm.remark"
              autocomplete="off"
              type="textarea"
              maxlength="250"
              show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchSendTihuo" :disabled="btnDisable"
        >确 定
        </el-button>
      </div>
    </el-dialog>
    <add-take-delivery-info-dialog :selected-trailer-id-list="selectedIds" :visible="takeVisible" :addTakeDeliveryInfoFlag="addTakeDeliveryInfoFlag"
                                   @ok="closeTake"></add-take-delivery-info-dialog>
    <take-order-detail :visible.sync="takeOrderDetailVisible" :takeIds="selectedTakeId" @ok="closeTakeDetail"></take-order-detail>
    <zc-order-detail :visible.sync="zcOrderDetailVisible" :takeIds="selectedzcId" @ok="closezcDetail"></zc-order-detail>
    <tc-order-detail :visible.sync="tcOrderDetailVisible" :takeIds="selectedtcId" @ok="closetcDetail"></tc-order-detail>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {putAction, postAction, getAction} from "@/api/manage";
import {
  validateCmb,
  validateCtn,
  validateCtn2,
} from "../../../utils/validator";
import Tempcclate from "../../isystem/Dict";
import ElementForm from "../../isystem/activity/refactor/form/ElementForm";
import {getallcountries, getcityList, getstateList, httpAction} from "../../../api/manage";
import addTakeDeliveryInfoDialog from "../../warehouse2.0/modules/addTakeDeliveryInfoDialog";
import takeOrderDetail from "../../warehouse2.0/modules/takeOrderDetail.vue";
import zcOrderDetail from "../../warehouse2.0/modules/zcOrderDetail.vue";
import tcOrderDetail from "../../warehouse2.0/modules/tcOrderDetail.vue";
export default {
  name: "Potential",
  mixins: [QueryMixins],
  components: {
    ElementForm,
    Tempcclate,
    addTakeDeliveryInfoDialog,
    takeOrderDetail,
    zcOrderDetail,
    tcOrderDetail
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {
      }
    },
    shipRes: {
      type: Object
    },
  },
  data() {
    return {

      isNeedTop: true,
      takeVisible: false,
      selectedIds: [],
      disableMixinMounted: false,
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.geteTime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.geteTime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.form.getsTime) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      dialogFormVisible: false,
      form: {ctn: "", kg: "", cmb: "", tableData: []},
      formLabelWidth: "120px",
      btnDisable: false,
      row: {},
      visible: false,
      //表单校验
      rules: {
        contact: [
          {required: true, message: "请选择联系人", trigger: "change"},
        ],
        getsTime: [
          {required: true, message: "请选择提货开始时间", trigger: "change"},
        ],
        geteTime: [
          {required: true, message: "请选择提货结束时间", trigger: "change"},
        ],
        contactAdd: [
          {required: true, message: "请输入地址", trigger: "blur"},
        ],
        contactTel: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "blur",
          // },
        ],
        number: [{validator: validateCtn, trigger: "change"}],
        number2: [
          // {required:true,message:'不能为空',trigger:'change'},
          {validator: validateCtn2, trigger: "change"}],
        trailerKg: [{required: true, message: '不能为空', trigger: 'change'}, {
          validator: validateCmb,
          trigger: "change"
        }],
      },
      columns: [
        {
          type: "selection",
          width: 20,
        },
        {
          prop: "action",
          label: "操作",

        },
        {
          prop: "busNo",
          label: "用车编号",

        },
        {
          prop: "takeStatus",
          label: "用车状态",

        },

        {
          prop: "driverInfo",
          label: "车牌/司机/电话",
          minWidth: 200
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 200
        },
        {
          prop: "customer",
          label: "客户名",

        },
        {
          prop: "salesman",
          label: "业务员",

        },
        {
          prop: "doc",
          label: "客服",

        },
        {
          prop: "takeFee",
          label: "运费",

        },
        {
          prop: "takeFeeStr",
          label: "运费分摊",

        },
        {
          prop: "getsTime",
          label: "提货时间",
          minWidth: 160,
        },
        {
          prop: "thSupplier",
          label: "供应商",
          minWidth: 160

        },
        {
          prop: "contact",
          label: "联系人信息",
          minWidth: 160
        },

        // {
        //   prop: "contactAdd",
        //   label: "地址",
        //   minWidth: 200
        // },
        //
        // {
        //   prop: "contactTel",
        //   label: "电话",
        //
        // },
        {
          prop: "oceanPkgs",
          label: "打托数",
          width: 60
        },
        {
          prop: "pcs",
          label: "PCS",
          width: 60
        },
        {
          prop: "ctn",
          label: "件数",
          width: 75
        },
        {
          prop: "kg",
          label: "重量",
          width: 75
        },
        {
          prop: "cmb",
          label: "体积",
          width: 75
        },
        {
          prop: "remark",
          label: "备注"
        },
        {
          prop: "isBanyun",
          label: "是否需要搬运"
        },
        {
          prop: "isWeiban",
          label: "是否需要尾板车"
        },
        {
          prop: "deliveryPointName",
          label: "交货仓库"
        },
        {
          prop: "lclPlaceName",
          label: "拼柜仓库"
        },
      ],
      url: {
        list: "/warehouse/takeDelivery/getTrailerPage",
        delByid: "/interface/ttOrder/deleteTrailerById/",
        add: "/interface/ttOrder/addOneTrailer",
        saveBatch: "/interface/ttOrder/updateTrailerBatch",
        // sendTihuo: "/order/os/trailer/sendTihuo",
        //新的发起提货接口
        sendTihuo: "/warehouse/takeDelivery/sendTake",
        sendTakeAndUpdateTrailer: "/warehouse/takeDelivery/sendTakeAndUpdateTrailer",
        getSupplierList: "/customer/supplier/getList",
        addSupplier: '/customer/supplier/add',
        cancelTake: '/warehouse/takeDelivery/updateCancelTakeByIds',
        updateDelTakeByIds: '/warehouse/takeDelivery/updateDelTakeByIds',

      },
      obj: {},
      tihuoList: [],
      supplierlist: [],
      supplierNamelist: [],
      suploading: false,
      suppliermap: {},
      cusId: "",
      index:0,
      supplier: {},
      supplierVisible: false,
      supplierDisable: true,
      supplierRules: {
        country: [
          {required: true, message: '请选择国家', trigger: 'change'}
        ],
        // state: [
        //     {required: true, message: '请选择州', trigger: 'change'}
        // ],
        // city: [
        //     {required: true, message: '请选择市', trigger: 'change'}
        // ],
        name: [
          {required: true, message: '请输入供应商名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],

        tel: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          // {pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        getStime: [
          {required: true, message: '请选择开始时间', trigger: 'blur'}
        ],
        getEtime: [
          {required: true, message: '请选择结束时间', trigger: 'blur'}
        ],

        address: [
          {required: true, message: '请输入地址', trigger: 'blur'},
          {max: 255, message: '地址长度不能超过255', trigger: 'blur'}
        ]
      },
      coloading: false,
      cityloading: false,
      stateloading: false,
      // 国家基础数据
      countrylist: [],
      // 省基础数据
      statelist: [],
      // 市基础数据
      citylist: [],
      trailerForm: {},
      tableWidth: {
        width: 0
      },
      takeOrderDetailVisible: false,
      selectedTakeId:'',
      zcOrderDetailVisible: false,
      selectedzcId:'',
      tcOrderDetailVisible: false,
      selectedtcId:'',
      addTakeDeliveryInfoFlag:false,
    };
  },
  created() {
  },
  methods: {
    closetcDetail(){
      this.tcOrderDetailVisible = false;
      this.handleQuery();
    },
    opentcDetail(takeId){
      this.selectedtcId = takeId;
      this.tcOrderDetailVisible = true;
    },
    closezcDetail(){
      this.zcOrderDetailVisible = false;
      this.handleQuery();
    },
    openzcDetail(takeId){
      this.selectedzcId = takeId;
      this.zcOrderDetailVisible = true;
    },
    closeTakeDetail(){
      this.takeOrderDetailVisible = false;
      this.handleQuery();
    },
    openTakeDetail(takeId){
      this.selectedTakeId = takeId;
      this.takeOrderDetailVisible = true;
    },
    //提货供应商的 显示
    getThSupplier(row){

      let map = row['supplierMap'];
      let key = row['contactId'];
      let name = '';
      let contact = '';
      if (map && map[key]){
        let obj = map[key];
        if (obj) {
          name = obj['name'] || '';
          contact = obj['contact'] || '';
        }
      }
      return name +' ' + contact;
    },
    //cell-click	当某个单元格被点击时会触发该事件	row, column, cell, event
    handleCellClick(row, column, cell, event) {
      console.log("某一行被点击了", row, column, cell, event);
      let id = row.id;
      let prop = column.property;
      let props = ['thSupplier', 'getsTime', 'ctn', 'kg', 'cmb', 'remark', 'isWeiban', 'isBanyun']
      if (props.indexOf(prop) !== -1) {
        for (let i = 0; i < this.form.tableData.length; i++) {
          let obj = this.form.tableData[i];

          for (let j = 0; j < props.length; j++) {
            let propName = props[j];
            if (propName === prop && obj && obj.id === id) {
              this.$set(this.form.tableData[i], 'canEdit' + propName, true);
            } else {
              this.$set(this.form.tableData[i], 'canEdit' + propName, false);
            }
          }

        }
      }

    },
    delTakeIds() {
      this.$confirm(`确定删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.updateDelTakeByIds, this.selectedRowKeys)
            .then(res => {
              this.$message.success(res.message);
            })
      })
    },
    cancelTakeIds() {
      let params = {ids: this.selectedRowKeys};
      this.$confirm("批量取消提货信息，请选择取消订单还是取消提货?", "提示", {
        confirmButtonText: "取消用车编号的此单号",
        cancelButtonText: "取消用车编号的全部订单",
        distinguishCancelAndClose: true,
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        params.type = '1';
        postAction(this.url.cancelTake, params)
            .then((res) => {
              this.$message.success(res.message);
              this.handleQuery();
            })
      }).catch((action => {
        if (action === 'cancel') {
          params.type = '2';
          postAction(this.url.cancelTake, params)
              .then((res) => {
                this.$message.success(res.message);
                this.handleQuery();
              })
        }
      }))
    },
    addTakeDeliveryInfo(){
      this.addTakeDeliveryInfoFlag = true
      this.takeVisible = true;
    },
    closeTake() {
      this.takeVisible = false;
      this.addTakeDeliveryInfoFlag = false
      this.selectedIds = [];
      this.handleQuery();
    },
    realSendTake(row, list) {
      let rows = [];
      let qq = [];
      if (row) {
        rows.push(row);
      } else if (list && list.length > 0) {
        rows = list;
      }
      rows.forEach(l => {

        if (l.thStatus !== '0') {
          qq.push(l.id);
        }
      })
      if (qq && qq.length > 0) {
        this.$message.warning('已经发起提货申请的不允许重复提货');
        return false;
      }
      this.selectedIds = rows.map(r => r.id);
      this.takeVisible = true;
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      this.$intoDocPage(orderNo);
    },

    //根据状态 获取颜色
    getStatusColor(status) {
      let str = 'color: ';
      let color = '#571212';
      if (status === '待派车') {
        color = '#00b7ee';
      } else if (status === '派车中') {
        color = '#ee6300';
      } else if (status === '提货中') {
        color = '#4b00ee';
      } else if (status === '已完成') {
        color = '#08ff00';
      }
      return str + color;
    },
    // 初始化国家基础数据
    initCountryList() {
      this.coloading = true

      this.countrylist = []
      getallcountries().then(res => {
        if (res.success) {
          for (var i = 0; i < res.result.length; i++) {
            let obj = {}
            obj.value = res.result[i].cnName
            obj.label = res.result[i].twoCode + '-' + res.result[i].cnName
            this.countrylist.push(obj)
          }
          this.coloading = false
        }
      })

      this.coloading = false

    },
    // 初始化省数据
    initStateList(val) {
      this.stateloading = true
      this.statelist = []
      this.citylist = []
      if (val) {
        getstateList({cnName: val})
            .then((res) => {
              if (res.success) {
                for (var i = 0; i < res.result.records.length; i++) {
                  let obj = {}
                  obj.value = res.result.records[i].cname
                  obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                  this.statelist.push(obj)
                }
                this.stateloading = false
              }
            })
      } else {
        this.stateloading = false
      }
    },
    // 初始化城市数据
    initCityList(val) {
      this.cityloading = true
      this.citylist = []
      if (val) {
        getcityList({cname: val})
            .then((res) => {
              if (res.success) {
                for (var i = 0; i < res.result.records.length; i++) {
                  let obj = {}
                  obj.value = res.result.records[i].cname
                  obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                  this.citylist.push(obj)
                }
                this.cityloading = false
              }
            })
      } else {
        this.cityloading = false
      }
    },
    checkboxClassName({row, column, rowIndex, columnIndex}) {
      if (row.thStatus !== "0") {
        return "tb_cell";
      }
    },
    updateSup(name, index, map,cusId) {
      if ("add" === name) {
        //如果是新增，就新增
        this.supplier = {}
        this.supplierVisible = true
        this.supplierDisable = false
        this.index = index;
        this.cusId = cusId;
        this.$set(this.form.tableData[index], 'contact', '');
      } else {
        if (name) {
          if (map && map[name]) {
            let obj = map[name];
            this.form.tableData[index].contactAdd = obj.address;
            this.form.tableData[index].contact = obj.contact;
            this.form.tableData[index].contactTel = obj.tel;
            this.form.tableData[index].thSupplier = obj.name;
          }
        }
      }
    },
    formatAddress(obj) {
      let s = "";
      if (obj) {
        s += "单号：" + this.$route.query.orderNo + "\n";
        s += "件：" + obj.ctn + " 重：" + obj.kg + " 体：" + obj.cmb + "\n";
        s += "地址：" + obj.contactAdd + "\n";
        s += "供应商：" + obj.thSupplier + "\n";
        s += "联系人： " + obj.contact + " 电话： " + obj.contactTel + "\n";
        s += "提货时间：" + obj.getsTime + "\n";
        s += "备注: ";
        if (obj.isWeiban === '1') {
          s += "尾板车，";
        }
        if (obj.isBanyun === '1') {
          s += "搬运";
        }
      }
      return s;
    },

    initSupplier(cusId,index) {
      this.supplierlist = [];
      this.supplierNamelist = [];
      this.suploading = true;
      //获取发货人列表
      getAction(this.url.getSupplierList, {cusId: cusId})
          .then((res) => {
            if (res.success) {
              let list = res.result;
              for (var i = 0; i < list.length; i++) {
                let value = list[i].id;
                let label = list[i].name + " " + list[i].contact;
                let name = list[i].name;
                this.supplierlist.push({value, label});
                this.supplierNamelist.push({value, name});
                list[i].value = value;
                list[i].label = label;
              }
              this.suppliermap = this.listToMap(list, "id");
              this.suploading = false;
              if (this.form.tableData && this.form.tableData[index] && list && list.length > 0) {

                let indexSuppliers = this.form.tableData[index]['supplierList'];
                if (!indexSuppliers) {
                  indexSuppliers = [];
                }
                //1.查询出 新的所有的id
                //2.过滤出 list中 所选的集合中 没有的id 添加进去
                let oldis = indexSuppliers.map(l=>l.id);
                let queshao = list.filter(item=>{
                  return oldis.indexOf(item.id)===-1;
                });
                if (queshao && queshao.length > 0) {
                  for (let i = 0; i < queshao.length; i++) {
                    indexSuppliers.push(queshao[i]);
                  }
                }
                this.$set(this.form.tableData[index],'supplierList',indexSuppliers);
                this.$set(this.form.tableData[index],'supplierMap',this.listToMap(indexSuppliers, "id"));
              }
            } else {
              this.$message.warning("获取被保人列表失败");
              this.suploading = false;
            }
          })
          .catch((err) => {
            this.suploading = false;
          });
    },
    handleSearch() {
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    handleQuery() {
      this.loading = true;
      let form = {...this.queryForm, ...this.filterForm};
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const {prop, order} = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }

      postAction(this.url.list, params)
          .then((res) => {
            if (res.success && res.result) {
              const {records, total} = res.result;
              this.tableData = records || res.result;
              console.log("表单数据", this.tableData);
              this.form.tableData = this.tableData;
              this.ipagination &&
              (this.ipagination.total = total || res.result.length);

            }
          })
          .finally(() => {
            this.loading = false;
            console.log("需要向上的滚动条", this.isNeedTop);
            if (this.isNeedTop) {
              this.$refs.GTable.setScrollWidth();
            }
          });
    },
    sendTihuo(row) {
      putAction(this.url.sendTihuo + "?ids=" + row.id)
          .then((res) => {
            if (res.success) {
              this.$alert(res.message, '提货结果', {
                dangerouslyUseHTMLString: true
              });
              this.handleQuery();
            }
          })
    },
    openDialogFormVisible() {
      let takedList = [];
      this.selectionRows.forEach(s => {
        if (s.takeId != null) {
          takedList.push(s.takeId);
        }
      })

      if (takedList.length > 0) {
        this.$message.warning("已在提货流程中的不允许重复发起提货申请！")
        return
      }

      this.dialogFormVisible = true

    },

    batchSendTihuo() {
      let ids = this.selectionRows.map(s => s.id).join(',');
      let hasTakeIds = [];
      for (let i = 0; i < this.selectionRows.length; i++) {
        let obje = this.selectionRows[i];
        if (obje && obje.takeId && obje.takeId.length > 0) {
          hasTakeIds.push(obje.id);
        }
      }
      if (hasTakeIds && hasTakeIds.length > 0) {
        this.$message.warning('存在已经提货过的订单，请勿重复提货');
        return false;
      }
      putAction(this.url.sendTakeAndUpdateTrailer + "?ids=" + ids, this.trailerForm)
          .then((res) => {
            if (res.success) {
              this.$alert(res.message, '提货结果', {
                dangerouslyUseHTMLString: true
              });
              this.dialogFormVisible = false
              this.trailerForm = {}
              this.handleQuery();
            }
          })

    },

    saveBatch(row) {
      if (!row.thStatus) {
        row.thStatus = '0';
      }
      let param = [];
      param.push(row)
      putAction(this.url.saveBatch, param)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
              this.handleClearSelection();
            }
          })
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.tihuoList = [];

      for (let i = 0; i < selection.length; i++) {
        let {id, contactAdd, contact, contactTel, name, getsTime, geteTime, thStatus} =
            selection[i];
        this.obj.id = id;
        this.obj.contactAdd = contactAdd;
        this.obj.contact = contact;
        this.obj.contactTel = contactTel;
        this.obj.name = name;
        this.obj.getsTime = getsTime;
        this.obj.geteTime = geteTime;

        arr.push(selection[i]["id"]);

      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
    saveSupplier() {
      this.supplierDisable = true;
      let url = this.url.addSupplier;
      let method = "post";
      this.supplier.cusId = this.cusId;
      this.$refs['supplier'].validate((valid) => {
        if (valid) {

          httpAction(url, method, this.supplier)
              .then(res => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.supplierVisible = false;
                  //重新获取供应商集合
                  this.initSupplier(this.cusId,this.index);
                } else {
                  this.supplierDisable = false;
                  this.$message.warning(res.message);
                }

              }).catch(err => {

            this.supplierDisable = false;
            this.$message.error(err.message);
          })
        } else {
          this.supplierDisable = false;
        }
      })
    },

    handleDel(scope) {
      if (scope.row.id) {
        this.$confirm(`确定删除${scope.row.contact}吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
        })
            .then(() => {
              putAction(this.url.delByid + scope.row.id).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleSearch();
              });
            });
      } else {
        this.form.tableData.splice(scope.$index, 1);
      }
    },
    handleAdd2() {
      this.dialogFormVisible = true;
      this.form = {};
    },
    handleAdd() {

    },
    handleEdit(row) {
      this.btnDisable = false;
      getAction(this.url.getbyid, {id: row.id})
          .then((res) => {
            if (res.success) {
              this.form = res.result;
            }
          })
      this.dialogFormVisible = true;
    },

  },
  watch: {
    shipRes(val) {
      if (val) {
        console.log("父亲传递过来tihuo的数据", val)
        this.tableData = val.osOrderTrailerList;
        this.form.tableData = val.osOrderTrailerList;
      } else {
        // this.handleQuery();
      }
    }
  },
  computed : {
    buttonShowList() {
      return {
        'plzjth':this.$btnIsShow('docpage/takegoods/TakeGoodsList','0','批量增加提货'),
        'fqth':this.$btnIsShow('docpage/takegoods/TakeGoodsList','0','发起提货'),
        'bc':this.$btnIsShow('docpage/takegoods/TakeGoodsList','0','保存'),
        'sc':this.$btnIsShow('docpage/takegoods/TakeGoodsList','0','删除'),
        'qx':this.$btnIsShow('docpage/takegoods/TakeGoodsList','0','取消'),
      }
    }
  }
};
</script>

<style lang="scss">
</style>
