<template>
  <!-- 批量下载入仓单 -->
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium" :rules="rules">
        <el-row :gutter="12" class="inputs_row">
          <el-col :span="4">
            <el-form-item label="促销编号">
              <el-input class="textarea_table" v-model="queryForm.promotionNo" type="textarea"
                 placeholder="请输入促销编号多个逗号分隔" :rows="4"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="4">
              <el-form-item label="渠道名称">
                            <f-select v-model="queryForm.channelId" :dict="'channel_set'" :is-need="queryForm.channelId" ></f-select>
                        </el-form-item>
          </el-col>
           <el-col :span="4">
              <el-form-item label="FBA CODE">
                          <f-select
                            :dict="'bd_fba_addr'"
                            v-model="queryForm.fbaCode"
                            :is-need="queryForm.fbaCode"
                    ></f-select>
                        </el-form-item>
          </el-col>
            <el-col :span="4">
             <el-form-item label="交货地点">
                <f-select
                    v-model="queryForm.deliveryPointId"
                    :is-need="queryForm.deliveryPointId"
                    :dict="'delivery_point'"
                ></f-select>
              </el-form-item>
          </el-col>
          <el-col :span="4">
             <el-form-item label="起运港">
                            <f-select v-model="queryForm.pol"
                                      :isNeed="queryForm.pol"
                                      placeholder="请选择" filterable clearable :dict="'sys_port'">
                            </f-select>
                        </el-form-item>
          </el-col>
           <el-col :span="4">
                        <el-form-item label="船名航次">
                            <el-input v-model="queryForm.shipNo" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                     
          <el-col :span="8">
            <el-form-item label="工作单日期" >
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="开船时间" >
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.shipmentBoardTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.shipmentBoardTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
          </el-col>
               <el-col :span="8">
            <el-form-item label="入仓时间" >
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
          </el-col>
          
        
          <el-col :span="4">
            <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcelPlus(url.promotionNumberExport,'促销编号')" :loading="exportLoading">导出</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import { printPdfDoc } from "@/utils/downloadJasper";
  import { getAction } from "../../api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";


  export default {
    components: {
    },
    mixins: [QueryMixins],
    props: {
    },
    data() {
      return {
        queryForm: {
          promotionNo: '',
        },
        rules: {
          orderNo: [
            { required: true, message: "请输入要下载入仓单的单号", trigger: "blur" },
          ],
        },
        url: {
          //下载入仓单
          promotionNumberExport: "/file/export/excel/promotionNumberExport",
          getDownLoadOrderNos:"file/jasper/download/getDownLoadOrderNos"
        }
      }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      handleReset() {
        this.queryForm={}
        // this.$set(this.queryForm, 'promotionNo', '');
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>