<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="fbacode">
                            <el-input v-model="queryForm.code" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道名称">
                            <f-select v-model="queryForm.channelId" :dict="'transportStatus_enable'" :is-need="queryForm.channelId" ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="拼柜仓库">
                            <f-select v-model="queryForm.lclPlaceId" :dict="'lclPlace'" :is-need="queryForm.lclPlaceId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="交货仓库">
                            <f-select v-model="queryForm.deliveryPointId" :dict="'delivery_point'" :is-need="queryForm.deliveryPointId"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="目的港">
                      <f-select v-model="queryForm.podEndId" :dict="'tg_port'" :is-need="queryForm.podEndId"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="报价版本">
                      <el-input v-model="queryForm.priceVersion"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="是否为生效版本">
                      <f-select :dict="'is_default'" :is-need="queryForm.isEffective" v-model="queryForm.isEffective" autocomplete="off"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="20px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchOld">历史记录查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card">
                <div class="f-p-b-15">
                    <el-button
                            type="primary"
                            size="medium"
                            icon="el-icon-plus"
                            @click="handleAdd"
                            v-if="buttonShowList.add"
                    >新增
                    </el-button
                    >
                    <el-button
                            type="primary"
                            size="medium"
                            @click="downloadModel(url.downloadFba, '散货报价导入模板')"
                    >模板下载
                    </el-button>
                    <el-upload
                            action="/"
                            :show-file-list="false"
                            :http-request="uploadSectionFile"
                            accept=".xls,.xlsx"
                            style="margin:0 10px;display:inline"
                    >
                        <el-button size="medium" type="primary"

                        >导入</el-button>
                    </el-upload>
                  <el-button type="primary" size="medium" @click="handleExportXls('散货报价')"
                             v-if="buttonShowList.daochu"
                  >导出</el-button>
<!--                  <el-button type="primary" size="medium" @click="handleExportXls('散货报价')">导出</el-button>-->
                    <el-button type="primary" size="small" icon="check" @click="deleteBatch" style="float: left;margin-left: 7px"
                               v-if="buttonShowList.delBatch"
                               :disabled="selectedRowKeys.length === 0">批量删除
                    </el-button>
                </div>
                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="tableData"
                        :cell-style="{ 'text-align': 'center' }"
                        tableHeight="73vh"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button type="text" @click="handleEdit(scope.row)"

                                       v-if="buttonShowList.edit"
                            >编辑</el-button>
                            <el-button type="text" @click="handleDele(scope.row)"

                                       v-if="buttonShowList.del"
                            >删除</el-button>
                        </template>
                        <template v-else-if="scope.column.property === 'shipmentType'">
                            <span>{{parseDict(scope.row.shipmentType,'shipment_type')}}</span>
                        </template>
                      <template v-else-if="scope.column.property === 'hasTax'">
                        <span v-if="scope.row.hasTax==='1'">是</span>
                        <span v-else>否</span>
                      </template>
                      <template v-else-if="scope.column.property === 'isEffective'">
                        <span v-if="scope.row.isEffective==='1'">是</span>
                        <span v-else>否</span>
                      </template>
                    </template>
                </f-table>
                <el-row class="f-text-right f-p-v-8">
                    <el-pagination
                            v-bind="ipagination"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                    ></el-pagination>
                </el-row>
            </el-card>
        </el-form>
        <el-dialog :title="form.id ? '编辑报价' : '新增报价'" append-to-body :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="form" :rules="rules" ref="form" class="addEditBox">
              <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="channelId">
                  <f-select :is-need="form.channelId" :dict="'transportStatus'" v-model="form.channelId" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="报价版本" :label-width="formLabelWidth" prop="priceVersion">
                  <el-input v-model="form.priceVersion" maxlength="50" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="拼柜仓库" :label-width="formLabelWidth" prop="lclPlaceId">
                  <f-select :dict="'lclPlace'" :is-need="form.lclPlaceId" v-model="form.lclPlaceId" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="交货仓库" :label-width="formLabelWidth" prop="deliveryPointId">
                  <f-select :dict="'delivery_point'" :is-need="form.deliveryPointId" v-model="form.deliveryPointId" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="地址类型" :label-width="formLabelWidth" prop="shipmentType">
                <el-select clearable filterable v-model="form.shipmentType">
                  <el-option value="0" label="FBA"></el-option>
                  <el-option value="5" label="AWD"></el-option>
                  <el-option value="2" label="第三方海外仓"></el-option>
                  <el-option value="4" label="大森林海外仓"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="fbacode" :label-width="formLabelWidth" prop="code">
                  <el-input v-model="form.code" maxlength="50" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="州" :label-width="formLabelWidth" prop="state">
                <el-input v-model="form.state" maxlength="50" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="单位" :label-width="formLabelWidth" prop="unit">
                <f-select :dict="'per_price_unit'" :is-need="form.unit" v-model="form.unit" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="最低消费" :label-width="formLabelWidth" prop="startNum">
                  <el-input v-model="form.startNum" type="number"></el-input>
              </el-form-item>
                <el-form-item label="结束值" :label-width="formLabelWidth" prop="endNum">
                  <el-input v-model="form.endNum" type="number"></el-input>
                </el-form-item>
                <el-form-item label="海运单价" :label-width="formLabelWidth" prop="price">
                  <el-input v-model="form.price" type="number"></el-input>
                </el-form-item>
              <el-form-item label="销售单价" :label-width="formLabelWidth" prop="price">
                <el-input v-model="form.salePrice" type="number"></el-input>
              </el-form-item>
              <el-form-item label="官网价格" :label-width="formLabelWidth" prop="price">
                <el-input v-model="form.custPrice" type="number"></el-input>
              </el-form-item>
                <el-form-item label="成本红线" :label-width="formLabelWidth" prop="minPrice">
                  <el-input v-model="form.minPrice" type="number"></el-input>
                </el-form-item>
              <el-form-item label="体重比" :label-width="formLabelWidth" prop="cbmKg">
                <el-input v-model="form.cbmKg" maxlength="20" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="时效" :label-width="formLabelWidth" prop="preDay">
                <el-input v-model="form.preDay" maxlength="100" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="派送方式" :label-width="formLabelWidth" prop="deliveryType">
                <el-input v-model="form.deliveryType" maxlength="100" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEndId">
                <f-select :dict="'tg_port'" :is-need="form.podEndId" v-model="form.podEndId" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="是否包税" :label-width="formLabelWidth" prop="hasTax">
                <f-select :dict="'is_default'" :is-need="form.hasTax" v-model="form.hasTax" autocomplete="off"></f-select>
              </el-form-item>
              <el-form-item label="有效开始时间" :label-width="formLabelWidth" prop="stime">
                <el-date-picker
                    class="f-flex-1"
                    v-model="form.stime"
                    type="datetime"
                    size="small"
                    placeholder="有效开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="有效结束时间" :label-width="formLabelWidth" prop="etime">
                <el-date-picker
                    class="f-flex-1"
                    v-model="form.etime"
                    type="datetime"
                    size="small"
                    placeholder="有效结束时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
  import {deleteAction, postAction, putAction} from "@/api/manage";
  import QueryMixins from "../../mixins/QueryMixins";
  import {getAction, getAllSupplier, getBdFeeTypeList} from "../../api/manage";
  import {zhengshuPattern, zhengshuxiaoshuPartten} from "@/utils/pattern";
  import {validateCmb,validateCtn} from "@/utils/validator";

  export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes:['transportStatus','lclPlace','channel_price','shipment_type','shipment_type'],
                rules: {
                    startNum: [
                        {required: true, message: "请填写最低消费", trigger: "change"}
                    ],
                    endNum: [
                        {required: true, message: "请填写结束值", trigger: "change"}
                    ],
                    price: [
                        {required: true, message: "请填写价格", trigger: "change"}
                    ],
                    minPrice: [
                        {required: true, message: "请填写价格", trigger: "change"}
                    ],
                    code: [
                        {required: true, message: "请输入fbacode", trigger: "blur"}
                        ],
                    channelId: [
                        {required: true, message: "请选择渠道", trigger: "change"}
                        ],
                    lclPlaceId: [
                        {required: true, message: "请选择拼柜仓库", trigger: "change"}
                        ],
                    unit: [
                        {required: true, message: "请输入单位", trigger: "change"}
                        ],
                    cbmKg: [
                      {required: true, message: "请输入体重比", trigger: "change"}
                    ],
                    priceVersion: [
                        {required: true, message: "请输入报价版本", trigger: "change"}
                    ],

                },
                feeTemplateList: [],
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormVisibleEmail: false,
                columns: [
                    {
                        type: "selection",

                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                  {
                    prop: "isEffective",
                    label: "是否为生效版本",
                    width: 100
                  },
                  {
                    prop: "priceVersion",
                    label: "报价版本",

                  },
                  {
                    prop: "preDay",
                    label: "时效",

                  },
                  {
                    prop: "hasTax",
                    label: "是否包税",

                  },
                  {
                    prop: "country",
                    label: "国家",
                  },
                  {
                    prop: "shipmentType",
                    label: "地址类型",
                  },
                    {
                    prop: "lclPlace",
                    label: "拼柜仓库",

                    },
                {
                    prop: "deliveryPoint",
                    label: "交货仓库",

                },
                    {
                        prop: "channelName",
                        label: "渠道名称",

                    },
                    {
                        prop: "code",
                        label: "fbacode",

                    },
                    {
                        prop: "state",
                        label: "州",

                    },
                    {
                        prop: "unit",
                        label: "单位",

                    },
                    {
                        prop: "startNum",
                        label: "最低消费",

                    },
                    {
                        prop: "endNum",
                        label: "结束值",

                    },
                    {
                        prop: "price",
                        label: "成本价格",

                    },
                  {
                    prop: "salePrice",
                    label: "销售价格",

                  },
                  {
                    prop: "custPrice",
                    label: "官网价格",

                  },
                    {
                        prop: "minPrice",
                        label: "成本红线",

                    },
                  {
                    prop: "cbmKg",
                    label: "体重比",

                  },
                  {
                    prop: "deliveryType",
                    label: "派送方式",

                  },
                  {
                    prop: "podEnd",
                    label: "目的港",

                  },
                  {
                    prop: "stime",
                    label: "有效开始时间",

                  },
                  {
                    prop: "etime",
                    label: "有效结束时间",

                  },
                    {
                        prop: "createBy",
                        label: "创建人",

                    },
                    {
                        prop: "createTime",
                        label: "创建时间",

                    },
                    {
                        prop: "updateBy",
                        label: "修改人",

                    },
                    {
                        prop: "updateTime",
                        label: "修改时间",

                    },
                ],
                url: {
                    list: "/order/channel/price/pageListPost",
                    add: "/order/channel/price/add",
                    edit: "/order/channel/price/edit",
                    getById: "/order/channel/price/queryById",
                    delete: "/order/channel/price/delete",
                    deleteBatch: "/order/channel/price/deleteBatch",
                    // importFba:'/order/channel/price/importFba',
                    importFba:'/file/read/excel/importChannelPrice',
                    downloadFba:'/order/channel/price/downloadFba',
                    exportXlsUrl:'/file/export/excel/downloadChannelPriceExcelVo',
                },
                isUsePost : true,
            };
        },
        methods: {
            handleSearchOld(){
              this.queryForm.isOld = '1';
              this.handleSearch();
            },
            uploadSectionFile(param) {
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                postAction(this.url.importFba, form)
                    .then((res) => {
                        //这里做上传之后的操作
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        } else {
                            this.handleQuery();
                        }
                    })
            },

            initBdFeeTypeList() {
                if (this.feeTypeList.length <= 0) {
                    this.feeTypeList = [];
                    getBdFeeTypeList("1")
                        .then((res) => {
                            if (res.success) {
                                for (var i = 0; i < res.result.length; i++) {
                                    var obj = {};
                                    obj.value = res.result[i].id;
                                    obj.label = res.result[i].chineseName;
                                    this.feeTypeList.push(obj);
                                }
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                }
            },

            initAllSupplier() {
                this.allSupplierList = [];
                //获取所有海运供应商
                getAllSupplier("1")
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id; //供应商ID
                                obj.label = res.result[i].chinaseName; //供应商中文名
                                this.allSupplierList.push(obj);
                            }
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //点击加号添加一行数据
            AddListRow() {
                /*  this.feeTemplateList=[];*/
                this.form.feeTemplateList.push({
                    feeId: "",
                    supId: "",
                    count: "",
                    cur: "",
                    price: "",
                });
            },
            handleDelete(index) {
                this.form.feeTemplateList.splice(index, 1);
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.form.feeTemplateList = [];
                this.dialogFormVisible = true;
                this.$refs.form.resetFields();
            },
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.initAllSupplier();
                getAction(this.url.getById + "?id=" + row.id).then((res) => {
                    if (res.success) {
                        this.form.feeTemplateList = res.result.feeTemplateList;
                        this.dialogFormVisible = true;
                    }
                });
            },

           async postOrPut(){
              var id = this.form.id;
              // this.form.feeTemplateList = this.form.feeTemplateList;
              //编辑
              if (this.form.id) {
              await  putAction(this.url.edit, this.form).then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery();
                  }
                });
              } else {
               await postAction(this.url.add, this.form).then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    id = res.id;
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery();
                  }
                });
              }
            },
            handleSave() {

                this.$refs.form.validate((valid) => {
                    if (valid) {
                      this.postOrPut();
                    }
                });
            },
            handleDele(row) {
                this.$confirm(`确定删除模板`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            deleteBatch(row) {
                this.$confirm(`确定删除模板`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
            this.queryForm.initFeeType = true;
            this.queryForm.initAllSupplier = true;
        },
        computed : {
          buttonShowList() {
            return {
              'add' : this.$btnIsShow('channel/FeeTemple','0','新增'),
              'daochu' : this.$btnIsShow('channel/FeeTemple','1','导出'),
              'delBatch' : this.$btnIsShow('channel/FeeTemple','1','批量删除'),
              'edit' : this.$btnIsShow('channel/FeeTemple','1','编辑'),
              'del' : this.$btnIsShow('channel/FeeTemple','1','删除'),
            }
          }
        }
    };
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
