<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="queryForm"
      :model="queryForm"
      label-width="86px"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="4">
            <el-form-item label="国家">
              <f-select
                v-model="queryForm.shipmentCountry"
                :isNeed="queryForm.shipmentCountry"
                :dict="'bd_country'"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单号">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="记录类型">
              <f-select
                v-model="queryForm.recordType"
                :isNeed="queryForm.recordType"
                :dict="'customs_record_type'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="depart" label="创建人">
              <f-select
                :isNeed="queryForm.createByid"
                v-model="queryForm.createByid"
                :dict="'sys_user_id'"
                @changetAllData="
                  (obj) => {
                    changetInfo(obj, 'createBy');
                  }
                "
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="depart" label="修改人">
              <f-select
                :isNeed="queryForm.updateByid"
                v-model="queryForm.updateByid"
                :dict="'sys_user_id'"
                @changetAllData="
                  (obj) => {
                    changetInfo(obj, 'updateBy');
                  }
                "
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="修改时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.updateTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.updateTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label-width="20px">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button size="medium" plain @click="handleReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="handleBatch('0')"
          >新增
        </el-button>
        <el-upload
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="margin: 0 10px; display: inline"
          v-if="buttonShowList['导入']"
        >
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button
          type="primary"
          size="medium"
          @click="downloadModel(url.downloadCustomsRecord, '关务记录模版')"
          v-if="buttonShowList['导入模版下载']"
          >导入模版下载
        </el-button>

        <el-button
          type="primary"
          size="medium"
          v-if="buttonShowList['关务记录']"
          @click="handleExportXls('关务记录')"
          >导出
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="handleBatch('1')"
          v-if="buttonShowList['批量删除']"
          :disabled="selectedRowKeys.length === 0"
          >批量删除
        </el-button>
      </div>
      <f-table
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="61vh"
        @selection-change="handleSelectionChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleBatch('3', scope.row)"
              v-if="buttonShowList['编辑']"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleBatch('4', scope.row)"
              v-if="buttonShowList['删除']"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'recordType'">
            <span>{{
              parseDict(scope.row.recordType, "customs_record_type")
            }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
      :title="form.id ? '编辑关务记录' : '新增关务记录'"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-form :model="form" ref="form" :rules="rules" label-width="80px">
        <el-form-item label="记录类型" prop="recordType">
          <f-select
            v-model="form.recordType"
            :isNeed="form.recordType"
            :dict="'customs_record_type'"
          ></f-select>
        </el-form-item>
        <el-form-item label="订单号" prop="orderNo">
          <el-input v-model="form.orderNo" @blur="getCountry"></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="shipmentCountry">
          <f-select
            v-model="form.shipmentCountry"
            :isNeed="form.shipmentCountry"
            :dict="'bd_country'"
            placeholder="根据订单号自动生成"
            :disabled="true"
          >
          </f-select>
        </el-form-item>
        <el-form-item label="记录内容" prop="content">
          <el-input
            v-model="form.content"
            maxlength="500"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="可能后果" prop="maybeResult">
          <el-input
            v-model="form.maybeResult"
            maxlength="500"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="关务建议" prop="customsAdvice">
          <el-input
            v-model="form.customsAdvice"
            maxlength="500"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消 </el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- <customs-record-dialog
      :visible="recordVisible"
      @update:visible="
        (val) => {
          recordVisible = val;
        }
      "
      @ok="handleQuery"
    /> -->
  </div>
</template>
<script>
import QueryMixins from "@/mixins/QueryMixins";
import {
  deleteAction,
  getAction,
  getallcountries,
  postAction,
} from "@/api/manage";
// import CustomsRecordDialog from "./modules/CustomsRecordDialog.vue";

export default {
  mixins: [QueryMixins],
  components: {
    // CustomsRecordDialog,
  },
  props: {},
  data() {
    return {
      visible: false,
      isUsePost: true,
      row: {},
      form: {},
      queryForm: {},
      url: {
        list: "/jeecg-cloud-operation-biz/operation/customsRecords/page",
        getOrderNoCountry:
          "/jeecg-cloud-operation-biz/operation/customsRecords/getOrderNoCountry",
        updateRecord:
          "/jeecg-cloud-operation-biz/operation/customsRecords/updateRecord",
        insertRecord:
          "/jeecg-cloud-operation-biz/operation/customsRecords/insertRecord",
        updateRecordDel:
          "/jeecg-cloud-operation-biz/operation/customsRecords/updateRecordDel",
        downloadCustomsRecord:
          "/jeecg-cloud-file-biz/file/download/model/downloadCustomsRecord",
        importCustomsRecord:
          "/jeecg-cloud-file-biz/file/read/excel/importCustomsRecord",
        exportXlsUrl:
          "/jeecg-cloud-file-biz/file/export/excel/downloadCustomsRecord",
      },
      rules: {
        recordType: [{ required: true, message: "请输入", trigger: "change" }],
        orderNo: [{ required: true, message: "请输入", trigger: "change" }],
        shipmentCountry: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        content: [{ required: true, message: "请输入", trigger: "change" }],
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "recordType",
          label: "记录类型",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "shipmentCountry",
          label: "国家",
        },
        {
          prop: "content",
          label: "记录内容",
        },
        {
          prop: "maybeResult",
          label: "可能后果",
        },
        {
          prop: "customsAdvice",
          label: "关务建议",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "updateBy",
          label: "修改人",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },
      ],
      recordVisible: false,
      dictCodes: ["customs_record_type"],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleBatch(type, row) {
      if (type == "0") {
        this.form = {};
        this.visible = true;
      } else if (type == "1") {
        postAction(this.url.updateRecordDel, {
          idList: this.selectedRowKeys,
        }).then((res) => {
          this.$message.success(res.message);
          this.handleQuery();
        });
      } else if (type == "2") {
        this.recordVisible = true;
      } else if (type == "3") {
        this.form = { ...row };
        this.visible = true;
      } else if (type == "4") {
        postAction(this.url.updateRecordDel, {
          idList: [row.id],
        }).then((res) => {
          this.$message.success(res.message);
          this.handleQuery();
        });
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction(
            this.form.id ? this.url.updateRecord : this.url.insertRecord,
            this.form
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.handleQuery();
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.visible = false;
    },
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importCustomsRecord, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },
    getCountry() {
      postAction(this.url.getOrderNoCountry, {
        orderNo: this.form.orderNo,
      }).then((res) => {
        if (res.success) {
          this.$set(this.form, "shipmentCountry", res.result);
        } else {
          this.$message.warning("暂无对应国家");
        }
      });
    },
    changetInfo(obj, str) {
      if (obj && obj.title) {
        this.queryForm[str] = obj.title;
      } else {
        this.queryForm[str] = "";
      }
    },
  },
  computed: {
    buttonShowList() {
      return {
        编辑: this.$btnIsShow("conformance/CustomsRecord", "1", "编辑"),
        删除: this.$btnIsShow("conformance/CustomsRecord", "1", "删除"),
        导入: this.$btnIsShow("conformance/CustomsRecord", "0", "导入"),
        导出: this.$btnIsShow("conformance/CustomsRecord", "0", "导出"),
        批量删除: this.$btnIsShow("conformance/CustomsRecord", "0", "批量删除"),
        关务记录: this.$btnIsShow("conformance/CustomsRecord", "0", "关务记录"),
        新增: this.$btnIsShow("conformance/CustomsRecord", "0", "新增"),
        导入模版下载: this.$btnIsShow(
          "conformance/CustomsRecord",
          "0",
          "导入模版下载"
        ),
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>