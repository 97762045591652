<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="标题">
              <el-input v-model="queryForm.title" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="应用类型">
              <el-select
                clearable
                filterable
                class="f-full-width"
                v-model="queryForm.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-dialog
      :title="form.id ? '编辑' : '新增'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="文章标题"
          :label-width="formLabelWidth"
          prop="title"
          style="margin-bottom: 22px"
        >
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="类型"
          :label-width="formLabelWidth"
          prop="type"
          style="margin-bottom: 22px"
        >
          <el-select
            clearable
            filterable
            class="f-full-width"
            v-model="form.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="图片"
          :label-width="formLabelWidth"
          prop="coverRef"
          ref="cover"
        >
          <f-upload
            v-model="form.coverList"
            list-type="picture-card"
            ref="coverRef"
            :limit="1"
            :dir="'content'"
          ></f-upload>
        </el-form-item>
        <el-form-item
          label="图片的跳转地址"
          :label-width="formLabelWidth"
          prop="pitureAddress"
        >
          <el-input v-model="form.pitureAddress" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="handleAdd"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleSave()"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        border
        ref="GTable"
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDele(scope.row)"
              >删除</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '0'">PC</span>
            <span v-else>小程序</span>
          </template>
          <template v-else-if="scope.column.property === 'pitureUrl'">
            <img :src="scope.row.pitureUrl" alt="还未上传" height="100px" />
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { deleteAction, postAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction } from "../../api/manage";

const form = {
  title: "",
  type: "",
  coverList: [],
};
export default {
  name: "Carousel",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      form: { ...form, coverList: [] },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "change" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        coverRef: [
          {
            validator: (rule, value, callback) => {
              if (this.form.coverList.length <= 0) {
                callback(new Error("请上传封面"));
              } else {
                callback();
              }
            },
            message: "请上传图片",
            trigger: "change",
          },
        ],
        pitureAddress: [
          { required: true, message: "请输入图片跳转地址", trigger: "change" },
        ],
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      ids: [],
      row: {},
      form: {},
      filelist: [],
      options: [
        {
          value: "0",
          label: "PC",
        },
        {
          value: "1",
          label: "小程序",
        },
      ],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "type",
          label: "应用类型",
          sortable: "custom",
        },
        {
          prop: "title",
          label: "标题",
          sortable: "custom",
        },
        {
          prop: "pitureAddress",
          label: "跳转路径",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "发布时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "pitureUrl",
          label: "轮播图片",
          sortable: "custom",
        },
      ],
      url: {
        list: "/sys/managerCarousel/list",
        edit: "/sys/managerCarousel/edit",
        add: "/sys/managerCarousel/add",
        delete: "/sys/managerCarousel/delete",
        queryById: "/sys/managerCarousel/queryById",
      },
    };
  },
  methods: {
    handleEdit(row) {
      for (var name in row) {
        this.$set(this.form, name, row[name]);
      }
      this.form.coverList = [];
      let id = this.form.pitureId;
      let url = this.form.pitureUrl;
      let obj = { id: id, url: url };
      this.form.coverList.push(obj);
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.form = { coverList: [] };
      this.dialogFormVisible = true;
    },
    //新增
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.pitureId = this.form.coverList[0].id;
          this.form.pitureUrl = this.form.coverList[0].url;
          if (this.form.pitureUrl) {
            if (this.form.pitureUrl.length >= 500) {
              return this.$message.warning("图片名称过长，请修改！");
            }
          }
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.add, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          }
        }
      });
    },
    handleDele(row) {
      this.$confirm(`确定删除${row.title}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
  },
  computed: {
    coverList() {
      return this.form.coverList;
    },
  },
  watch: {
    coverList(newV) {
      if (newV && newV.length >= 1) {
        if (this.$refs["cover"]) {
          this.$refs["cover"].clearValidate();
        }
      }
    },
  },
};
</script>
<style lang="scss" scope>
.input_top .el-form-item {
  margin-bottom: 2px;
}
</style>
