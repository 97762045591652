<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row class="inputs_row">
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="注册时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.registerTimeStart"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.registerTimeEnd"
                                type="datetime"
                                size="small"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                          </div>
                        </el-form-item>
                      </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="客户名称">
                                <el-input  v-model="queryForm.customer"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务员">
                          <f-select  v-model="queryForm.salesmanId" :is-need="queryForm.salesmanId" :dict="'sys_user_id'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="销售组别">
                          <f-select  v-model="queryForm.salesGroup" :is-need="queryForm.salesGroup" :dict="'sys_depart'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="文件名称">
                          <el-input  v-model="queryForm.name"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="文件类型">
                          <f-select  v-model="queryForm.fileType" :is-need="queryForm.fileType" :dict="'customer_file'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="上传时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeStart"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeEnd"
                                type="datetime"
                                size="small"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                          </div>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="上传人">
                          <f-select  v-model="queryForm.createBy" :is-need="queryForm.createBy" :dict="'sys_user'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'客户文件列表')">导出
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'customer'">
                    <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "ScanLog",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "registerTime",
                        label: "注册时间",
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                    },
                  {
                    prop: "salesman",
                    label: "业务员",
                  },
                  {
                    prop: "salesGroup",
                    label: "销售组别",
                  },
                  {
                    prop: "name",
                    label: "文件名称",
                  },
                  {
                    prop: "fileType",
                    label: "文件类型",
                  },
                  {
                    prop: "createBy",
                    label: "上传人",
                  },
                  {
                    prop: "createTime",
                    label: "上传时间",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/customer/cus/queryCusFile2Page",

                    //导出excel的接口
                    exportExcelUrl: '/file/sale3/dcCusFile',
                },


            };
        },
        methods: {

        },

    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
