<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="周期数">
                      <el-input v-model="queryForm.week" @keyup.enter.native="handleCheck"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.settlement" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务员">
                            <f-select
                                    class="f-full-width"
                                    :dict="'salesmanId'"
                                    v-model="queryForm.salesmanId"
                                    :is-need="queryForm.salesmanId"
                                    placeholder="请选择"
                                    style="width: 100%"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="费用币种">
                            <f-select
                                    v-model="queryForm.moneyType"
                                    dict="bd_cur"
                                    :is-need="queryForm.moneyType"
                            ></f-select>
                        </el-form-item>
                    </el-col>

                    <!--          <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                    <!--            <el-form-item label="收款员">-->
                    <!--              <f-select-->
                    <!--                placeholder="请选择收款人"-->
                    <!--                v-model="queryForm.billman"-->
                    <!--                :is-need="queryForm.billman"-->
                    <!--                dict="operations"-->
                    <!--              >-->
                    <!--              </f-select>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="录入时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="stime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStartOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="otime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用名">
                            <f-select v-model="queryForm.feeId" :dict="'bd_fee_type'" :is-need="queryForm.feeId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单类型">
                            <f-select
                                v-model="queryForm.orderType"
                                :is-need="queryForm.orderType"
                                :clearable="false"
                                dict="all_order_type"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="7" v-if="queryForm.orderType ==='2'">
                        <el-form-item  label="发货时间">
                            <el-date-picker
                                class="f-flex-1 f-flex-time"
                                v-model="goodGoTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item prop="type" label="销账状态">
                            <el-select v-model="queryForm.status" clearable>
                                <el-option value="0" label="未销账"></el-option>
                                <el-option value="1" label="已销账"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="数据时间">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.dataCreateTime"
                                    :is-need="queryForm.dataCreateTime"
                                    :dict="'data_create_time'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    size="medium"
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15">
<!--                <el-button size="medium" type="primary" @click="applyPay">申请付款</el-button>-->
<!--                <el-button size="medium" type="primary" @click="importBill">导入账单</el-button>-->
                <el-button
                    size="medium"
                    type="primary"
                    @click="downloadPayFee"
                    v-if="buttonShowList.dcexcel"
                >导出excel
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="64vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.realAgent}}</a>
                    </template>

                    <template v-else-if="scope.column.property === 'week'">
                        <el-button  type="text" @click="infomation(scope.row.containerId)">{{
                            scope.row.week
                            }}
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'status'">
                        {{parseDict(scope.row.status,'settle_status')}}
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanId'">
                      <span>{{parseDict(scope.row.salesmanId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                      <span>{{parseDict(scope.row.operateId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'docId'">
                      <span>{{parseDict(scope.row.docId,'sys_user_id')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <pay-apply-dialog ref="payApply" @ok="this.handleQuery"></pay-apply-dialog>
    </div>
</template>
<script>
    import QueryMixins from "../../../../mixins/QueryMixins";
    import {getAction, postAction} from "@/api/manage";
    import payApplyDialog from "./payApplyDialog";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        mixins: [QueryMixins],
        components: {
            payApplyDialog,
        },
        data() {
            return {
                queryForm:{orderType:'0'},
                dictCodes: ['settle_status', 'sys_user_id'],
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos: [],
                loading: false,
                form: {},
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: "orderNo",
                        label: "工作单号",
                        width: 150,
                        fixed: 'left'
                    },
                    {
                        prop: "realAgent",
                        label: "客户",
                        width: 150,
                        fixed: 'left'
                    },
                    {
                        prop: "agent",
                        label: "结算对象",
                        fixed: 'left'
                    },
                    {
                        prop: "createTime",
                        label: "下单时间",
                    },
                    {
                        prop: "week",
                        label: "周期",
                        width: 200,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },

                    {
                        prop: "feename",
                        label: "费用中文",
                    },
                    {
                        prop: "feenameUs",
                        label: "费用英文",
                    },
                    {
                        prop: 'status',
                        label: '费用状态',
                    },
                    {
                        prop: "price",
                        label: "价格",
                    },
                    {
                        prop: "quantity",
                        label: "数量",
                    },
                    {
                        prop: "moneyType",
                        label: "费用币种",
                    },
                    {
                        prop: "total",
                        label: "合计",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "screateTime",
                        label: "录入时间",
                    },
                    {
                        prop: "salesmanId",
                        label: "业务",
                    },
                    {
                        prop: "operateId",
                        label: "操作",
                    },
                    {
                        prop: "docId",
                        label: "客服",
                    },
                ],

                url: {
                    list: "/shipping/hySettlePay/allPaySettleList",
                    export: "/file/export/excel/allPayFeeQueryStream"
                },

                rules: {
                    realCtn: [
                        {required: true, message: "请输入实际总件数", trigger: "blur"},
                        {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                    ],
                    realKg: [
                        {required: true, message: "请输入实际总重量", trigger: "blur"},
                        {
                            pattern:
                                /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                            message: "只能输入最多三位小数的数字",
                            trigger: "change",
                        },
                    ],
                },
                stime: [],
                otime: [],
                goodGoTime:[],
            };
        },
        computed: {
          buttonShowList() {
            return {
              'dcexcel':this.$btnIsShow('hyFinanceManage/modules/pay/waitApplyPayContainer','0','导出excel'),
            }
          }
        },
        created() {
            this.queryForm.dataCreateTime = '3';
        },
        methods: {

            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
            },
            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            importBill() {
                this.$message.warning("此功能后续开放！");
            },
            applyPay(selection) {
                // console.log("申请付款以下id的费用", this.selectionRows);
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选费用");
                    return;
                }
              let settleIdCount = [];
              let settleNameCount = [];
              this.selectionRows.forEach((item) => {
                settleIdCount.push(item.settleId);
                settleNameCount.push(item.settlement);
              });
              let idArr = Array.from(new Set(settleIdCount));
              let nameArr = Array.from(new Set(settleNameCount));
              if (idArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
              }
              if (nameArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
              }
                let arr = this.tableData.filter((item) => {
                    return this.selectionRows.indexOf(item) === -1;
                });
                // console.log("arr--", arr);
                this.$refs.payApply.edit(this.selectionRows, arr, this.columns);
            },

            handleSelectionChange(selection) {
                // 多选
                // console.log(selection);
                let arr = [];
                let nos = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    nos.push(selection[i]["orderNo"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                this.selectedNos = [...nos];
            },
            handleReceive() {
                // console.log("申请收款以下id的费用", this.selectedRowKeys);
            },
            downloadPayFee(){
                //所有条件为空时不进行导出
                if((this.queryForm.orderNo === undefined || this.queryForm.orderNo === '') &&
                    (this.queryForm.week === undefined || this.queryForm.week === '') &&
                    (this.queryForm.username === undefined || this.queryForm.username === '') &&
                    (this.queryForm.settlement === undefined || this.queryForm.settlement === '') &&
                    (this.queryForm.salesmanId === undefined || this.queryForm.salesmanId === '') &&
                    (this.queryForm.moneyType === undefined || this.queryForm.moneyType === '') &&
                    (this.queryForm.status === undefined || this.queryForm.status === '') &&
                    (this.otime == null || this.otime.length ===0) &&
                    (this.stime == null || this.stime.length ===0) &&
                    (this.goodGoTime == null || this.goodGoTime.length ===0)
                ){
                    this.$message.warning("导出数据时，查询条件不能为空！");
                    return
                }

                // this.loading = true;

                if (this.otime != null && this.otime.length > 1) {
                    this.queryForm.createTimeS = this.otime[0];
                    this.queryForm.createTimeE = this.otime[1];
                }else{
                    this.queryForm.createTimeS = '';
                    this.queryForm.createTimeE = '';
                }
                if (this.stime != null && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                }else{
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }

                if (this.goodGoTime && this.goodGoTime.length>1){
                    this.queryForm.deliverGoodsTimeS = this.goodGoTime[0]
                    this.queryForm.deliverGoodsTimeE = this.goodGoTime[1]
                }else{
                    this.queryForm.deliverGoodsTimeS = '';
                    this.queryForm.deliverGoodsTimeE = '';
                }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                let filename = this.getDateStr() + '导出所有应付费用'
                let url = this.url.export
                postAction(url, params, {responseType: 'blob'})
                    .then(res => {
                        // console.log(res)
                        if (res.size === 0) {
                            this.$message.error('导出失败，请稍后再试或联系管理员')
                            this.visible = false
                            return
                        }
                        var binaryData = [];
                        binaryData.push(res);

                        let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', filename + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象

                        this.visible = false
                        setTimeout(() => {   //设置延迟执行
                            this.$message.success("导出成功！")
                            // this.$emit('ok')
                            this.handleCheck();
                        }, 3000)
                    })

            },
            handleReset() {
                this.queryForm = {};
                this.$set(this.queryForm,'orderType',"0")
                this.otime = "";
                this.stime = "";
                this.goodGoTime = ''
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleCheck() {
                this.loading = true;
                // console.log(this.otime)
                if (this.otime != null && this.otime.length > 1) {
                    this.queryForm.createTimeS = this.otime[0];
                    this.queryForm.createTimeE = this.otime[1];
                }else{
                    this.queryForm.createTimeS = '';
                    this.queryForm.createTimeE = '';
                }
                if (this.stime != null && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                }else{
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }

                if (this.goodGoTime && this.goodGoTime.length>1){
                    this.queryForm.deliverGoodsTimeS = this.goodGoTime[0]
                    this.queryForm.deliverGoodsTimeE = this.goodGoTime[1]
                }else{
                    this.queryForm.deliverGoodsTimeS = '';
                    this.queryForm.deliverGoodsTimeE = '';
                }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                // console.log("查询对象", params);

                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.tableData = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            noConvertBill() {
                // console.log("申请收款以下id的费用", this.selectedRowKeys);
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选费用");
                    return;
                }

                let countCur = [];
                this.selectionRows.forEach((item) => {
                    countCur.push(item.moneyType);
                });
                let curArr = Array.from(new Set(countCur));
                // console.log("curArr->", curArr);
                if (curArr.length > 1) {
                    this.$message.warning("所选费用的费用币种不一致！");
                    return;
                }

              let settleIdCount = [];
              let settleNameCount = [];
              this.selectionRows.forEach((item) => {
                settleIdCount.push(item.settleId);
                settleNameCount.push(item.settlement);
              });
              let idArr = Array.from(new Set(settleIdCount));
              let nameArr = Array.from(new Set(settleNameCount));
              if (idArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
              }
              if (nameArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
              }
                this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
            },
        },
    };
</script>
<style scoped>
  .el-button{
    user-select: unset;
  }
</style>
