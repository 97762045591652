<template>
    <div class="main-full-content">

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo"  type="textarea" class="textarea_table" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.settlement" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.realAgent" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.moneyType" dict="bd_cur"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="录入时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.sCreateTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                :picker-options="editStartOptions"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.sCreateTimeE"
                                type="datetime"
                                size="small"
                                :picker-options="editEndOptions"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                            <!-- <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker> -->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"

                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeE"
                                type="datetime"
                                size="small"

                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                            <!-- <el-date-picker
                                class="f-flex-1"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions1"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker> -->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="到港时间">
                          <div class="f-flex">
                            <el-date-picker

                                class="f-flex-1"
                                v-model="queryForm.arriveTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"

                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker

                                class="f-flex-1"
                                v-model="queryForm.arriveTimeE"
                                type="datetime"
                                size="small"

                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                          <div class="f-flex">
                            <el-date-picker

                                class="f-flex-1"
                                v-model="queryForm.boatTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"

                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker

                                class="f-flex-1"
                                v-model="queryForm.boatTimeE"
                                type="datetime"
                                size="small"

                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                            <!-- <el-date-picker
                                :disabled="boatStatus"
                                class="f-flex-1"
                                v-model="btime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker> -->
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="7">
                    <el-form-item label="派送时间">
                      <div class="f-flex">
                        <el-date-picker

                            class="f-flex-1"
                            v-model="queryForm.deliveryTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"

                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker

                            class="f-flex-1"
                            v-model="queryForm.deliveryTimeEnd"
                            type="datetime"
                            size="small"

                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                      <!-- <el-date-picker
                          :disabled="boatStatus"
                          class="f-flex-1"
                          v-model="btime"
                          type="datetimerange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="editStopOptions"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          unlink-panels
                      >
                      </el-date-picker> -->
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="锁定时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.lockTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                :picker-options="editStartOptionsLock"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.lockTimeE"
                                type="datetime"
                                size="small"
                                :picker-options="editEndOptionsLock"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                            <!-- <el-date-picker
                                class="f-flex-1"
                                v-model="ltime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker> -->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="销账时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.removeTimeS"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                :picker-options="editStartOptionsRemove"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.removeTimeE"
                                type="datetime"
                                size="small"
                                :picker-options="editEndOptionsRemove"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                          </div>
                            <!-- <el-date-picker
                                class="f-flex-1"
                                v-model="rtime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker> -->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="快递发货时间">
                            <div class="f-flex">
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.kdDeliveryTimeS"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    :picker-options="editStartOptionsRemove"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.kdDeliveryTimeE"
                                    type="datetime"
                                    size="small"
                                    :picker-options="editEndOptionsRemove"
                                    placeholder="结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务">
                            <f-select
                                v-model="queryForm.salesmanId"
                                :isNeed="queryForm.salesmanId"
                                :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <!--                            <el-input v-model="queryForm.docId" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select
                                v-model="queryForm.docId"
                                :isNeed="queryForm.docId"
                                :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作">
                            <!--                            <el-input v-model="queryForm.operateId" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select
                                v-model="queryForm.operateId"
                                :isNeed="queryForm.operateId"
                                :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款人">
                            <!--                            <el-input v-model="queryForm.operateId" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select
                                v-model="queryForm.billmanId"
                                :isNeed="queryForm.billmanId"
                                :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="费用类型">
                            <el-select v-model="queryForm.settleType">
                                <el-option value="0" label="应收"></el-option>
                                <el-option value="1" label="应付"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="费用名">
                            <f-select v-model="queryForm.feeId" :dict="'bd_fee_type'" :is-need="queryForm.feeId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="销账状态">
                            <el-select v-model="queryForm.removeType">
                                <el-option value="0" label="未销账"></el-option>
                                <el-option value="1" label="已销账"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="订单类型">
                            <el-select v-model="queryForm.orderType"
                                       @change="orderTypeChange">
                                <el-option value="0" label="海运">
                                </el-option>
                                <el-option value="2" label="快递">
                                </el-option>
<!--                                <el-option value="3" label="海外仓">-->
<!--                                </el-option>-->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="关账状态">
                            <el-select v-model="queryForm.isCloseAccount">
                                <el-option value="0" label="未关账"></el-option>
                                <el-option value="1" label="已关账"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="关账时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.closeTimeS"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.closeTimeE"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="柜号">
                            <el-input type="textarea" v-model.trim="queryForm.containerNo" @keyup.enter.native="handleCheck"
                                      :disabled="containerNoStatus" placeholder="支持多柜号查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="货物状态">
                            <!--<el-select v-model="queryForm.delivery" :disabled="deliveryStatus">-->
                                <!--<el-option value="0" label="正常放货"></el-option>-->
                                <!--<el-option value="1" label="暂停放货"></el-option>-->
                            <!--</el-select>-->
                            <f-select :dict="'delivery_order_status'" v-model="queryForm.delivery" :is-need="queryForm.delivery"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="录入来源">
                            <f-select :dataList="createTypeList" v-model="queryForm.createType" :is-need="queryForm.createType"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="需要柜子分摊前">
                            <el-select v-model="queryForm.isNeedOsContainerSettle" clearable filterable>
                                <el-option value="0" label="否"></el-option>
                                <el-option value="1" label="是"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="数据时间">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.dataCreateTime"
                                    :is-need="queryForm.dataCreateTime"
                                    :dict="'data_create_time'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置查询条件</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
<!--                <el-button size="medium" type="primary" @click="exportExcel" v-if="$btnIsShow('hyFinanceManage/modules/allSettles','0','导出费用')">导出费用</el-button>-->
                <el-button
                    size="medium"
                    type="primary"
                    @click="exportExcelPlus(url.allSettleItemsQueryStream,'所有费用数据报表'+ new Date().toLocaleDateString())"
                    v-if="buttonShowList.dcfy"
                >导出费用</el-button>
                <!--                <el-button @click="chufa">chufa</el-button>-->
<!--                <el-button size="medium" type="primary" @click="exportOsAllSettleExcel" v-if="$btnIsShow('hyFinanceManage/modules/allSettles','0','导出海运财务所有费用表')">导出海运财务所有费用表</el-button>-->
                <el-button
                    size="medium"
                    type="primary"
                    @click="exportExcelPlus(url.allHySettleItemsQueryStream,'海运财务所有费用数据报表'+ new Date().toLocaleDateString())"
                    v-if="buttonShowList.dchycwsyfyb"
                >导出海运财务所有费用表</el-button>
                <el-button size="medium" type="primary" @click="downloadHaiyunData1"
                           v-if="buttonShowList.dchyfsjb"
                >导出海运费数据表</el-button>

                <el-button size="medium" type="primary" @click="exportExcelPlus(url.exportAllKdSettleData,'快递费用数据表'+new Date().toLocaleDateString() )"
                           v-if="buttonShowList.dckdfsjb"
                >导出快递费数据表</el-button>
                <el-button
                    size="medium"
                    type="primary"
                    @click="closeAccountBatch"
                    v-if="buttonShowList.plgz"
                >批量关账</el-button>

            </div>
            <!--<el-button size="medium" type="primary" @click="createVoucherInfoDate()"-->
                       <!--v-if="$btnIsShow('hyFinanceManage/modules/allSettles','0','导出海运费数据表')"-->
            <!--&gt;以 [导出海运费数据表] 数据生成凭证</el-button>-->
            <!--<el-button size="medium" type="primary" @click="createVoucherInfoDate()"-->
                       <!--v-if="$btnIsShow('hyFinanceManage/modules/allSettles','0','导出快递费数据表')"-->
            <!--&gt;以 [导出快递费数据表] 数据生成凭证</el-button>-->
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="56vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'checkStatus'">
                        <span v-if="scope.row.checkStatus === '3'">已销账</span>
                    </template>
                    <template v-else-if="scope.column.property === 'locker'">
                        <span v-if="scope.row.locker === '1'" style="color: #ce8483">费用已锁</span>
                        <span v-else-if="scope.row.locker === '0' || scope.row.locker === null" style="color: #398439">未锁定</span>
                    </template>
                    <template v-else-if="scope.column.property === 'type'">
                        <span v-if="scope.row.type === '1'">应付</span>
                        <span v-else-if="scope.row.type === '0'">应收</span>
                    </template>
                    <template v-else-if="scope.column.property === 'delivery'">
                           <span v-if="['1','2','5'].indexOf(scope.row.delivery) !== -1"
                                 style="background-color: orange;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
                        <span v-if="['0','3','4','6'].indexOf(scope.row.delivery) !== -1"
                              style="background-color: green;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderType'">
                        <span v-if="scope.row.orderType === '0'">海运</span>
                        <span v-else-if="scope.row.orderType === '1'">空运</span>
                        <span v-else-if="scope.row.orderType === '2'">快递</span>
                        <span v-else-if="scope.row.orderType === '9'">柜子</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.realAgent}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'isCloseAccount'">
                        <span>{{scope.row.isCloseAccount == '1' ? '是':'否'}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'thirdPartyPaymentStatus'">
                    <span v-if="scope.row.thirdPartyPaymentStatus === '0'">风控催收</span>
                    <span v-if="scope.row.thirdPartyPaymentStatus === '1'" >诉讼催收</span>
                    <span v-if="scope.row.thirdPartyPaymentStatus === '2'" >其他</span>
                    </template>
                    <!--                    <template v-else-if="scope.column.property === 'createBy'">-->
                    <!--                        <span>{{parseDict(scope.row.createBy,'sys_create_by')}}</span>-->
                    <!--                    </template>-->

                </template>


            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <receive-apply-update ref="apply"></receive-apply-update>
        <remove-dialog ref="removeDialog" @ok="this.handleQuery"></remove-dialog>
    </div>
</template>
<script>
import QueryMixins from "../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import removeDialog from "./receive/removeDialog";
import receiveApplyUpdate from "./receive/receiveApplyUpdate";
import { setUpRouteUtil } from '@/utils/util';
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../../utils/downloadJasper";

export default {
    mixins: [QueryMixins],
    components: {
        removeDialog,
        receiveApplyUpdate
    },
    data() {
        return {
            disableMixinMounted: true,
            day32:2764800000,
            // dictCodes:['sys_create_by'],
            // 录入时间-限定只能选择一个月范围内的
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.queryForm.sCreateTimeE) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.sCreateTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.sCreateTimeE).getTime() - this.day32);
                    }
                },
            },
            editEndOptions: {
                disabledDate: (time) => {
                    if (!this.queryForm.sCreateTimeS) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.sCreateTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.sCreateTimeS).getTime());
                    }
                },
            },
            // 下单时间-限定只能选择一个月范围内的
            editStartOptionsCreate: {
                disabledDate: (time) => {
                    if (!this.queryForm.createTimeE) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.createTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeE).getTime() - this.day32);
                    }
                },
            },
            editEndOptionsCreate: {
                disabledDate: (time) => {
                    if (!this.queryForm.createTimeS) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.createTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeS).getTime());
                    }
                },
            },
            // 开船时间-限定只能选择一个月范围内的
            editStartOptionsBoat: {
                disabledDate: (time) => {
                    if (!this.queryForm.boatTimeE) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.boatTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.boatTimeE).getTime() - this.day32);
                    }
                },
            },
            editEndOptionsBoat: {
                disabledDate: (time) => {
                    if (!this.queryForm.boatTimeS) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.boatTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.boatTimeS).getTime());
                    }
                },
            },
            // 锁定时间-限定只能选择一个月范围内的
            editStartOptionsLock: {
                disabledDate: (time) => {
                    if (!this.queryForm.lockTimeE) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.lockTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.lockTimeE).getTime() - this.day32);
                    }
                },
            },
            editEndOptionsLock: {
                disabledDate: (time) => {
                    if (!this.queryForm.lockTimeS) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.lockTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.lockTimeS).getTime());
                    }
                },
            },
            // 销账时间-限定只能选择一个月范围内的
            editStartOptionsRemove: {
                disabledDate: (time) => {
                    if (!this.queryForm.removeTimeE) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.removeTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.removeTimeE).getTime() - this.day32);
                    }
                },
            },
            editEndOptionsRemove: {
                disabledDate: (time) => {
                    if (!this.queryForm.removeTimeS) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return !(new Date(this.queryForm.removeTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.removeTimeS).getTime());
                    }
                },
            },
            pickerMinDate: null,
            pickerMaxDate: null,
            day30: 30 * 24 * 3600 * 1000,   //以30天为例
            // 日期使用
            pickerOptions1: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (time.getTime() > (this.pickerMinDate + this.day30)) || (time.getTime() < (this.pickerMinDate - this.day30));
                    }
                    return false;
                }
            },
            containerNoStatus:false,
            boatStatus: true,
            deliveryStatus: true,
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                // {
                //     type: "selection",
                //     width: 50,
                // },
                // {
                //     prop: "action",
                //     label: "操作",
                //     width: 130,
                // },
                {
                    prop: "orderNo",
                    label: "工作单号",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "containerNo",
                    label: "柜号",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "orderType",
                    label: "费用类型",
                    fixed: 'left'
                },
                {
                    prop: "shipmentBoard",
                    label: "开船时间",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "logistics",
                    label: "最新物流",
                    width: 140,
                },
                {
                    prop: "type",
                    label: "费用类型",
                    fixed: 'left'
                },
                {
                    prop: "locker",
                    label: "锁定状态",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "delivery",
                    label: "货物状态",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "realAgent",
                    label: "客户",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "payType",
                    label: "付款方式",
                    width: 100,
                    fixed: 'left'
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                    width: 140,
                },
                {
                    prop: "screateTime",
                    label: "录入时间",
                    width: 140,
                },
                {
                    prop: "settleTime",
                    label: "销账时间",
                    width: 140,
                },
                {
                    prop: "agent",
                    label: "结算对象",
                    width: 140,
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合总价",
                },
                {
                    prop: "remark",
                    label: "备注",
                },
                {
                    prop: "payed",
                    label: "已收金额",
                },
                {
                    prop: "variance",
                    label: "差异额",
                },
                {
                    prop: "waitPay",
                    label: "待收金额",
                    headerType:'remark',
                    detail:'提示：待收余额将在销账之后更新',
                    width:100
                },
                {
                    prop: "thirdPartyPaymentStatus",
                    label: "第三方收款",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "salesman",
                    label: "业务",
                },
                {
                    prop: "doc",
                    label: "客服",
                },
                {
                    prop: "operator",
                    label: "操作",
                },
                {
                    prop: "billman",
                    label: "收款人",
                },
                {
                    prop: "isCloseAccount",
                    label: "是否关账",
                },
            ],
            imgVisible: false,
            imgUrl: '',
            url: {
                list: "/shipping/hySettleReceive/allSettles",
                exportAllSettlesExcel: "/shipping/hySettleReceive/exportAllSettlesExcel",
                exportOsAllSettleExcel: "/shipping/hySettleReceive/exportOsAllSettleExcel",
                exportSettleDataExcel: "/shipping/hySettleReceive/exportSettleDataExcel",

                exportKdSettleDataExcel: "/shipping/hySettleReceive/exportKdSettleDataExcel",
                updateSettleSettleTime:'/shipping/hySettlePay/updateSettleSettleTime',
                //更新导出海运费数据表接口
                exportAllHySettleData:'/file/export/excel/allSettleDataQueryStream',
                //更新导出快递费数据表
                exportAllKdSettleData:'/file/export/excel/allKdSettleDataQueryStream',
                //更新导出费用
                allSettleItemsQueryStream:'/file/export/excel/allSettleItemsQueryStream',
                //更新导出海运财务所有费用表
                allHySettleItemsQueryStream:'/file/export/excel/allHySettleItemsQueryStream',
                saveIncomeAndCostData: "/finance/voucher/saveIncomeAndCostData",
                closeAccountBatch: "/shipping/hySettleReceive/closeAccountBatch",

            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],
            btime: [],  //开船时间
            ltime: [],  //锁定时间
            rtime: [],  //销账时间
            createTypeList: [
                { label: '新增一行', value: '1'},
                { label: '批量导入费用', value: '2'},
                { label: '柜子分摊', value: '3'},
                { label: '导入应收分税', value: '4'},
                { label: '导入应付分税', value: '5'},
            ],
    };
    },
    computed: {
      buttonShowList() {
        return {
          'dcfy':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','导出费用'),
          'dchycwsyfyb':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','导出海运财务所有费用表'),
          'dchyfsjb':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','导出海运费数据表'),
          'dckdfsjb':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','导出快递费数据表'),
          'plgz':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','批量关账'),
        }
      }
    },
    created() {
        this.queryForm.dataCreateTime = '3';
    },
    methods: {
        downloadHaiyunData1(){
          this.exportExcelPlus(this.url.exportAllHySettleData,'海运费用数据报表'+ new Date().toLocaleDateString())
        },
        downloadHaiyunData2(){
          downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("post",
              this.url.exportAllHySettleData,
              this.queryForm,
              '海运费数据表.zip',
              this
          );
        },

        closeAccountBatch(){
            if(!this.checkQuery()){
                this.$message.info("查询条件不能为空")
                return
            }
            this.loading = true;
            let form = {...this.queryForm, ...this.filterForm};
            postAction(this.url.closeAccountBatch, form).then((res) => {
                if(res.success){
                    this.loading = false;
                    this.$message({
                        type: 'success',
                        message: res.message
                    })

                }
            }).finally(() => {
                this.loading = false;
            })
        },
        chufa(){
            getAction(this.url.updateSettleSettleTime)
        },
        exportExcel(){
            if(!this.checkQuery()){
                return
            }
            this.loading = true;

            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.ltime && this.ltime.length > 1) {
            //     this.queryForm.lockTimeS = this.ltime[0];
            //     this.queryForm.lockTimeE = this.ltime[1];
            // }else{
            //     this.queryForm.lockTimeS = '';
            //     this.queryForm.lockTimeE = '';
            // }
            // if (this.rtime && this.rtime.length > 1) {
            //     this.queryForm.removeTimeS = this.rtime[0];
            //     this.queryForm.removeTimeE = this.rtime[1];
            // }else{
            //     this.queryForm.removeTimeS = '';
            //     this.queryForm.removeTimeE = '';
            // }
            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '费用导出'+ new Date().toLocaleDateString();
            postAction(this.url.exportAllSettlesExcel, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },
        exportOsAllSettleExcel(){
            if(!this.checkQuery()){
                return
            }
            this.loading = true;

            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.ltime && this.ltime.length > 1) {
            //     this.queryForm.lockTimeS = this.ltime[0];
            //     this.queryForm.lockTimeE = this.ltime[1];
            // }else{
            //     this.queryForm.lockTimeS = '';
            //     this.queryForm.lockTimeE = '';
            // }
            // if (this.rtime && this.rtime.length > 1) {
            //     this.queryForm.removeTimeS = this.rtime[0];
            //     this.queryForm.removeTimeE = this.rtime[1];
            // }else{
            //     this.queryForm.removeTimeS = '';
            //     this.queryForm.removeTimeE = '';
            // }
            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '海运财务所有费用报表'+ new Date().toLocaleDateString();
            postAction(this.url.exportOsAllSettleExcel, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },
        exportSettleDataExcel(){
            if(!this.checkQuery()){
                return
            }
            this.loading = true;

            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.ltime && this.ltime.length > 1) {
            //     this.queryForm.lockTimeS = this.ltime[0];
            //     this.queryForm.lockTimeE = this.ltime[1];
            // }else{
            //     this.queryForm.lockTimeS = '';
            //     this.queryForm.lockTimeE = '';
            // }
            // if (this.rtime && this.rtime.length > 1) {
            //     this.queryForm.removeTimeS = this.rtime[0];
            //     this.queryForm.removeTimeE = this.rtime[1];
            // }else{
            //     this.queryForm.removeTimeS = '';
            //     this.queryForm.removeTimeE = '';
            // }
            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '海运费用数据报表'+ new Date().toLocaleDateString();
            postAction(this.url.exportSettleDataExcel, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },
        exportKdSettleDataExcel(){
            if(!this.checkQuery()){
                return
            }
            this.loading = true;

            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '快递费用数据报表'+ new Date().toLocaleDateString();
            postAction(this.url.exportKdSettleDataExcel, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },

        orderTypeChange(){
            this.boatStatus = !(this.queryForm.orderType === '0');
            this.deliveryStatus = !(this.queryForm.orderType === '0');
            if(this.queryForm.orderType==='2'){
                this.queryForm.containerNo = '';
                this.containerNoStatus = true;
            }else{
                this.containerNoStatus = false;
            }
        },
        boatTimeChange(){
            this.boatStatus = !(this.queryForm.orderType === '0');
        },
        setProof() {
            // console.log("应收-生成凭证以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选付款申请");
                return;
            }
            let count = [];
            this.selectionRows.forEach((item) => {
                count.push(item.id);
            });

            let arr = Array.from(new Set(count));
            // console.log('', arr)
        },
        showApplyDetail(row) {
            // this.$refs.apply.showDetail(row);
            this.$refs.apply.onlyShowDetail(row);
        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        handleCheckBill(row) {
            // console.log(row);
            this.$refs.removeDialog.edit(row);
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.otime = "";
            this.stime = "";
            this.btime = "";
            this.ltime = "";
            this.rtime = "";
            this.ipagination.currentPage = 1;
            this.boatStatus = true
            // this.handleQuery();
        },
        handleCheck() {
            if(!this.checkQuery()){
                return
            }
            this.loading = true;

            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.ltime && this.ltime.length > 1) {
            //     this.queryForm.lockTimeS = this.ltime[0];
            //     this.queryForm.lockTimeE = this.ltime[1];
            // }else{
            //     this.queryForm.lockTimeS = '';
            //     this.queryForm.lockTimeE = '';
            // }
            // if (this.rtime && this.rtime.length > 1) {
            //     this.queryForm.removeTimeS = this.rtime[0];
            //     this.queryForm.removeTimeE = this.rtime[1];
            // }else{
            //     this.queryForm.removeTimeS = '';
            //     this.queryForm.removeTimeE = '';
            // }
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkQuery(){
            // console.log('orderNo',this.queryForm.orderNo)
            if((this.queryForm.orderNo=='' || this.queryForm.orderNo == undefined)
                && (this.queryForm.settlement==undefined || this.queryForm.settlement=='')
                && (this.queryForm.realAgent==undefined || this.queryForm.realAgent=='')
                && (this.queryForm.moneyType ==undefined || this.queryForm.moneyType=='')
                && (this.queryForm.settleType ==undefined || this.queryForm.settleType=='')
                && (this.queryForm.removeType ==undefined || this.queryForm.removeType=='')
                && (this.queryForm.orderType ==undefined || this.queryForm.orderType=='')
                && (this.queryForm.docId ==undefined || this.queryForm.docId=='')
                && (this.queryForm.operateId ==undefined || this.queryForm.operateId=='')
                && (this.queryForm.billmanId ==undefined || this.queryForm.billmanId=='')
                && (this.queryForm.salesmanId ==undefined || this.queryForm.salesmanId=='')
                && (this.queryForm.containerNo ==undefined || this.queryForm.containerNo=='')
                && (this.queryForm.lockTimeS == undefined || this.queryForm.lockTimeS=='')
                && (this.queryForm.lockTimeE == undefined || this.queryForm.lockTimeE=='')
                && (this.queryForm.createTimeS == undefined || this.queryForm.createTimeS=='')
                && (this.queryForm.createTimeE == undefined || this.queryForm.createTimeE=='')
                && (this.queryForm.sCreateTimeS == undefined || this.queryForm.sCreateTimeS=='')
                && (this.queryForm.sCreateTimeE == undefined || this.queryForm.sCreateTimeE=='')
                && (this.queryForm.removeTimeS == undefined || this.queryForm.removeTimeS=='')
                && (this.queryForm.removeTimeE == undefined || this.queryForm.removeTimeE=='')
                && (this.queryForm.boatTimeS == undefined || this.queryForm.boatTimeS=='')
                && (this.queryForm.boatTimeE == undefined || this.queryForm.boatTimeE=='')
                && (this.queryForm.feeId == undefined || this.queryForm.feeId=='')
            ){
                // this.$message.warning("请填写查询条件之后再进行查询")
                // return false
            }else{
                return true
            }
        },
        createVoucherInfoDate() {
            let params = {...this.queryForm, ...this.filterForm,...this.initData};
            if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                params['selections'] = this.selectedRowKeys.join(',');
                params['ids'] = this.selectedRowKeys.join(',')
            } else {
                params['ids'] = '';
            }
            //清空字符串的前后空格
            this.trimParamsStr(params);
            let that = this;
            postRefreshAction(this.url.saveIncomeAndCostData, params).then((data) => {
                //// 处理下载失败的问题，data是得到的请求结果

            })
        },

    },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
</style>
