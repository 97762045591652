<!--待提交-->
<template>
    <div class="main-full-content" v-loading.fullscreen.lock="fullscreenLoading">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="清关代理">
                            <f-select
                                    v-model="queryForm.clearAgentId"
                                    :isNeed="queryForm.clearAgentId"
                                    :dict="'clearAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="拆柜代理">
                            <f-select
                                    v-model="queryForm.dismantleAgentId"
                                    :isNeed="queryForm.dismantleAgentId"
                                    :dict="'dismantleAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="送货代理">
                            <f-select
                                    v-model="queryForm.deliveryAgentId"
                                    :isNeed="queryForm.deliveryAgentId"
                                    :dict="'deliveryAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="ETD">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etd"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etdEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="ETA">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.eta"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etaEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>


                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
          <div class="f-p-b-15">
            <el-button
                v-if="buttonShowList.kc"
                type="primary"
                size="medium"
                @click="handleSail(null)"
                :disabled="selectionRows.length === 0 "
            >批量开船
            </el-button>
            <el-button
                v-if="buttonShowList.kc"
                type="primary"
                size="medium"
                @click="handleTruck(null)"
                :disabled="selectionRows.length === 0 "
            >卡航发车
            </el-button>
          </div>
          <div class="c-alert f-m-b-10">
            已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
            <span
                class="stress f-m-l-12 f-cursor-pointer"
                @click="handleClearSelection"
            >清空</span
            >
          </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                v-if="scope.row.circulationState == '1' && buttonShowList.bg"
                                @click="handEditcirculationState(scope.row)"
                        >国内报关
                        </el-button>
                        <el-button type="text" @click="handEditClearance(scope.row)" style="margin: 0"
                                   v-if="buttonShowList.bgjg"

                        >报关结果
                        </el-button
                        >
                        <el-button
                                type="text"
                                @click="handUpdateCirculationState(scope.row)"
                                v-if="buttonShowList.qxzg">取消装柜</el-button>
                        <el-button type="text" @click="tieReminder(scope.row)" v-if="buttonShowList.scyw">上传延误通知书</el-button>
                        <el-button type="text" @click="handleSail(scope.row)" v-if="scope.row.circulationState == '2' &&
                (scope.row.customsBrokerResult == '1' || scope.row.customsBrokerResult == '4')  && buttonShowList.kc">开船</el-button>
                        <el-button type="text" @click="handleShuai(scope.row)" v-if="scope.row.circulationState == '2' &&
                         (scope.row.customsBrokerResult == '1' || scope.row.customsBrokerResult == '4') && buttonShowList.sg">甩柜</el-button>
                        <el-button type="text" @click="getSonList(scope.row)"
                                   v-if="scope.row.week && scope.row.week.indexOf('传统')!==-1"
                        >查看子柜子
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'isDelay'">
                        <span v-if="scope.row.isDelay == '0'">否</span>
                        <span v-if="scope.row.isDelay == '1'">是</span>
                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
            <span>{{
              parseDict(scope.row.complianceState, "compliance_state")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
            <span>{{
              parseDict(scope.row.cabinetCategory, "cabinet_category")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id_chinese") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus == '0'">自拼</span>
                        <span v-if="scope.row.weekStatus == '1'">非自拼</span>
                        <span v-if="scope.row.weekStatus == '2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{
                            scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customsBrokerResult'">
                        <!-- <span>{{parseDict(scope.row.customsBrokerResult,'clearAgent_result')}}</span>-->
                        <span v-if="scope.row.customsBrokerResult == '0'">无</span>
                        <span v-if="scope.row.customsBrokerResult == '1'">国内报关放行</span>
                        <span v-if="scope.row.customsBrokerResult == '2'">国内查验通知</span>
                        <span v-if="scope.row.customsBrokerResult == '3'">国内查验未放行</span>
                        <span v-if="scope.row.customsBrokerResult == '4'">国内查验已放行</span>
                        <span v-if="scope.row.customsBrokerResult == '5'">国内查验查验退关</span>
                    </template>
                    <template v-else-if="scope.column.property === 'clearAgentId'">
                        <span>{{ parseDict(scope.row.clearAgentId, "clearAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'dismantleAgentId'">
                        <span>{{ parseDict(scope.row.dismantleAgentId, "dismantleAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryAgentId'">
                        <span>{{ parseDict(scope.row.deliveryAgentId, "deliveryAgent_id") }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                title="报关结果"
                :visible.sync="dialogClearanceFormVisible"
                :close-on-click-modal="false"
                append-to-body
        >
            <el-form :model="form" :rules="rules" ref="customsBrokerResultForm">
                <el-form-item
                        label="报关结果"
                        :label-width="formLabelWidth"
                        prop="weekStatus"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="form.customsBrokerResult"
                            placeholder="请选择"

                    >
                        <el-option label="暂无" value="0"></el-option>
                        <el-option label="国内报关放行" value="1"></el-option>
                        <el-option label="国内查验通知" value="2"></el-option>
                        <el-option label="国内查验未放行" value="3"></el-option>
                        <el-option label="国内查验已放行" value="4"></el-option>
                        <el-option label="国内查验查验退关" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="查验描述"
                        :label-width="formLabelWidth"
                        prop="checkedDesc"
                        v-if="form.customsBrokerResult==='4'"
                >
                    <f-select
                            class="f-full-width"
                            v-model="form.checkedDesc"
                            :isNeed="form.checkedDesc"
                            :dict="'checked_desc'"
                            placeholder="请选择"

                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="查验文件"
                        :label-width="formLabelWidth"
                        prop="checkedFileList"
                        v-if="form.customsBrokerResult !=='0' && form.customsBrokerResult !=='1'"
                >
                    <f-upload
                            class="f-full-width"
                            v-model="form.checkedFileList"
                            :isUseOss="true"
                    ></f-upload>
                </el-form-item>
                <el-form-item
                        label="理由"
                        :label-width="formLabelWidth"
                        prop="reason"
                        v-if="form.customsBrokerResult == '3' || form.customsBrokerResult == '5'"
                >
                    <el-input v-model="form.reason"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogClearanceFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveClearance">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="开船" :visible.sync="dialogSailFormVisible" :close-on-click-modal="false" append-to-body>
            <el-form :model="sailForm" ref="sailForm" :rules="rules">
                <el-form-item
                        label="开船时间"
                        :label-width="formLabelWidth"
                        prop="etd"
                        v-if="sailForm.isDelay == '0'"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="sailForm.etd"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"

                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                        label="预计到港时间"
                        :label-width="formLabelWidth"
                        prop="eta"
                        v-if="sailForm.isDelay == '0'"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="sailForm.eta"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="00:00:00"

                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSailFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveShipMessage">确 定</el-button>
            </div>
        </el-dialog>
      <el-dialog title="发车" :visible.sync="dialogTruckFormVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="sailForm" ref="sailForm" :rules="rules">
          <el-form-item
              label="发车时间"
              :label-width="formLabelWidth"
              prop="etd"
              v-if="sailForm.isDelay == '0'"
          >
            <el-date-picker
                class="f-flex-1"
                v-model="sailForm.etd"
                type="datetime"
                size="small"
                placeholder="开始时间"

                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
              label="预计到达时间"
              :label-width="formLabelWidth"
              prop="eta"
              v-if="sailForm.isDelay == '0'"
          >
            <el-date-picker
                class="f-flex-1"
                v-model="sailForm.eta"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="00:00:00"

                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTruckFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveShipMessageForTruck">确 定</el-button>
        </div>
      </el-dialog>
        <el-dialog title="甩柜" :visible.sync="dialogSailFormVisibleShuaiGui" :close-on-click-modal="false" append-to-body>
            <el-form :model="sailForm" ref="sailForm" :rules="rules">

                <el-form-item label="船名是否更改" :label-width="formLabelWidth" prop="isChangeShipsName" key="isChangeShipsName">
                    <el-radio-group
                            v-model="sailForm.isChangeShipsName"
                            @change="$forceUpdate()"
                    >
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="延船原因" :label-width="formLabelWidth" v-if="sailForm.isChangeShipsName === '1' "
                              prop="shuaiReasonType" key="shuaiReasonType">
                    <f-select
                            :is-need="sailForm.shuaiReasonType"
                            v-model="sailForm.shuaiReasonType"
                            :dict="'convert_ship_reason'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="延船原因" :label-width="formLabelWidth" v-if="sailForm.isChangeShipsName === '0' "
                              prop="delayReasonType" key="delayReasonType">
                    <f-select
                            :is-need="sailForm.delayReasonType"
                            v-model="sailForm.delayReasonType"
                            :dict="'ship_delay_reason'"
                    ></f-select>
                </el-form-item>
              <el-form-item label="备注(此条信息将会发送给客户)" :label-width="formLabelWidth"
                            v-if="(sailForm.isChangeShipsName === '1' && sailForm.shuaiReasonType === 'x')
                            || (sailForm.isChangeShipsName === '0' && sailForm.delayReasonType === 'x')"
                  prop="otherReason" key="otherReason">
                <el-input v-model="sailForm.otherReason" maxlength="255" show-word-limit type="textarea"></el-input>
              </el-form-item>
                <el-form-item label="预计开船时间" :label-width="formLabelWidth" prop="predictEta">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="sailForm.predictEta"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="延误通知书" :label-width="formLabelWidth" ref="yanwu">
                    <el-upload
                            :action="'/sys/oss/file/upload'"
                            :file-list="fileList"
                            :http-request="handleUpload"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-upload="beforeUpload"
                            :on-exceed="onexceed"
                            :limit="1"
                            v-bind="$attrs"

                    >
                        <template slot="trigger">
                            <template
                                    v-if="this.$attrs.drag === '' || this.$attrs.drag === true"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    将文件拖到此处，或<em>点击上传</em>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="this.$attrs['list-type'] === 'picture-card'">
                                    <i class="el-icon-plus"></i>
                                </template>
                                <el-button size="small" type="primary" v-else
                                >点击上传
                                </el-button
                                >
                            </template>
                        </template>
                        <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSailFormVisibleShuaiGui = false">取 消</el-button>
                <el-button type="primary" @click="saveShipMessage">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="上传延误通知书"
                   :visible.sync="dialogTieReminderVisible"
                   :close-on-click-modal="false"
                   v-if="dialogTieReminderVisible">
            <el-form :model="tieForm" ref="tieForm" :rules="tieRules">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="tieForm.title"></el-input>
                </el-form-item>
                <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="tieForm.remark" type="textarea"></el-input>
                </el-form-item>

                <el-form-item label="延误通知书" :label-width="formLabelWidth" prop="fileList">
                    <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
            </div>
        </el-dialog>
        <son-cabinet :container-id="rowContainerId" :visible.sync="sonVisible" :table-list="sonTableList" @close="close"></son-cabinet>

    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';
    import SonCabinet from "@/views/operation/cabinet/SonCabinet.vue";

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        components:{SonCabinet},
        data() {
            const yanwuFileList = (rule, value, callback) => {
                if (!this.fileList.length) {
                    callback(new Error("请选择延误通知书"));
                } else {
                    callback();
                }
            };
            return {
              isUsePost:true,
                dialogTieReminderVisible: false,
                tieForm: {},
                fileList: [],
                tip: "",
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.sailForm.eta) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.sailForm.etd).getTime();
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.sailForm.eta).getTime() ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                tieRules: {
                    title: [{required: true, message: "请填写标题", trigger: "change"},
                        {max: 100, message: '长度不能超过100', trigger: 'change'},
                    ],
                    remark: [{required: true, message: "请填写提醒事项", trigger: "change"},
                        {max: 1000, message: '长度不能超过1000', trigger: 'change'},
                    ]
                },
                rules: {
                  otherReason: [
                    {required : true, message : '请输入备注', trigger : 'change'}
                  ],
                    isDelay: [
                        {required: true, message: "请选择是否延误", trigger: "change"},
                    ],
                    eta: [{required: true, message: "请输入预计到港时间", trigger: "change"}],
                    etaTruck: [{required: true, message: "请输入预计到达时间", trigger: "change"}],
                    etd: [
                        {required: true, message: "请输入开船时间", trigger: "change"},
                    ],
                    etdTruck: [
                        {required: true, message: "请输入发车时间", trigger: "change"},
                    ],
                    isChangeShipsName: [
                        {required: true, message: "请选择船名是否更改", trigger: "change"},
                    ],
                    fileId: [
                        {validator: yanwuFileList, trigger: "change"},
                    ],
                    predictEta: [
                        {required: true, message: "请选择预计开船时间", trigger: "change"},
                    ],
                    reason: [
                        {required: true, message: "请输入理由", trigger: "change"},
                    ],
                    weekStatus: [
                        {required: true, message: "请选择报关结果", trigger: "change"},
                    ],
                    shuaiReasonType: [
                        {required: true, message: "请选择延船原因", trigger: "change"},
                    ],
                    delayReasonType: [
                        {required: true, message: "请选择延船原因", trigger: "change"},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                sailForm: {},
                boardType : '',
                EditCabinetForm: {},
                updateAlertCabinrt: {},
                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id_chinese", "clearAgent_id", "dismantleAgent_id", "deliveryAgent_id"],
                formLabelWidth: "120px",
                dialogClearanceFormVisible: false,
                dialogSailFormVisible: false,
                dialogTruckFormVisible: false,
                isRailwayTransport: '0',
                dialogSailFormVisibleShuaiGui: false,
                //控制弹框显示
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 120,
                    },
                    {
                        prop: "isDelay",
                        label: "是否延期开船",
                    },
                    {
                        prop: "week",
                        label: "周数",
                    },
                    {
                        prop: "cabinetCategory",
                        label: "专线or拆柜",
                        width: 120,
                    },
                    {
                        prop: "bookAgentId",
                        label: "订舱代理",
                        width: 140,
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        width: 100,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱号",
                        width: 140,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        width: 160,
                    },
                    {
                        prop: "originalNo",
                        label: "封条",
                        width: 100,
                    },
                    {
                        prop: "etd",
                        label: "ETD",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "weekStatus",
                        label: "柜子用途",
                        minWidth: 180,
                    },
                    {
                        prop: "customsBrokerResult",
                        label: "国内报关状态",
                        minWidth: 180,
                    },
                    {
                        prop: "si",
                        label: "截SI",
                        minWidth: 180,
                    },
                    /*{
                        prop: "",
                        label: "柜装船时间",
                        minWidth: 180,
                        sortable: "",
                    },*/
                    {
                        prop: "clearAgentId",
                        label: "清关代理",
                        minWidth: 180,
                    },
                    {
                        prop: "dismantleAgentId",
                        label: "拆柜代理",
                        minWidth: 180,
                    },
                    {
                        prop: "deliveryAgentId",
                        label: "送货代理",
                        minWidth: 180,
                    },
                ],
                url: {
                    list: "/operation/container/waitingTosailList",
                    updateCirculationState: "/operation/container/updateCirculationState",
                    delCabinet: "/operation/container/delCabinet",
                    upload: "/sys/oss/file/upload",
                    addShipMessageBatch: "/operation/container/addShipMessageBatch",
                    // tieReminder: "/order/all/container/tieReminder",  //上传延误通知书
                    tieReminder: "/file/sendEmail/container/tieReminder",  //上传延误通知书
                    getSonList:'/operation/container/getSonContainerListByContainerId',

                },
                rowContainerId:'',
                sonVisible:false,
                sonTableList:[],
                countryList: [],
                addressList: [],
                fullscreenLoading: false,
            };
        },
        methods: {
            getSonList(row){
                this.rowContainerId = row.id;
                postAction(this.url.getSonList,{id:row.id})
                    .then(res=>{
                        this.sonTableList = res.result;
                        this.sonVisible = true;
                    })
            },
            close(){
                this.sonVisible = false;
            },
            tieReminder(row) {
                this.tieForm = {containerId : row.id};
                this.tieForm.fileList = [];
                this.dialogTieReminderVisible = true;
            },
            sendTieReminderEmail(row) {
                this.$refs.tieForm.validate(valid => {
                    if (valid) {
                        postAction(this.url.tieReminder, this.tieForm)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogTieReminderVisible = false;
                                }
                            })
                    }
                })


            },
            handlePreview(file) {
                console.log("文件的url", file.url);

                if (!file.url) {
                    this.$message.error("下载失败");
                    return;
                }

                const type = file.url.substring(file.url.lastIndexOf(".") + 1);
                console.log("type", type);
                if (
                    type === "jpg" ||
                    type === "jpeg" ||
                    type === "img" ||
                    type === "gif" ||
                    type === "png" ||
                    type === "pneg"
                ) {
                    console.log("是图片预览", file.url);
                    this.imgUrl = file.url;
                    this.visible = true;
                }
                // 判断文件类型
                else if (
                    type === "doc" ||
                    type === "docx" ||
                    type === "xlsx" ||
                    type === "xls" ||
                    type === "ppt" ||
                    type === "pptx"
                ) {
                    console.log("不是图片下载", file.url);
                    // 在当前浏览器直接下载
                    document.location.href = file.url;
                } else {
                    // 图片在浏览器打开 新的页面展示
                    window.open(file.url, "hello");
                }
            },
            onexceed() {
                this.$message.warning("最多上传1个文件哦,亲");
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleRemove(file) {
                console.log(file);
                if (file.id) {
                    const index = this.fileList.indexOf(file);
                    const {id} = this.fileList[index];
                    deleteAction("/sys/oss/file/delete", {id})
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.fileList.splice(index, 1);
                            }
                            this.fileList.splice(index, 1);
                        })
                        .catch((err) => {
                            this.$emit("delete", index);
                            this.fileList.splice(index, 1);
                        });
                    //如果传递参数为合同，则删除合同
                }
            },
            handleUpload(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append("file", data.file);
                console.log("客户文件", formData);
                postAction(this.url.upload, formData).then((res) => {
                    console.log(res);
                    if (res.success) {
                        this.sailForm.fileId = res.result.id;
                        this.fileList.push(res.result);
                        if (this.$refs['yanwu']) {
                            this.$refs['yanwu'].clearValidate();
                        }
                        if (this.$refs['sailForm'] && this.$refs['sailForm'].$refs['yanwu']) {
                            this.$refs['sailForm'].$refs['yanwu'].clearValidate();
                        }
                    }
                });
            },
            saveShipMessage() {
                console.log(this.sailForm);
                this.$refs["sailForm"].validate((valid) => {
                    if (valid) {
                        let list = this.fileList;
                        console.log('list', this.form)
                        if (list && list.length > 0) {
                            postAction('/order/os/file/saveFileListWithContainerId?containerId=' + this.sailForm.containerId + "&type=50", list)
                                .then(res => {
                                })
                        }
                        this.fullscreenLoading = true;

                        putAction(this.url.addShipMessageBatch, this.sailForm)
                            .then((res) => {
                                this.fullscreenLoading = false;
                                if (res.success) {
                                    this.$message.success(res.message);
                                    if (this.sailForm.isDelay === '0') {
                                        this.dialogTruckFormVisible = false;
                                        // 清空参数
                                        this.sailForm = {};
                                    } else {
                                         this.dialogSailFormVisibleShuaiGui = false;
                                    }
                                    this.dialogSailFormVisible  = false;
                                    this.handleSearch();
                                }
                            })
                            .catch((err) => {
                                this.fullscreenLoading = false;
                            });
                    } else {
                        return false;
                    }
                });
            },
            saveShipMessageForTruck() {
                this.$refs["sailForm"].validate((valid) => {
                    if (valid) {
                        let list = this.fileList;
                        console.log('list', this.form)
                        if (list && list.length > 0) {
                            postAction('/order/os/file/saveFileListWithContainerId?containerId=' + this.sailForm.containerId + "&type=50", list)
                                .then(res => {
                                })
                        }
                        console.log(this.sailForm);
                        this.fullscreenLoading = true;


                        putAction(this.url.addShipMessageBatch, this.sailForm)
                            .then((res) => {
                                this.fullscreenLoading = false;
                                if (res.success) {
                                    this.$message.success(res.message);
                                    if (this.sailForm.isDelay === '0') {
                                        this.dialogTruckFormVisible = false;
                                        // 清空参数
                                        this.sailForm = {};
                                    } else {
                                        this.dialogSailFormVisibleShuaiGui = false;
                                    }
                                    this.handleSearch();
                                }
                            })
                            .catch((err) => {
                                this.fullscreenLoading = false;
                                // this.$message.error(err.message);
                            });
                    } else {
                        return false;
                    }
                });
            },
            //导入
            async uploadSectionFile(param) {
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                postAction(this.url.upload, form)
                    .then((res) => {
                        //这里做上传之后的操作
                        if (res.success) {
                            console.log(res);
                            this.$message.success(res.message);
                            this.sailForm.fileId = res.id;
                        }
                    });
            },
            //开船
          handleSail(row) {
            this.sailForm = {};
            if (row) {
              console.log(row);
              this.$set(this.sailForm, "eta", row.eta);
              this.$set(this.sailForm, "etd", row.etd);
              this.sailForm.containerIdList = [row.id];
            } else {
              this.sailForm.containerIdList = JSON.parse(JSON.stringify(this.selectedRowKeys));
            }
            this.sailForm.isDelay = "0";
            this.sailForm.isRailwayTransport = '0';
            this.dialogSailFormVisible = true;
            console.log(this.sailForm);
          },
          handleTruck(row) {
            if (row) {
              this.sailForm.containerId = row.id;
              this.$set(this.sailForm, "eta", row.eta);
              this.$set(this.sailForm, "etd", row.etd);
              this.sailForm.containerIdList = [row.id];
            } else {
              this.sailForm.containerIdList = JSON.parse(JSON.stringify(this.selectedRowKeys));
            }
            this.sailForm.isDelay = "0";
            this.sailForm.isRailwayTransport = '1';
            this.dialogTruckFormVisible = true;
          },
            //延船
            handleShuai(row) {
                this.sailForm = {};
                console.log(row);
                this.sailForm.containerId = row.id;
                this.sailForm.isDelay = "1";  //延期
                this.$set(this.sailForm, "eta", row.eta);
                this.$set(this.sailForm, "etd", row.etd);
                this.sailForm.eta = row.eta;
                // this.sailForm.etd = row.etd;
                // Vue.set(this.sailForm,'isDelay','0')
                this.dialogSailFormVisibleShuaiGui = true;
                this.fileList = [];
                console.log(this.sailForm);
            },

            handEditcirculationState(row) {
                let form = JSON.parse(JSON.stringify(row));
                form.circulationState = 2;
                form.actionType = "circulationState";

                this.$confirm(`确定国内报关?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        this.fullscreenLoading = true;
                        putAction(this.url.updateCirculationState, form).then((res) => {
                            this.fullscreenLoading = false;
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.handleSearch();
                            }
                        }).catch(err => {
                            this.fullscreenLoading = false;
                        });
                    })
                    .catch(() => {
                    });
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                // this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/ToSailEdit');
                this.$router.push("/operation/cabinet/ToSailEdit?id=" + id);
                console.log(id);
            },
            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handEditClearance(row) {
                getAction("/operation/container/getOneById", {id: row.id})
                    .then(res => {
                        this.form = res.result;
                        console.log(this.form);
                        this.dialogClearanceFormVisible = true;
                    })

            },
            //取消装柜
            handUpdateCirculationState(row) {
                let form = JSON.parse(JSON.stringify(row));
                this.$confirm(`是否取消${form.week}装柜`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        this.fullscreenLoading = true;
                        form.actionType = "cancelLoading";
                        form.circulationState = "0";
                        form.complianceState = "0";
                        form.customsBrokerResult = "0";
                        putAction(this.url.updateCirculationState, form).then((res) => {
                            this.fullscreenLoading = false;
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogClearanceFormVisible = false;
                                this.handleSearch();
                            }
                        }).catch(err => {
                            this.fullscreenLoading = false;
                        });
                    })
                    .catch(() => {
                    });
            },
            saveClearance() {
                this.$refs['customsBrokerResultForm'].validate(valid => {
                    if (valid) {
                        let list = this.form.checkedFileList;
                        console.log('list', this.form)
                        if (list && list.length > 0) {

                            postAction('/order/os/file/saveFileListWithContainerId?containerId=' + this.form.id + "&checkedFileIds=" + this.form.checkedFileIds + "&type=11", list)
                                .then(res => {

                                })
                            this.form.checkedFileIds = list.map(l => l.id).join(",");
                        }
                        this.fullscreenLoading = true;
                        this.form.actionType = "clearance";
                        console.log(this.form)
                        putAction(this.url.updateCirculationState, this.form).then((res) => {
                            this.fullscreenLoading = false;
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogClearanceFormVisible = false;
                                this.handleSearch();
                            }
                        }).catch(err => {
                            this.fullscreenLoading = false;
                        });
                    }
                })

            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {}
                this.queryForm.pageType = "1";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
            this.queryForm.pageType = "1";
        },
      computed:{
        buttonShowList() {
          return {
            'bg':this.$btnIsShow('operation/cabinet/WaitingToSail','1','国内报关'),
            'bgjg':this.$btnIsShow('operation/cabinet/WaitingToSail','1','报关结果'),
            'qxzg':this.$btnIsShow('operation/cabinet/WaitingToSail','1','取消装柜'),
            'scyw':this.$btnIsShow('operation/cabinet/WaitingToSail','1','上传延误通知书'),
            'kc':this.$btnIsShow('operation/cabinet/WaitingToSail','1','开船'),
            'sg':this.$btnIsShow('operation/cabinet/WaitingToSail','1','甩柜'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
