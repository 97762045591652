
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="公司名称">
                        <el-input v-model="queryForm.company" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="国家">
                    <el-input v-model="queryForm.country" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="邮编/CODE">
                    <el-input v-model="queryForm.zipcode" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="联系人">
                    <el-input v-model="queryForm.contact" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="联系人电话">
                    <el-input v-model="queryForm.tel" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="仓库代码">
                            <el-input v-model="queryForm.shipmentZip" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="城市">
                      <el-input v-model="queryForm.city" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                     <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="创建时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="修改时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.updateTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.updateTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card">
             <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                           v-if="buttonShowList.add"
                >新增</el-button>
               <el-button type="primary" size="medium" icon="el-icon-download" @click="handleExportXls('第三方仓储列表')"
                          v-if="buttonShowList.dc"
               >导出</el-button>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)" v-if="buttonShowList.bj"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDele(scope.row)" v-if="buttonShowList.sc"
                    >删除</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑第三方海外仓公司':'新增第三方海外仓公司'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
              <el-form-item prop="companyId" label="公司名称" :label-width="formLabelWidth">
                <el-select v-model="form.companyId" placeholder="请选择">
                  <el-option
                      v-for="item in options.name"
                      :key="item.id"
                      :label="item.company"
                      :value="item.id">
                  </el-option>
                </el-select>
               </el-form-item>
                <el-form-item label="仓库代码" :label-width="formLabelWidth" prop="shipmentZip">
                    <el-input v-model="form.shipmentZip" autocomplete="off"></el-input>
                </el-form-item>
              <el-form-item label="国家" :label-width="formLabelWidth" prop="country">
                   <f-select
                      :dict="'bd_country'"
                      v-model="form.country"
                      :is-need="form.country"
                      @changet='changetCountry'
                    ></f-select>
               </el-form-item>
              <el-form-item label="州" :label-width="formLabelWidth" prop="state">
                <el-input v-model="form.state" autocomplete="off" maxlength="20" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="城市" :label-width="formLabelWidth" prop="city">
                <el-input v-model="form.city" autocomplete="off" maxlength="20" show-word-limit></el-input>
              </el-form-item>
                <el-form-item label="邮编/CODE" :label-width="formLabelWidth" prop="zipcode">
                  <el-input v-model="form.zipcode" autocomplete="off"></el-input>
                </el-form-item>
               <el-form-item label="地址行1" :label-width="formLabelWidth" prop="address">
                <el-input v-model="form.address" autocomplete="off"></el-input>
               </el-form-item>
               <el-form-item label="地址行2" :label-width="formLabelWidth" prop="address2">
                <el-input v-model="form.address2" autocomplete="off"></el-input>
               </el-form-item>
               <el-form-item label="联系人" :label-width="formLabelWidth" prop="contact">
                <el-input v-model="form.contact" autocomplete="off"></el-input>
               </el-form-item>
               <el-form-item label="联系电话" :label-width="formLabelWidth" prop="tel">
               <el-input v-model="form.tel" autocomplete="off"></el-input>
               </el-form-item>
                  <el-form-item
                                        prop="isYuyue"
                                        label="是否预约:"
                                        :label-width="formLabelWidth"
                                >
                                    <el-radio-group v-model="form.isYuyue">
                                        <el-radio :label="'1'">是</el-radio>
                                        <el-radio :label="'0'">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                  <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth" v-if="form.isYuyue=='1'">
                    <el-input v-model="form.email"></el-input>
                    
                </el-form-item>
                  <el-form-item
                                        prop="yuyueType"
                                        label="派送预约方式:"
                                        :label-width="formLabelWidth"
                                        v-if="form.isYuyue=='1'"
                                >
                                 <el-radio-group v-model="form.yuyueType">
    <el-radio :label="item.value" v-for="(item, index) in addressyuyueCn" :key="index">{{item.text}}</el-radio>
  </el-radio-group>
                                </el-form-item>
                                 <el-form-item
                                        prop="yuyueRemark"
                                        label="预约备注:"
                                           :label-width="formLabelWidth"
                                           v-if="form.isYuyue=='1'"
                                >
                                 <el-input v-model.trim="form.yuyueRemark" placeholder="请输入备注" type="textarea" maxlength="350"></el-input></el-form-item>
               <el-form-item label="排序号码" :label-width="formLabelWidth" prop="sortNum">
                <el-input v-model="form.sortNum" autocomplete="off" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
              </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction,getAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {downFile} from "../../../api/manage";
    import {zhengshuPattern} from "@/utils/pattern";
    //import {mobilePattern} from '@/utils/pattern'

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
               UppercaseEnglish:(rule, value, callback)=>{
           // 值不为空时进行校验
      if (value != "") {
        //  校验英文的正则
        if (/^[A-Z]{2}$/.test(value) == false) {
          callback(new Error("只能由两个大写英文字母组成"));
        } else {
          //校验通过
          callback();
        }
      }
      },
                rules: {
                  companyId: [
                        {required: true, message: '请输入公司名称', trigger: 'change'},
                        {max: 64, message: '长度不能超过64', trigger: 'change'},
                    ],
                  country: [
                        {required: true, message: '请输入公司名称', trigger: 'change'},
                        {max: 64, message: '长度不能超过64', trigger: 'change'},
                    ],
                    shipmentZip: [
                        {required: true, message: '请输入仓库代码', trigger: 'change'},
                        {max: 30, message: '长度不能超过30', trigger: 'change'},
                    ],
                  zipcode: [
                        {required: true, message: '请输入邮编', trigger: 'change'},
                        {max: 64, message: '长度不能超过64', trigger: 'change'},
                    ],
                  address: [
                    {required: true, message: '请输入地址', trigger: 'change'},
                    {max: 255, message: '长度不能超过255', trigger: 'change'},
                  ],
                  contact: [
                    {required: true, message: '请输入联系人', trigger: 'change'},
                    {max: 64, message: '长度不能超过64', trigger: 'change'},
                  ],
                  tel: [
                    {required: true, message: '请输入手机号', trigger: 'change'},
                    {max: 32, message: '长度不能超过32', trigger: 'change'},
                  ],
                  state:[
          {trigger:'change',required: false, message: '请填写' },
          {validator:''},
        ],
         city: [
          {required: false, message: "请输入英文", trigger: "change"},
          {
            // validator: noZhongwenValidator,
            trigger: "change",
          },
        ],
          isYuyue: [
          {required: true, message: "请选择是否预约", trigger: "change"},
        ],
          yuyueType: [
          { required: true, message: '请填写', trigger: "change" },
        ],
        email:[
           { required: true, message: '请填写', trigger: "change" },
        ]

                },
                emailList: [],
                options: {
                  name: [],
                  country: {}
                },
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                  {
                        prop: 'company',
                        label: '公司名称',
                  },
                    {
                        prop: 'shipmentZip',
                        label: '仓库代码',
                    },
                  {
                    prop: 'country',
                    label: '国家',

                  },
                  {
                    prop: 'state',
                    label: '州',

                  },
                  {
                    prop: 'city',
                    label: '城市',

                  },
                  {
                    prop: 'zipcode',
                    label: '邮编/CODE',
                  },
                  {
                    prop: 'contact',
                    label: '联系人',
                  },
                  {
                    prop: 'tel',
                    label: '电话',
                  },
                  {
                    prop: 'address',
                    label: '地址',
                  },
                  {
                    prop: 'createTime',
                    label: '创建时间',
                  },
                  {
                    prop: 'createBy',
                    label: '创建人',
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                  },
                  {
                    prop: "updateBy",
                    label: "修改人",
                  },

                ],
                url: {
                    list: '/sys/thirdWarehouse/personalAddressList',
                    add: '/sys/thirdWarehouse/add',
                    edit: '/sys/thirdWarehouse/edit',
                    getById: '/sys/thirdWarehouse/queryById',
                    delete: '/sys/thirdWarehouse/delete',
                    contactsCountries:'/sys/thirdWarehouse/contactsCountries',
                    exportXlsUrl: '/sys/thirdWarehouse/exportXls',
                      dict: '/sys/dict/getDictItems/'
                },
                  // dictCodes: [''],
                  addressyuyueCn:[]
            }
        },
        methods: {
            getQuery() {
              getAction(this.url.contactsCountries).then((res) => {
                console.log(res)
                this.options.name = res.result.contacts
                this.options.country = res.result.countries
              })
            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            getDict() {
               ['third_address_type'].forEach(item=>{
        getAction(this.url.dict + item).then(res => {
                if(item=='third_address_type') {
                  this.addressyuyueCn= res.result;
                }else if(item=='eng_address_yytype') {
                    this.addressyuyueEn= res.result;
                }
    })
    })
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该第三方海外仓公司`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
              changetCountry(val) {
       if(val=='US-美国'||val == 'CA-加拿大') {
          this.rules.state[0].required = true
            this.rules.state[1].validator = this.UppercaseEnglish
           this.rules.city[0].required = true
        }else{
           this.rules.state[0].required = false
             this.rules.state[1].validator =''
           this.rules.city[0].required = false
        }
    }
        },
      created(){
        this.getQuery()
        this.getDict()
      },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/OtherWarehouse','0','新增'),
            'dc':this.$btnIsShow('isystem/BaseData/OtherWarehouse','0','导出'),
            'bj':this.$btnIsShow('isystem/BaseData/OtherWarehouse','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/OtherWarehouse','1','删除'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
