<template>
  <div>

    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订单号">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订单类型">
                    <el-select v-model="queryForm.containerType" filterable clearable>
                      <el-option value="0" label="LCL拼柜"></el-option>
                      <el-option value="1" label="FCL整柜"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户名">
                    <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label-width="150px" label="是否上传报关资料：">
                    <f-select :isNeed="queryForm.isCle"
                            :dict="'unit_whether'"
                              v-model="queryForm.isCle"
                              style="width:100px">
                    </f-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客服">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.docId"
                      :is-need="queryForm.docId"
                      :father-val="queryForm.docId"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="业务员">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :father-val="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="操作员">
                  <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.operateId"
                      :is-need="queryForm.operateId"
                      :father-val="queryForm.operateId"
                  ></f-select>
                </el-form-item>
              </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="国家">
                    <f-select
                      :dict="'bd_country'"
                      v-model="queryForm.shipmentCountry"
                      :is-need="queryForm.shipmentCountry"
                      :father-val="queryForm.shipmentCountry"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="仓库编码">
                    <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="渠道名称">
                    <f-select
                      :dict="'transportStatus'"
                      :multiple="true"
                      v-model="queryForm.transportStatusList"
                      :is-need="queryForm.transportStatusList"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="报关类型">
                    <f-select
                      :dict="'os_declare_type'"
                      v-model="queryForm.declareType"
                      :is-need="queryForm.declareType"
                      :father-val="queryForm.declareType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="派送地址类型" label-width="90px">
                    <f-select
                      :dict="'shipment_type'"
                      v-model="queryForm.shipmentType"
                      :is-need="queryForm.shipmentType"
                      :father-val="queryForm.shipmentType"
                    ></f-select>
                  </el-form-item>
                </el-col>
              <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="拼柜仓库" label-width="80px">
                  <f-select
                      :dict="'lclPlace'"
                      :multiple="true"
                      v-model="queryForm.lclPlaceList"
                      :is-need="queryForm.lclPlaceList"
                  ></f-select>
                </el-form-item>
              </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="交货方式">
                    <f-select
                      :dict="'wh_type'"
                      v-model="queryForm.whType"
                      :is-need="queryForm.whType"
                      :father-val="queryForm.whType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计件/重/体" label-width="90px">
                    <el-input v-model="queryForm.yuji" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户提醒">
                    <el-input v-model="queryForm.froRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户内部单号" label-width="90px">
                    <el-input v-model="queryForm.withinOrderRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否购买保险" label-width="90px">
                    <f-select
                      :dict="'is_default'"
                      v-model="queryForm.insurance"
                      :is-need="queryForm.insurance"
                      :father-val="queryForm.insurance"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否问题件" label-width="90px">
                        <f-select
                                :dict="'is_default'"
                                v-model="queryForm.isWarning"
                                :is-need="queryForm.isWarning"
                                :father-val="queryForm.isWarning"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否入仓" label-width="100px">
                        <f-select
                                :dict="'is_default'"
                                v-model="queryForm.isRealCmb"
                                :is-need="queryForm.isRealCmb"
                                :father-val="queryForm.isRealCmb"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                  <el-form-item label="下单时间">
                    <div class="f-flex">
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                      <span class="f-p-h-2">~</span>
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch":class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="进口商类型">
                        <f-select
                                :dict="'order_importer'"
                                v-model="queryForm.importerType"
                                :is-need="queryForm.importerType"
                                :father-val="queryForm.importerType"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col   v-if="showLoadingSearch"  :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label-width="150px" label="是否上传清关箱单发票：">
                        <f-select :isNeed="queryForm.isUpdateType42File"
                                  :dict="'unit_whether'"
                                  v-model="queryForm.isUpdateType42File"
                                  style="width:100px">
                        </f-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                    <el-form-item label="发票确认">
                        <div class="f-flex">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.invoiceConfirmTimeStart"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.invoiceConfirmTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-download"
                                @click="exportExcelPlus(url.exportInvoice1,'发票已确认工作表')"
                                :loading="exportLoading"
                        >导出</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :options="typelist"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
        @handleKeyup="handleSearch"
        :tableHeight="showLoadingSearch ? '65vh' : '68vh'"
        :isRecordTableScroll="true"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="remarkProblem(scope.row)"
                v-if="buttonShowList.bjwtj && scope.row.isWarning !=='1'"
              >标记问题件</el-button>
              <span v-if="scope.row.isWarning==='1'" style="color: red">问题件</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
              <a v-if="scope.row.isWarning==='1'"
                 style="color: red"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
              <a v-else
                 style="color: #00a0e9"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '0'">公司负责人</span>
            <span v-else-if="scope.row.type === '1'">报价对接人</span>
            <span v-else-if="scope.row.type === '2'">操作联系人</span>
            <span v-else>财务联系人</span>
          </template>
          <template v-else-if="scope.column.property === 'containerType'">
            <span>{{ scope.row.containerType === '0' ? 'LCL拼柜' : 'FCL整柜' }}</span>
          </template>
          <template v-else-if="scope.column.property === 'shipmentType'">
            <span>{{
              parseDict(scope.row.shipmentType, "shipment_type")
            }}</span>
          </template>

          <template v-else-if="scope.column.property === 'declareType'">
            <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'insurance'">
            <span>{{ parseDict(scope.row.insurance, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'whType'">
             <span>{{ parseDict(scope.row.whType, "wh_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'importerType'">
             <span>{{ parseDict(scope.row.importerType, "order_importer") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderStatus'">
            <span>{{
              parseDict(scope.row.orderStatus, "os_order_status")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isWeiban'">
            <span>{{ parseDict(scope.row.isWeiban, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isBanyun'">
            <span>{{ parseDict(scope.row.isBanyun, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'docId'">
            <span>{{ parseDict(scope.row.docId, "all_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'salesmanId'">
            <span>{{ parseDict(scope.row.salesmanId, "all_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
          </template>
           <template v-else-if="scope.column.property === 'importerTypeStr'">
            <span :class="scope.row.importerTypeStr == '大森林'?'':'red_text'"> {{scope.row.importerTypeStr }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <add-problem-dialog
      :visible.sync="proVisible"
      :row="row"
      @ok="handleQuery"
      v-if="proVisible"
    ></add-problem-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { mobilePattern, emailPattern } from "@/utils/pattern";
import AddProblemDialog from "./modules/AddProblemDialog";
import { deleteAction, postAction, putAction, getAction } from "@/api/manage";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "ContactModule",
  mixins: [QueryMixins],
  components: {
    AddProblemDialog,
  },
  data() {
    return {
      showLoadingSearch: false,
      dictCodes: [
        "is_haiyun",
        "os_declare_type",
        "transportStatus",
        "shipment_type",
        "wh_type",
        "is_default",
        "os_order_status",'all_user_id'
      ],
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      typelist: [
        { value: "0", label: "公司负责人" },
        { value: "1", label: "报价对接人" },
        { value: "2", label: "操作联系人" },
        { value: "3", label: "财务联系人" },
      ],

      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
          minWidth: 90,
          over: true,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 90,
        },

        {
          prop: "containerType",
          label: "订单类型",
          minWidth: 70,
        },
        {
          prop: "customer",
          label: "客户名",
          minWidth: 80,
        },
        {
          prop: "importerTypeStr",
          label: "进口商类型",
          minWidth: 80,
        },
        {
          prop: "docId",
          label: "客服",
          minWidth: 100,
        },
        {
          prop: "salesmanId",
          label: "业务员",
          minWidth: 100,
        },
        {
          prop: "operator",
          label: "操作员",
          minWidth: 100,
        },
        {
          prop: "createTime",
          label: "下单时间",
          minWidth: 130,
        },
        {
          prop: "shipmentCountry",
          label: "国家",
          minWidth: 60,
        },
        {
          prop: "shipmentCode",
          label: "仓库编码",
          minWidth: 60,
        },
        {
          prop: "channelName",
          label: "渠道名称",
          minWidth: 100,
        },
        {
          prop: "declareType",
          label: "报关类型",
          minWidth: 80,
        },
        {
          prop: "shipmentType",
          label: "派送地址类型",
          minWidth: 80,
        },
        {
          prop: "whType",
          label: "交货方式",
          minWidth: 80,
        },
        {
          prop: "yuji",
          label: "预计件/重/体",
          minWidth: 100,
        },
        {
          prop: "froRemark",
          label: "客户提醒",
          minWidth: 150,
        },
        {
          prop: "shipmentId",
          label: "FBA 号",
          minWidth: 100,
        },
        {
          prop: "withinOrderRemark",
          label: "客户内部单号",
          minWidth: 100,
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          minWidth: 80,
        },
      ],
      proVisible: false,
      url: {
        list: "/interface/ttOrder/getNoBookingPlacedOrderPage",
        exportInvoice1: "/file/export/excel/downloadInvoice1Excel",
      },
      issalesman: "0",
    };
  },
  created() {
    this.queryForm.invoiceStatus = "1";
    // this.queryForm.orderStatus = "2";
    this.issales();
  },
  methods: {
    handleQuery() {
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm, ...this.initData}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else if (this.enabledCreatetime){
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
        //排序
        //提货中查询
        if (this.queryForm.column === 'sort') {
          this.$set(params, 'column', 'sort')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
       postAction(this.url.list+"?pageNo="+params.pageNo+"&pageSize="+params.pageSize+"&column="+params.column+"&order="+params.order, params).then((res) => {
        console.log(res)
        if (res.success) {
          this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
          if (res.result) {
            const {records, total} = res.result
            this.tableData = records || res.result;

            this.ipagination && (this.ipagination.total = total || res.result.length)
          } else {

            this.tableData = [];

            this.ipagination = 0;
          }

        }
      }).finally(() => {
        this.loading = false;
        this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
      })
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {}
      this.ipagination.currentPage = 1;
      this.queryForm.invoiceStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },

    async issales() {
      let ss = localStorage.getItem("USERINFO");
      var user = JSON.parse(ss);
      let result = await getAction("/sys/dict/getDictItems/salesmanId");
      var ids = result.result
        .map((e) => {
          return e.value;
        })
        .join(",");
      if (ids.indexOf(user.id) !== -1) {
        this.issalesman = "1";
      } else {
        this.issalesman = "0";
      }
      console.log("小彪子销售", this.issalesman);
      //测试
      // this.issalesman = '1';
    },
    remarkProblem(row) {
      //打开标记问题弹窗
      this.row.orderNo = row.orderNo;
      this.proVisible = true;
    },
    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
  computed : {
    buttonShowList() {
      return {
        'bjwtj':this.$btnIsShow('docpage/InvoiceStatusOsOrder','1','标记问题件'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.red_text {
  color: red;
}
</style>
