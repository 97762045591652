<template>
    <div>
        <el-dialog
            title="柜子费用申请-修改币种及添加账户"
            border
            :visible="changeCurVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="curBankDialogClose">
            <el-form :rules="rules">
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="cur" label="折合币种">
                            <f-select v-model="billForm.cur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      clearable
                                      filterable
                                      @change="curChangeBank"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">
                        <el-form-item prop="bank" label="银行账户">
                            <el-select v-model="billForm.bankId"
                                       placeholder="根据币种选择银行"
                                       clearable
                                       filterable
                                       :isNeed="true"
                                       class="f-full-width">
                                <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName"
                                           :key="item.id">
                                    {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="changeCurSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
            title="添加费用"
            border
            :visible="addFeeDialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleAddFeeDialogClose">
            <el-form>
                <el-row>

                    <el-col>
                        <el-form-item label="单号">
                            <el-input v-model="feeOrderNo" style="width: 70%" placeholder="请用逗号或空格隔开"></el-input>
                        </el-form-item>
                        <el-form-item label="柜号">
                            <el-input v-model="feeContainerNo" style="width: 70%" placeholder="请用逗号或空格隔开"></el-input>
                        </el-form-item>
                        <el-form-item label="周期">
                            <el-input v-model="feeWeekNo" style="width: 70%" placeholder="请用逗号或空格隔开"></el-input>
                        </el-form-item>
                        <el-button type="primary" @click="addFeesAgain">查找订单费用</el-button>
                        <!--                        <el-button type="primary" @click="addFeesAgainContainer">查找柜子费用</el-button>-->
                    </el-col>

                </el-row>
            </el-form>
            <f-table
                :columns="addColumns"
                :cell-style="{ 'text-align': 'center' }"
                :data="feesAgain"
                @selection-change="addSelectionChange"
            ></f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
            title="添加柜子费用"
            border
            :visible="addContainerFeeDialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleAddContainerFeeDialogClose">
            <el-form>
                <el-row>
                    <el-col>
                        <el-form-item label="柜号">
                            <el-input v-model="feeContainerNo" style="width: 70%" placeholder="请用逗号或空格隔开"></el-input>
                        </el-form-item>
                        <el-form-item label="周期">
                            <el-input v-model="feeWeekNo" style="width: 70%" placeholder="请用逗号或空格隔开"></el-input>
                        </el-form-item>
                        <!--                        <el-button type="primary" @click="addFeesAgain">查找订单费用</el-button>-->
                        <el-button type="primary" @click="addFeesAgainContainer">查找柜子费用</el-button>
                    </el-col>

                </el-row>
            </el-form>
            <f-table
                :columns="containerColumns"
                :cell-style="{ 'text-align': 'center' }"
                :data="feesAgain"
                @selection-change="addSelectionChange"
            ></f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination2"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                >
                </el-pagination>
            </el-row>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addContainerSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
            :title="title"
            :visible="visible"
            width="70%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose"

        >
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px">
                <!--                <el-row>-->
                <!--                    <el-col>-->
                <!--                        <el-form-item label="上传水单">-->
                <!--                            <el-upload-->
                <!--                                    class="upload-demo"-->
                <!--                                    :action="url.uploadInvoice"-->
                <!--                                    accept=".jpg,.png"-->
                <!--                                    :http-request="handleUpload"-->
                <!--                                    :on-preview="handlePicPreview"-->
                <!--                                    :before-upload="beforeUpload"-->
                <!--                                    :on-remove="handleRemove"-->
                <!--                                    :file-list="fileList"-->
                <!--                                    list-type="picture">-->
                <!--                                <el-button size="small" type="primary" v-if="!isWaitRemove">点击上传</el-button>-->
                <!--                                <div slot="tip" class="el-upload__tip" v-if="showOnly">只能上传jpg/png文件，且不超过500kb</div>-->
                <!--                            </el-upload>-->
                <!--                        </el-form-item>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
                <div class="flex_center_start">
                    <el-row style="width: 50%">
                        <el-col>
                            <el-form-item prop="payMan" label="收款人">
                                <el-input v-model="billForm.payMan" style="width: 250px"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item prop="bankId" label="供应商收款银行">
                                <el-select v-model="billForm.bankId"
                                           placeholder="根据币种选择银行"
                                           clearable
                                           filterable
                                           :father-val="billForm.bankId"
                                           :is-need="billForm.bankId"
                                           class="f-full-width"
                                           @change="changeAccountNameToPayman"
                                           :disabled="isWaitRemove"
                                           style="width: 250px">
                                    <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName"
                                               :key="item.id">
                                        {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item prop="amount" label="金ㅤ额">
                                <el-input v-model="billForm.amount" style="width: 100px"
                                          @blur="changeAmount" :disabled="ifDis"
                                          oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                                <span style="color: dimgrey">(新添加费用的金额将在提交修改后更新到申请中)</span>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item label="发票号">
                                <el-input v-model="billForm.invoiceNo" style="width: 100px"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item label="备ㅤ注">
                                <el-input v-model="billForm.invoiceRemark" style="width: 100px"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row style="width: 50%">
                        <el-col>
                            <div class="payApplyRow" v-if="sumSettlement.length > 0" style="float: left">
                                <span style="">结算概览：</span>
                                <div class="payApplyRow payApplyRowItem" v-for="(item,index) in sumSettlement"
                                     :key="index">
                                    <span style="float: left">{{ item.type }}</span>
                                    <span style="margin-left: 10px">{{ item.settlement }}</span>
                                    <span style="margin-left: 10px">{{ item.finalTotal }}</span>
                                    <span style="margin-left: 10px">{{ item.realMoneyType }}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-row>

                    <el-button plain @click="addFee" v-if="!isWaitRemove">添加订单费用</el-button>
                    <el-button plain @click="addContainerFee" v-if="!isWaitRemove">添加柜子费用</el-button>
                    <el-button plain @click="changeCurBank" v-if="showOnly">修改币种及添加账户</el-button>


                    <el-upload
                        class="upload-demo"
                        :action="url.addFeeMix"
                        :http-request="handleUploadImportSettle"
                        :before-upload="beforeUpload"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :file-list="fileList"
                        style="margin-left: 10px">
                        <el-button size="medium" type="primary"
                                   v-if="buttonShowList.a"
                        >导入对账-添加费用
                        </el-button>
                    </el-upload>

                </el-row>
<!--                使用u-table优化-->
              <!--              使用u-table 虚拟 替换 -->

<!--              <u-table-->
<!--                  ref="plTable"-->
<!--                  :data="billForm.settleList"-->
<!--                  :height="500"-->
<!--                  use-virtual-->
<!--                  :big-data-checkbox="true"-->
<!--                  showBodyOverFlow="title"-->
<!--                  showHeaderOverflow="title"-->
<!--                  :row-height="50"-->
<!--                  border-->
<!--              >-->
<!--                <u-table-column type="index" width="55" fixed/>-->
<!--                <u-table-column-->
<!--                    v-for="(item,index) in columns"-->
<!--                    :key="item.prop + index"-->
<!--                    :show-overflow-tooltip="true"-->
<!--                    :prop="item.prop"-->
<!--                    :label="item.label"-->
<!--                    :width="item.width"-->
<!--                >-->
<!--                  <template v-slot="scope">-->
<!--                    <template v-if="scope.column.property === 'action' && !isWaitRemove">-->

<!--                      &lt;!&ndash;                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">&ndash;&gt;-->
<!--                      <el-button type="text" @click="delFee(scope.row)" v-if="billForm.settleList.length > 1">删除-->
<!--                      </el-button>-->
<!--                      &lt;!&ndash;                            </el-tooltip>&ndash;&gt;-->
<!--                    </template>-->

<!--                    <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='0'">-->
<!--                      <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo);visible=false">{{-->
<!--                          scope.row.orderNo-->
<!--                        }}</a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='9'">-->
<!--                      <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                         @click="infomation(scope.row.containerId)">-->
<!--                        {{ scope.row.orderNo }}-->
<!--                      </a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'orderType'">-->
<!--                      <span v-if="scope.row.orderType==='9'">柜子费用</span>-->
<!--                      <span v-if="scope.row.orderType==='0'">海运费用</span>-->
<!--                      <span v-if="scope.row.orderType==='1'">空运费用</span>-->
<!--                      <span v-if="scope.row.orderType==='2'">快递费用</span>-->
<!--                      <span v-if="scope.row.orderType==='3'">海外仓费用</span>-->
<!--                    </template>-->


<!--                    <template v-else-if="scope.column.property === 'customer'">-->
<!--                      <a style="color:#57a3f3"-->
<!--                         @click.prevent="$intoCustomerEditPage(scope.row.cusId);visible=false">{{ scope.row.customer }}</a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'week'">-->
<!--                      <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                         @click="infomation(scope.row.containerId)">{{-->
<!--                          scope.row.week-->
<!--                        }}-->
<!--                      </a>-->
<!--                    </template>-->
<!--                    &lt;!&ndash; 其他默认渲染 &ndash;&gt;-->
<!--                    <template v-else>-->
<!--                      {{ scope.row[item.prop] }}-->
<!--                    </template>-->
<!--                  </template>-->
<!--                </u-table-column>-->
<!--              </u-table>-->
<!--              <ux-grid-->
<!--                  ref="plxTable"-->
<!--                  :height="500"-->
<!--                  :checkboxConfig="{checkMethod: selectable, highlight: true}"-->

<!--                  @table-body-scroll="scroll"-->
<!--                  show-header-overflow="ellipsis"-->
<!--              >-->
<!--                <ux-table-column type="index" width="55" fixed/>-->
<!--                <ux-table-column-->
<!--                    v-for="(item,index) in columns"-->
<!--                    :key="item.prop + index"-->
<!--                    :show-overflow-tooltip="true"-->
<!--                    :field="item.prop"-->
<!--                    :title="item.label"-->
<!--                    :width="item.width"-->
<!--                >-->
<!--                  <template v-slot="scope">-->
<!--                    <template v-if="scope.column.property === 'action' && !isWaitRemove">-->

<!--                      &lt;!&ndash;                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">&ndash;&gt;-->
<!--                      <el-button type="text" @click="delFee(scope.row)" v-if="billForm.settleList.length > 1">删除-->
<!--                      </el-button>-->
<!--                      &lt;!&ndash;                            </el-tooltip>&ndash;&gt;-->
<!--                    </template>-->

<!--                    <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='0'">-->
<!--                      <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo);visible=false">{{-->
<!--                          scope.row.orderNo-->
<!--                        }}</a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='9'">-->
<!--                      <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                         @click="infomation(scope.row.containerId)">-->
<!--                        {{ scope.row.orderNo }}-->
<!--                      </a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'orderType'">-->
<!--                      <span v-if="scope.row.orderType==='9'">柜子费用</span>-->
<!--                      <span v-if="scope.row.orderType==='0'">海运费用</span>-->
<!--                      <span v-if="scope.row.orderType==='1'">空运费用</span>-->
<!--                      <span v-if="scope.row.orderType==='2'">快递费用</span>-->
<!--                      <span v-if="scope.row.orderType==='3'">海外仓费用</span>-->
<!--                    </template>-->


<!--                    <template v-else-if="scope.column.property === 'customer'">-->
<!--                      <a style="color:#57a3f3"-->
<!--                         @click.prevent="$intoCustomerEditPage(scope.row.cusId);visible=false">{{ scope.row.customer }}</a>-->
<!--                    </template>-->
<!--                    <template v-else-if="scope.column.property === 'week'">-->
<!--                      <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                         @click="infomation(scope.row.containerId)">{{-->
<!--                          scope.row.week-->
<!--                        }}-->
<!--                      </a>-->
<!--                    </template>-->
<!--                    &lt;!&ndash; 其他默认渲染 &ndash;&gt;-->
<!--                    <template v-else>-->
<!--                      {{ scope.row[item.prop] }}-->
<!--                    </template>-->
<!--                  </template>-->
<!--                </ux-table-column>-->
<!--              </ux-grid>-->
<!--              <f-u-table-->
<!--                :table-data="billForm.settleList"-->
<!--                :columns="columns"-->
<!--                @selection-change="handleSelectionChange"-->
<!--              >-->
<!--                <template v-slot:action="{ row }" v-if="!isWaitRemove">-->
<!--                  <el-button type="text" @click="delFee(row)" v-if="billForm.settleList.length > 1">删除-->
<!--                  </el-button>-->
<!--                </template>-->
<!--                <template v-slot:orderNo="{ row }" >-->
<!--                  <a style="color: #00a0e9" v-if="row.orderType === '0'"-->
<!--                     @click.prevent="$intoDocPage(row.orderNo);-->
<!--                     visible=false">-->
<!--                    {{ row.orderNo }}-->
<!--                  </a>-->
<!--                  <a class="f-cursor-pointer" style="color: #00a0e9" v-else-if="row.orderType === '9'"-->
<!--                     @click="infomation(row.containerId)">-->
<!--                    {{ row.orderNo }}-->
<!--                  </a>-->
<!--                  <span v-else>{{ row.orderNo }}</span>-->
<!--                </template>-->
<!--                <template v-slot:orderType="{ row }">-->
<!--                  <span v-if="row.orderType==='9'">柜子费用</span>-->
<!--                  <span v-else-if="row.orderType==='0'">海运费用</span>-->
<!--                  <span v-else-if="row.orderType==='1'">空运费用</span>-->
<!--                  <span v-else-if="row.orderType==='2'">快递费用</span>-->
<!--                  <span v-else-if="row.orderType==='3'">海外仓费用</span>-->
<!--                </template>-->
<!--                <template v-slot:cutsomer="{ row }">-->
<!--                  <a style="color:#57a3f3"-->
<!--                     @click.prevent="$intoCustomerEditPage(row.cusId);visible=false">{{ row.customer }}</a>-->
<!--                </template>-->
<!--                <template v-slot:week="{ row }">-->
<!--                  <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                     @click="infomation(row.containerId)">{{ row.week }}-->
<!--                  </a>-->
<!--                </template>-->
<!--                  <template v-if="scope.column.property === 'action' && !isWaitRemove">-->

<!--                    &lt;!&ndash;                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">&ndash;&gt;-->
<!--                    <el-button type="text" @click="delFee(scope.row)" v-if="billForm.settleList.length > 1">删除-->
<!--                    </el-button>-->
<!--                    &lt;!&ndash;                            </el-tooltip>&ndash;&gt;-->
<!--                  </template>-->

<!--                  <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='0'">-->
<!--                    <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo);visible=false">{{-->
<!--                        scope.row.orderNo-->
<!--                      }}</a>-->
<!--                  </template>-->
<!--                  <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='9'">-->
<!--                    <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                       @click="infomation(scope.row.containerId)">-->
<!--                      {{ scope.row.orderNo }}-->
<!--                    </a>-->
<!--                  </template>-->
<!--                  <template v-else-if="scope.column.property === 'orderType'">-->
<!--                    <span v-if="scope.row.orderType==='9'">柜子费用</span>-->
<!--                    <span v-if="scope.row.orderType==='0'">海运费用</span>-->
<!--                    <span v-if="scope.row.orderType==='1'">空运费用</span>-->
<!--                    <span v-if="scope.row.orderType==='2'">快递费用</span>-->
<!--                    <span v-if="scope.row.orderType==='3'">海外仓费用</span>-->
<!--                  </template>-->


<!--                  <template v-else-if="scope.column.property === 'customer'">-->
<!--                    <a style="color:#57a3f3"-->
<!--                       @click.prevent="$intoCustomerEditPage(scope.row.cusId);visible=false">{{ scope.row.customer }}</a>-->
<!--                  </template>-->
<!--                  <template v-else-if="scope.column.property === 'week'">-->
<!--                    <a class="f-cursor-pointer" style="color: #00a0e9"-->
<!--                       @click="infomation(scope.row.containerId)">{{-->
<!--                        scope.row.week-->
<!--                      }}-->
<!--                    </a>-->
<!--                  </template>-->

                  <!--                        <template v-else-if="scope.column.property === 'realPay'">-->
                  <!--                            <el-form-item-->
                  <!--                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">-->
                  <!--                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" :placeholder="scope.row.exchangeTotal"></el-input>-->
                  <!--                            </el-form-item>-->

                  <!--                        </template>-->


<!--              </f-u-table>-->
                <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="billForm.settleList"
                    :cell-style="{ 'text-align': 'center' }"
                    style="margin-top: 15px"
                    @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action' && !isWaitRemove">

                            <!--                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">-->
                            <el-button type="text" @click="delFee(scope.row)" v-if="billForm.settleList.length > 1">删除
                            </el-button>
                            <!--                            </el-tooltip>-->
                        </template>

                        <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='0'">
                            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo);visible=false">{{
                                    scope.row.orderNo
                                }}</a>
                        </template>
                        <template v-else-if="scope.column.property === 'orderNo' && scope.row.orderType==='9'">
                            <a class="f-cursor-pointer" style="color: #00a0e9"
                               @click="infomation(scope.row.containerId)">
                                {{ scope.row.orderNo }}
                            </a>
                        </template>
                        <template v-else-if="scope.column.property === 'orderType'">
                            <span v-if="scope.row.orderType==='9'">柜子费用</span>
                            <span v-if="scope.row.orderType==='0'">海运费用</span>
                            <span v-if="scope.row.orderType==='1'">空运费用</span>
                            <span v-if="scope.row.orderType==='2'">快递费用</span>
                            <span v-if="scope.row.orderType==='3'">海外仓费用</span>
                        </template>


                        <template v-else-if="scope.column.property === 'customer'">
                            <a style="color:#57a3f3"
                               @click.prevent="$intoCustomerEditPage(scope.row.cusId);visible=false">{{ scope.row.customer }}</a>
                        </template>
                        <template v-else-if="scope.column.property === 'week'">
                            <a class="f-cursor-pointer" style="color: #00a0e9"
                               @click="infomation(scope.row.containerId)">{{
                                    scope.row.week
                                }}
                            </a>
                        </template>

                        <!--                        <template v-else-if="scope.column.property === 'realPay'">-->
                        <!--                            <el-form-item-->
                        <!--                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">-->
                        <!--                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" :placeholder="scope.row.exchangeTotal"></el-input>-->
                        <!--                            </el-form-item>-->

                        <!--                        </template>-->

                    </template>

                </f-table>
            </el-form>

            <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit"
                       :disabled="ifDis">确定修改</el-button>
        </span>
        </el-dialog>

        <reconciliation-import-settle-dialog
            ref="reconciliationImportSettleDialog"></reconciliation-import-settle-dialog>
    </div>
</template>

<script>
import {getAction, postAction} from '@/api/manage'
import {setUpRouteUtil} from '@/utils/util';
// import QueryMixins from "../../../../mixins/QueryMixins";
import reconciliationImportSettleDialog from "@/views/hyFinanceManage/modules/pay/reconciliationImportSettleDialog";

export default {
    name: 'receiveApply',
    // mixins: [QueryMixins],
    components: {reconciliationImportSettleDialog},

    data() {
        return {
          detailLoading:false,
            // id:'',
            addColumns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "orderNo",
                    label: "工作单号",
                },
                {
                    prop: "realAgent",
                    label: "客户",
                },
                {
                    prop: "agent",
                    label: "结算对象",
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "feenameUs",
                    label: "费用英文",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合金额",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "screateTime",
                    label: "录入时间",
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                },

            ],
            addFeeDialogVisible: false,
            addContainerFeeDialogVisible: false,
            loading: false,
            right: 'right',
            remark: '',
            imgUrl: '',
            labelPosition: 'right',
            flag: false,
            visible: false,
            changeCurVisible: false,
            isChecked: true,
            ifDis: false,
            allKeys: [],
            fileList: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            billForm: {
                billType: 'noConvert',
                billTitle: '',
                payMan: '',
                amount: '',//实付
                remark: '',
                ids: [],
                orderNos: [],
                cur: '',//更改的币种
                bankId: '',
                settleList: [],
                uploadFileIds: [],//上传文件的id，保存时增加申请单号
                invoiceNo: '00000000',
                invoiceRemark: ''
            },
            url: {
                list: "/shipping/hySettlePay/waitPayApplyList",
                containerFeeList: "/shipping/hySettlePay/containerFeeList",
                deleteBatch: '/sys/bdCountry/deleteBatch',
                openBill: '/file/jasper/openReceiveBill',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                curGetBank: '/shipping/hySettleReceive/curGetBank',
                updatePayApply: '/shipping/hySettlePay/updatePayApply',
                getSettlesByApplyNo: "/shipping/hySettlePay/getPaySettlesByApplyNo",
                uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                curGetContactBank: '/shipping/hySettlePay/curGetContactBank',
                delFile: '/shipping/hySettlePay/delFile',
                addFeeMix: '/shipping/hySettlePay/importPaySettleMix',
            },
            rules: {
                payMan: [
                    {required: true, message: '请输入付款人', trigger: 'blur'},
                ],
                amount: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                realPay: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                realMoneyType: [
                    {required: true, message: '请选择币种', trigger: 'blur'},
                ],
                bankId: [
                    {required: true, message: '请选择银行', trigger: 'blur'},
                ],


            },
            title: '收款申请',
            settleIds: [],
            showOnly: true,
            noChoose: [],
            columns: [
                {
                    label: "操作",
                    prop: "action",
                    width: 50,
                },
                {
                    prop: "createTime",
                    label: "录入时间",
                },
                {
                    prop: "orderType",
                    label: "类型",
                },
                {
                    prop: "orderNo",
                    label: "单号",
                },
                {
                    prop: "week",
                    label: "周期",
                    width: 150,
                },
                {
                    prop: "containerNo",
                    label: "柜号",
                },
                {
                    prop: "settlementStr",
                    label: "结算对象",
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "englishName",
                    label: "费用英文",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "remark",
                    label: "备注",
                },


            ],
            containerColumns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "createTime",
                    label: "录入时间",
                },
                {
                    prop: "week",
                    label: "周期",
                    width: 150,
                },
                {
                    prop: "containerNo",
                    label: "柜号",
                },
                {
                    prop: "settlementStr",
                    label: "结算对象",
                },
                {
                    prop: "chineseName",
                    label: "费用中文",
                },
                {
                    prop: "englishName",
                    label: "费用英文",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "remark",
                    label: "备注",
                },


            ],
            selectionRows: [],
            addSelectRows: [],
            banks: [],
            feesAgain: [],
            feeOrderNo: '',
            feeContainerNo: '',
            feeWeekNo: '',
            ipagination: {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
          ipagination2: {
            currentPage: 1,
            pageSize: 50,
            pageSizes: [10, 20, 50, 100],
            total: 0
          },
            currentSettlementId: '',//当前在页面上的结算对象id
            currentSettleIds: [],//当前在页面显示的费用id
            currentContainerSettleIds:[],//当前在页面显示的柜子费用id
            isWaitRemove: true,
            applyNo: '',
            sumSettlement: [],
            agentPayType:'',
          scrollTop:0,
        }
    },
    methods: {
      scroll ({scrollTop, scrollLeft}) {
        this.scrollTop = scrollTop
      },
      selectable ({row}) {
        return row.id !== 2
      },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD") !== -1) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            } else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        infomation(id) {
            // 对于路由不在左侧菜单中的选中菜单处理
            setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
            this.$router.push("/operation/cabinet/Edit?id=" + id);
            this.visible = false

        },
        //上传文件到oss
        handleUpload(data) {
            // if (data.file.name.length> 20){
            //     this.$message.warning("文件名过长，自动截取前50个字符");
            //     let index = data.file.name.lastIndexOf(".");
            //     let suffix = data.file.name.substr(index+1);
            //     console.log(suffix)
            //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
            // }

            const formData = new FormData();
            formData.append("file", data.file);

            postAction(this.url.uploadInvoice, formData).then(res => {
                // console.log(res)
                if (res.success) {
                    //上传成功,返回结果赋值给form对象的文件集合
                    this.billForm.uploadFileIds.push(res.result.osOrderFile.id);
                    // console.log(this.billForm.uploadFileIds)
                    this.fileList.push(res.result.osOrderFile);
                    this.$message.success('上传成功');
                } else {

                }
            })
        },
        beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
                this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
                this.$message.warning("该文件名不合法");
            }
            const len = file.name.length < 100;
            if (!len) {
                this.$message.warning("该文件名太长，请修改文件名最多100个字符");
            }
            return isLt2M && isFile && len;
        },
        changeAmount() {
            let sumPay = 0;
            this.billForm.settleList.forEach(item => sumPay = Number(sumPay) + Number(item.total));
            this.billForm.amount = sumPay.toFixed(3);
        },
        toS(v) {
            return String(v)
        },
        delFee(row) {
            // console.log(row)
            this.billForm.settleList.splice(this.billForm.settleList.indexOf(row), 1);
            //判断是柜子还是订单的费用
            if(row.orderType!=='9'){
                this.currentSettleIds.splice(this.currentSettleIds.indexOf(row.id), 1);
            }else{
                this.currentContainerSettleIds.splice(this.currentSettleIds.indexOf(row.id), 1);
            }
            // this.$refs.plxTable.reloadData(this.billForm.settleList);
            // console.log('currentSettleIds',this.currentSettleIds)
            // console.log('currentContainerSettleIds',this.currentContainerSettleIds)
            this.changeAmount()

        },
        changeCurBank() {
            this.changeCurVisible = true;
        },
        curChangeBank(cur, settleId) {
            // console.log('curChangeBank')
            this.banks = [];
            // this.billForm.bankId = ''
            getAction(this.url.curGetContactBank, {cur: cur, contactId: settleId}).then((res) => {
                if (res.result.length === 0) {
                    this.$message.warning("此供应商在该币种下无相应账号！")

                } else {
                    // console.log(this.banks)
                    this.banks = res.result
                    // this.billForm.bankId = ''
                }

            })
        },
        changeAccountNameToPayman() {
            let oneBank = this.banks.filter(i => {
                return i.id === this.billForm.bankId;
            })
            // this.billForm.payMan = oneBank[0].accountName
            // console.log('oneBank[0].accountName', oneBank[0].accountName)
            this.$set(this.billForm, 'payMan', oneBank[0].accountName)
        },

        addSelectionChange(selection) { // 多选
            // console.log(selection)
            this.addSelectRows = selection;
        },
        addFee() {

            //获取当前结算对象的相关费用
            this.feesAgain = [];
            this.feeOrderNo = ''
            let obj = {
                settlementId: this.currentSettlementId,
                currentSettleIds: this.currentSettleIds,
                moneyType: this.billForm.cur
            }

            // console.log(obj)
            postAction(this.url.list, {
                settlementId: this.currentSettlementId,
                currentSettleIds: this.currentSettleIds,
                moneyType: this.billForm.cur,
                orderType:'0'
            }).then((res) => {
                // console.log(res)
                if (res.result.records.length > 0) {
                    this.feesAgain = res.result.records
                    this.addFeeDialogVisible = true;
                    this.ipagination.currentPage = res.result.current;
                    this.ipagination.pageSize = res.result.size;
                    this.ipagination.total = res.result.total;
                } else {
                    this.$message.warning("无相同结算对象或币种的费用")
                    this.addFeeDialogVisible = false;
                }
            })
        },
        addContainerFee() {

            //获取当前结算对象的相关费用
            this.feesAgain = [];
            this.feeOrderNo = ''
            let obj = {
                settlementId: this.currentSettlementId,
                // currentSettleIds: this.currentSettleIds,
                currentContainerSettleIds: this.currentContainerSettleIds,
                moneyType: this.billForm.cur
            }

            // console.log(obj)
            postAction(this.url.containerFeeList, {
                settlementId: this.currentSettlementId,
                currentContainerSettleIds: this.currentContainerSettleIds,
                moneyType: this.billForm.cur,
              pageNo:this.ipagination2.currentPage,
              pageSize:this.ipagination2.pageSize
            }).then((res) => {
                // console.log(res)
                if (res.result.records.length > 0) {
                    this.feesAgain = res.result.records
                    this.addContainerFeeDialogVisible = true;
                    this.ipagination2.currentPage = res.result.current;
                    this.ipagination2.pageSize = res.result.size;
                    this.ipagination2.total = res.result.total;
                } else {
                    this.$message.warning("无相同结算对象或币种的费用")
                    // this.addContainerFeeDialogVisible = false;
                }
            })
        },
        addSubmit() {
            // this.settleList.concat(this.addSelectRows)
            // this.addSelectRows = []
            for (let i = 0; i < this.addSelectRows.length; i++) {
                this.addSelectRows[i].customer = this.addSelectRows[i].realAgent
                this.addSelectRows[i].settlementStr = this.addSelectRows[i].agent
                this.addSelectRows[i].englishName = this.addSelectRows[i].feenameUs

                this.billForm.settleList.push(this.addSelectRows[i])
                this.currentSettleIds.push(this.addSelectRows[i].id)//判断当前申请费用的id 用作排除添加费用时已用的费用
                this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
            }
            this.addFeeDialogVisible = false;
            this.addSelectRows = []

        },
        addContainerSubmit() {
            // this.settleList.concat(this.addSelectRows)
            // this.addSelectRows = []
            for (let i = 0; i < this.addSelectRows.length; i++) {
                this.addSelectRows[i].customer = this.addSelectRows[i].realAgent
                this.addSelectRows[i].feename = this.addSelectRows[i].chineseName
                this.billForm.settleList.push(this.addSelectRows[i])
                this.currentContainerSettleIds.push(this.addSelectRows[i].id)//判断当前申请费用的id 用作排除添加费用时已用的费用
                this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
            }
            this.changeAmount();
            this.addContainerFeeDialogVisible = false;
            this.addSelectRows = []


        },
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.addFeesAgain()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.addFeesAgain()
        },
      handleSizeChange2(val) {
        this.ipagination2.currentPage = 1
        this.ipagination2.pageSize = val
        this.addContainerFee()
      },
      handleCurrentChange2(val) {
        this.ipagination2.currentPage = val
        this.addContainerFee()
      },
        addFeesAgain() {
            let orderNo = JSON.parse(JSON.stringify(this.feeOrderNo));
            let containerNo = JSON.parse(JSON.stringify(this.feeContainerNo));
            let weekNo = JSON.parse(JSON.stringify(this.feeWeekNo));
            if (orderNo.trim() === '') {
                orderNo = null
            }
            postAction(this.url.list, {
                orderNo: orderNo,
                containerNo: containerNo,
                weekNum: weekNo,
                settlementId: this.currentSettlementId,
                currentSettleIds: this.currentSettleIds,
                moneyType: this.billForm.cur,
                pageNo: this.ipagination.currentPage,
                pageSize: this.ipagination.pageSize,
                orderType:'0'
            }).then((res) => {
                // console.log(res)
                if (res.result.records.length > 0) {
                    this.feesAgain = res.result.records
                    this.addFeeDialogVisible = true;
                    this.ipagination.currentPage = res.result.current;
                    this.ipagination.pageSize = res.result.size;
                    this.ipagination.total = res.result.total;
                } else {
                    this.$message.warning("无相同结算对象或币种的费用")
                    this.addFeeDialogVisible = false;
                }
            })
        },
        addFeesAgainContainer() {
            // console.log('currentSettlementId', this.currentSettlementId)
            let containerNo = JSON.parse(JSON.stringify(this.feeContainerNo));
            let weekNo = JSON.parse(JSON.stringify(this.feeWeekNo));
            postAction(this.url.containerFeeList, {
                containerNo: containerNo,
                weekNum: weekNo,
                settlementId: this.currentSettlementId,
                // currentSettleIds: this.currentSettleIds,
                currentContainerSettleIds: this.currentContainerSettleIds,
                moneyType: this.billForm.cur,
                pageNo: this.ipagination.currentPage,
                pageSize: this.ipagination.pageSize,
            }).then((res) => {
                // console.log(res)
                if (res.result.records.length > 0) {
                    this.feesAgain = res.result.records
                    this.addContainerFeeDialogVisible = true;
                    this.ipagination.currentPage = res.result.current;
                    this.ipagination.pageSize = res.result.size;
                    this.ipagination.total = res.result.total;
                } else {
                    this.$message.warning("无相关费用")
                    // this.addContainerFeeDialogVisible = false;
                }
            })
        },
        changeCurSubmit() {
            // console.log(this.billForm)
            // console.log(this.billForm.settleList)
            // 修改原tableData中的币种和银行
            this.billForm.settleList.forEach(data => {
                data.realMoneyType = this.billForm.cur
                data.bank = this.billForm.bank
            })


            this.curBankDialogClose()
        },
        handleSubmit() {

            this.changeAmount();
            // if (Number(this.billForm.amount) === 0) {
            //     this.$message.warning("金额不能为零！")
            //     return;
            // }
            // console.log(this.billForm)
            this.$refs.billForm.validate((valid) => {
                if (valid) {
                    postAction(this.url.updatePayApply, this.billForm).then((res) => {
                        // console.log(res)
                        if (res.success === true) {
                            this.visible = false
                            this.$emit('ok')
                        }
                    })
                }
            })
        },
        handleClose() {
            // this.$refs.form.resetFields()
            this.visible = false;

        },
        curBankDialogClose() {
            // this.$refs.form.resetFields()
            this.changeCurVisible = false;

        },
        handleAddFeeDialogClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.addFeeDialogVisible = false;

        },
        handleAddContainerFeeDialogClose() {
            this.addContainerFeeDialogVisible = false;
        },
        enabled() {
            setTimeout(() => {
                this.ifDis = false;
            }, 2000)
        },
        edit(selectionRows, arr, columns) {
            // console.log("申请付款的selectionRows", selectionRows)
            this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
            this.noChoose = JSON.parse(JSON.stringify(arr));
            this.addColumns = columns;
            this.showOnly = true;
            this.title = '付款申请'
            this.visible = true
        },
        showDetail(row) {
          this.detailLoading = true;
            // console.log('申请对象柜子->', row);
            this.billForm.settleList = []
            if (row.checkStatus !== '0') {
                this.isWaitRemove = true
            } else {
                this.isWaitRemove = false
            }
            // console.log('isWaitRemove->', this.isWaitRemove);

            let settleId = row.settleId
            this.applyNo = row.applyNo
            // this.currentSettlementId = row.settlement
            this.currentSettlementId = row.settleId
            getAction(this.url.getSettlesByApplyNo, {applyNo: row.applyNo}).then((res) => {
              this.detailLoading = false;
                // console.log(res)
                this.currentSettleIds = [];
                this.currentContainerSettleIds = [];

                this.sumSettlement = [];
                let feeRows = JSON.parse(JSON.stringify(res.result.containerSettleList))
                let settlementArr = [];
                let settlements = [];
                let orderTypeArr = [];
                let orderTypes = [];
                feeRows.forEach(fee => {
                    // console.log(fee.orderType)
                    if(fee.orderType!=='9'){
                        //除了柜子类型的费用
                        this.currentSettleIds.push(fee.id)
                    }else{
                        this.currentContainerSettleIds.push(fee.id)
                    }
                    orderTypeArr.push(fee.orderType)
                    settlementArr.push(fee.settlementStr)
                })
                orderTypes = Array.from(new Set(orderTypeArr))
                settlements = Array.from(new Set(settlementArr))
                orderTypes.forEach(type => {
                    let feesByType = feeRows.filter(row => {
                        return row.orderType === type
                    })
                    settlements.forEach(settlement => {
                        let finalFee = feesByType.filter(fee => {
                            return fee.settlementStr === settlement
                        })
                        let total = 0
                        finalFee.forEach(f => {
                            total = f.total + total
                        })
                        if (type === '0') {
                            type = '海运'
                        } else if (type === '1') {
                            type = '空运'
                        } else if (type === '2') {
                            type = '快递'
                        } else if (type === '9') {
                            type = '柜子'
                        }
                        this.sumSettlement.push({
                            type: type,
                            settlement: settlement,
                            finalTotal: total.toFixed(2),
                            realMoneyType: finalFee[0].moneyType
                        })
                    })
                })
                let lastTotal = 0
                this.sumSettlement.forEach(s => {
                    lastTotal = (Number(lastTotal) + Number(s.finalTotal)).toFixed(2)
                })
                this.sumSettlement.push({
                    type: '合计',
                    finalTotal: lastTotal,
                    realMoneyType: this.sumSettlement[0].realMoneyType
                })
                this.feesAgain = []
                this.billForm.applyNo = row.applyNo;
                this.billForm.settleList = feeRows;
                this.billForm.payMan = res.result.payMan;
                this.billForm.amount = res.result.amount;
                this.billForm.cur = feeRows[0].moneyType;
                // this.billForm.settlementId = settleId;
                this.billForm.invoiceRemark = res.result.invoiceRemark;
                this.billForm.invoiceNo = res.result.invoiceNo;
                // this.fileList = res.result.uploadedFiles;

                // settleId = res.result.settleList[0].settleId
              // this.$refs.plxTable.reloadData(this.billForm.settleList)
            })
            this.title = '柜子费用申请'
            this.showOnly = false;
            this.visible = true
            this.curChangeBank(row.payCur, settleId)
            this.$nextTick(() => {

                // console.log('row.settleAccountId', row.settleAccountId)

                // this.billForm.bankId = row.settleAccountId
                this.$set(this.billForm, 'bankId', row.settleAccountId)
                // console.log('this.billForm.bankId', this.billForm.bankId)
            });

        },


        handleRemove(file, fileList) {
            this.fileList.map((item, index) => {
                if (item.uid == file.uid) {
                    //向服务器发送要删除的文件的路径，删除oss上的文件
                    // console.log(item)
                    postAction(this.url.delFile, {osOrderFileId: item.id, url: item.url}).then((res) => {
                        // console.log(res)
                        //页面的上的文件也要删除
                        this.fileList.splice(index, 1)
                        this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id), 1)
                    })

                }
            })

        },
        handlePicPreview(file) {
            // console.log(file)
            // console.log('新窗口打开')
            window.open(file.url, '_blank')
        },
        handleSelectionChange(selection) { // 多选
            // console.log(selection)
        },
        handleUploadImportSettle(data) {

            const formData = new FormData();
            formData.append("file", data.file);
            // console.log('importApplyNo', this.applyNo)
            formData.append("applyNo", this.applyNo);

            postAction(this.url.addFeeMix, formData).then(res => {
                // console.log(res)
                if (res.result.success) {
                    //上传成功,返回结果赋值给form对象的文件集合
                    // this.billForm.uploadFileIds.push(res.result.id);
                    // console.log(this.billForm.uploadFileIds)
                    // this.fileList.push(res.result);
                    this.$message.success('上传成功');
                    this.fileList = []
                    if (res.result.result.errorResult !== null) {
                        // this.$refs.reconciliation.edit(res.result.result.errorResult,'0');
                        this.$refs.reconciliationImportSettleDialog.edit(res.result.result, this.applyNo);
                    } else {
                        this.$refs.reconciliationImportSettleDialog.editNoProblem(res.result.result, this.applyNo);
                    }
                } else {
                    this.$message.error(res.result.message);
                }
            })
        },
    },
  computed:{
      buttonShowList(){
        return {
          'a':this.$btnIsShow('hyFinanceManage/modules/pay/waitApplyPay','0','导入对账-添加费用'),
        }
      }
  },
  mounted() {
      const showDetailDatas = [];
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}

.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}

</style>
