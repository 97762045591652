<template>
    <div class="main-full-content">

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="结算对象">
                            <el-input v-model.trim="queryForm.settlement" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.moneyType" :is-need="queryForm.moneyType"
                                dict="bd_cur"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="费用名">
                            <f-select v-model="queryForm.feeId" :dict="'bd_fee_type'"
                                :is-need="queryForm.feeId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录入人">
                            <el-input v-model.trim="queryForm.createBy" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="录入时间">
                            <div class="f-flex">
                                <el-date-picker class="f-flex-1" v-model="queryForm.sCreateTimeS" type="datetime"
                                    size="small" placeholder="开始时间" default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker class="f-flex-1" v-model="queryForm.sCreateTimeE" type="datetime"
                                    size="small" placeholder="结束时间" default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="周期数">
                            <el-input v-model="queryForm.weekNum" type="textarea" class="textarea_table"
                                @keyup.enter.native="handleCheck" placeholder="请用逗号隔开"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="柜号">
                            <el-input type="textarea" v-model.trim="queryForm.containerNo"
                                @keyup.enter.native="handleCheck" placeholder="请用逗号隔开"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否关账">
                            <f-select v-model="queryForm.isCloseAccount" :isNeed="queryForm.isCloseAccount"
                                :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="关账时间">
                            <div class="f-flex">
                                <el-date-picker class="f-flex-1" v-model="queryForm.closeTimeS" type="datetime"
                                    size="small" placeholder="开始时间" default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker class="f-flex-1" v-model="queryForm.closeTimeE" type="datetime"
                                    size="small" placeholder="结束时间" default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <div class="f-flex">
                                <el-date-picker class="f-flex-1" v-model="queryForm.createTimeS" type="datetime"
                                    size="small" placeholder="开始时间" default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker class="f-flex-1" v-model="queryForm.createTimeE" type="datetime"
                                    size="small" placeholder="结束时间" default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <div class="f-flex">
                                <el-date-picker class="f-flex-1" v-model="queryForm.boatTimeS" type="datetime" size="small"
                                    placeholder="开始时间" default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker class="f-flex-1" v-model="queryForm.boatTimeE" type="datetime" size="small"
                                    placeholder="结束时间" default-time="23:59:59" value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="数据时间">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.dataCreateTime"
                                    :is-need="queryForm.dataCreateTime"
                                    :dict="'data_create_time'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button type="primary" icon="el-icon-search" @click="handleCheck">查询
                            </el-button>
                            <el-button plain @click="handleResetCheck()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="closeAccountBatch('1')"
                    v-if="buttonShowList.pljsgz">批量解锁关账</el-button>
                <el-button size="medium" type="primary" @click="closeAccountBatch('0')"
                    v-if="buttonShowList.plgz">批量关账</el-button>
            </div>
            <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="56vh"
                :isRecordTableScroll="true" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.containerId)">{{scope.row.week }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'isCloseAccount'">
                        <span>{{scope.row.isCloseAccount == '1' ? '是':'否'}}</span>
                    </template>
                    
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import { getAction, postAction } from "@/api/manage";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        mixins: [QueryMixins],
        components: {
        },
        data() {
            return {
                isUsePost: true,
                disableMixinMounted: true,
                columns: [
                    {
                        prop: "createTime",
                        label: "录入时间",
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: "week",
                        label: "周期",
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: "settlementStr",
                        label: "结算对象",
                        width: 140,
                    },
                    {
                        prop: "chineseName",
                        label: "费用中文",
                    },
                    {
                        prop: "englishName",
                        label: "费用英文",
                    },
                    {
                        prop: "moneyType",
                        label: "费用币种",
                    },
                    {
                        prop: "total",
                        label: "合计",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },
                    {
                        prop: "isCloseAccount",
                        label: "是否关账",
                    },
                    {
                        prop: "closeTime",
                        label: "关账时间",
                        width: 140,
                    },
                    {
                        prop: "etd",
                        label: "开船时间",
                        width: 140,
                    },
                    {
                        prop: "orderTime",
                        label: "下单时间",
                        width: 140,
                    },
                ],
                url: {
                    list: "/shipping/hySettleReceive/ContainerList",
                    closeContainerSettleAccount: "/shipping/hySettleReceive/closeContainerSettleAccount",
                    unlockContainerSettleAccount: "/shipping/hySettleReceive/unlockContainerSettleAccount",
                    listNum: "/shipping/hySettleReceive/countList",

                },
            };
        },
        computed: {
          buttonShowList(){
            return {
              'pljsgz':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','批量解锁关账'),
              'plgz':this.$btnIsShow('hyFinanceManage/modules/allSettles','0','批量关账'),
            }
          }
        },
        created() {
            this.queryForm.dataCreateTime = '3';
        },
        methods: {
            closeAccountBatch(val) {
                if (!this.checkQuery()) {
                    return
                }
                this.$store.commit("setFullscreenLoading", true);
                let form = { ...this.queryForm, ...this.filterForm, isCloseAccount: val };
                postAction(this.url.listNum, form).then((res) => {
                    this.$store.commit("setFullscreenLoading", false);
                    if (res.success) {
                        this.$confirm(`当前查询条件下共有${res.result}条数据，请确认是否${val == '1' ? '解锁关账' : '关账'}`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            closeOnClickModal: false,
                        })
                            .then(() => {
                                let url = val == '1' ? this.url.unlockContainerSettleAccount : this.url.closeContainerSettleAccount;
                                this.$store.commit("setFullscreenLoading", true);
                                postAction(url, form).then((res) => {
                                    this.$store.commit("setFullscreenLoading", false);
                                    if (res.success) {
                                        this.$message({
                                            type: 'success',
                                            message: res.message
                                        })
                                    }
                                }).catch(err => {
                                    this.$store.commit("setFullscreenLoading", false);
                                });
                            })
                            .catch(() => {
                            });
                    }
                }).catch(err => {
                    this.$store.commit("setFullscreenLoading", false);
                });
            },
            handleCheck() {
                if (!this.checkQuery()) {
                    return
                }
                this.handleQuery();
            },
            handleResetCheck() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.tableData = [];
            },
            checkQuery() {
                let isValue = false;
                if (this.queryForm && JSON.stringify(this.queryForm) !== '{}') {
                    for (const key in this.queryForm) {
                        if (key && this.queryForm[key]) {
                            isValue = true;
                            break;
                        }
                    }
                }
                !isValue && this.$message.warning("查询条件不能为空");
                console.log(isValue, 'isValue')
                return isValue;
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
        },
    };
</script>
<style scoped>
    .el-button {
        user-select: unset;
    }
</style>